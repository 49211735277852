import React from 'react'

function Employees_Exit() {
  return (
    <div>
      Employees_Exit
Employees_Exit
    </div>
  )
}

export default Employees_Exit
