import React from 'react'

function Transfer_List() {
  return (
    <div>
Transfer_List
Transfer_List
    </div>
  )
}

export default Transfer_List
