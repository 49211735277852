import React from 'react'

function Event_Types() {
  return (
    <div>
      Event_Types
Event_TypesEvent_Types
Event_Types
    </div>
  )
}

export default Event_Types
