import React from 'react'

function Company_Reports() {
  return (
    <div>
      Company_Reports
    </div>
  )
}

export default Company_Reports
