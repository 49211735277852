import React from 'react'

function Currency() {
  return (
    <div>
      Currency
Currency
    </div>
  )
}

export default Currency
