import React from 'react'

function Travel_Arrangement_Type() {
  return (
    <div>
Travel_Arrangement_Type
Travel_Arrangement_Type
    </div>
  )
}

export default Travel_Arrangement_Type
