import React from 'react'

function Assign_assert() {
  return (
    <div>
      Assign_assert
Assign_assert
    </div>
  )
}

export default Assign_assert
