export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// derbe action types 
// list of all actions in the total project and each page 


export const  HRM_EMPLOYEES = "HRM_EMPLOYEES";
export const  HRM_EMPLOYEES_LIST = "HRM_EMPLOYEES_LIST";
export const  HRM_ROLES_PRIVILEGES = "HRM_ROLES_PRIVILEGES";
export const  HRM_SHIFT_SCHEDULING = "HRM_SHIFT_SCHEDULING";
export const  HRM_DEPARTMENT = "HRM_DEPARTMENT";
export const  HRM_DEPARTMENT_LIST = "HRM_DEPARTMENT_LIST";
export const  HRM_DESIGNATION_LIST = "HRM_DESIGNATION_LIST";
export const  HRM_RESIGNATIONS = "HRM_RESIGNATIONS";
export const  HRM_CREATE_RESIGNATIONS = "HRM_CREATE_RESIGNATIONS";
export const  HRM_APPROVE_RESIGNATIONS = "HRM_APPROVE_RESIGNATIONS";
export const  HRM_RESIGNATION_LIST = "HRM_RESIGNATION_LIST";
export const  HRM_EMPLOYEES_EXIT = "HRM_EMPLOYEES_EXIT";
export const  HRM_EXIT_TYPES = "HRM_EXIT_TYPES";
export const  HRM_EXIT_CLEARANCE_CERTIFICATES = "HRM_EXIT_CLEARANCE_CERTIFICATES";
export const  HRM_TRANSFERS = "HRM_TRANSFERS";
export const  HRM_TRANSFER_LIST = "HRM_TRANSFER_LIST";
export const  HRM_APPROVE_TRANSFERS = "HRM_APPROVE_TRANSFERS";
export const  HRM_NEW_TRANSFERS = "HRM_NEW_TRANSFERS";
export const  HRM_COMPLAINTS = "HRM_COMPLAINTS";
export const  HRM_LODGE_COMPLAINT = "HRM_LODGE_COMPLAINT";
export const  HRM_COMPLAINT_LIST = "HRM_COMPLAINT_LIST";
export const  HRM_RESOLVED_LIST = "HRM_RESOLVED_LIST";
export const  HRM_AWARDS = "HRM_AWARDS";

export const  HRM_AWARD_ASSIGN_LIST = "HRM_AWARD_ASSIGN_LIST";
export const  HRM_AWARD_TYPE = "HRM_AWARD_TYPE";
export const  HRM_ACTIVITIES = "HRM_ACTIVITIES";
export const  HRM_TRAVELS = "HRM_TRAVELS";
export const  HRM_NEW_BUSINESS_TRAVEL = "HRM_NEW_BUSINESS_TRAVEL";
export const  HRM_TRAVEL_ARRANGEMENT_TYPE = "HRM_TRAVEL_ARRANGEMENT_TYPE";
export const  HRM_TRANSPORT_TYPE = "HRM_TRANSPORT_TYPE";
export const  HRM_PAYMENTS = "HRM_PAYMENTS";
export const  HRM_TRAVEL_MANAGEMENT = "HRM_TRAVEL_MANAGEMENT";
export const  HRM_EVENTS = "HRM_EVENTS";
export const  HRM_NEW_EVENT = "HRM_NEW_EVENT";
export const  HRM_EVENT_TYPES = "HRM_EVENT_TYPES";
export const  HRM_HOLIDAYS = "HRM_HOLIDAYS";
export const  HRM_NEW_HOLIDAY = "HRM_NEW_HOLIDAY";
export const  HRM_HOLIDAY_LIST = "HRM_HOLIDAY_LIST";
export const  HRM_VISITORS = "HRM_VISITORS";
export const  HRM_NEW_VISITOR = "HRM_NEW_VISITOR";
export const  HRM_VISITORS_LIST = "HRM_VISITORS_LIST";
export const  HRM_CONFERENCES = "HRM_CONFERENCES";
export const  HRM_CONF_BOOKING = "HRM_CONF_BOOKING";
export const  HRM_MEETING_LIST = "HRM_MEETING_LIST";
export const  HRM_DOCUMENTS_MANAGER = "HRM_DOCUMENTS_MANAGER";
export const  HRM_GENERAL = "HRM_GENERAL";
export const  HRM_GENERAL_UPLOADING_FILES = "HRM_GENERAL_UPLOADING_FILES";
export const  HRM_GENERAL_MANAGING_FILES = "HRM_GENERAL_MANAGING_FILES";
export const  HRM_OFFICIAL = "HRM_OFFICIAL";
export const  HRM_OFFICIAL_UPLOADING_FILES = "HRM_OFFICIAL_UPLOADING_FILES";
export const  HRM_OFFICIAL_MANAGING_FILES = "HRM_OFFICIAL_MANAGING_FILES";
export const  HRM_PROCESS_DOCUMENTS = "HRM_PROCESS_DOCUMENTS";
export const  HRM_UPLOADING_FILES = "HRM_UPLOADING_FILES";
export const  HRM_MANAGING_FILES = "HRM_MANAGING_FILES";
export const  HRM_PERFORMANCE_PMS = "HRM_PERFORMANCE_PMS";
export const  HRM_KPI_INDICATORS = "HRM_KPI_INDICATORS";
export const  HRM_KPA_APPRAISALS = "HRM_KPA_APPRAISALS";
export const  HRM_OKRS_TRACKING_GOALS = "HRM_OKRS_TRACKING_GOALS";
export const  HRM_COMPETENCIES = "HRM_COMPETENCIES";
export const  HRM_TECHNICAL = "HRM_TECHNICAL";
export const  HRM_ORGANIZATIONAL = "HRM_ORGANIZATIONAL";
export const  HRM_GOAL_TYPES = "HRM_GOAL_TYPES";
export const  HRM_GOAL_CALENDAR = "HRM_GOAL_CALENDAR";
export const  HRM_RECRUITMENT_ATS = "HRM_RECRUITMENT_ATS";
export const  HRM_NEW_POSTINGS = "HRM_NEW_POSTINGS";
export const  HRM_CANDIDATE_LIST = "HRM_CANDIDATE_LIST";
export const  HRM_INTERVIEWS = "HRM_INTERVIEWS";

export const  HRM_INTERVIEWERS_LIST = "HRM_INTERVIEWERS_LIST";
export const  HRM_ATTENDANCE = "HRM_ATTENDANCE";

export const  HRM_MANUAL_ATTENDANCE = "HRM_MANUAL_ATTENDANCE";
export const  HRM_MONTHLY_REPORT = "HRM_MONTHLY_REPORT";
export const  HRM_OVERTIME_REQUEST = "HRM_OVERTIME_REQUEST";
export const  HRM_POLICIES = "HRM_POLICIES";
export const  HRM_CREATE_NEW_POLICY = "HRM_CREATE_NEW_POLICY";
export const  HRM_POLICY_LIST = "HRM_POLICY_LIST";
export const  HRM_MAKE_ANNOUNCEMENT = "HRM_MAKE_ANNOUNCEMENT";
export const  HRM_NEW_ANNOUNCEMENT = "HRM_NEW_ANNOUNCEMENT";
export const  HRM_ANNOUNCEMENT_LIST = "HRM_ANNOUNCEMENT_LIST";
export const  HRM_ORGANISATION_CHART = "HRM_ORGANISATION_CHART";
export const  HRM_VIEW = "HRM_VIEW";
export const  HRM_CREATE_CHART = "HRM_CREATE_CHART";
export const  HRM_LEAVE_REQUESTS = "HRM_LEAVE_REQUESTS";
export const  HRM_MANAGE_LEAVES = "HRM_MANAGE_LEAVES";
export const  HRM_LEAVES_TYPE = "HRM_LEAVES_TYPE";
export const  HRM_SETTINGS = "HRM_SETTINGS";
export const  HRE_EMPLOYEES = "HRE_EMPLOYEES";
export const  HRE_EMPLOYEES_LIST = "HRE_EMPLOYEES_LIST";
export const  HRE_ROLES_PRIVILEGES = "HRE_ROLES_PRIVILEGES";
export const  HRE_SHIFT_SCHEDULING = "HRE_SHIFT_SCHEDULING";
export const  HRE_DEPARTMENT = "HRE_DEPARTMENT";
export const  HRE_DEPARTMENT_LIST = "HRE_DEPARTMENT_LIST";
export const  HRE_DESIGNATION_LIST = "HRE_DESIGNATION_LIST";
export const  HRE_RESIGNATIONS = "HRE_RESIGNATIONS";
export const  HRE_CREATE_RESIGNATIONS = "HRE_CREATE_RESIGNATIONS";
export const  HRE_APPROVE_RESIGNATIONS = "HRE_APPROVE_RESIGNATIONS";
export const  HRE_RESIGNATION_LIST = "HRE_RESIGNATION_LIST";
export const  HRE_EMPLOYEES_EXIT = "HRE_EMPLOYEES_EXIT";
export const  HRE_EXIT_TYPES = "HRE_EXIT_TYPES";
export const  HRE_EXIT_CLEARANCE_CERTIFICATES = "HRE_EXIT_CLEARANCE_CERTIFICATES";
export const  HRE_TRANSFERS = "HRE_TRANSFERS";
export const  HRE_TRANSFER_LIST = "HRE_TRANSFER_LIST";
export const  HRE_APPROVE_TRANSFERS = "HRE_APPROVE_TRANSFERS";
export const  HRE_NEW_TRANSFERS = "HRE_NEW_TRANSFERS";
export const  HRE_COMPLAINTS = "HRE_COMPLAINTS";
export const  HRE_LODGE_COMPLAINT = "HRE_LODGE_COMPLAINT";
export const  HRE_COMPLAINT_LIST = "HRE_COMPLAINT_LIST";
export const  HRE_RESOLVED_LIST = "HRE_RESOLVED_LIST";
export const  HRE_AWARDS = "HRE_AWARDS";

export const  HRE_AWARD_ASSIGN_LIST = "HRE_AWARD_ASSIGN_LIST";
export const  HRE_AWARD_TYPE = "HRE_AWARD_TYPE";
export const  HRE_ACTIVITIES = "HRE_ACTIVITIES";
export const  HRE_TRAVELS = "HRE_TRAVELS";
export const  HRE_NEW_BUSINESS_TRAVEL = "HRE_NEW_BUSINESS_TRAVEL";
export const  HRE_TRAVEL_ARRANGEMENT_TYPE = "HRE_TRAVEL_ARRANGEMENT_TYPE";
export const  HRE_TRANSPORT_TYPE = "HRE_TRANSPORT_TYPE";
export const  HRE_PAYMENTS = "HRE_PAYMENTS";
export const  HRE_TRAVEL_MANAGEMENT = "HRE_TRAVEL_MANAGEMENT";
export const  HRE_EVENTS = "HRE_EVENTS";
export const  HRE_NEW_EVENT = "HRE_NEW_EVENT";
export const  HRE_EVENT_TYPES = "HRE_EVENT_TYPES";
export const  HRE_HOLIDAYS = "HRE_HOLIDAYS";
export const  HRE_NEW_HOLIDAY = "HRE_NEW_HOLIDAY";
export const  HRE_HOLIDAY_LIST = "HRE_HOLIDAY_LIST";
export const  HRE_VISITORS = "HRE_VISITORS";
export const  HRE_NEW_VISITOR = "HRE_NEW_VISITOR";
export const  HRE_VISITORS_LIST = "HRE_VISITORS_LIST";
export const  HRE_CONFERENCES = "HRE_CONFERENCES";
export const  HRE_CONF_BOOKING = "HRE_CONF_BOOKING";
export const  HRE_MEETING_LIST = "HRE_MEETING_LIST";
export const  HRE_DOCUMENTS_MANAGER = "HRE_DOCUMENTS_MANAGER";
export const  HRE_GENERAL = "HRE_GENERAL";
export const  HRE_GENERAL_UPLOADING_FILES = "HRE_GENERAL_UPLOADING_FILES";
export const  HRE_GENERAL_MANAGING_FILES = "HRE_GENERAL_MANAGING_FILES";
export const  HRE_OFFICIAL = "HRE_OFFICIAL";
export const  HRE_OFFICIAL_UPLOADING_FILES = "HRE_OFFICIAL_UPLOADING_FILES";
export const  HRE_OFFICIAL_MANAGING_FILES = "HRE_OFFICIAL_MANAGING_FILES";
export const  HRE_PROCESS_DOCUMENTS = "HRE_PROCESS_DOCUMENTS";
export const  HRE_UPLOADING_FILES = "HRE_UPLOADING_FILES";
export const  HRE_MANAGING_FILES = "HRE_MANAGING_FILES";
export const  HRE_PERFORMANCE_PMS = "HRE_PERFORMANCE_PMS";
export const  HRE_KPI_INDICATORS = "HRE_KPI_INDICATORS";
export const  HRE_KPA_APPRAISALS = "HRE_KPA_APPRAISALS";
export const  HRE_OKRS_TRACKING_GOALS = "HRE_OKRS_TRACKING_GOALS";
export const  HRE_COMPETENCIES = "HRE_COMPETENCIES";
export const  HRE_TECHNICAL = "HRE_TECHNICAL";
export const  HRE_ORGANIZATIONAL = "HRE_ORGANIZATIONAL";
export const  HRE_GOAL_TYPES = "HRE_GOAL_TYPES";
export const  HRE_GOAL_CALENDAR = "HRE_GOAL_CALENDAR";
export const  HRE_RECRUITMENT_ATS = "HRE_RECRUITMENT_ATS";
export const  HRE_NEW_POSTINGS = "HRE_NEW_POSTINGS";
export const  HRE_CANDIDATE_LIST = "HRE_CANDIDATE_LIST";
export const  HRE_INTERVIEWS = "HRE_INTERVIEWS";

export const  HRE_INTERVIEWERS_LIST = "HRE_INTERVIEWERS_LIST";
export const  HRE_ATTENDANCE = "HRE_ATTENDANCE";

export const  HRE_MANUAL_ATTENDANCE = "HRE_MANUAL_ATTENDANCE";
export const  HRE_MONTHLY_REPORT = "HRE_MONTHLY_REPORT";
export const  HRE_OVERTIME_REQUEST = "HRE_OVERTIME_REQUEST";
export const  HRE_POLICIES = "HRE_POLICIES";
export const  HRE_CREATE_NEW_POLICY = "HRE_CREATE_NEW_POLICY";
export const  HRE_POLICY_LIST = "HRE_POLICY_LIST";
export const  HRE_MAKE_ANNOUNCEMENT = "HRE_MAKE_ANNOUNCEMENT";
export const  HRE_NEW_ANNOUNCEMENT = "HRE_NEW_ANNOUNCEMENT";
export const  HRE_ANNOUNCEMENT_LIST = "HRE_ANNOUNCEMENT_LIST";
export const  HRE_ORGANISATION_CHART = "HRE_ORGANISATION_CHART";
export const  HRE_VIEW = "HRE_VIEW";
export const  HRE_CREATE_CHART = "HRE_CREATE_CHART";
export const  HRE_LEAVE_REQUESTS = "HRE_LEAVE_REQUESTS";
export const  HRE_MANAGE_LEAVES = "HRE_MANAGE_LEAVES";
export const  HRE_LEAVES_TYPE = "HRE_LEAVES_TYPE";
export const  HRE_SETTINGS = "HRE_SETTINGS";
export const  TH_LEAVE_REQUESTS = "TH_LEAVE_REQUESTS";
export const  TH_MANAGE_LEAVES = "TH_MANAGE_LEAVES";
export const  TH_LEAVES_TYPE = "TH_LEAVES_TYPE";
export const  TH_TRAININGS = "TH_TRAININGS";
export const  TH_TRAINING_SESSIONS = "TH_TRAINING_SESSIONS";
export const  TH_TRAINERS = "TH_TRAINERS";
export const  TH_TRAINING_SKILLS = "TH_TRAINING_SKILLS";
export const  TH_MANAGE_TRAININGS = "TH_MANAGE_TRAININGS";
export const  TH_DISCIPLINARY_CASES = "TH_DISCIPLINARY_CASES";
export const  TH_ACTIONS = "TH_ACTIONS";
export const  TH_RESOLUTIONS = "TH_RESOLUTIONS";
export const  TH_SETTINGS = "TH_SETTINGS";
export const  TTL_LEAVE_REQUESTS = "TTL_LEAVE_REQUESTS";
export const  TTL_MANAGE_LEAVES = "TTL_MANAGE_LEAVES";
export const  TTL_LEAVES_TYPE = "TTL_LEAVES_TYPE";
export const  TTL_TRAININGS = "TTL_TRAININGS";
export const  TTL_TRAINING_SESSIONS = "TTL_TRAINING_SESSIONS";
export const  TTL_TRAINERS = "TTL_TRAINERS";
export const  TTL_TRAINING_SKILLS = "TTL_TRAINING_SKILLS";
export const  TTL_MANAGE_TRAININGS = "TTL_MANAGE_TRAININGS";
export const  TTL_DISCIPLINARY_CASES = "TTL_DISCIPLINARY_CASES";
export const  TTL_ACTIONS = "TTL_ACTIONS";
export const  TTL_RESOLUTIONS = "TTL_RESOLUTIONS";
export const  TTL_SETTINGS = "TTL_SETTINGS";
export const  TR_LEAVE_REQUESTS = "TR_LEAVE_REQUESTS";
export const  TR_MANAGE_LEAVES = "TR_MANAGE_LEAVES";
export const  TR_LEAVES_TYPE = "TR_LEAVES_TYPE";
export const  TR_TRAININGS = "TR_TRAININGS";
export const  TR_TRAINING_SESSIONS = "TR_TRAINING_SESSIONS";
export const  TR_TRAINERS = "TR_TRAINERS";
export const  TR_TRAINING_SKILLS = "TR_TRAINING_SKILLS";
export const  TR_MANAGE_TRAININGS = "TR_MANAGE_TRAININGS";
export const  TR_DISCIPLINARY_CASES = "TR_DISCIPLINARY_CASES";
export const  TR_ACTIONS = "TR_ACTIONS";
export const  TR_RESOLUTIONS = "TR_RESOLUTIONS";
export const  TR_SETTINGS = "TR_SETTINGS";
export const  OA_DOCUMENTS_MANAGER = "OA_DOCUMENTS_MANAGER";
export const  OA_GENERAL = "OA_GENERAL";
export const  OA_GENERAL_UPLOADING_FILES = "OA_UPLOADING_FILES";
export const  OA_GENERAL_MANAGING_FILES = "OA_MANAGING_FILES";
export const  OA_OFFICIAL = "OA_OFFICIAL";
export const  OA_OFFICIAL_UPLOADING_FILES = "OA_UPLOADING_FILES";
export const  OA_OFFICIAL_MANAGING_FILES = "OA_MANAGING_FILES";
export const  OA_VISITORS = "OA_VISITORS";
export const  OA_NEW_VISITOR = "OA_NEW_VISITOR";
export const  OA_VISITORS_LIST = "OA_VISITORS_LIST";
export const  OA_LEAVE_REQUESTS = "OA_LEAVE_REQUESTS";
export const  OA_MANAGE_LEAVES = "OA_MANAGE_LEAVES";
export const  OA_LEAVES_TYPE = "OA_LEAVES_TYPE";
export const  OA_SETTINGS = "OA_SETTINGS";
export const  FM_ACCOUNTS = "FM_ACCOUNTS";
export const  FM_DEPOSITS = "FM_DEPOSITS";
export const  FM_DEPOSITS_LIST = "FM_DEPOSITS_LIST";
export const  FM_CATEGORIES = "FM_CATEGORIES";
export const  FM_EXPENSES = "FM_EXPENSES";
export const  FM_TRANSACTIONS = "FM_TRANSACTIONS";
export const  FM_PAYROLL = "FM_PAYROLL";
export const  FM_PAY_SLIP_HISTORY = "FM_PAY_SLIP_HISTORY";
export const  FM_ADVANCE_SALARY = "FM_ADVANCE_SALARY";
export const  FM_LOAN = "FM_LOAN";
export const  FM_LOAN_TYPES = "FM_LOAN_TYPES";
export const  FM_COMMISSION_TYPES = "FM_COMMISSION_TYPES";
export const  FM_INCENTIVE_TYPES = "FM_INCENTIVE_TYPES";
export const  FM_INVOICES = "FM_INVOICES";
export const  FM_BILLING_INVOICES = "FM_BILLING_INVOICES";
export const  FM_INVOICE_PAYMENTS = "FM_INVOICE_PAYMENTS";
export const  FM_TAX_TYPE = "FM_TAX_TYPE";
export const  FM_ESTIMATES = "FM_ESTIMATES";

export const  FM_ESTIMATE_TYPE = "FM_ESTIMATE_TYPE";
export const  FM_LEAVE_REQUESTS = "FM_LEAVE_REQUESTS";
export const  FM_MANAGE_LEAVES = "FM_MANAGE_LEAVES";
export const  FM_LEAVES_TYPE = "FM_LEAVES_TYPE";
export const  FM_SETTINGS = "FM_SETTINGS";
export const  AH_ACCOUNTS = "AH_ACCOUNTS";
export const  AH_DEPOSITS = "AH_DEPOSITS";
export const  AH_DEPOSITS_LIST = "AH_DEPOSITS_LIST";
export const  AH_CATEGORIES = "AH_CATEGORIES";
export const  AH_EXPENSES = "AH_EXPENSES";
export const  AH_TRANSACTIONS = "AH_TRANSACTIONS";
export const  AH_PAYROLL = "AH_PAYROLL";
export const  AH_PAY_SLIP_HISTORY = "AH_PAY_SLIP_HISTORY";
export const  AH_ADVANCE_SALARY = "AH_ADVANCE_SALARY";
export const  AH_LOAN = "AH_LOAN";
export const  AH_LOAN_TYPES = "AH_LOAN_TYPES";
export const  AH_COMMISSION_TYPES = "AH_COMMISSION_TYPES";
export const  AH_INCENTIVE_TYPES = "AH_INCENTIVE_TYPES";
export const  AH_INVOICES = "AH_INVOICES";
export const  AH_BILLING_INVOICES = "AH_BILLING_INVOICES";
export const  AH_INVOICE_PAYMENTS = "AH_INVOICE_PAYMENTS";
export const  AH_TAX_TYPE = "AH_TAX_TYPE";
export const  AH_ESTIMATES = "AH_ESTIMATES";

export const  AH_ESTIMATE_TYPE = "AH_ESTIMATE_TYPE";
export const  AH_LEAVE_REQUESTS = "AH_LEAVE_REQUESTS";
export const  AH_MANAGE_LEAVES = "AH_MANAGE_LEAVES";
export const  AH_LEAVES_TYPE = "AH_LEAVES_TYPE";
export const  AH_SETTINGS = "AH_SETTINGS";
export const  AE_ACCOUNTS = "AE_ACCOUNTS";
export const  AE_DEPOSITS = "AE_DEPOSITS";
export const  AE_DEPOSITS_LIST = "AE_DEPOSITS_LIST";
export const  AE_CATEGORIES = "AE_CATEGORIES";
export const  AE_EXPENSES = "AE_EXPENSES";
export const  AE_TRANSACTIONS = "AE_TRANSACTIONS";
export const  AE_PAYROLL = "AE_PAYROLL";
export const  AE_PAY_SLIP_HISTORY = "AE_PAY_SLIP_HISTORY";
export const  AE_ADVANCE_SALARY = "AE_ADVANCE_SALARY";
export const  AE_LOAN = "AE_LOAN";
export const  AE_LOAN_TYPES = "AE_LOAN_TYPES";
export const  AE_COMMISSION_TYPES = "AE_COMMISSION_TYPES";
export const  AE_INCENTIVE_TYPES = "AE_INCENTIVE_TYPES";
export const  AE_INVOICES = "AE_INVOICES";
export const  AE_BILLING_INVOICES = "AE_BILLING_INVOICES";
export const  AE_INVOICE_PAYMENTS = "AE_INVOICE_PAYMENTS";
export const  AE_TAX_TYPE = "AE_TAX_TYPE";
export const  AE_ESTIMATES = "AE_ESTIMATES";

export const  AE_ESTIMATE_TYPE = "AE_ESTIMATE_TYPE";
export const  AE_LEAVE_REQUESTS = "AE_LEAVE_REQUESTS";
export const  AE_MANAGE_LEAVES = "AE_MANAGE_LEAVES";
export const  AE_LEAVES_TYPE = "AE_LEAVES_TYPE";
export const  AE_SETTINGS = "AE_SETTINGS";
export const  AC_ACCOUNTS = "AC_ACCOUNTS";
export const  AC_DEPOSITS = "AC_DEPOSITS";
export const  AC_DEPOSITS_LIST = "AC_DEPOSITS_LIST";
export const  AC_CATEGORIES = "AC_CATEGORIES";
export const  AC_EXPENSES = "AC_EXPENSES";
export const  AC_TRANSACTIONS = "AC_TRANSACTIONS";
export const  AC_PAYROLL = "AC_PAYROLL";
export const  AC_PAY_SLIP_HISTORY = "AC_PAY_SLIP_HISTORY";
export const  AC_ADVANCE_SALARY = "AC_ADVANCE_SALARY";
export const  AC_LOAN = "AC_LOAN";
export const  AC_LOAN_TYPES = "AC_LOAN_TYPES";
export const  AC_COMMISSION_TYPES = "AC_COMMISSION_TYPES";
export const  AC_INCENTIVE_TYPES = "AC_INCENTIVE_TYPES";
export const  AC_INVOICES = "AC_INVOICES";
export const  AC_BILLING_INVOICES = "AC_BILLING_INVOICES";
export const  AC_INVOICE_PAYMENTS = "AC_INVOICE_PAYMENTS";
export const  AC_TAX_TYPE = "AC_TAX_TYPE";
export const  AC_ESTIMATES = "AC_ESTIMATES";

export const  AC_ESTIMATE_TYPE = "AC_ESTIMATE_TYPE";
export const  AC_LEAVE_REQUESTS = "AC_LEAVE_REQUESTS";
export const  AC_MANAGE_LEAVES = "AC_MANAGE_LEAVES";
export const  AC_LEAVES_TYPE = "AC_LEAVES_TYPE";
export const  AC_SETTINGS = "AC_SETTINGS";
export const  ITH_INVENTORY_CONTROL  = "ITH_INVENTORY_CONTROL ";
export const  ITH_WARE_HOUSES  = "ITH_WARE_HOUSES ";
export const  ITH_PRODUCTS  = "ITH_PRODUCTS ";
export const  ITH_OUT_OF_STOCK  = "ITH_OUT_OF_STOCK ";
export const  ITH_EXPIRED_PRODUCTS  = "ITH_EXPIRED_PRODUCTS ";
export const  ITH_PRODUCT_TAX  = "ITH_PRODUCT_TAX ";
export const  ITH_BILLING_INVOICES  = "ITH_BILLING_INVOICES ";
export const  ITH_INVOICE_PAYMENTS  = "ITH_INVOICE_PAYMENTS ";
export const  ITH_TAX_TYPE  = "ITH_TAX_TYPE ";
export const  ITH_PRODUCT_CATEGORY  = "ITH_PRODUCT_CATEGORY ";
export const  ITH_SUPPLIERS  = "ITH_SUPPLIERS ";
export const  ITH_NEW_SUPPLIER  = "ITH_NEW_SUPPLIER ";
export const  ITH_SUPPLIERS_LIST  = "ITH_SUPPLIERS_LIST ";
export const  ITH_PURCHASES  = "ITH_PURCHASES ";
export const  ITH_NEW_PURCHASE  = "ITH_NEW_PURCHASE ";
export const  ITH_PURCHASE_LIST  = "ITH_PURCHASE_LIST ";
export const  ITH_SALES_ORDER  = "ITH_SALES_ORDER ";
export const  ITH_SALES_ORDER_LIST  = "ITH_SALES_ORDER_LIST ";
export const  ITH_NEW_ORDER_INVOICE  = "ITH_NEW_ORDER_INVOICE ";
export const  ITH_MANAGE_ORDERS  = "ITH_MANAGE_ORDERS ";
export const  ITH_PAID_ORDERS  = "ITH_PAID_ORDERS ";
export const  ITH_UNPAID_ORDERS  = "ITH_UNPAID_ORDERS ";
export const  ITH_DELIVERED_ORDERS  = "ITH_DELIVERED_ORDERS ";
export const  ITH_CANCELLED_ORDERS  = "ITH_CANCELLED_ORDERS ";
export const  ITH_CREDIT_TYPES  = "ITH_CREDIT_TYPES ";
export const  ITH_HELP_DESK  = "ITH_HELP_DESK ";
export const  ITH_TICKETS  = "ITH_TICKETS ";
export const  ITH_TICKETS_LIST  = "ITH_TICKETS_LIST ";
export const  ITH_CREATE_TICKET  = "ITH_CREATE_TICKET ";
export const  ITH_SUPPORT_TICKETS  = "ITH_SUPPORT_TICKETS ";
export const  ITH_RESOLVED_LIST  = "ITH_RESOLVED_LIST ";
export const  ITH_ASSIGN_TICKETS_SOLVE  = "ITH_ASSIGN_TICKETS_SOLVE ";
export const  ITH_LEAVE_REQUESTS  = "ITH_LEAVE_REQUESTS ";
export const  ITH_MANAGE_LEAVES  = "ITH_MANAGE_LEAVES ";
export const  ITH_LEAVES_TYPE  = "ITH_LEAVES_TYPE ";
export const  ITH_ASSETS_LIST  = "ITH_ASSETS_LIST ";
export const  ITH_ASSETS_MANAGEMENT  = "ITH_ASSETS_MANAGEMENT ";
export const  ITH_CREATE_ASSERT  = "ITH_CREATE_ASSERT ";
export const  ITH_ASSIGN_ASSERT  = "ITH_ASSIGN_ASSERT ";
export const  ITH_DESTORY_ASSERT  = "ITH_DESTORY_ASSERT ";
export const  ITH_ASSERT_CATEGORY  = "ITH_ASSERT_CATEGORY ";
export const  ITH_NEW_CATEGORY  = "ITH_NEW_CATEGORY ";
export const  ITH_MANAGE_CATEGORY  = "ITH_MANAGE_CATEGORY ";
export const  ITH_ASSET_BRAND  = "ITH_ASSET_BRAND ";
export const  ITH_NEW_BRAND  = "ITH_NEW_BRAND ";
export const  ITH_MANAGE_BRAND  = "ITH_MANAGE_BRAND ";
export const  ITH_SETTINGS  = "ITH_SETTINGS ";
export const  ITM_INVENTORY_CONTROL = "ITM_INVENTORY_CONTROL";
export const  ITM_WARE_HOUSES = "ITM_WARE_HOUSES";
export const  ITM_PRODUCTS = "ITM_PRODUCTS";
export const  ITM_OUT_OF_STOCK = "ITM_OUT_OF_STOCK";
export const  ITM_EXPIRED_PRODUCTS = "ITM_EXPIRED_PRODUCTS";
export const  ITM_PRODUCT_TAX = "ITM_PRODUCT_TAX";
export const  ITM_BILLING_INVOICES = "ITM_BILLING_INVOICES";
export const  ITM_INVOICE_PAYMENTS = "ITM_INVOICE_PAYMENTS";
export const  ITM_TAX_TYPE = "ITM_TAX_TYPE";
export const  ITM_PRODUCT_CATEGORY = "ITM_PRODUCT_CATEGORY";
export const  ITM_SUPPLIERS = "ITM_SUPPLIERS";
export const  ITM_NEW_SUPPLIER = "ITM_NEW_SUPPLIER";
export const  ITM_SUPPLIERS_LIST = "ITM_SUPPLIERS_LIST";
export const  ITM_PURCHASES = "ITM_PURCHASES";
export const  ITM_NEW_PURCHASE = "ITM_NEW_PURCHASE";
export const  ITM_PURCHASE_LIST = "ITM_PURCHASE_LIST";
export const  ITM_SALES_ORDER = "ITM_SALES_ORDER";
export const  ITM_SALES_ORDER_LIST = "ITM_SALES_ORDER_LIST";
export const  ITM_NEW_ORDER_INVOICE = "ITM_NEW_ORDER_INVOICE";
export const  ITM_MANAGE_ORDERS = "ITM_MANAGE_ORDERS";
export const  ITM_PAID_ORDERS = "ITM_PAID_ORDERS";
export const  ITM_UNPAID_ORDERS = "ITM_UNPAID_ORDERS";
export const  ITM_DELIVERED_ORDERS = "ITM_DELIVERED_ORDERS";
export const  ITM_CANCELLED_ORDERS = "ITM_CANCELLED_ORDERS";
export const  ITM_CREDIT_TYPES = "ITM_CREDIT_TYPES";
export const  ITM_HELP_DESK = "ITM_HELP_DESK";
export const  ITM_TICKETS = "ITM_TICKETS";
export const  ITM_TICKETS_LIST = "ITM_TICKETS_LIST";
export const  ITM_CREATE_TICKET = "ITM_CREATE_TICKET";
export const  ITM_SUPPORT_TICKETS = "ITM_SUPPORT_TICKETS";
export const  ITM_RESOLVED_LIST = "ITM_RESOLVED_LIST";
export const  ITM_ASSIGN_TICKETS_SOLVE = "ITM_ASSIGN_TICKETS_SOLVE";
export const  ITM_LEAVE_REQUESTS = "ITM_LEAVE_REQUESTS";
export const  ITM_MANAGE_LEAVES = "ITM_MANAGE_LEAVES";
export const  ITM_LEAVES_TYPE = "ITM_LEAVES_TYPE";
export const  ITM_ASSETS_LIST = "ITM_ASSETS_LIST";
export const  ITM_ASSETS_MANAGEMENT = "ITM_ASSETS_MANAGEMENT";
export const  ITM_CREATE_ASSERT = "ITM_CREATE_ASSERT";
export const  ITM_ASSIGN_ASSERT = "ITM_ASSIGN_ASSERT";
export const  ITM_DESTORY_ASSERT = "ITM_DESTORY_ASSERT";
export const  ITM_ASSERT_CATEGORY = "ITM_ASSERT_CATEGORY";
export const  ITM_NEW_CATEGORY = "ITM_NEW_CATEGORY";
export const  ITM_MANAGE_CATEGORY = "ITM_MANAGE_CATEGORY";
export const  ITM_ASSET_BRAND = "ITM_ASSET_BRAND";
export const  ITM_NEW_BRAND = "ITM_NEW_BRAND";
export const  ITM_MANAGE_BRAND = "ITM_MANAGE_BRAND";
export const  ITM_SETTINGS = "ITM_SETTINGS";
export const  AMHWA_INVENTORY_CONTROL = "AMHWA_INVENTORY_CONTROL";
export const  AMHWA_WARE_HOUSES = "AMHWA_WARE_HOUSES";
export const  AMHWA_PRODUCTS = "AMHWA_PRODUCTS";
export const  AMHWA_OUT_OF_STOCK = "AMHWA_OUT_OF_STOCK";
export const  AMHWA_EXPIRED_PRODUCTS = "AMHWA_EXPIRED_PRODUCTS";
export const  AMHWA_PRODUCT_TAX = "AMHWA_PRODUCT_TAX";
export const  AMHWA_BILLING_INVOICES = "AMHWA_BILLING_INVOICES";
export const  AMHWA_INVOICE_PAYMENTS = "AMHWA_INVOICE_PAYMENTS";
export const  AMHWA_TAX_TYPE = "AMHWA_TAX_TYPE";
export const  AMHWA_PRODUCT_CATEGORY = "AMHWA_PRODUCT_CATEGORY";
export const  AMHWA_SUPPLIERS = "AMHWA_SUPPLIERS";
export const  AMHWA_NEW_SUPPLIER = "AMHWA_NEW_SUPPLIER";
export const  AMHWA_SUPPLIERS_LIST = "AMHWA_SUPPLIERS_LIST";
export const  AMHWA_PURCHASES = "AMHWA_PURCHASES";
export const  AMHWA_NEW_PURCHASE = "AMHWA_NEW_PURCHASE";
export const  AMHWA_PURCHASE_LIST = "AMHWA_PURCHASE_LIST";
export const  AMHWA_SALES_ORDER = "AMHWA_SALES_ORDER";
export const  AMHWA_SALES_ORDER_LIST = "AMHWA_SALES_ORDER_LIST";
export const  AMHWA_NEW_ORDER_INVOICE = "AMHWA_NEW_ORDER_INVOICE";
export const  AMHWA_MANAGE_ORDERS = "AMHWA_MANAGE_ORDERS";
export const  AMHWA_PAID_ORDERS = "AMHWA_PAID_ORDERS";
export const  AMHWA_UNPAID_ORDERS = "AMHWA_UNPAID_ORDERS";
export const  AMHWA_DELIVERED_ORDERS = "AMHWA_DELIVERED_ORDERS";
export const  AMHWA_CANCELLED_ORDERS = "AMHWA_CANCELLED_ORDERS";
export const  AMHWA_CREDIT_TYPES = "AMHWA_CREDIT_TYPES";
export const  AMHWA_HELP_DESK = "AMHWA_HELP_DESK";
export const  AMHWA_TICKETS = "AMHWA_TICKETS";
export const  AMHWA_TICKETS_LIST = "AMHWA_TICKETS_LIST";
export const  AMHWA_CREATE_TICKET = "AMHWA_CREATE_TICKET";
export const  AMHWA_SUPPORT_TICKETS = "AMHWA_SUPPORT_TICKETS";
export const  AMHWA_RESOLVED_LIST = "AMHWA_RESOLVED_LIST";
export const  AMHWA_ASSIGN_TICKETS_SOLVE = "AMHWA_ASSIGN_TICKETS_SOLVE";
export const  AMHWA_LEAVE_REQUESTS = "AMHWA_LEAVE_REQUESTS";
export const  AMHWA_MANAGE_LEAVES = "AMHWA_MANAGE_LEAVES";
export const  AMHWA_LEAVES_TYPE = "AMHWA_LEAVES_TYPE";
export const  AMHWA_ASSETS_LIST = "AMHWA_ASSETS_LIST";
export const  AMHWA_ASSETS_MANAGEMENT = "AMHWA_ASSETS_MANAGEMENT";
export const  AMHWA_CREATE_ASSERT = "AMHWA_CREATE_ASSERT";
export const  AMHWA_ASSIGN_ASSERT = "AMHWA_ASSIGN_ASSERT";
export const  AMHWA_DESTORY_ASSERT = "AMHWA_DESTORY_ASSERT";
export const  AMHWA_ASSERT_CATEGORY = "AMHWA_ASSERT_CATEGORY";
export const  AMHWA_NEW_CATEGORY = "AMHWA_NEW_CATEGORY";
export const  AMHWA_MANAGE_CATEGORY = "AMHWA_MANAGE_CATEGORY";
export const  AMHWA_ASSET_BRAND = "AMHWA_ASSET_BRAND";
export const  AMHWA_NEW_BRAND = "AMHWA_NEW_BRAND";
export const  AMHWA_MANAGE_BRAND = "AMHWA_MANAGE_BRAND";
export const  AMHWA_SETTINGS = "AMHWA_SETTINGS";
export const  A_MAPS = "A_MAPS";
export const  A_INVENTORY_CONTROL = "A_INVENTORY_CONTROL";
export const  A_WARE_HOUSES = "A_WARE_HOUSES";
export const  A_PRODUCTS = "A_PRODUCTS";
export const  A_OUT_OF_STOCK = "A_OUT_OF_STOCK";
export const  A_EXPIRED_PRODUCTS = "A_EXPIRED_PRODUCTS";
export const  A_PRODUCT_TAX = "A_PRODUCT_TAX";
export const  A_BILLING_INVOICES = "A_BILLING_INVOICES";
export const  A_INVOICE_PAYMENTS = "A_INVOICE_PAYMENTS";
export const  A_TAX_TYPE = "A_TAX_TYPE";
export const  A_PRODUCT_CATEGORY = "A_PRODUCT_CATEGORY";
export const  A_SUPPLIERS = "A_SUPPLIERS";
export const  A_NEW_SUPPLIER = "A_NEW_SUPPLIER";
export const  A_SUPPLIERS_LIST = "A_SUPPLIERS_LIST";
export const  A_PURCHASES = "A_PURCHASES";
export const  A_NEW_PURCHASE = "A_NEW_PURCHASE";
export const  A_PURCHASE_LIST = "A_PURCHASE_LIST";
export const  A_SALES_ORDER = "A_SALES_ORDER";
export const  A_SALES_ORDER_LIST = "A_SALES_ORDER_LIST";
export const  A_NEW_ORDER_INVOICE = "A_NEW_ORDER_INVOICE";
export const  A_MANAGE_ORDERS = "A_MANAGE_ORDERS";
export const  A_PAID_ORDERS = "A_PAID_ORDERS";
export const  A_UNPAID_ORDERS = "A_UNPAID_ORDERS";
export const  A_DELIVERED_ORDERS = "A_DELIVERED_ORDERS";
export const  A_CANCELLED_ORDERS = "A_CANCELLED_ORDERS";
export const  A_CREDIT_TYPES = "A_CREDIT_TYPES";
export const  A_HELP_DESK = "A_HELP_DESK";
export const  A_TICKETS = "A_TICKETS";
export const  A_TICKETS_LIST = "A_TICKETS_LIST";
export const  A_CREATE_TICKET = "A_CREATE_TICKET";
export const  A_SUPPORT_TICKETS = "A_SUPPORT_TICKETS";
export const  A_RESOLVED_LIST = "A_RESOLVED_LIST";
export const  A_ASSIGN_TICKETS_SOLVE = "A_ASSIGN_TICKETS_SOLVE";
export const  A_LEAVE_REQUESTS = "A_LEAVE_REQUESTS";
export const  A_MANAGE_LEAVES = "A_MANAGE_LEAVES";
export const  A_LEAVES_TYPE = "A_LEAVES_TYPE";
export const  A_ASSETS_LIST = "A_ASSETS_LIST";
export const  A_ASSETS_MANAGEMENT = "A_ASSETS_MANAGEMENT";
export const  A_CREATE_ASSERT = "A_CREATE_ASSERT";
export const  A_ASSIGN_ASSERT = "A_ASSIGN_ASSERT";
export const  A_DESTORY_ASSERT = "A_DESTORY_ASSERT";
export const  A_ASSERT_CATEGORY = "A_ASSERT_CATEGORY";
export const  A_NEW_CATEGORY = "A_NEW_CATEGORY";
export const  A_MANAGE_CATEGORY = "A_MANAGE_CATEGORY";
export const  A_ASSET_BRAND = "A_ASSET_BRAND";
export const  A_NEW_BRAND = "A_NEW_BRAND";
export const  A_MANAGE_BRAND = "A_MANAGE_BRAND";
export const  A_SETTINGS = "A_SETTINGS";
export const  MISM_INVENTROY_CONTROL = "MISM_INVENTROY_CONTROL";
export const  MISM_WARE_HOUSES = "MISM_WARE_HOUSES";
export const  MISM_PRODUCTS = "MISM_PRODUCTS";
export const  MISM_BILLING_INVOICES = "MISM_BILLING_INVOICES";
export const  MISM_INVOICE_PAYMENTS = "MISM_INVOICE_PAYMENTS";
export const  MISM_TAX_TYPE = "MISM_TAX_TYPE";
export const  MISM_PRODUCT_CATEGORY = "MISM_PRODUCT_CATEGORY";
export const  MISM_SUPPLIERS = "MISM_SUPPLIERS";
export const  MISM_NEW_SUPPLIER = "MISM_NEW_SUPPLIER";
export const  MISM_SUPPLIERS_LIST = "MISM_SUPPLIERS_LIST";
export const  MISM_PURCHASES = "MISM_PURCHASES";
export const  MISM_NEW_PURCHASE = "MISM_NEW_PURCHASE";
export const  MISM_PURCHASE_LIST = "MISM_PURCHASE_LIST";
export const  MISM_SALES_ORDER = "MISM_SALES_ORDER";
export const  MISM_SALES_ORDER_LIST = "MISM_SALES_ORDER_LIST";
export const  MISM_NEW_ORDER_INVOICE = "MISM_NEW_ORDER_INVOICE";
export const  MISM_MANAGE_ORDERS = "MISM_MANAGE_ORDERS";
export const  MISM_PAID_ORDERS = "MISM_PAID_ORDERS";
export const  MISM_UNPAID_ORDERS = "MISM_UNPAID_ORDERS";
export const  MISM_DELIVERED_ORDERS = "MISM_DELIVERED_ORDERS";
export const  MISM_CANCELLED_ORDERS = "MISM_CANCELLED_ORDERS";
export const  MISM_CREDIT_TYPES = "MISM_CREDIT_TYPES";
export const  MISM_HELP_DESK = "MISM_HELP_DESK";
export const  MISM_TICKETS = "MISM_TICKETS";
export const  MISM_TICKETS_LIST = "MISM_TICKETS_LIST";
export const  MISM_CREATE_TICKET = "MISM_CREATE_TICKET";
export const  MISM_SUPPORT_TICKETS = "MISM_SUPPORT_TICKETS";
export const  MISM_RESOLVED_LIST = "MISM_RESOLVED_LIST";
export const  MISM_ASSIGN_TICKETS_SOLVED = "MISM_ASSIGN_TICKETS_SOLVED";
export const  MISM_LEAVE_REQUESTS = "MISM_LEAVE_REQUESTS";
export const  MISM_MANAGE_LEAVES = "MISM_MANAGE_LEAVES";
export const  MISM_LEAVES_TYPE = "MISM_LEAVES_TYPE";
export const  MISM_ASSERTS = "MISM_ASSERTS";
export const  MISM_ASSETS_LIST = "MISM_ASSETS_LIST";
export const  MISM_ASSETS_MANAGEMENT = "MISM_ASSETS_MANAGEMENT";
export const  MISM_CREATE_ASSERT = "MISM_CREATE_ASSERT";
export const  MISM_ASSIGN_ASSERT = "MISM_ASSIGN_ASSERT";
export const  MISM_DESTORY_ASSERT = "MISM_DESTORY_ASSERT";
export const  MISM_ASSERT_CATEGORY = "MISM_ASSERT_CATEGORY";
export const  MISM_NEW_CATEGORY = "MISM_NEW_CATEGORY";
export const  MISM_MANAGE_CATEGORY = "MISM_MANAGE_CATEGORY";
export const  MISM_ASSET_BRAND = "MISM_ASSET_BRAND";
export const  MISM_NEW_BRAND = "MISM_NEW_BRAND";
export const  MISM_MANAGE_BRAND = "MISM_MANAGE_BRAND";
export const  MISM_REPORTS = "MISM_REPORTS";
export const  MISM_DAILY = "MISM_DAILY";
export const  MISM_MONTHLY = "MISM_MONTHLY";
export const  MISM_YEARLY = "MISM_YEARLY";
export const  MISM_PROCESS = "MISM_PROCESS";
export const  MISM_SETTINGS = "MISM_SETTINGS";
export const  MISE_INVENTROY_CONTROL = "MISE_INVENTROY_CONTROL";
export const  MISE_WARE_HOUSES = "MISE_WARE_HOUSES";
export const  MISE_PRODUCTS = "MISE_PRODUCTS";
export const  MISE_BILLING_INVOICES = "MISE_BILLING_INVOICES";
export const  MISE_INVOICE_PAYMENTS = "MISE_INVOICE_PAYMENTS";
export const  MISE_TAX_TYPE = "MISE_TAX_TYPE";
export const  MISE_PRODUCT_CATEGORY = "MISE_PRODUCT_CATEGORY";
export const  MISE_SUPPLIERS = "MISE_SUPPLIERS";
export const  MISE_NEW_SUPPLIER = "MISE_NEW_SUPPLIER";
export const  MISE_SUPPLIERS_LIST = "MISE_SUPPLIERS_LIST";
export const  MISE_PURCHASES = "MISE_PURCHASES";
export const  MISE_NEW_PURCHASE = "MISE_NEW_PURCHASE";
export const  MISE_PURCHASE_LIST = "MISE_PURCHASE_LIST";
export const  MISE_SALES_ORDER = "MISE_SALES_ORDER";
export const  MISE_SALES_ORDER_LIST = "MISE_SALES_ORDER_LIST";
export const  MISE_NEW_ORDER_INVOICE = "MISE_NEW_ORDER_INVOICE";
export const  MISE_MANAGE_ORDERS = "MISE_MANAGE_ORDERS";
export const  MISE_PAID_ORDERS = "MISE_PAID_ORDERS";
export const  MISE_UNPAID_ORDERS = "MISE_UNPAID_ORDERS";
export const  MISE_DELIVERED_ORDERS = "MISE_DELIVERED_ORDERS";
export const  MISE_CANCELLED_ORDERS = "MISE_CANCELLED_ORDERS";
export const  MISE_CREDIT_TYPES = "MISE_CREDIT_TYPES";
export const  MISE_HELP_DESK = "MISE_HELP_DESK";
export const  MISE_TICKETS = "MISE_TICKETS";
export const  MISE_TICKETS_LIST = "MISE_TICKETS_LIST";
export const  MISE_CREATE_TICKET = "MISE_CREATE_TICKET";
export const  MISE_SUPPORT_TICKETS = "MISE_SUPPORT_TICKETS";
export const  MISE_RESOLVED_LIST = "MISE_RESOLVED_LIST";
export const  MISE_ASSIGN_TICKETS_SOLVED = "MISE_ASSIGN_TICKETS_SOLVED";
export const  MISE_LEAVE_REQUESTS = "MISE_LEAVE_REQUESTS";
export const  MISE_MANAGE_LEAVES = "MISE_MANAGE_LEAVES";
export const  MISE_LEAVES_TYPE = "MISE_LEAVES_TYPE";
export const  MISE_ASSERTS = "MISE_ASSERTS";
export const  MISE_ASSETS_LIST = "MISE_ASSETS_LIST";
export const  MISE_ASSETS_MANAGEMENT = "MISE_ASSETS_MANAGEMENT";
export const  MISE_CREATE_ASSERT = "MISE_CREATE_ASSERT";
export const  MISE_ASSIGN_ASSERT = "MISE_ASSIGN_ASSERT";
export const  MISE_DESTORY_ASSERT = "MISE_DESTORY_ASSERT";
export const  MISE_ASSERT_CATEGORY = "MISE_ASSERT_CATEGORY";
export const  MISE_NEW_CATEGORY = "MISE_NEW_CATEGORY";
export const  MISE_MANAGE_CATEGORY = "MISE_MANAGE_CATEGORY";
export const  MISE_ASSET_BRAND = "MISE_ASSET_BRAND";
export const  MISE_NEW_BRAND = "MISE_NEW_BRAND";
export const  MISE_MANAGE_BRAND = "MISE_MANAGE_BRAND";
export const  MISE_REPORTS = "MISE_REPORTS";
export const  MISE_DAILY = "MISE_DAILY";
export const  MISE_MONTHLY = "MISE_MONTHLY";
export const  MISE_YEARLY = "MISE_YEARLY";
export const  MISE_PROCESS = "MISE_PROCESS";
export const  MISE_SETTINGS = "MISE_SETTINGS";
export const  PH_LEAVE_REQUESTS = "PH_LEAVE_REQUESTS";
export const  PH_MANAGE_LEAVES = "PH_MANAGE_LEAVES";
export const  PH_LEAVES_TYPE = "PH_LEAVES_TYPE";
export const  PH_PROCESSES = "PH_PROCESSES";
export const  PH_NEW_PROCESS = "PH_NEW_PROCESS";
export const  PH_PROCESS_FLOW = "PH_PROCESS_FLOW";
export const  PH_PROCESS_MANAGEMENT = "PH_PROCESS_MANAGEMENT";
export const  PH_COMPLETED_PROCESS = "PH_COMPLETED_PROCESS";
export const  PH_COMPLACENCIES = "PH_COMPLACENCIES";

export const  PH_CLIENT = "PH_CLIENT";
export const  PH_REPORTS = "PH_REPORTS";
export const  PH_DAILY = "PH_DAILY";
export const  PH_MONTHLY = "PH_MONTHLY";
export const  PH_YEARLY = "PH_YEARLY";
export const  PH_PROCESS = "PH_PROCESS";
export const  PH_PAYMENTS_AND_COMMISSIONS = "PH_PAYMENTS_AND_COMMISSIONS";
export const  PH_MESSAGING = "PH_MESSAGING";
export const  PH_SETTINGS = "PH_SETTINGS";
export const  PM_LEAVE_REQUESTS = "PM_LEAVE_REQUESTS";
export const  PM_MANAGE_LEAVES = "PM_MANAGE_LEAVES";
export const  PM_LEAVES_TYPE = "PM_LEAVES_TYPE";
export const  PM_PROCESSES = "PM_PROCESSES";
export const  PM_NEW_PROCESS = "PM_NEW_PROCESS";
export const  PM_PROCESS_FLOW = "PM_PROCESS_FLOW";
export const  PM_PROCESS_MANAGEMENT = "PM_PROCESS_MANAGEMENT";
export const  PM_COMPLETED_PROCESS = "PM_COMPLETED_PROCESS";
export const  PM_COMPLACENCIES = "PM_COMPLACENCIES";

export const  PM_CLIENT = "PM_CLIENT";
export const  PM_REPORTS = "PM_REPORTS";
export const  PM_DAILY = "PM_DAILY";
export const  PM_MONTHLY = "PM_MONTHLY";
export const  PM_YEARLY = "PM_YEARLY";
export const  PM_PROCESS = "PM_PROCESS";
export const  PM_PAYMENTS_AND_COMMISSIONS = "PM_PAYMENTS_AND_COMMISSIONS";
export const  PM_MESSAGING = "PM_MESSAGING";
export const  PM_SETTINGS = "PM_SETTINGS";
export const  TLFE_LEAVE_REQUESTS = "TLFE_LEAVE_REQUESTS";
export const  TLFE_MANAGE_LEAVES = "TLFE_MANAGE_LEAVES";
export const  TLFE_LEAVES_TYPE = "TLFE_LEAVES_TYPE";
export const  TLFE_EXECUTIVES = "TLFE_EXECUTIVES";
export const  TLFE_ASSIGNED = "TLFE_ASSIGNED";
export const  TLFE_MANAGED = "TLFE_MANAGED";
export const  TLFE_CASES = "TLFE_CASES";

export const  TLFE_REASSIGNED = "TLFE_REASSIGNED";
export const  TLFE_DISCIPLINARY = "TLFE_DISCIPLINARY";
export const  TLFE_ACTIONS = "TLFE_ACTIONS";
export const  TLFE_RESOLUTIONS = "TLFE_RESOLUTIONS";
export const  TLFE_PAYMENTS_AND_COMMISSIONS = "TLFE_PAYMENTS_AND_COMMISSIONS";
export const  TLFE_MESSAGING = "TLFE_MESSAGING";
export const  TLFE_SETTINGS = "TLFE_SETTINGS";
export const  TLPB_LEAVE_REQUESTS = "TLPB_LEAVE_REQUESTS";
export const  TLPB_MANAGE_LEAVES = "TLPB_MANAGE_LEAVES";
export const  TLPB_LEAVES_TYPE = "TLPB_LEAVES_TYPE";
export const  TLPB_PHONEBANKER = "TLPB_PHONEBANKER";
export const  TLPB_ASSIGNED = "TLPB_ASSIGNED";
export const  TLPB_MANAGED = "TLPB_MANAGED";
export const  TLPB_CASES = "TLPB_CASES";

export const  TLPB_REASSIGNED = "TLPB_REASSIGNED";
export const  TLPB_DISCIPLINARY = "TLPB_DISCIPLINARY";
export const  TLPB_ACTIONS = "TLPB_ACTIONS";
export const  TLPB_RESOLUTIONS = "TLPB_RESOLUTIONS";
export const  TLPB_PAYMENTS_AND_COMMISSIONS = "TLPB_PAYMENTS_AND_COMMISSIONS";
export const  TLPB_MESSAGING = "TLPB_MESSAGING";
export const  TLPB_SETTINGS = "TLPB_SETTINGS";
export const  TLT_TRACING = "TLT_TRACING";
export const  TLT_IDENTIFYING = "TLT_IDENTIFYING";
export const  TLT_SEARCH = "TLT_SEARCH";
export const  TLT_RESULTS = "TLT_RESULTS";
export const  TLT_TRACERS = "TLT_TRACERS";
export const  TLT_ASSIGNED = "TLT_ASSIGNED";
export const  TLT_MANAGED = "TLT_MANAGED";
export const  TLT_CASES = "TLT_CASES";

export const  TLT_REASSIGNED = "TLT_REASSIGNED";
export const  TLT_DISCIPLINARY = "TLT_DISCIPLINARY";
export const  TLT_ACTIONS = "TLT_ACTIONS";
export const  TLT_RESOLUTIONS = "TLT_RESOLUTIONS";
export const  TLT_PAYMENTS_AND_COMMISSIONS = "TLT_PAYMENTS_AND_COMMISSIONS";
export const  TLT_MESSAGING = "TLT_MESSAGING";
export const  TLT_LEAVE_REQUESTS = "TLT_LEAVE_REQUESTS";
export const  TLT_MANAGE_LEAVES = "TLT_MANAGE_LEAVES";
export const  TLT_LEAVES_TYPE = "TLT_LEAVES_TYPE";
export const  TLT_SETTINGS = "TLT_SETTINGS";

export const  PB_CASES = "PB_CASES";
export const  PB_ASSIGNED = "PB_ASSIGNED";
export const  PB_MANAGED = "PB_MANAGED";
export const  PB_REASSIGNED = "PB_REASSIGNED";
export const  PB_DISCIPLINARY = "PB_DISCIPLINARY";
export const  PB_ACTIONS = "PB_ACTIONS";
export const  PB_RESOLUTIONS = "PB_RESOLUTIONS";
export const  PB_MESSAGING = "PB_MESSAGING";
export const  PB_PAYMENTS_AND_COMMISSIONS = "PB_PAYMENTS_AND_COMMISSIONS";
export const  PB_LEAVE_REQUESTS = "PB_LEAVE_REQUESTS";
export const  PB_MANAGE_LEAVES = "PB_MANAGE_LEAVES";
export const  PB_LEAVES_TYPE = "PB_LEAVES_TYPE";
export const  PB_SETTINGS = "PB_SETTINGS";

export const  FE_CASES = "FE_CASES";
export const  FE_ASSIGNED = "FE_ASSIGNED";
export const  FE_MANAGED = "FE_MANAGED";
export const  FE_REASSIGNED = "FE_REASSIGNED";
export const  FE_DISCIPLINARY = "FE_DISCIPLINARY";
export const  FE_ACTIONS = "FE_ACTIONS";
export const  FE_RESOLUTIONS = "FE_RESOLUTIONS";
export const  FE_MESSAGING = "FE_MESSAGING";
export const  FE_PAYMENTS_AND_COMMISSIONS = "FE_PAYMENTS_AND_COMMISSIONS";
export const  FE_LEAVE_REQUESTS = "FE_LEAVE_REQUESTS";
export const  FE_MANAGE_LEAVES = "FE_MANAGE_LEAVES";
export const  FE_LEAVES_TYPE = "FE_LEAVES_TYPE";
export const  FE_SETTINGS = "FE_SETTINGS";

export const  TC_TRACING = "TC_TRACING";
export const  TC_IDENTIFYING = "TC_IDENTIFYING";
export const  TC_SEARCH = "TC_SEARCH";
export const  TC_RESULTS = "TC_RESULTS";
export const  TC_CASES = "TC_CASES";
export const  TC_ASSIGNED = "TC_ASSIGNED";
export const  TC_MANAGED = "TC_MANAGED";
export const  TC_REASSIGNED = "TC_REASSIGNED";
export const  TC_DISCIPLINARY = "TC_DISCIPLINARY";
export const  TC_ACTIONS = "TC_ACTIONS";
export const  TC_RESOLUTIONS = "TC_RESOLUTIONS";
export const  TC_MESSAGING = "TC_MESSAGING";
export const  TC_PAYMENTS_AND_COMMISSIONS = "TC_PAYMENTS_AND_COMMISSIONS";
export const  TC_LEAVE_REQUESTS = "TC_LEAVE_REQUESTS";
export const  TC_MANAGE_LEAVES = "TC_MANAGE_LEAVES";
export const  TC_LEAVES_TYPE = "TC_LEAVES_TYPE";
export const  TC_SETTINGS = "TC_SETTINGS";

export const  COH_OPERATIONS = "COH_OPERATIONS";
export const  COH_RESOURCES = "COH_RESOURCES";
export const  COH_FINANCES = "COH_FINANCES";
export const  COH_MESSAGING = "COH_MESSAGING";
export const  COH_MAPS = "COH_MAPS";
export const  COH_PAYMENTS_AND_COMMISSIONS = "COH_PAYMENTS_AND_COMMISSIONS";
export const  COH_PROCESS_BASED = "COH_PROCESS_BASED";
export const  COH_TOTAL_PAYMENTS = "COH_TOTAL_PAYMENTS";
export const  COH_SETTINGS = "COH_SETTINGS";

export const  ZOH_OPERATIONS = "ZOH_OPERATIONS";
export const  ZOH_RESOURCES = "ZOH_RESOURCES";
export const  ZOH_FINANCES = "ZOH_FINANCES";
export const  ZOH_MESSAGING = "ZOH_MESSAGING";
export const  ZOH_MAPS = "ZOH_MAPS";
export const  ZOH_PAYMENTS_AND_COMMISSIONS = "ZOH_PAYMENTS_AND_COMMISSIONS";
export const  ZOH_PROCESS_BASED = "ZOH_PROCESS_BASED";
export const  ZOH_TOTAL_PAYMENTS = "ZOH_TOTAL_PAYMENTS";
export const  ZOH_SETTINGS = "ZOH_SETTINGS";

export const  SOH_OPERATIONS = "SOH_OPERATIONS";
export const  SOH_RESOURCES = "SOH_RESOURCES";
export const  SOH_FINANCES = "SOH_FINANCES";
export const  SOH_MESSAGING = "SOH_MESSAGING";
export const  SOH_MAPS = "SOH_MAPS";
export const  SOH_PAYMENTS_AND_COMMISSIONS = "SOH_PAYMENTS_AND_COMMISSIONS";
export const  SOH_PROCESS_BASED = "SOH_PROCESS_BASED";
export const  SOH_TOTAL_PAYMENTS = "SOH_TOTAL_PAYMENTS";
export const  SOH_SETTINGS = "SOH_SETTINGS";

export const  ROH_OPERATIONS = "ROH_OPERATIONS";
export const  ROH_RESOURCES = "ROH_RESOURCES";
export const  ROH_FINANCES = "ROH_FINANCES";
export const  ROH_MESSAGING = "ROH_MESSAGING";
export const  ROH_MAPS = "ROH_MAPS";
export const  ROH_PAYMENTS_AND_COMMISSIONS = "ROH_PAYMENTS_AND_COMMISSIONS";
export const  ROH_PROCESS_BASED = "ROH_PROCESS_BASED";
export const  ROH_TOTAL_PAYMENTS = "ROH_TOTAL_PAYMENTS";
export const  ROH_SETTINGS = "ROH_SETTINGS";

export const  DHBH_OPERATIONS = "DHBH_OPERATIONS";
export const  DHBH_RESOURCES = "DHBH_RESOURCES";
export const  DHBH_FINANCES = "DHBH_FINANCES";
export const  DHBH_MESSAGING = "DHBH_MESSAGING";
export const  DHBH_MAPS = "DHBH_MAPS";
export const  DHBH_PAYMENTS_AND_COMMISSIONS = "DHBH_PAYMENTS_AND_COMMISSIONS";
export const  DHBH_PROCESS_BASED = "DHBH_PROCESS_BASED";
export const  DHBH_TOTAL_PAYMENTS = "DHBH_TOTAL_PAYMENTS";
export const  DHBH_SETTINGS = "DHBH_SETTINGS";

export const  COP_OPERATIONS = "COP_OPERATIONS";
export const  COP_RESOURCES = "COP_RESOURCES";
export const  COP_FINANCES = "COP_FINANCES";
export const  COP_MESSAGING = "COP_MESSAGING";
export const  COP_MAPS = "COP_MAPS";
export const  COP_PAYMENTS_AND_COMMISSIONS = "COP_PAYMENTS_AND_COMMISSIONS";
export const  COP_PROCESS_BASED = "COP_PROCESS_BASED";
export const  COP_TOTAL_PAYMENTS = "COP_TOTAL_PAYMENTS";
export const  COP_SETTINGS = "COP_SETTINGS";

export const  CUA_RESOURCES = "CUA_RESOURCES";
export const  CUA_ASSERTS = "CUA_ASSERTS";
export const  CUA_USERS = "CUA_USERS";
export const  CUA_COUNTRY_REPORTS = "CUA_COUNTRY_REPORTS";
export const  CUA_SETTINGS = "CUA_SETTINGS";

export const  SA_RESOURCES = "SA_RESOURCES";
export const  SA_ASSERTS = "SA_ASSERTS";
export const  SA_USERS = "SA_USERS";
export const  SA_STATE_REPORTS = "SA_STATE_REPORTS";
export const  SA_SETTINGS = "SA_SETTINGS";

export const  COA_RESOURCES = "COA_RESOURCES";
export const  COA_ASSERTS = "COA_ASSERTS";
export const  COA_USERS = "COA_USERS";
export const  COA_COMPANY_REPORTS = "COA_COMPANY_REPORTS";
export const  COA_SETTINGS = "COA_SETTINGS";

export const  SYA_CODES_DP = "SYA_CODES_DP";
export const  SYA_CONTACTABLE = "SYA_CONTACTABLE";
export const  SYA_NON_CONTACTABLE = "SYA_NON_CONTACTABLE";
export const  SYA_CODE_MATCHING_LIST = "SYA_CODE_MATCHING_LIST";
export const  SYA_SETTINGS = "SYA_SETTINGS";

export const  SUA_COMPANY = "SUA_COMPANY";
export const  SUA_COMPANY_DETAILS = "SUA_COMPANY_DETAILS";
export const  SUA_BACKUP_COMPANY_DATA = "SUA_BACKUP_COMPANY_DATA";
export const  SUA_RESTORE_COMPANY_DATA = "SUA_RESTORE_COMPANY_DATA";
export const  SUA_BASIC = "SUA_BASIC";
export const  SUA_CURRENCY = "SUA_CURRENCY";
export const  SUA_LANGUAGE = "SUA_LANGUAGE";
export const  SUA_TAXES = "SUA_TAXES";
export const  SUA_SYSTEM = "SUA_SYSTEM";
export const  SUA_LOG = "SUA_LOG";
export const  SUA_LOCATION_LOG = "SUA_LOCATION_LOG";
export const  SUA_BACKUP = "SUA_BACKUP";
export const  SUA_RESTORE = "SUA_RESTORE";
export const  SUA_COMPANY_BASED_REPORTS = "SUA_COMPANY_BASED_REPORTS";
export const  SUA_SERVICE_REPORTS = "SUA_SERVICE_REPORTS";
export const  SUA_MAINTENANCE_REPORTS = "SUA_MAINTENANCE_REPORTS";
export const  SUA_SETTINGS = "SUA_SETTINGS";

export const  HR_MANAGER_DASHBOARD = "HR_MANAGER_DASHBOARD";
export const  HR_EXECUTIVE_DASHBOARD = "HR_EXECUTIVE_DASHBOARD";
export const  TRAINING_HEAD_DASHBOARD = "TRAINING_HEAD_DASHBOARD";
export const  TRAINEE_TEAM_LEADER_DASHBOARD = "TRAINEE_TEAM_LEADER_DASHBOARD";
export const  TRAINER_DASHBOARD = "TRAINER_DASHBOARD";
export const  OFFICE_ADMIN_DASHBOARD = "OFFICE_ADMIN_DASHBOARD";
export const  FINANCE_MANAGER_DASHBOARD = "FINANCE_MANAGER_DASHBOARD";
export const  ACCOUNTS_HEAD_DASHBOARD = "ACCOUNTS_HEAD_DASHBOARD";
export const  ACCOUNTS_EXECUTIVE_DASHBOARD = "ACCOUNTS_EXECUTIVE_DASHBOARD";
export const  ACCOUNTS_CLERK_DASHBOARD = "ACCOUNTS_CLERK_DASHBOARD";
export const  IT_HEAD_DASHBOARD = "IT_HEAD_DASHBOARD";
export const  IT_MANAGER_DASHBOARD = "IT_MANAGER_DASHBOARD";

export const  ASSERTS_MANAGER_HW_ADMINISTRATOR_DASHBOARD = "ASSERTS_MANAGER_HW_ADMINISTRATOR_DASHBOARD";
export const  ADMINISTRATOR_DASHBOARD = "ADMINISTRATOR_DASHBOARD";
export const  MIS_MANAGER_DASHBOARD = "MIS_MANAGER_DASHBOARD";
export const  MIS_EXECUTIVE_DASHBOARD = "MIS_EXECUTIVE_DASHBOARD";
export const  PROCESS_HEAD_DASHBOARD = "PROCESS_HEAD_DASHBOARD";
export const  PROCESS_MANAGER_DASHBOARD = "PROCESS_MANAGER_DASHBOARD";
export const  TEAM_LEADER_FIELD_EXECUTIVES_DASHBOARD = "TEAM_LEADER_FIELD_EXECUTIVES_DASHBOARD";
export const  TEAM_LEADER_PHONE_BANKERS_DASHBOARD = "TEAM_LEADER_PHONE_BANKERS_DASHBOARD";
export const  TEAM_LEADER_TRACERS_DASHBOARD = "TEAM_LEADER_TRACERS_DASHBOARD";
export const  PHONE_BANKER_DASHBOARD = "PHONE_BANKER_DASHBOARD";
export const  FIELD_EXECUTIVE_DASHBOARD = "FIELD_EXECUTIVE_DASHBOARD";
export const  TRACERS_DASHBOARD = "TRACERS_DASHBOARD";

export const  COUNTRY_OPERATIONS_HEAD_DASHBOARD = "COUNTRY_OPERATIONS_HEAD_DASHBOARD";
export const  ZONE_OPERATIONS_HEAD_DASHBOARD = "ZONE_OPERATIONS_HEAD_DASHBOARD";
export const  STATE_OPERATIONS_HEAD_DASHBOARD = "STATE_OPERATIONS_HEAD_DASHBOARD";
export const  REGIONAL_OPER_HEAD_DASHBOARD = "REGIONAL_OPER_HEAD_DASHBOARD";
export const  DISTRICT_HEAD_BRANCH_HEAD_DASHBOARD = "DISTRICT_HEAD_BRANCH_HEAD_DASHBOARD";
export const  COMPANY_OWNER_PARTNER_DASHBOARD = "COMPANY_OWNER_PARTNER_DASHBOARD";
export const  COUNTRY_ADMINISTRATOR_DASHBOARD = "COUNTRY_ADMINISTRATOR_DASHBOARD";
export const  STATE_ADMINISTRATOR_DASHBOARD = "STATE_ADMINISTRATOR_DASHBOARD";
export const  COMPANY_ADMINISTRATOR_DASHBOARD = "COMPANY_ADMINISTRATOR_DASHBOARD";
export const  SYSTEM_ADMINISTRATOR_DASHBOARD = "SYSTEM_ADMINISTRATOR_DASHBOARD";
export const  SUPER_ADMINISTRATOR_DASHBOARD = "SUPER_ADMINISTRATOR_DASHBOARD" ;