import React from 'react'

function Paid_Orders() {
  return (
    <div>
      Paid_Orders
Paid_Orders
    </div>
  )
}

export default Paid_Orders
