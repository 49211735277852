import React from 'react'

function Cases() {
  return (
    <div>
      Cases
Cases
    </div>
  )
}

export default Cases
