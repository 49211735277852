import React from 'react'

function Search() {
  return (
    <div>
      Search
Search
    </div>
  )
}

export default Search
