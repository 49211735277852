import React from 'react'

function Daily() {
  return (
    <div>
      Daily
Daily
    </div>
  )
}

export default Daily
