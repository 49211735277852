import React from 'react'

function Department() {
  return (
    <div>
      Department
Department
    </div>
  )
}

export default Department
