import React from 'react'

function Support_Tickets() {
  return (
    <div>
      Support_Tickets
Support_Tickets
    </div>
  )
}

export default Support_Tickets
