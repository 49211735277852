import React from 'react'

function Managed() {
  return (
    <div>
      Managed
Managed
    </div>
  )
}

export default Managed
