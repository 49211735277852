import React from 'react'

function Create_Ticket() {
  return (
    <div>
Create_Ticket
Create_Ticket
    </div>
  )
}

export default Create_Ticket
