import React from 'react'

function Accounts() {
  return (
    <div>
      Accounts
Accounts
    </div>
  )
}

export default Accounts
