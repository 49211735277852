import React from 'react'

function Transactions() {
  return (
    <div>
      Transactions
Transactions
    </div>
  )
}

export default Transactions
