import React from 'react'

function Manage_category() {
  return (
    <div>
      Manage_category
Manage_category
    </div>
  )
}

export default Manage_category
