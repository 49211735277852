import React from 'react'

function Department_list() {
  return (
    <div>
      Department_list
Department_list
    </div>
  )
}

export default Department_list
