import React from 'react'

function Competencies() {
  return (
    <div>
      Competencies
Competencies
    </div>
  )
}

export default Competencies
