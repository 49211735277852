import React from 'react'

function New_Holiday() {
  return (
    <div>
      New_Holiday
New_Holiday
    </div>
  )
}

export default New_Holiday
