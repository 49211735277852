import React from 'react'

function Tickets() {
  return (
    <div>
      Tickets
Tickets
    </div>
  )
}

export default Tickets
