import React from 'react'

function Product_Tax() {
  return (
    <div>
      Product_Tax
Product_Tax
    </div>
  )
}

export default Product_Tax
