import React from 'react'

function Make_Announcement() {
  return (
    <div>
      Make_Announcement
Make_Announcement
    </div>
  )
}

export default Make_Announcement
