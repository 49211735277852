import { combineReducers } from 'redux';

// import all the reducers for the pages 
import settings from './settings';
import auth from './auth';
import message from './message';

// TODO: derbe pages


export default combineReducers({
    settings,
    auth,
    message
    // TODO: add each reducer here 
});

// combine reducer is exported as rootReducer