import React from 'react'

function Holidays() {
  return (
    <div>
      Holidays
Holidays
    </div>
  )
}

export default Holidays
