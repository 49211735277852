import React from 'react'

function Recruitment_ATS() {
  return (
    <div>
      Recruitment_ATS
Recruitment_ATS
    </div>
  )
}

export default Recruitment_ATS
