import React from 'react'

function Announcement_List() {
  return (
    <div>
      Announcement_List
Announcement_List
    </div>
  )
}

export default Announcement_List
