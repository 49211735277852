import React from 'react'

function Transfers() {
  return (
    <div>
      Transfers
Transfers
    </div>
  )
}

export default Transfers
