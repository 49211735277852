import React from 'react'

function Asset_Brand() {
  return (
    <div>
      Asset_Brand
Asset_Brand
    </div>
  )
}

export default Asset_Brand
