import React from 'react'

function Roles_Privileges() {
  return (
    <div>
      Roles_Privileges
Roles_Privileges
    </div>
  )
}

export default Roles_Privileges
