import React from 'react'

function Purchases() {
  return (
    <div>
      Purchases
Purchases
    </div>
  )
}

export default Purchases
