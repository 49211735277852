import React from 'react'

function View() {
  return (
    <div>
      View
View
    </div>
  )
}

export default View
