import React from 'react'

function Complaint_List() {
  return (
    <div>
      Complaint_List
Complaint_List
    </div>
  )
}

export default Complaint_List
