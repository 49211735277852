import React from 'react'

function Technical() {
  return (
    <div>
      Technical
Technical
    </div>
  )
}

export default Technical
