import React from 'react'

function Employees() {
  return (
    <div>
      Employees
Employees
    </div>
  )
}

export default Employees
