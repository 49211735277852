import React from 'react'

function Messaging() {
  return (
    <div>
      Messaging
      
    </div>
  )
}

export default Messaging
