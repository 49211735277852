import React from 'react'

function Operations() {
  return (
    <div>
      Operations
Operations
    </div>
  )
}

export default Operations
