import React from 'react'

function Resignations() {
  return (
    <div>
      Resignations
Resignations
    </div>
  )
}

export default Resignations
