import React from 'react'

function New_Announcement() {
  return (
    <div>
      New_Announcement
New_Announcement
    </div>
  )
}

export default New_Announcement
