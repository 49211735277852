import React from 'react'

function Travels() {
  return (
    <div>
Travels
Travels
    </div>
  )
}

export default Travels
