import React from 'react'

function Training_Skills() {
  return (
    <div>
      Training_Skills
Training_Skills
    </div>
  )
}

export default Training_Skills
