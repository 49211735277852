import React from 'react'

function Tax_Type() {
  return (
    <div>
      Tax_Type
Tax_Type
    </div>
  )
}

export default Tax_Type
