import React from 'react'

function Complaints() {
  return (
    <div>
      Complaints
Complaints
    </div>
  )
}

export default Complaints
