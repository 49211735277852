import React from 'react'

function Location_Log() {
  return (
    <div>
      Location_Log
    </div>
  )
}

export default Location_Log
