import React from 'react'

function Disciplinary() {
  return (
    <div>
      Disciplinary
Disciplinary
    </div>
  )
}

export default Disciplinary
