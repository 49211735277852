import React from 'react'

function Payments() {
  return (
    <div>
      Payments
Payments
    </div>
  )
}

export default Payments
