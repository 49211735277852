import React from 'react'

function Expired_Products() {
  return (
    <div>
      Expired_Products
Expired_Products
    </div>
  )
}

export default Expired_Products
