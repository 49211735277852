import React from 'react'

function Disciplinary_Cases() {
  return (
    <div>
      Disciplinary_Cases
Disciplinary_Cases
    </div>
  )
}

export default Disciplinary_Cases
