import React from 'react'

function Conf_booking() {
  return (
    <div>
      Conf_booking
Conf_booking
    </div>
  )
}

export default Conf_booking
