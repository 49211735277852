import React from 'react'

function Company() {
  return (
    <div>
      Company
    </div>
  )
}

export default Company
