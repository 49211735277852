import React from 'react'

function Incentive_Types() {
  return (
    <div>
      Incentive_Types
Incentive_Types
    </div>
  )
}

export default Incentive_Types
