import React from 'react'

function Destory_assert() {
  return (
    <div>
      Destory_assert
Destory_assert
    </div>
  )
}

export default Destory_assert
