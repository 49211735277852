import React, { Component } from 'react';
import { connect } from 'react-redux';
import MetisMenu from 'react-metismenu';
import { Switch, Route, NavLink } from 'react-router-dom';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import DefaultLink from './DefaultLink';


import {
	darkModeAction, darkHeaderAction, fixNavbarAction,
	darkMinSidebarAction, darkSidebarAction, iconColorAction,
	gradientColorAction, rtlAction, fontAction,
	subMenuIconAction,
	menuIconAction,
	boxLayoutAction,
	statisticsAction, friendListAction,
	statisticsCloseAction, friendListCloseAction, toggleLeftMenuAction
} from '../../actions/settingsAction';
import Routes from '../Route';


const masterNone = {
	display: 'none',
};

const masterBlock = {
	display: 'block',
};

class Menu extends Component {
	constructor(props) {
		super(props);
		this.toggleLeftMenu = this.toggleLeftMenu.bind(this);
		this.toggleUserMenu = this.toggleUserMenu.bind(this);
		this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
		this.toggleSubMenu = this.toggleSubMenu.bind(this);
		this.handleDarkMode = this.handleDarkMode.bind(this);
		this.handleFixNavbar = this.handleFixNavbar.bind(this);
		this.handleDarkHeader = this.handleDarkHeader.bind(this);
		this.handleMinSidebar = this.handleMinSidebar.bind(this);
		this.handleSidebar = this.handleSidebar.bind(this);
		this.handleIconColor = this.handleIconColor.bind(this);
		this.handleGradientColor = this.handleGradientColor.bind(this);
		this.handleRtl = this.handleRtl.bind(this);
		this.handleFont = this.handleFont.bind(this);
		this.handleStatistics = this.handleStatistics.bind(this);
		this.handleFriendList = this.handleFriendList.bind(this);
		this.closeFriendList = this.closeFriendList.bind(this);
		this.closeStatistics = this.closeStatistics.bind(this);
		this.handleBoxLayout = this.handleBoxLayout.bind(this);
		this.handler = this.handler.bind(this);
		this.state = {
			isToggleLeftMenu: false,
			isOpenUserMenu: false,
			isOpenRightSidebar: false,
			isBoxLayout: false,
			parentlink: null,
			childlink: null,
		};
	}

	componentDidMount() {
		const { location } = this.props;
		const links = location.pathname.substring(1).split(/-(.+)/);
		const parentlink = links[0];
		const nochildlink = links[1];

		if (parentlink && nochildlink && nochildlink === 'dashboard') {
			this.handler(parentlink, `${parentlink}${nochildlink}`);
		} else if (parentlink && nochildlink && nochildlink !== 'dashboard') {
			this.handler(parentlink, nochildlink);
		} else if (parentlink) {
			this.handler(parentlink, '');
		} else {
			this.handler('hr', 'dashboard');
		}
	}

	componentDidUpdate(prevprops, prevstate) {
		const { location } = this.props;
		const links = location.pathname.substring(1).split(/-(.+)/);
		const parentlink = links[0];
		const nochildlink = links[1];
		if (prevprops.location !== location) {
			if (parentlink && nochildlink && nochildlink === 'dashboard') {
				this.handler(parentlink, `${parentlink}${nochildlink}`);
			} else if (parentlink && nochildlink && nochildlink !== 'dashboard') {
				this.handler(parentlink, nochildlink);
			} else if (parentlink) {
				this.handler(parentlink, '');
			} else {
				this.handler('hr', 'dashboard');
			}
		}
	}

	handler(parentlink, nochildlink) {
		this.setState({
			parentlink: parentlink,
			childlink: nochildlink,
		});
	}

	handleDarkMode(e) {
		this.props.darkModeAction(e.target.checked)
	}
	handleFixNavbar(e) {
		this.props.fixNavbarAction(e.target.checked)
	}
	handleDarkHeader(e) {
		this.props.darkHeaderAction(e.target.checked)
	}
	handleMinSidebar(e) {
		this.props.darkMinSidebarAction(e.target.checked)
	}
	handleSidebar(e) {
		this.props.darkSidebarAction(e.target.checked)
	}
	handleIconColor(e) {
		this.props.iconColorAction(e.target.checked)
	}
	handleGradientColor(e) {
		this.props.gradientColorAction(e.target.checked)
	}
	handleRtl(e) {
		this.props.rtlAction(e.target.checked)
	}
	handleFont(e) {
		this.props.fontAction(e)
	}
	handleFriendList(e) {
		this.props.friendListAction(e)
	}
	handleStatistics(e) {
		this.props.statisticsAction(e)
	}
	closeFriendList(e) {
		this.props.friendListCloseAction(e)
	}
	closeStatistics(e) {
		this.props.statisticsCloseAction(e)
	}
	handleSubMenuIcon(e) {
		this.props.subMenuIconAction(e)
	}
	handleMenuIcon(e) {
		this.props.menuIconAction(e)
	}
	handleBoxLayout(e) {
		this.props.boxLayoutAction(e.target.checked)
	}
	toggleLeftMenu(e) {
		console.log(e, 'asdasdada')
		this.props.toggleLeftMenuAction(e)
	}
	toggleRightSidebar() {
		this.setState({ isOpenRightSidebar: !this.state.isOpenRightSidebar })
	}
	toggleUserMenu() {
		this.setState({ isOpenUserMenu: !this.state.isOpenUserMenu })
	}
	toggleSubMenu(e) {
		let menucClass = ''
		if (e.itemId) {
			const subClass = e.items.map((menuItem, i) => {
				if (menuItem.to === this.props.location.pathname) {
					menucClass = "in";
				} else {
					menucClass = "collapse";
				}
				return menucClass
			})
			return subClass
			// return "collapse";
		} else {
			return e.visible ? "collapse" : "metismenu";
		}
	}

	// switch finction for the menu name 
	

	render() {
		const tes ='SystemAdministrator';
		const content = [
			{
				"id": 'Directories',
				"label": "Menu"
			},
			{
				"id": 1,
				"icon": "icon-rocket",
				"label": "HRMS",
				"to": "#!",
				content: [
					{
						"id": 3,
						"label": "Dashboard",
						"to": "/"
					},
					{
						"id": 4,
						"label": "Users",
						"to": "/hr-users"
					},
					{
						"id": 5,
						"label": "Department",
						"to": "/hr-department"
					},
					{
						"id": 6,
						"label": "Employee",
						"to": "/hr-employee"
					},
					{
						"id": 7,
						"label": "Activities",
						"to": "/hr-activities"
					},
					{
						"id": 8,
						"label": "Holidays",
						"to": "/hr-holidays"
					},
					{
						"id": 9,
						"label": "Events",
						"to": "/hr-events"
					},
					{
						"id": 10,
						"label": "Payroll",
						"to": "/hr-payroll"
					},
					{
						"id": 11,
						"label": "Accounts",
						"to": "/hr-accounts"
					},
					{
						"id": 12,
						"label": "Report",
						"to": "/hr-report"
					}
				]
			},
			{
				"id": 13,
				"icon": "icon-cup",
				"label": "Project",
				content: [
					{
						"id": 14,
						"label": "Dashboard",
						"to": "/project-dashboard"
					},
					{
						"id": 15,
						"label": "Project List",
						"to": "/project-list"
					},
					{
						"id": 16,
						"label": "Taskboard",
						"to": "/project-taskboard"
					},
					{
						"id": 17,
						"label": "Ticket List",
						"to": "/project-ticket"
					},
					{
						"id": 18,
						"label": "Ticket Details",
						"to": "/project-ticket-details"
					},
					{
						"id": 19,
						"label": "Clients",
						"to": "/project-clients"
					},
					{
						"id": 20,
						"label": "Todo List",
						"to": "/project-todo"
					}
				]
			},
			{
				"id": 21,
				"icon": "icon-briefcase",
				"label": "Job Portal",
				content: [
					{
						"id": 22,
						"label": "Job Dashboard",
						"to": "/jobportal-dashboard"
					},
					{
						"id": 23,
						"label": "Positions",
						"to": "/jobportal-positions"
					},
					{
						"id": 24,
						"label": "Applicant",
						"to": "/jobportal-applicants"
					},
					{
						"id": 25,
						"label": "Resumes",
						"to": "/jobportal-resumes"
					},
					{
						"id": 26,
						"label": "Settings",
						"to": "/jobportal-settings"
					}
				]
			},
			{
				"id": 27,
				"icon": "icon-lock",
				"label": "Authentication",
				content: [
					{
						"id": 28,
						"label": "Login",
						"to": "/login"
					},
					{
						"id": 29,
						"label": "Register",
						"to": "/signup"
					},
					{
						"id": 30,
						"label": "Forgot Password",
						"to": "/forgotpassword"
					},
					{
						"id": 31,
						"label": "404 error",
						"to": "/notfound"
					},
					{
						"id": 32,
						"label": "500 Error",
						"to": "/internalserver"
					}
				]
			},
			{
				"id": 'UiElements',
				"label": "Ui Elements"
			},
			{
				"id": 33,
				"icon": "icon-tag",
				"label": "Icons",
				"to": "/icons",
			},
			{
				"id": 34,
				"icon": "icon-bar-chart",
				"label": "Charts",
				"to": "/charts",
			},
			{
				"id": 35,
				"icon": "icon-layers",
				"label": "Forms",
				"to": "/forms",
			},
			{
				"id": 36,
				"icon": "icon-tag",
				"label": "Tables",
				"to": "/tables",
			},
			{
				"id": 37,
				"icon": "icon-puzzle",
				"label": "Widgets",
				"to": "/widgets",
			},
			{
				"id": 38,
				"icon": "icon-map",
				"label": "Maps",
				"to": "/maps",
			},
			{
				"id": 39,
				"icon": "icon-picture",
				"label": "Gallery",
				"to": "/gallery",
			},
		];




		// SystemAdministrator
		const MenuSystemAdministrator = [
			{
				"id": 'Directories',
				"label": "System Administrator"
			},

			{"id":880, "label":"Dashboard", "to": "/system-administrator-dashboard", },
			{"id":881, "label":"Codes DP", "to": "/sya-codes-dp", content: [ 
				{"id":8811, "label":"Codes DP", "to": "/sya-codes-dp",},
			{"id":882, "label":"Contactable", "to": "/sya-contactable", }  ,
			{"id":883, "label":"Non Contactable", "to": "/sya-non-contactable", }  ,
			{"id":884, "label":"Code Matching List", "to": "/sya-code-matching-list", }, ], }, 
			
			{"id":885, "label":"Settings", "to": "/sya-settings", },


			// SuperAdministrator

			{
				"id": 'Directories',
				"label": "Super Administrator"
			},
					
			{"id":887, "label":"Dashboard", "to": "/super-administrator-dashboard", },
			{"id":888, "label":"Company", "to": "/sua-company", content: [  
			{"id":889, "label":"Company Details", "to": "/sua-company-details", } ,
			{"id":890, "label":"Backup company data", "to": "/sua-backup-company-data", },
			{"id":891, "label":"Restore company data", "to": "/sua-restore-company-data", }, ], }, 
			{"id":892, "label":"Basic", "to": "/sua-basic", content: [  
			{"id":893, "label":"Currency", "to": "/sua-currency", },
			{"id":894, "label":"Language", "to": "/sua-language", },
			{"id":895, "label":"Taxes", "to": "/sua-taxes", }, ], }, 
			{"id":896, "label":"System", "to": "/sua-system", content: [  
			{"id":897, "label":"Log", "to": "/sua-log", }  ,
			{"id":898, "label":"Location Log", "to": "/sua-location-log", }  ,
			{"id":899, "label":"Backup", "to": "/sua-backup", }  ,
			{"id":900, "label":"restore", "to": "/sua-restore", }, ], }, 
			{"id":901, "label":"Company based Reports", "to": "/sua-company-based-reports", content: [  
			{"id":902, "label":"Service Reports", "to": "/sua-service-reports", } ,
			{"id":903, "label":"Maintenance Reports", "to": "/sua-maintenance-reports", }, ], }, 
			{"id":904, "label":"Settings", "to": "/sua-settings", },
			
				// CompanyAdministrator
			{
				"id": 'Directories',
				"label": "Company Administrator"
			},
			{"id":872, "label":"Dashboard", "to": "/company-administrator-dashboard", },
			{"id":873, "label":"Resources ", "to": "/coa-resources", content: [
			{"id":874, "label":"Asserts", "to": "/coa-asserts", },
			{"id":875, "label":"Users ", "to": "/coa-users", }, ], },
			{"id":876, "label":"Maps & Track " , "to": "/coa-maps", },
			{"id":877, "label":"Company Reports " , "to": "/coa-company-reports", },
			{"id":878, "label":"Settings", "to": "/coa-settings", },


			// CountryAdministrator

			{
				"id": 'Directories',
				"label": "Country Administrator"
			},		

			{"id":857, "label":"Dashboard " , "to": "/country-administrator-dashboard", },
			{"id":858, "label":"Resources ","to": "/cua-resources", content: [  
			{"id":859, "label":"Asserts", "to": "/cua-asserts", }  ,
			{"id":860, "label":"Users ", "to": "/cua-users", }, ], }, 
			{"id":861, "label":"Country Reports " , "to": "/cua-country-reports", },
			{"id":862, "label":"Settings", "to": "/cua-settings", },

			// StateAdministrator

			{
				"id": 'Directories',
				"label": "State Administrator"
			},
			

			{"id":864, "label":"Dashboard  ", "to": "/state-administrator-dashboard", } , 
			{"id":865, "label":"Resources ", "to": "/sa-resources", content: [  
			{"id":866, "label":"Asserts", "to": "/sa-asserts", }  ,
			{"id":867, "label":"Users ", "to": "/sa-users", }, ], }, 
			{"id":868, "label":"Maps & Track " , "to": "/maps", },
			{"id":869, "label":"state Reports " , "to": "/sa-state-reports", },
			{"id":870, "label":"Settings", "to": "/sa-settings", },

			//Business Administration

			// CompanyOwnerPartner
			{
				"id": 'Directories',
				"label": "Company Owner Partner"
			},
			

			{"id":845, "label":"Dashboard " , "to": "/company-owner-partner-dashboard", }  ,
			{"id":846, "label":"Operations" , "to": "/cop-operations", },
			{"id":847, "label":"Resources" , "to": "/cop-resources", },
			{"id":848, "label":"Finances" , "to": "/cop-finances", },
			{"id":849, "label":"Messaging" , "to": "/cop-messaging", },
			{"id":850, "label":"Maps" , "to": "/cop-maps", },
			{"id":851, "label":"Payments and Commissions", "to": "/cop-payments-and-commissions", content: [ 
			{"id":852, "label":"Process based", "to": "/cop-process-based", }  ,
			{"id":853, "label":"Total Payments", "to": "/cop-total-payments", }, ], }, 
			{"id":854, "label":"Settings", "to": "/cop-settings", },


			// CountryOperationsHead
			{
				"id": 'Directories',
				"label": "Country Operations Head"
			},
			

			{"id":790, "label":"Dashboard " , "to": "/country-operations-head-dashboard", },
			{"id":791, "label":"Operations" , "to": "/coh-operations", },
			{"id":792, "label":"Resources" , "to": "/coh-resources", },
			{"id":793, "label":"Finances" , "to": "/coh-finances", },
			{"id":794, "label":"Messaging" , "to": "/coh-messaging", },
			{"id":795, "label":"Maps" , "to": "/coh-maps", },
			{"id":796, "label":"Payments and Commissions", "to": "/coh-payments-and-commissions",  content: [ 
			{"id":797, "label":"Process based", "to": "/coh-process-based", } , 
			{"id":798, "label":"Total Payments", "to": "/coh-total-payments", }, ], }, 
			{"id":799, "label":"Settings", "to": "/coh-settings", },

			// ZoneOperationsHead
			
			{
				"id": 'Directories',
				"label": "Zone Operations Head"
			},
			

			{"id":801, "label":"Dashboard " , "to": "/zone-operations-head-dashboard", },
			{"id":802, "label":"Operations" , "to": "/zoh-operations", },
			{"id":803, "label":"Resources" , "to": "/zoh-resources", },
			{"id":804, "label":"Finances" , "to": "/zoh-finances", },
			{"id":805, "label":"Messaging" , "to": "/zoh-messaging", },
			{"id":806, "label":"Maps" , "to": "/zoh-maps", },
			{"id":807, "label":"Payments and Commissions", "to": "/zoh-payments-and-commissions",  content: [
			{"id":808, "label":"Process based", "to": "/zoh-process-based", } , 
			{"id":809, "label":"Total Payments", "to": "/zoh-total-payments", }, ], }, 
			{"id":810, "label":"Settings", "to": "/zoh-settings", },

			
			// RegionalOperHead

			{
				"id": 'Directories',
				"label": "Regional Operations Head"
			},
			

			{"id":823, "label":"Dashboard " , "to": "/regional-oper-head-dashboard", },
			{"id":824, "label":"Operations" , "to": "/roh-operations", },
			{"id":825, "label":"Resources" , "to": "/roh-resources", },
			{"id":826, "label":"Finances" , "to": "/roh-finances", },
			{"id":827, "label":"Messaging" , "to": "/roh-messaging", },
			{"id":828, "label":"Maps" , "to": "/roh-maps", },
			{"id":829, "label":"Payments and Commissions", "to": "/roh-payments-and-commissions", content: [  
			{"id":830, "label":"Process based", "to": "/roh-process-based", }  ,
			{"id":831, "label":"Total Payments", "to": "/roh-total-payments", }, ], }, 
			{"id":832, "label":"Settings", "to": "/roh-settings", },


			// StateOperationsHead

			{
				"id": 'Directories',
				"label": "State Operations Head"
			},
			

			{"id":812, "label":"Dashboard " , "to": "/state-operations-head-dashboard", },
			{"id":813, "label":"Operations" , "to": "/soh-operations", },
			{"id":814, "label":"Resources" , "to": "/soh-resources", },
			{"id":815, "label":"Finances" , "to": "/soh-finances", },
			{"id":816, "label":"Messaging" , "to": "/soh-messaging", },
			{"id":817, "label":"Maps" , "to": "/soh-maps", },
			{"id":818, "label":"Payments and Commissions", "to": "/soh-payments-and-commissions",  content: [  
			{"id":819, "label":"Process based", "to": "/soh-process-based", }  ,
			{"id":820, "label":"Total Payments", "to": "/soh-total-payments", }, ], }, 
			{"id":821, "label":"Settings", "to": "/soh-settings", },

			

			// DistrictHeadBranchHead

			{
				"id": 'Directories',
				"label": "District Head Branch Head"
			},
			

			{"id":834, "label":"Dashboard " , "to": "/district-head-branch-head-dashboard", },
			{"id":835, "label":"Operations" , "to": "/dhbh-operations", },
			{"id":836, "label":"Resources" , "to": "/dhbh-resources", },
			{"id":837, "label":"Finances" , "to": "/dhbh-finances", },
			{"id":838, "label":"Messaging" , "to": "/dhbh-messaging", },
			{"id":839, "label":"Maps" , "to": "/dhbh-maps", },
			{"id":840, "label":"Payments and Commissions", "to": "/dhbh-payments-and-commissions", content: [
			{"id":841, "label":"Process based", "to": "/dhbh-process-based", }  ,
			{"id":842, "label":"Total Payments", "to": "/dhbh-total-payments", }, ], }, 
			{"id":843, "label":"Settings", "to": "/dhbh-settings", },			
			

			//Process Management Dept.

			// ProcessHead

			{
				"id": 'Directories',
				"label": "Process Head"
			},
			
			{"id":641, "label":"Dashboard", "to": "/process-head-dashboard", },
			{"id":642, "label":"Leave Requests", "to": "/ph-leave-requests", content: [
			{"id":643, "label":"Manage Leaves", "to": "/ph-manage-leaves", },
			{"id":644, "label":"Leaves Type", "to": "/ph-leaves-type", }, ], }, 
			{"id":645, "label":"Processes", "to": "/ph-processes", content: [
			{"id":646, "label":"New Process", "to": "/ph-new-process", },
			{"id":647, "label":"Process flow", "to": "/ph-process-flow", },
			{"id":648, "label":"Process Management", "to": "/ph-process-management", },
			{"id":649, "label":"Completed Process", "to": "/ph-completed-process", }, ], }, 
			{"id":650, "label":"Complacencies ", "to": "/ph-complacencies",  content: [
			{"id":651, "label":"Process", "to": "/ph-process", },
			{"id":652, "label":"Client", "to": "/ph-client", }, ], }, 
			{"id":653, "label":"Reports ", "to": "/ph-reports", content: [
			{"id":654, "label":"Daily ", "to": "/ph-daily", },
			{"id":655, "label":"Monthly ", "to": "/ph-monthly", },
			{"id":656, "label":"Yearly", "to": "/ph-yearly", },
			{"id":657, "label":"Process ", "to": "/ph-process", }, ], }, 
			{"id":658, "label":"Payments and Commissions" , "to": "/ph-payments-and-commissions", },
			{"id":659, "label":"Messaging " , "to": "/ph-messaging", },
			{"id":660, "label":"Settings", "to": "/ph-settings", },

			
			

			// ProcessManager

			{
				"id": 'Directories',
				"label": "Process Manager"
			},
			

			{"id":660, "label":"Dashboard", "to": "/process-manager-dashboard", },
			{"id":661, "label":"Leave Requests", "to": "/pm-leave-requests", content: [
			{"id":662, "label":"Manage Leaves", "to": "/pm-manage-leaves", },
			{"id":663, "label":"Leaves Type", "to": "/pm-leaves-type", }, ], }, 
			{"id":664, "label":"Processes", "to": "/pm-processes", content: [
			{"id":665, "label":"New Process", "to": "/pm-new-process", },
			{"id":666, "label":"Process flow", "to": "/pm-process-flow", },
			{"id":667, "label":"Process Management", "to": "/pm-process-management", },
			{"id":668, "label":"Completed Process", "to": "/pm-completed-process", }, ], }, 
			{"id":669, "label":"Complacencies ", "to": "/pm-complacencies", content: [
			{"id":670, "label":"Process", "to": "/pm-process", },
			{"id":671, "label":"Client", "to": "/pm-client", }, ], }, 
			{"id":672, "label":"Reports ", "to": "/pm-reports", content: [
			{"id":673, "label":"Daily ", "to": "/pm-daily", },
			{"id":674, "label":"Monthly ", "to": "/pm-monthly", },
			{"id":675, "label":"Yearly", "to": "/pm-yearly", },
			{"id":676, "label":"Process ", "to": "/pm-process", }, ], }, 
			{"id":677, "label":"Payments and Commissions" , "to": "/pm-payments-and-commissions", },
			{"id":678, "label":"Messaging " , "to": "/pm-messaging", },
			{"id":679, "label":"Settings", "to": "/pm-settings", },

			// TeamLeaderFieldExecutives

			{
				"id": 'Directories',
				"label": "Team Leader Field Executives"
			},
			
			{"id":681, "label":"Dashboard", "to": "/team-leader-field-executives-dashboard", },
			{"id":682, "label":"Leave Requests", "to": "/tlfe-leave-requests", content: [  
			{"id":683, "label":"Manage Leaves", "to": "/tlfe-manage-leaves", } ,
			{"id":684, "label":"Leaves Type", "to": "/tlfe-leaves-type", }, ], }, 
			{"id":685, "label":"Executives", "to": "/tlfe-executives", content: [  
			{"id":686, "label":"Assigned", "to": "/tlfe-assigned", },  
			{"id":687, "label":"Managed ", "to": "/tlfe-managed", }, ], }, 
			{"id":688, "label":"Cases", "to": "/tlfe-cases", content: [  
			{"id":689, "label":"Assigned ", "to": "/tlfe-assigned", }  ,
			{"id":690, "label":"Managed ", "to": "/tlfe-managed", }  ,
			{"id":691, "label":"Reassigned ", "to": "/tlfe-reassigned", }, ], }, 
			{"id":692, "label":"Disciplinary ", "to": "/tlfe-disciplinary", content: [  
			{"id":693, "label":"Actions ", "to": "/tlfe-actions", }  ,
			{"id":694, "label":"Resolutions", "to": "/tlfe-resolutions", }, ], }, 
			{"id":695, "label":"Payments and Commissions" , "to": "/tlfe-payments-and-commissions", },
			{"id":696, "label":"Messaging " , "to": "/tlfe-messaging", },
			{"id":697, "label":"Settings", "to": "/tlfe-settings", },


			
			// TeamLeaderPhoneBankers

			{
				"id": 'Directories',
				"label": "Team Leader Phone Bankers"
			},
			

			{"id":699, "label":"Dashboard", "to": "/team-leader-phone-bankers-dashboard", },
			{"id":700, "label":"Leave Requests", "to": "/tlpb-leave-requests", content: [
			{"id":701, "label":"Manage Leaves", "to": "/tlpb-manage-leaves", },
			{"id":702, "label":"Leaves Type", "to": "/tlpb-leaves-type", }, ], }, 
			{"id":703, "label":"Phone Bankers ", "to": "/tlpb-phonebanker", content: [
			{"id":704, "label":"Assigned", "to": "/tlpb-assigned", },
			{"id":705, "label":"Managed ", "to": "/tlpb-managed", }, ], }, 
			{"id":706, "label":"Cases", "to": "/tlpb-cases", content: [
			{"id":707, "label":"Assigned ", "to": "/tlpb-assigned", },
			{"id":708, "label":"Managed ", "to": "/tlpb-managed", },
			{"id":709, "label":"Reassigned ", "to": "/tlpb-reassigned", }, ], }, 
			{"id":710, "label":"Disciplinary ", "to": "/tlpb-disciplinary", content: [
			{"id":711, "label":"Actions ", "to": "/tlpb-actions", },
			{"id":712, "label":"Resolutions", "to": "/tlpb-resolutions", }, ], }, 
			{"id":713, "label":"Payments and Commissions" , "to": "/tlpb-payments-and-commissions", },
			{"id":714, "label":"Messaging " , "to": "/tlpb-messaging", },
			{"id":715, "label":"Settings", "to": "/tlpb-settings", },



			
			// TeamLeaderTracers

			{
				"id": 'Directories',
				"label": "TeamLeader Tracers"
			},
			

			{"id":717, "label":"Dashboard", "to": "/team-leader-tracers-dashboard", },
			{"id":718, "label":"Tracing ", "to": "/tlt-tracing", content: [
			{"id":719, "label":"Identifying ", "to": "/tlt-identifying", },
			{"id":720, "label":"Search", "to": "/tlt-search", },
			{"id":721, "label":"Results ", "to": "/tlt-results", }, ], }, 
			{"id":722, "label":"Tracers", "to": "/tlt-tracers", content: [
			{"id":723, "label":"Assigned", "to": "/tlt-assigned", },
			{"id":724, "label":"Managed ", "to": "/tlt-managed", }, ], }, 
			{"id":725, "label":"Cases", "to": "/tlt-cases", content: [
			{"id":726, "label":"Assigned ", "to": "/tlt-assigned", },
			{"id":727, "label":"Managed ", "to": "/tlt-managed", },
			{"id":728, "label":"Reassigned ", "to": "/tlt-reassigned", }, ], }, 
			{"id":729, "label":"Disciplinary ", "to": "/tlt-disciplinary", content: [
			{"id":730, "label":"Actions ", "to": "/tlt-actions", },
			{"id":731, "label":"Resolutions", "to": "/tlt-resolutions", }, ], }, 
			{"id":732, "label":"Payments and Commissions" , "to": "/tlt-payments-and-commissions", },
			{"id":733, "label":"Messaging ", "to": "/tlt-messaging", },
			{"id":734, "label":"Leave Requests", "to": "/tlt-leave-requests", content: [
			{"id":735, "label":"Manage Leaves", "to": "/tlt-manage-leaves", },
			{"id":736, "label":"Leaves Type", "to": "/tlt-leaves-type", }, ], }, 
			{"id":737, "label":"Settings", "to": "/tlt-settings", },



			// PhoneBanker

			{
				"id": 'Directories',
				"label": "Phone Banker"
			},
			

			{"id":739, "label":"Dashboard", "to": "/phone-banker-dashboard", },
			{"id":740, "label":"Cases", "to": "/pb-cases", content: [
			{"id":741, "label":"Assigned ", "to": "/pb-assigned", },
			{"id":742, "label":"Managed ", "to": "/pb-managed", },
			{"id":743, "label":"Reassigned ", "to": "/pb-reassigned", }, ], }, 
			{"id":744, "label":"Disciplinary ", "to": "/pb-disciplinary", content: [
			{"id":745, "label":"Actions ", "to": "/pb-actions", },
			{"id":746, "label":"Resolutions", "to": "/pb-resolutions", }, ], }, 
			{"id":747, "label":"Messaging " , "to": "/pb-messaging", },
			{"id":748, "label":"Payments and Commissions" , "to": "/pb-payments-and-commissions", },
			{"id":749, "label":"Leave Requests", "to": "/pb-leave-requests", content: [
			{"id":750, "label":"Manage Leaves", "to": "/pb-manage-leaves", },
			{"id":751, "label":"Leaves Type", "to": "/pb-leaves-type", }, ], }, 
			{"id":752, "label":"Settings", "to": "/pb-settings", },




			// FieldExecutive

			{
				"id": 'Directories',
				"label": "Field Executive"
			},
			

			{"id":754, "label":"Dashboard", "to": "/field-executive-dashboard", },
			{"id":755, "label":"Cases", "to": "/fe-cases", content: [
			{"id":756, "label":"Assigned ", "to": "/fe-assigned", },
			{"id":757, "label":"Managed ", "to": "/fe-managed", },
			{"id":758, "label":"Reassigned ", "to": "/fe-reassigned", }, ], }, 
			{"id":759, "label":"Disciplinary ", "to": "/fe-disciplinary", content: [
			{"id":760, "label":"Actions ", "to": "/fe-actions", },
			{"id":761, "label":"Resolutions", "to": "/fe-resolutions", }, ], }, 
			{"id":762, "label":"Messaging " , "to": "/fe-messaging", },
			{"id":763, "label":"Payments and Commissions" , "to": "/fe-payments-and-commissions", },
			{"id":764, "label":"Leave Requests", "to": "/fe-leave-requests", content: [
			{"id":765, "label":"Manage Leaves", "to": "/fe-manage-leaves", },
			{"id":766, "label":"Leaves Type", "to": "/fe-leaves-type", }, ], }, 
			{"id":767, "label":"Settings", "to": "/fe-settings", },




			// Tracers
			{
				"id": 'Directories',
				"label": "Tracers"
			},
			

			{"id":769, "label":"Dashboard", "to": "/tracers-dashboard", },
			{"id":770, "label":"Tracing ", "to": "/tc-tracing", content: [
			{"id":771, "label":"Identifying ", "to": "/tc-identifying", },
			{"id":772, "label":"Search", "to": "/tc-search", },
			{"id":773, "label":"Results ", "to": "/tc-results", }, ], }, 
			{"id":774, "label":"Cases", "to": "/tc-cases", content: [
			{"id":775, "label":"Assigned ", "to": "/tc-assigned", },
			{"id":776, "label":"Managed ", "to": "/tc-managed", },
			{"id":777, "label":"Reassigned ", "to": "/tc-reassigned", }, ], }, 
			{"id":778, "label":"Disciplinary ", "to": "/tc-disciplinary", content: [
			{"id":779, "label":"Actions ", "to": "/tc-actions", },
			{"id":780, "label":"Resolutions", "to": "/tc-resolutions", }, ], }, 
			{"id":781, "label":"Messaging " , "to": "/tc-messaging", },
			{"id":782, "label":"Payments and Commissions" , "to": "/tc-payments-and-commissions", },
			{"id":783, "label":"Leave Requests", "to": "/tc-leave-requests", content: [
			{"id":784, "label":"Manage Leaves", "to": "/tc-manage-leaves", },
			{"id":785, "label":"Leaves Type", "to": "/tc-leaves-type", }, ], }, 
			{"id":786, "label":"Settings", "to": "/tc-settings", },





			// Assert Management & IT Dept			

			// ITHead

			{
				"id": 'Directories',
				"label": "IT Head"
			},
			


			{"id":349, "label":"Dashboard", "to": "/it-head-dashboard", }, 
			{"id":350, "label":"Inventory Control " , "to": "/ith-inventory-control ", }, 
			{"id":351, "label":"Ware Houses", "to": "/ith-ware-houses ", }, 
			{"id":352, "label":"Products", "to": "/ith-products ", content: [ 
			{"id":353, "label":"Out Of Stock", "to": "/ith-out-of-stock ", }  , 
			{"id":354, "label":"Expired Products", "to": "/ith-expired-products ", }  , 
			{"id":355, "label":"Product Tax", "to": "/ith-product-tax ", }, ], }, 
			{"id":356, "label":"Billing Invoices" , "to": "/ith-billing-invoices ", }, 
			{"id":357, "label":"Invoice Payments"  , "to": "/ith-invoice-payments ", }, 
			{"id":358, "label":"Tax Type", "to": "/ith-tax-type ", content: [  
			{"id":359, "label":"Product Category", "to": "/ith-product-category ", }, ], }, 
			{"id":360, "label":"Suppliers", "to": "/ith-suppliers ", content: [  
			{"id":361, "label":"new supplier", "to": "/ith-new-supplier ", }  , 
			{"id":362, "label":"suppliers list", "to": "/ith-suppliers-list ", }, ], }, 
			{"id":363, "label":"Purchases", "to": "/ith-purchases ", content: [  
			{"id":364, "label":"New Purchase", "to": "/ith-new-purchase ", }  , 
			{"id":365, "label":"Purchase List", "to": "/ith-purchase-list ", }, ], }, 
			{"id":366, "label":"Sales Order", "to": "/ith-sales-order ", content: [  
			{"id":367, "label":"Sales Order List", "to": "/ith-sales-order-list ", }  , 
			{"id":368, "label":"New Order Invoice", "to": "/ith-new-order-invoice ", }  , 
			{"id":369, "label":"Manage Orders", "to": "/ith-manage-orders ", }  , 
			{"id":370, "label":"Paid Orders", "to": "/ith-paid-orders ", }  , 
			{"id":371, "label":"Unpaid Orders", "to": "/ith-unpaid-orders ", }  , 
			{"id":372, "label":"Delivered Orders", "to": "/ith-delivered-orders ", }  , 
			{"id":373, "label":"Cancelled Orders", "to": "/ith-cancelled-orders ", }  , 
			{"id":374, "label":"Credit Types", "to": "/ith-credit-types ", }, ], }, 
			{"id":375, "label":"Help Desk", "to": "/ith-help-desk ", content: [  
			{"id":376, "label":"Tickets", "to": "/ith-tickets ", }  , 
			{"id":377, "label":"Tickets List", "to": "/ith-tickets-list ", }  , 
			{"id":378, "label":"Create Ticket", "to": "/ith-create-ticket ", }  , 
			{"id":379, "label":"Support Tickets", "to": "/ith-support-tickets ", }  , 
			{"id":380, "label":"Resolved List", "to": "/ith-resolved-list ", }  , 
			{"id":381, "label":"Assign Tickets Solve", "to": "/ith-assign-tickets-solve ", }, ], }, 
			{"id":382, "label":"Leave Requests", "to": "/ith-leave-requests ", content: [  
			{"id":383, "label":"Manage Leaves", "to": "/ith-manage-leaves ", }  , 
			{"id":384, "label":"Leaves Type", "to": "/ith-leaves-type ", }, ], }, 
			{"id":385, "label":"Assets List"  , "to": "/ith-assets-list ", }, 
			{"id":386, "label":"Assets management", "to": "/ith-assets-management ", content: [  
			{"id":387, "label":"Create assert", "to": "/ith-create-assert ", }  , 
			{"id":388, "label":"Assign assert", "to": "/ith-assign-assert ", }  , 
			{"id":389, "label":"Destory assert", "to": "/ith-destory-assert ", }, ], }, 
			{"id":390, "label":"assert Category", "to": "/ith-assert-category ", content: [  
			{"id":391, "label":"New category", "to": "/ith-new-category ", }  , 
			{"id":392, "label":"Manage category", "to": "/ith-manage-category ", }, ], }, 
			{"id":393, "label":"Asset Brand", "to": "/ith-asset-brand ", content: [  
			{"id":394, "label":"New Brand", "to": "/ith-new-brand ", }  , 
			{"id":395, "label":"Manage Brand", "to": "/ith-manage-brand ", }, ], }, 
			{"id":396, "label":"Settings" , "to": "/ith-settings", }, 
				



			// ITManager

			{
				"id": 'Directories',
				"label": "IT Manager"
			},


			{"id":349, "label":"Dashboard", "to": "/it-manager-dashboard", }, 
			{"id":350, "label":"Inventory Control " , "to": "/itm-inventory-control ", }, 
			{"id":351, "label":"Ware Houses", "to": "/itm-ware-houses ", }, 
			{"id":352, "label":"Products", "to": "/itm-products ", content: [  
			{"id":353, "label":"Out Of Stock", "to": "/itm-out-of-stock ", }  , 
			{"id":354, "label":"Expired Products", "to": "/itm-expired-products ", }  , 
			{"id":355, "label":"Product Tax", "to": "/itm-product-tax ", }, ], }, 
			{"id":356, "label":"Billing Invoices" , "to": "/itm-billing-invoices ", }, 
			{"id":357, "label":"Invoice Payments"  , "to": "/itm-invoice-payments ", }, 
			{"id":358, "label":"Tax Type", "to": "/itm-tax-type ", content: [  
			{"id":359, "label":"Product Category", "to": "/itm-product-category ", }, ], }, 
			{"id":360, "label":"Suppliers", "to": "/itm-suppliers ", content: [  
			{"id":361, "label":"new supplier", "to": "/itm-new-supplier ", }  , 
			{"id":362, "label":"suppliers list", "to": "/itm-suppliers-list ", }, ], }, 
			{"id":363, "label":"Purchases", "to": "/itm-purchases ", content: [  
			{"id":364, "label":"New Purchase", "to": "/itm-new-purchase ", }  , 
			{"id":365, "label":"Purchase List", "to": "/itm-purchase-list ", }, ], }, 
			{"id":366, "label":"Sales Order", "to": "/itm-sales-order ", content: [   
			{"id":367, "label":"Sales Order List", "to": "/itm-sales-order-list ", }  , 
			{"id":368, "label":"New Order Invoice", "to": "/itm-new-order-invoice ", }  , 
			{"id":369, "label":"Manage Orders", "to": "/itm-manage-orders ", }  , 
			{"id":370, "label":"Paid Orders", "to": "/itm-paid-orders ", }  , 
			{"id":371, "label":"Unpaid Orders", "to": "/itm-unpaid-orders ", }  , 
			{"id":372, "label":"Delivered Orders", "to": "/itm-delivered-orders ", }  , 
			{"id":373, "label":"Cancelled Orders", "to": "/itm-cancelled-orders ", }  , 
			{"id":374, "label":"Credit Types", "to": "/itm-credit-types ", }, ], }, 
			{"id":375, "label":"Help Desk", "to": "/itm-help-desk ", content: [  
			{"id":376, "label":"Tickets", "to": "/itm-tickets ", }  , 
			{"id":377, "label":"Tickets List", "to": "/itm-tickets-list ", }  , 
			{"id":378, "label":"Create Ticket", "to": "/itm-create-ticket ", }  , 
			{"id":379, "label":"Support Tickets", "to": "/itm-support-tickets ", }  , 
			{"id":380, "label":"Resolved List", "to": "/itm-resolved-list ", }  , 
			{"id":381, "label":"Assign Tickets Solve", "to": "/itm-assign-tickets-solve ", }, ], }, 
			{"id":382, "label":"Leave Requests", "to": "/itm-leave-requests ", content: [  
			{"id":383, "label":"Manage Leaves", "to": "/itm-manage-leaves ", }  , 
			{"id":384, "label":"Leaves Type", "to": "/itm-leaves-type ", }, ], }, 
			{"id":385, "label":"Assets List"  , "to": "/itm-assets-list ", }, 
			{"id":386, "label":"Assets management", "to": "/itm-assets-management ", content: [  
			{"id":387, "label":"Create assert", "to": "/itm-create-assert ", }  , 
			{"id":388, "label":"Assign assert", "to": "/itm-assign-assert ", }  , 
			{"id":389, "label":"Destory assert", "to": "/itm-destory-assert ", }, ], }, 
			{"id":390, "label":"assert Category", "to": "/itm-assert-category ", content: [  
			{"id":391, "label":"New category", "to": "/itm-new-category ", }  , 
			{"id":392, "label":"Manage category", "to": "/itm-manage-category ", }, ], }, 
			{"id":393, "label":"Asset Brand", "to": "/itm-asset-brand ", content: [  
			{"id":394, "label":"New Brand", "to": "/itm-new-brand ", }  , 
			{"id":395, "label":"Manage Brand", "to": "/itm-manage-brand ", }, ], }, 
			{"id":396, "label":"Settings" , "to": "/itm-settings", }, 



			// TODO: Administrator   

			{
				"id": 'Directories',
				"label": "Administrator"
			},
			{"id":349, "label":"Dashboard", "to": "/administrator-dashboard", }, 
			{"id":350, "label":"Inventory Control " , "to": "/amhwa-inventory-control", }, 
			{"id":351, "label":"Ware Houses", "to": "/amhwa-ware-houses", }, 

			// AssertsManagerHWAdministrator

			{
				"id": 'Directories',
				"label": "Asserts Manager HW Administrator"
			},
			

			{"id":349, "label":"Dashboard", "to": "/asserts-manager-hw-administrator-dashboard", }, 
			{"id":350, "label":"Inventory Control " , "to": "/amhwa-inventory-control", }, 
			{"id":351, "label":"Ware Houses", "to": "/amhwa-ware-houses", }, 
			{"id":352, "label":"Products", "to": "/amhwa-products", content: [  
			{"id":353, "label":"Out Of Stock", "to": "/amhwa-out-of-stock", }  , 
			{"id":354, "label":"Expired Products", "to": "/amhwa-expired-products", }  , 
			{"id":355, "label":"Product Tax", "to": "/amhwa-product-tax", }, ], }, 
			{"id":356, "label":"Billing Invoices" , "to": "/amhwa-billing-invoices", }, 
			{"id":357, "label":"Invoice Payments"  , "to": "/amhwa-invoice-payments", }, 
			{"id":358, "label":"Tax Type", "to": "/amhwa-tax-type", content: [  
			{"id":359, "label":"Product Category", "to": "/amhwa-product-category", }, ], }, 
			{"id":360, "label":"Suppliers", "to": "/amhwa-suppliers", content: [  
			{"id":361, "label":"new supplier", "to": "/amhwa-new-supplier", }  , 
			{"id":362, "label":"suppliers list", "to": "/amhwa-suppliers-list", }, ], }, 
			{"id":363, "label":"Purchases", "to": "/amhwa-purchases", content: [  
			{"id":364, "label":"New Purchase", "to": "/amhwa-new-purchase", }  , 
			{"id":365, "label":"Purchase List", "to": "/amhwa-purchase-list", }, ], }, 
			{"id":366, "label":"Sales Order", "to": "/amhwa-sales-order", content: [   
			{"id":367, "label":"Sales Order List", "to": "/amhwa-sales-order-list", }  , 
			{"id":368, "label":"New Order Invoice", "to": "/amhwa-new-order-invoice", }  , 
			{"id":369, "label":"Manage Orders", "to": "/amhwa-manage-orders", }  , 
			{"id":370, "label":"Paid Orders", "to": "/amhwa-paid-orders", }  , 
			{"id":371, "label":"Unpaid Orders", "to": "/amhwa-unpaid-orders", }  , 
			{"id":372, "label":"Delivered Orders", "to": "/amhwa-delivered-orders", }  , 
			{"id":373, "label":"Cancelled Orders", "to": "/amhwa-cancelled-orders", }  , 
			{"id":374, "label":"Credit Types", "to": "/amhwa-credit-types", }, ], }, 
			{"id":375, "label":"Help Desk", "to": "/amhwa-help-desk", content: [  
			{"id":376, "label":"Tickets", "to": "/amhwa-tickets", }  , 
			{"id":377, "label":"Tickets List", "to": "/amhwa-tickets-list", }  , 
			{"id":378, "label":"Create Ticket", "to": "/amhwa-create-ticket", }  , 
			{"id":379, "label":"Support Tickets", "to": "/amhwa-support-tickets", }  , 
			{"id":380, "label":"Resolved List", "to": "/amhwa-resolved-list", }  , 
			{"id":381, "label":"Assign Tickets Solve", "to": "/amhwa-assign-tickets-solve", }, ], }, 
			{"id":382, "label":"Leave Requests", "to": "/amhwa-leave-requests", content: [  
			{"id":383, "label":"Manage Leaves", "to": "/amhwa-manage-leaves", }  , 
			{"id":384, "label":"Leaves Type", "to": "/amhwa-leaves-type", }, ], }, 
			{"id":385, "label":"Assets List"  , "to": "/amhwa-assets-list", }, 
			{"id":386, "label":"Assets management", "to": "/amhwa-assets-management", content: [  
			{"id":387, "label":"Create assert", "to": "/amhwa-create-assert", }  , 
			{"id":388, "label":"Assign assert", "to": "/amhwa-assign-assert", }  , 
			{"id":389, "label":"Destory assert", "to": "/amhwa-destory-assert", }, ], }, 
			{"id":390, "label":"assert Category", "to": "/amhwa-assert-category", content: [  
			{"id":391, "label":"New category", "to": "/amhwa-new-category", }  , 
			{"id":392, "label":"Manage category", "to": "/amhwa-manage-category", }, ], }, 
			{"id":393, "label":"Asset Brand", "to": "/amhwa-asset-brand", content: [  
			{"id":394, "label":"New Brand", "to": "/amhwa-new-brand", }  , 
			{"id":395, "label":"Manage Brand", "to": "/amhwa-manage-brand", }, ], }, 
			{"id":396, "label":"Settings" , "to": "/amhwa-settings", }, 



			// MISManager

			{
				"id": 'Directories',
				"label": "MIS Manager"
			},
			

			{"id":535, "label":"Dashboard", "to": "/mis-manager-dashboard", },
			{"id":536, "label":"Inventroy Control ", "to": "/mism-inventroy-control", content: [  
			{"id":537, "label":"Ware Houses", "to": "/mism-ware-houses", }  ,
			{"id":538, "label":"Products", "to": "/mism-products", }  ,
			{"id":539, "label":"Billing Invoices", "to": "/mism-billing-invoices", }  ,
			{"id":540, "label":"Invoice Payments", "to": "/mism-invoice-payments", }  ,
			{"id":541, "label":"Tax Type", "to": "/mism-tax-type", }  ,
			{"id":542, "label":"Product Category", "to": "/mism-product-category", }  ,
			{"id":543, "label":"Suppliers", "to": "/mism-suppliers", }  ,
			{"id":544, "label":"new supplier", "to": "/mism-new-supplier", }  ,
			{"id":545, "label":"suppliers list", "to": "/mism-suppliers-list", }  ,
			{"id":546, "label":"Purchases", "to": "/mism-purchases", }  ,
			{"id":547, "label":"New Purchase", "to": "/mism-new-purchase", }  ,
			{"id":548, "label":"Purchase List", "to": "/mism-purchase-list", }  ,
			{"id":549, "label":"Sales Order", "to": "/mism-sales-order", }  ,
			{"id":550, "label":"Sales Order List", "to": "/mism-sales-order-list", }  ,
			{"id":551, "label":"New Order Invoice", "to": "/mism-new-order-invoice", }  ,
			{"id":552, "label":"Manage Orders", "to": "/mism-manage-orders", }  ,
			{"id":553, "label":"Paid Orders", "to": "/mism-paid-orders", }  ,
			{"id":554, "label":"Unpaid Orders", "to": "/mism-unpaid-orders", }  ,
			{"id":555, "label":"Delivered Orders", "to": "/mism-delivered-orders", }  ,
			{"id":556, "label":"Cancelled Orders", "to": "/mism-cancelled-orders", }  ,
			{"id":557, "label":"Credit Types", "to": "/mism-credit-types", }, ], }, 
			{"id":558, "label":"Help Desk", "to": "/mism-help-desk", content: [  
			{"id":559, "label":"Tickets", "to": "/mism-tickets", }  ,
			{"id":560, "label":"Tickets List", "to": "/mism-tickets-list", }  ,
			{"id":561, "label":"Create Ticket", "to": "/mism-create-ticket", }  ,
			{"id":562, "label":"Support Tickets", "to": "/mism-support-tickets", }  ,
			{"id":563, "label":"Resolved List", "to": "/mism-resolved-list", }  ,
			{"id":564, "label":"Assign Tickets Solved", "to": "/mism-assign-tickets-solved", }, ], }, 
			{"id":566, "label":"Leave Requests", "to": "/mism-leave-requests", content: [  
			{"id":567, "label":"Manage Leaves", "to": "/mism-manage-leaves", }  ,
			{"id":568, "label":"Leaves Type", "to": "/mism-leaves-type", }, ], }, 
			{"id":569, "label":"Asserts", "to": "/mism-asserts", content: [  
			{"id":570, "label":"Assets List", "to": "/mism-assets-list", }  ,
			{"id":571, "label":"Assets management", "to": "/mism-assets-management", }  ,
			{"id":572, "label":"Create assert", "to": "/mism-create-assert", }  ,
			{"id":573, "label":"Assign assert", "to": "/mism-assign-assert", }  ,
			{"id":574, "label":"Destory assert", "to": "/mism-destory-assert", }  ,
			{"id":575, "label":"assert Category", "to": "/mism-assert-category", }  ,
			{"id":576, "label":"New category", "to": "/mism-new-category", }  ,
			{"id":577, "label":"Manage category", "to": "/mism-manage-category", }  ,
			{"id":578, "label":"Asset Brand", "to": "/mism-asset-brand", }  ,
			{"id":579, "label":"New Brand", "to": "/mism-new-brand", }  ,
			{"id":580, "label":"Manage Brand", "to": "/mism-manage-brand", }, ], }, 
			{"id":581, "label":"Reports", "to": "/mism-reports", content: [  
			{"id":582, "label":"Daily ", "to": "/mism-daily", }  ,
			{"id":583, "label":"Monthly ", "to": "/mism-monthly", }  ,
			{"id":584, "label":"Yearly", "to": "/mism-yearly", }  ,
			{"id":585, "label":"Process ", "to": "/mism-process", }, ], }, 
			{"id":586, "label":"Settings" , "to": "/mism-settings", },



			// MISExecutive

			{
				"id": 'Directories',
				"label": "MIS Executive"
			},
			

			{"id":588, "label":"Dashboard", "to": "/mis-executive-dashboard", },
			{"id":589, "label":"Inventroy Control ", "to": "/mise-inventroy-control", content: [  
			{"id":590, "label":"Ware Houses", "to": "/mise-ware-houses", }  ,
			{"id":591, "label":"Products", "to": "/mise-products", }  ,
			{"id":592, "label":"Billing Invoices", "to": "/mise-billing-invoices", }  ,
			{"id":593, "label":"Invoice Payments", "to": "/mise-invoice-payments", }  ,
			{"id":594, "label":"Tax Type", "to": "/mise-tax-type", }  ,
			{"id":595, "label":"Product Category", "to": "/mise-product-category", }  ,
			{"id":596, "label":"Suppliers", "to": "/mise-suppliers", }  ,
			{"id":597, "label":"new supplier", "to": "/mise-new-supplier", }  ,
			{"id":598, "label":"suppliers list", "to": "/mise-suppliers-list", }  ,
			{"id":599, "label":"Purchases", "to": "/mise-purchases", }  ,
			{"id":600, "label":"New Purchase", "to": "/mise-new-purchase", }  ,
			{"id":601, "label":"Purchase List", "to": "/mise-purchase-list", }  ,
			{"id":602, "label":"Sales Order", "to": "/mise-sales-order", }  ,
			{"id":603, "label":"Sales Order List", "to": "/mise-sales-order-list", }  ,
			{"id":604, "label":"New Order Invoice", "to": "/mise-new-order-invoice", }  ,
			{"id":605, "label":"Manage Orders", "to": "/mise-manage-orders", }  ,
			{"id":606, "label":"Paid Orders", "to": "/mise-paid-orders", }  ,
			{"id":607, "label":"Unpaid Orders", "to": "/mise-unpaid-orders", }  ,
			{"id":608, "label":"Delivered Orders", "to": "/mise-delivered-orders", }  ,
			{"id":609, "label":"Cancelled Orders", "to": "/mise-cancelled-orders", }  ,
			{"id":610, "label":"Credit Types", "to": "/mise-credit-types", }, ], }, 
			{"id":611, "label":"Help Desk", "to": "/mise-help-desk", content: [  
			{"id":612, "label":"Tickets", "to": "/mise-tickets", }  ,
			{"id":613, "label":"Tickets List", "to": "/mise-tickets-list", }  ,
			{"id":614, "label":"Create Ticket", "to": "/mise-create-ticket", }  ,
			{"id":615, "label":"Support Tickets", "to": "/mise-support-tickets", }  ,
			{"id":616, "label":"Resolved List", "to": "/mise-resolved-list", }  ,
			{"id":617, "label":"Assign Tickets Solved", "to": "/mise-assign-tickets-solved", }, ], }, 
			{"id":618, "label":"Leave Requests", "to": "/mise-leave-requests", content: [  
			{"id":619, "label":"Manage Leaves", "to": "/mise-manage-leaves", }  ,
			{"id":620, "label":"Leaves Type", "to": "/mise-leaves-type", }, ], }, 
			{"id":621, "label":"Asserts", "to": "/mise-asserts", content: [  
			{"id":622, "label":"Assets List", "to": "/mise-assets-list", }  ,
			{"id":623, "label":"Assets management", "to": "/mise-assets-management", }  ,
			{"id":624, "label":"Create assert", "to": "/mise-create-assert", }  ,
			{"id":625, "label":"Assign assert", "to": "/mise-assign-assert", }  ,
			{"id":626, "label":"Destory assert", "to": "/mise-destory-assert", }  ,
			{"id":627, "label":"assert Category", "to": "/mise-assert-category", }  ,
			{"id":628, "label":"New category", "to": "/mise-new-category", }  ,
			{"id":629, "label":"Manage category", "to": "/mise-manage-category", }  ,
			{"id":630, "label":"Asset Brand", "to": "/mise-asset-brand", }  ,
			{"id":631, "label":"New Brand", "to": "/mise-new-brand", }  ,
			{"id":632, "label":"Manage Brand", "to": "/mise-manage-brand", }, ], }, 
			{"id":633, "label":"Reports", "to": "/mise-reports", content: [  
			{"id":634, "label":"Daily ", "to": "/mise-daily", }  ,
			{"id":635, "label":"Monthly ", "to": "/mise-monthly", }  ,
			{"id":636, "label":"Yearly", "to": "/mise-yearly", }  ,
			{"id":637, "label":"Process ", "to": "/mise-process", }, ], }, 
			{"id":638, "label":"Settings", "to": "/mise-settings", },
			
			


			// Finance Dept.

			// FinanceManager

			{
				"id": 'Directories',
				"label": "Finance Manager"
			},
			
			{"id":322, "label":"Dashboard", "to": "/finance-manager-dashboard",} , 
			{"id":323, "label":"Accounts " , "to": "/fm-accounts",} , 
			{"id":324, "label":"Deposits", "to": "/fm-deposits", content: [
			{"id":325, "label":"Deposits List", "to": "/fm-deposits-list", }  , 
			{"id":326, "label":"Categories", "to": "/fm-categories", }, ], }, 
			{"id":327, "label":"Expenses", "to": "/fm-expenses", },
			{"id":328, "label":"Transactions", "to": "/fm-transactions",},
			{"id":329, "label":"Payroll", "to": "/fm-payroll", content: [
			{"id":330, "label":"Pay Slip History", "to": "/fm-pay-slip-history", }  , 
			{"id":331, "label":"Advance Salary", "to": "/fm-advance-salary", }, ], }, 
			{"id":332, "label":"Loan", "to": "/fm-loan", content: [
			{"id":333, "label":"Loan Types", "to": "/fm-loan-types", }  , 
			{"id":334, "label":"Commission types ", "to": "/fm-commission-types", }  , 
			{"id":335, "label":"Incentive Types", "to": "/fm-incentive-types", }, ], }, 
			{"id":336, "label":"Invoices", "to": "/fm-invoices",  content: [
			{"id":337, "label":"Billing Invoices", "to": "/fm-billing-invoices", }  , 
			{"id":338, "label":"Invoice Payments", "to": "/fm-invoice-payments", }  , 
			{"id":339, "label":"Tax Type", "to": "/fm-tax-type", }, ], }, 
			{"id":340, "label":"Estimates", "to": "/fm-estimates",  content: [
			{"id":341, "label":"Manage Leaves", "to": "/fm-manage-leaves", }  , 
			{"id":342, "label":"Leaves Type", "to": "/fm-estimate-type", }, ], }, 
			{"id":343, "label":"Leave Requests", "to": "/fm-leave-requests",  content: [
			{"id":344, "label":"Manage Leaves", "to": "/fm-manage-leaves", }  , 
			{"id":345, "label":"Leaves Type", "to": "/fm-leaves-type", }, ], }, 
			{"id":346, "label":"Settings", "to": "/fm-settings", },
			
			
			// AccountsHead

			{
				"id": 'Directories',
				"label": "Accounts Head"
			},
			

			{"id":322, "label":"Dashboard", "to": "/accounts-head-dashboard",} , 
			{"id":323, "label":"Accounts " , "to": "/ah-accounts",} , 
			{"id":324, "label":"Deposits", "to": "/ah-deposits", content: [
			{"id":325, "label":"Deposits List", "to": "/ah-deposits-list", }  , 
			{"id":326, "label":"Categories", "to": "/ah-categories", }, ], }, 
			{"id":327, "label":"Expenses", "to": "/ah-expenses", },
			{"id":328, "label":"Transactions", "to": "/ah-transactions",},
			{"id":329, "label":"Payroll", "to": "/ah-payroll", content: [
			{"id":330, "label":"Pay Slip History", "to": "/ah-pay-slip-history", }  , 
			{"id":331, "label":"Advance Salary", "to": "/ah-advance-salary", }, ], }, 
			{"id":332, "label":"Loan", "to": "/ah-loan", content: [
			{"id":333, "label":"Loan Types", "to": "/ah-loan-types", }  , 
			{"id":334, "label":"Commission types", "to": "/ah-commission-types", }  , 
			{"id":335, "label":"Incentive Types", "to": "/ah-incentive-types", }, ], }, 
			{"id":336, "label":"Invoices", "to": "/ah-invoices",  content: [
			{"id":337, "label":"Billing Invoices", "to": "/ah-billing-invoices", }  , 
			{"id":338, "label":"Invoice Payments", "to": "/ah-invoice-payments", }  , 
			{"id":339, "label":"Tax Type", "to": "/ah-tax-type", }, ], }, 
			{"id":340, "label":"Estimates", "to": "/ah-estimates",  content: [
			{"id":341, "label":"Manage Leaves", "to": "/ah-manage-leaves", }  , 
			{"id":342, "label":"Leaves Type", "to": "/ah-estimate-type", }, ], }, 
			{"id":343, "label":"Leave Requests", "to": "/ah-leave-requests",  content: [
			{"id":344, "label":"Manage Leaves", "to": "/ah-manage-leaves", }  , 
			{"id":345, "label":"Leaves Type", "to": "/ah-leaves-type", }, ], }, 
			{"id":346, "label":"Settings", "to": "/ah-settings", },

			
			// AccountsExecutive

			{
				"id": 'Directories',
				"label": "Accounts Executive"
			},
			

			{"id":322, "label":"Dashboard", "to": "/accounts-executive-dashboard",} , 
			{"id":323, "label":"Accounts " , "to": "/ae-accounts",} , 
			{"id":324, "label":"Deposits", "to": "/ae-deposits", content: [
			{"id":325, "label":"Deposits List", "to": "/ae-deposits-list", }  , 
			{"id":326, "label":"Categories", "to": "/ae-categories", }, ], }, 
			{"id":327, "label":"Expenses", "to": "/ae-expenses", },
			{"id":328, "label":"Transactions", "to": "/ae-transactions",},
			{"id":329, "label":"Payroll", "to": "/ae-payroll", content: [
			{"id":330, "label":"Pay Slip History", "to": "/ae-pay-slip-history", }  , 
			{"id":331, "label":"Advance Salary", "to": "/ae-advance-salary", }, ], }, 
			{"id":332, "label":"Loan", "to": "/ae-loan", content: [
			{"id":333, "label":"Loan Types", "to": "/ae-loan-types", }  , 
			{"id":334, "label":"Commission types", "to": "/ae-commission-types", }  , 
			{"id":335, "label":"Incentive Types", "to": "/ae-incentive-types", }, ], }, 
			{"id":336, "label":"Invoices", "to": "/ae-invoices",  content: [
			{"id":337, "label":"Billing Invoices", "to": "/ae-billing-invoices", }  , 
			{"id":338, "label":"Invoice Payments", "to": "/ae-invoice-payments", }  , 
			{"id":339, "label":"Tax Type", "to": "/ae-tax-type", }, ], }, 
			{"id":340, "label":"Estimates", "to": "/ae-estimates",  content: [
			{"id":341, "label":"Manage Leaves", "to": "/ae-manage-leaves", }  , 
			{"id":342, "label":"Leaves Type", "to": "/ae-estimate-type", }, ], }, 
			{"id":343, "label":"Leave Requests", "to": "/ae-leave-requests",  content: [
			{"id":344, "label":"Manage Leaves", "to": "/ae-manage-leaves", }  , 
			{"id":345, "label":"Leaves Type", "to": "/ae-leaves-type", }, ], }, 
			{"id":346, "label":"Settings", "to": "/ae-settings", },


			
			// AccountsClerk

			{
				"id": 'Directories',
				"label": "Accounts Clerk"
			},
			
			{"id":322, "label":"Dashboard", "to": "/accounts-clerk-dashboard",} , 
			{"id":323, "label":"Accounts " , "to": "/ac-accounts",} , 
			{"id":324, "label":"Deposits", "to": "/ac-deposits", content: [
			{"id":325, "label":"Deposits List", "to": "/ac-deposits-list", }  , 
			{"id":326, "label":"Categories", "to": "/ac-categories", }, ], }, 
			{"id":327, "label":"Expenses", "to": "/ac-expenses", },
			{"id":328, "label":"Transactions", "to": "/ac-transactions",},
			{"id":329, "label":"Payroll", "to": "/ac-payroll", content: [
			{"id":330, "label":"Pay Slip History", "to": "/ac-pay-slip-history", }  , 
			{"id":331, "label":"Advance Salary", "to": "/ac-advance-salary", }, ], }, 
			{"id":332, "label":"Loan", "to": "/ac-loan", content: [
			{"id":333, "label":"Loan Types", "to": "/ac-loan-types", }  , 
			{"id":334, "label":"Commission types", "to": "/ac-commission-types", }  , 
			{"id":335, "label":"Incentive Types", "to": "/ac-incentive-types", }, ], }, 
			{"id":336, "label":"Invoices", "to": "/ac-invoices",  content: [
			{"id":337, "label":"Billing Invoices", "to": "/ac-billing-invoices", }  , 
			{"id":338, "label":"Invoice Payments", "to": "/ac-invoice-payments", }  , 
			{"id":339, "label":"Tax Type", "to": "/ac-tax-type", }, ], }, 
			{"id":340, "label":"Estimates", "to": "/ac-estimates",  content: [
			{"id":341, "label":"Manage Leaves", "to": "/ac-manage-leaves", }  , 
			{"id":342, "label":"Leaves Type", "to": "/ac-estimate-type", }, ], }, 
			{"id":343, "label":"Leave Requests", "to": "/ac-leave-requests",  content: [
			{"id":344, "label":"Manage Leaves", "to": "/ac-manage-leaves", }  , 
			{"id":345, "label":"Leaves Type", "to": "/ac-leaves-type", }, ], }, 
			{"id":346, "label":"Settings", "to": "/ac-settings", },




			

			// Human Resource Dept.

			// HRManager

			{
				"id": 'Directories',
				"label": "HR Manager"
			},
			

			
			{"id": 3, "label": "Dashboard" , "to": "/hr-manager-dashboard", }, 
			{"id": 4, "label": "Employees",  content: [
			{"id": 4000, "label": "Employees", "to": "/hrm-employees", },
			{"id":5, "label":"Employees List", "to": "/hrm-employees-list", },
			{"id":6, "label":"Roles & Privileges", "to": "/hrm-roles-privileges", },
			{"id":7, "label":"Shift & Scheduling", "to": "/hrm-shift-scheduling", }, ], }, 
			{"id":8, "label":"Department", "to": "/hrm-department", content: [
			{"id":9, "label":"Department list", "to": "/hrm-department-list", },
			{"id":10, "label":"Designation list", "to": "/hrm-designation-list", }, ], }, 
			{"id":11, "label":"Resignations", "to": "/hrm-resignations",  content: [
			{"id":12, "label":"Create Resignations", "to": "/hrm-create-resignations", },
			{"id":13, "label":"Approve Resignations", "to": "/hrm-approve-resignations", },
			{"id":14, "label":"Resignation List", "to": "/hrm-resignation-list", },
			{"id":15, "label":"Employees Exit", "to": "/hrm-employees-exit", },
			{"id":16, "label":"Exit Types", "to": "/hrm-exit-types", },
			{"id":17, "label":"Exit Clearance Certificates", "to": "/hrm-exit-clearance-certificates", }, ], }, 
			{"id":18, "label":"Transfers", "to": "/hrm-transfers",  content: [
			{"id":19, "label":"Transfer List", "to": "/hrm-transfer-list", },
			{"id":20, "label":"Approve Transfers", "to": "/hrm-approve-transfers", },
			{"id":21, "label":"New Transfers", "to": "/hrm-new-transfers", }, ], }, 
			{"id":22, "label":"Complaints", "to": "/hrm-complaints",  content: [
			{"id":23, "label":"Lodge Complaint", "to": "/hrm-lodge-complaint", },
			{"id":24, "label":"Complaint List", "to": "/hrm-complaint-list", },
			{"id":25, "label":"Resolved List", "to": "/hrm-resolved-list", }, ], }, 
			{"id":26, "label":"Awards", "to": "/hrm-awards",  content: [
			{"id":27, "label":"awards", "to": "/hrm-awards", },
			{"id":28, "label":"award assign list", "to": "/hrm-award-assign-list", },
			{"id":29, "label":"award Type", "to": "/hrm-award-type", }, ], }, 
			{"id":30, "label":"Activities ", "to": "/hrm-activities",  content: [
			{"id":31, "label":"Travels", "to": "/hrm-travels", },
			{"id":32, "label":"New Business Travel ", "to": "/hrm-new-business-travel", },
			{"id":33, "label":"Travel Arrangement Type", "to": "/hrm-travel-arrangement-type", },
			{"id":34, "label":"Transport Type", "to": "/hrm-transport-type", },
			{"id":35, "label":"Payments", "to": "/hrm-payments", },
			{"id":36, "label":"Travel Management", "to": "/hrm-travel-management", },
			{"id":37, "label":"Events", "to": "/hrm-events", },
			{"id":38, "label":"New Event", "to": "/hrm-new-event", },
			{"id":39, "label":"Event Types", "to": "/hrm-event-types", },
			{"id":40, "label":"Holidays", "to": "/hrm-holidays", },
			{"id":41, "label":"New Holiday", "to": "/hrm-new-holiday", },
			{"id":42, "label":"Holiday list", "to": "/hrm-holiday-list", },
			{"id":43, "label":"Visitors", "to": "/hrm-visitors", },
			{"id":44, "label":"New visitor", "to": "/hrm-new-visitor", },
			{"id":45, "label":"Visitors List ", "to": "/hrm-visitors-list", },
			{"id":46, "label":"Conferences", "to": "/hrm-conferences", },
			{"id":47, "label":"Conf booking", "to": "/hrm-conf-booking", },
			{"id":48, "label":"Meeting List", "to": "/hrm-meeting-list", }, ], }, 
			{"id":49, "label":"Documents Manager", "to": "/hrm-documents-manager",  content: [
			{"id":50, "label":"general", "to": "/hrm-general", },
			{"id":51, "label":"Uploading files", "to": "/hrm-uploading-files", },
			{"id":52, "label":"Managing Files", "to": "/hrm-managing-files", },
			{"id":53, "label":"official", "to": "/hrm-official", },
			{"id":54, "label":"Uploading files", "to": "/hrm-uploading-files", },
			{"id":55, "label":"Managing Files", "to": "/hrm-managing-files", },
			{"id":56, "label":"Process Documents", "to": "/hrm-process-documents", },
			{"id":57, "label":"Uploading files", "to": "/hrm-uploading-files", },
			{"id":58, "label":"Managing Files", "to": "/hrm-managing-files", }, ], }, 
			{"id":59, "label":"Performance (PMS)", "to": "/hrm-performance-pms",  content: [
			{"id":60, "label":"KPI (indicators)", "to": "/hrm-kpi-indicators", },
			{"id":61, "label":"KPA (Appraisals)", "to": "/hrm-kpa-appraisals", },
			{"id":62, "label":"OKRs Tracking Goals", "to": "/hrm-okrs-tracking-goals", },
			{"id":63, "label":"Competencies", "to": "/hrm-competencies", },
			{"id":64, "label":"Technical ", "to": "/hrm-technical", },
			{"id":65, "label":"Organizational", "to": "/hrm-organizational", },
			{"id":66, "label":"Goal Types", "to": "/hrm-goal-types", },
			{"id":67, "label":"Goal Calendar", "to": "/hrm-goal-calendar", }, ], }, 
			{"id":69, "label":"Recruitment (ATS)", "to": "/hrm-recruitment-ats",  content: [
			{"id":70, "label":"New postings", "to": "/hrm-new-postings", },
			{"id":71, "label":"Candidate List", "to": "/hrm-candidate-list", },			
			{"id":73, "label":"Interviews ", "to": "/hrm-interviews", },
			{"id":74, "label":"Interviewers List", "to": "/hrm-interviewers-list", }, ], }, 
			{"id":75, "label":"Attendance", "to": "/hrm-attendance",  content: [
			{"id":76, "label":"Attendance", "to": "/hrm-attendance", },
			{"id":77, "label":"Manual Attendance", "to": "/hrm-manual-attendance", },
			{"id":78, "label":"Monthly Report", "to": "/hrm-monthly-report", },
			{"id":79, "label":"Overtime Request", "to": "/hrm-overtime-request", }, ], }, 
			{"id":80, "label":"Policies", "to": "/hrm-policies",  content: [
			{"id":81, "label":"Create new Policy ", "to": "/hrm-create-new-policy", },
			{"id":82, "label":"Policy list", "to": "/hrm-policy-list", }, ], }, 
			{"id":83, "label":"Make Announcement", "to": "/hrm-make-announcement",  content: [
			{"id":84, "label":"New Announcement ", "to": "/hrm-new-announcement", },
			{"id":85, "label":"Announcement List", "to": "/hrm-announcement-list", }, ], }, 
			{"id":86, "label":"Organisation Chart", "to": "/hrm-organisation-chart",  content: [
			{"id":87, "label":"View ", "to": "/hrm-view", },
			{"id":88, "label":"Create Chart ", "to": "/hrm-create-chart", }, ], }, 
			{"id":89, "label":"Leave Requests", "to": "/hrm-leave-requests",  content: [
			{"id":90, "label":"Manage Leaves", "to": "/hrm-manage-leaves", },
			{"id":91, "label":"Leaves Type", "to": "/hrm-leaves-type", }, ], }, 
			{"id":92, "label":"Settings", "to": "/hrm-settings", },

			
			

			
			// HRExecutive

			{
				"id": 'Directories',
				"label": "HR Executive"
			},	


			
			{"id":94, "label":"Dashboard", "to":"hr-executive-dashboard" },  
			{"id":95, "label": "Employees", "to": "/hre-employees", content: [ 
			{"id":96, "label":"Employees List", "to": "/hre-employees-list", }, 
			{"id":97, "label":"Roles & Privileges", "to": "/hre-roles-privileges", }, 
			{"id":98, "label":"Shift & Scheduling", "to": "/hre-shift-scheduling", }, ], }, 
			{"id":99, "label":"Department", "to": "/hre-department", content: [ 
			{"id":100, "label":"Department list", "to": "/hre-department-list", }, 
			{"id":101, "label":"Designation list", "to": "/hre-designation-list", }, ], }, 
			{"id":102, "label":"Resignations", "to": "/hre-resignations",  content: [ 
			{"id":103, "label":"Create Resignations", "to": "/hre-create-resignations", }, 
			{"id":104, "label":"Approve Resignations", "to": "/hre-approve-resignations", }, 
			{"id":105, "label":"Resignation List", "to": "/hre-resignation-list", }, 
			{"id":106, "label":"Employees Exit", "to": "/hre-employees-exit", }, 
			{"id":107, "label":"Exit Types", "to": "/hre-exit-types", }, 
			{"id":108, "label":"Exit Clearance Certificates", "to": "/hre-exit-clearance-certificates", }, ], }, 
			{"id":109, "label":"Transfers", "to": "/hre-transfers",  content: [ 
			{"id":110, "label":"Transfer List", "to": "/hre-transfer-list", }, 
			{"id":111, "label":"Approve Transfers", "to": "/hre-approve-transfers", }, 
			{"id":112, "label":"New Transfers", "to": "/hre-new-transfers", }, ], }, 
			{"id":113, "label":"Complaints", "to": "/hre-complaints",  content: [ 
			{"id":114, "label":"Lodge Complaint", "to": "/hre-lodge-complaint", }, 
			{"id":115, "label":"Complaint List", "to": "/hre-complaint-list", }, 
			{"id":116, "label":"Resolved List", "to": "/hre-resolved-list", }, ], }, 
			{"id":117, "label":"Awards", "to": "/hre-awards",  content: [ 
			{"id":118, "label":"awards", "to": "/hre-awards", }, 
			{"id":119, "label":"award assign list", "to": "/hre-award-assign-list", }, 
			{"id":120, "label":"award Type", "to": "/hre-award-type", }, ], }, 
			{"id":121, "label":"Activities", "to": "/hre-activities",  content: [ 
			{"id":122, "label":"Travels", "to": "/hre-travels", }, 
			{"id":123, "label":"New Business Travel", "to": "/hre-new-business-travel", }, 
			{"id":124, "label":"Travel Arrangement Type", "to": "/hre-travel-arrangement-type", }, 
			{"id":125, "label":"Transport Type", "to": "/hre-transport-type", }, 
			{"id":126, "label":"Payments", "to": "/hre-payments", }, 
			{"id":127, "label":"Travel Management", "to": "/hre-travel-management", }, 
			{"id":128, "label":"Events", "to": "/hre-events", }, 
			{"id":129, "label":"New Event", "to": "/hre-new-event", }, 
			{"id":130, "label":"Event Types", "to": "/hre-event-types", }, 
			{"id":131, "label":"Holidays", "to": "/hre-holidays", }, 
			{"id":132, "label":"New Holiday", "to": "/hre-new-holiday", }, 
			{"id":133, "label":"Holiday list", "to": "/hre-holiday-list", }, 
			{"id":134, "label":"Visitors", "to": "/hre-visitors", }, 
			{"id":135, "label":"New visitor", "to": "/hre-new-visitor", }, 
			{"id":136, "label":"Visitors List", "to": "/hre-visitors-list", }, 
			{"id":137, "label":"Conferences", "to": "/hre-conferences", }, 
			{"id":138, "label":"Conf booking", "to": "/hre-conf-booking", }, 
			{"id":139, "label":"Meeting List", "to": "/hre-meeting-list", }, ], }, 
			{"id":140, "label":"Documents Manager", "to": "/hre-documents-manager",  content: [ 
			{"id":141, "label":"general", "to": "/hre-general", }, 
			{"id":142, "label":"Uploading files", "to": "/hre-uploading-files", }, 
			{"id":143, "label":"Managing Files", "to": "/hre-managing-files", }, 
			{"id":144, "label":"official", "to": "/hre-official", }, 
			{"id":145, "label":"Uploading files", "to": "/hre-uploading-files", }, 
			{"id":146, "label":"Managing Files", "to": "/hre-managing-files", }, 
			{"id":147, "label":"Process Documents", "to": "/hre-process-documents", }, 
			{"id":148, "label":"Uploading files", "to": "/hre-uploading-files", }, 
			{"id":149, "label":"Managing Files", "to": "/hre-managing-files", }, ], }, 
			{"id":150, "label":"Performance (PMS)", "to": "/hre-performance-pms",  content: [ 
			{"id":151, "label":"KPI (indicators)", "to": "/hre-kpi-indicators", }, 
			{"id":152, "label":"KPA (Appraisals)", "to": "/hre-kpa-appraisals", }, 
			{"id":153, "label":"OKRs Tracking Goals", "to": "/hre-okrs-tracking-goals", }, 
			{"id":154, "label":"Competencies", "to": "/hre-competencies", }, 
			{"id":155, "label":"Technical", "to": "/hre-technical", }, 
			{"id":156, "label":"Organizational", "to": "/hre-organizational", }, 
			{"id":157, "label":"Goal Types", "to": "/hre-goal-types", }, 
			{"id":158, "label":"Goal Calendar", "to": "/hre-goal-calendar", }, ], }, 
			{"id":159, "label":"Recruitment (ATS)", "to": "/hre-recruitment-ats",  content: [ 
			{"id":160, "label":"New postings", "to": "/hre-new-postings", }, 
			{"id":161, "label":"Candidate List", "to": "/hre-candidate-list", }, 			
			{"id":163, "label":"Interviews", "to": "/hre-interviews", }, 
			{"id":164, "label":"Interviewers List", "to": "/hre-interviewers-list", }, ], }, 
			{"id":165, "label":"Attendance", "to": "/hre-attendance",  content: [ 
			{"id":166, "label":"Attendance", "to": "/hre-attendance", }, 
			{"id":167, "label":"Manual Attendance", "to": "/hre-manual-attendance", }, 
			{"id":168, "label":"Monthly Report", "to": "/hre-monthly-report", }, 
			{"id":169, "label":"Overtime Request", "to": "/hre-overtime-request", }, ], }, 
			{"id":170, "label":"Policies", "to": "/hre-policies",  content: [ 
			{"id":171, "label":"Create new Policy", "to": "/hre-create-new-policy", }, 
			{"id":172, "label":"Policy list", "to": "/hre-policy-list", }, ], }, 
			{"id":173, "label":"Make Announcement", "to": "/hre-make-announcement",  content: [ 
			{"id":174, "label":"New Announcement", "to": "/hre-new-announcement", }, 
			{"id":175, "label":"Announcement List", "to": "/hre-announcement-list", }, ], }, 
			{"id":176, "label":"Organisation Chart", "to": "/hre-organisation-chart",  content: [ 
			{"id":177, "label":"View", "to": "/hre-view", }, 
			{"id":178, "label":"Create Chart", "to": "/hre-create-chart", }, ], }, 
			{"id":179, "label":"Leave Requests", "to": "/hre-leave-requests",  content: [
			{"id":180, "label":"Manage Leaves", "to": "/hre-manage-leaves", }, 
			{"id":181, "label":"Leaves Type", "to": "/hre-leaves-type", }, ], }, 
			{"id":182, "label":"Settings", "to": "/hre-settings", }, 




			// TrainingHead
			{
				"id": 'Directories',
				"label": "Training Head"
			},
			

			{"id":185, "label":"Dashboard", "to": "/training-head-dashboard" } ,
			{"id":186, "label":"Leave Requests", "to": "/th-leave-requests", content: [
			{"id":187, "label":"Manage Leaves", "to": "/th-manage-leaves", },
			{"id":188, "label":"Leaves Type", "to": "/th-leaves-type", }, ], }, 
			{"id":189, "label":"Trainings", "to": "/th-trainings", content: [
			{"id":190, "label":"Training Sessions", "to": "/th-training-sessions", },
			{"id":191, "label":"Trainers", "to": "/th-trainers", },
			{"id":192, "label":"Training Skills", "to": "/th-training-skills", },
			{"id":193, "label":"Manage Trainings", "to": "/th-manage-trainings", }, ], }, 
			{"id":194, "label":"Disciplinary Cases", "to": "/th-disciplinary-cases", content: [
			{"id":195, "label":"Actions", "to": "/th-actions", },
			{"id":196, "label":"Resolutions", "to": "/th-resolutions", }, ], }, 
			{"id":197, "label":"Settings", "to": "/th-settings", },



			// TraineeTeamLeader

			{
				"id": 'Directories',
				"label": "Trainee Team Leader"
			},
			
			
			{"id":199, "label":"Dashboard" ,"to":"/trainee-team-leader-dashboard"}, 
			{"id":200, "label":"Leave Requests", "to": "/ttl-leave-requests",     content: [ 
			{"id":201, "label":"Manage Leaves", "to": "/ttl-manage-leaves",  },
			{"id":202, "label":"Leaves Type", "to": "/ttl-leaves-type",  },     ], }, 
			{"id":203, "label":"Training", "to": "/ttl-trainings",     content: [ 
			{"id":204, "label":"Training Sessions", "to": "/ttl-training-sessions",  },
			{"id":205, "label":"Trainers", "to": "/ttl-trainers",  },
			{"id":206, "label":"Training Skills", "to": "/ttl-training-skills",  } ,
			{"id":207, "label":"Manage Trainings", "to": "/ttl-manage-trainings",  }, ], }, 
			{"id":208, "label":"Disciplinary Cases", "to": "/ttl-disciplinary-cases",     content: [ 
			{"id":209, "label":"Actions", "to": "/ttl-actions",  } ,
			{"id":210, "label":"Resolutions", "to": "/ttl-resolutions",  }, ], }, 
			{"id":211, "label":"Settings", "to": "/ttl-settings",   }, 



			// Trainer

			{
				"id": 'Directories',
				"label": "Trainer"
			},
			

			
			{"id":213, "label":"Dashboard","to":"/trainer-dashboard"}, 
			{"id":214, "label":"Training", "to": "/tr-trainings", content: [  
			{"id":215, "label":"Training Sessions", "to": "/tr-training-sessions", } ,
			{"id":216, "label":"Trainers", "to": "/tr-trainers", } ,
			{"id":217, "label":"Training Skills", "to": "/tr-training-skills", } ,
			{"id":218, "label":"Manage Trainings", "to": "/tr-manage-trainings", }, ], }, 
			{"id":219, "label":"Leave Requests", "to": "/tr-leave-requests", content: [  
			{"id":220, "label":"Manage Leaves", "to": "/tr-manage-leaves", } ,
			{"id":221, "label":"Leaves Type", "to": "/tr-leaves-type", }  , ], }, 
			{"id":222, "label":"Disciplinary Cases", "to": "/tr-disciplinary-cases", content: [
			{"id":223, "label":"Actions", "to": "/tr-actions", }  ,
			{"id":224, "label":"Resolutions", "to": "/tr-resolutions", }, ], }, 
			{"id":225, "label":"Settings" , "to": "/tr-settings", },



			// OfficeAdmin

			{
				"id": 'Directories',
				"label": "Office Admin"
			},
			

			{"id":227, "label":"Dashboard" , "to": "/office-admin-dashboard",   },
			{"id":228, "label":"Documents Manager", "to":  "/oa-documents-manager", },
			{"id":229, "label":"general", "to":  "/oa-general",content: [
			{"id":230, "label":"Uploading files", "to":  "/oa-uploading-files",}  , 
			{"id":231, "label":"Managing Files", "to":  "/oa-managing-files",}, ], }, 
			{"id":232, "label":"official", "to":  "/oa-official",content: [
			{"id":233, "label":"Uploading files", "to":  "/oa-uploading-files",}  , 
			{"id":234, "label":"Managing Files", "to":  "/oa-managing-files",}, ], }, 
			{"id":235, "label":"Visitors", "to":  "/oa-visitors",content: [
			{"id":236, "label":"New visitor", "to":  "/oa-new-visitor",}  , 
			{"id":237, "label":"Visitors List", "to":  "/oa-visitors-list",}  ,],}, 
			{"id":238, "label":"Leave Requests", "to":  "/oa-leave-requests",content: [ 
			{"id":239, "label":"Manage Leaves", "to":  "/oa-manage-leaves",}  , 
			{"id":240, "label":"Leaves Type", "to":  "/oa-leaves-type",}, ], }, 
			{"id":241, "label":"Settings", "to":  "/oa-settings",},
			
			
			//////////

			// system administor completed

		];


		// CompanyAdministrator
		const MenuCompanyAdministrator = [
			{
				"id": 'Directories',
				"label": "Company Administrator"
			},
			

			{"id":872, "label":"Dashboard", "to": "/company-administrator-dashboard", },
			{"id":873, "label":"Resources ", "to": "/coa-resources", content: [
			{"id":874, "label":"Asserts", "to": "/coa-asserts", },
			{"id":875, "label":"Users ", "to": "/coa-users", }, ], },
			{"id":876, "label":"Maps & Track " , "to": "/coa-maps", },
			{"id":877, "label":"Company Reports " , "to": "/coa-company-reports", },
			{"id":878, "label":"Settings", "to": "/coa-settings", },

			
		];
		// CompanyOwnerPartner
		const MenuCompanyOwnerPartner = [
			{
				"id": 'Directories',
				"label": "Company Owner Partner"
			},
			

			{"id":845, "label":"Dashboard " , "to": "/company-owner-partner-dashboard", }  ,
			{"id":846, "label":"Operations" , "to": "/cop-operations", },
			{"id":847, "label":"Resources" , "to": "/cop-resources", },
			{"id":848, "label":"Finances" , "to": "/cop-finances", },
			{"id":849, "label":"Messaging" , "to": "/cop-messaging", },
			{"id":850, "label":"Maps" , "to": "/cop-maps", },
			{"id":851, "label":"Payments and Commissions", "to": "/cop-payments-and-commissions", content: [ 
			{"id":852, "label":"Process based", "to": "/cop-process-based", }  ,
			{"id":853, "label":"Total Payments", "to": "/cop-total-payments", }, ], }, 
			{"id":854, "label":"Settings", "to": "/cop-settings", },

			
			
		];
		// CountryOperationsHead
		const MenuCountryOperationsHead = [
			{
				"id": 'Directories',
				"label": "Country Operations Head"
			},
			

			{"id":790, "label":"Dashboard " , "to": "/country-operations-head-dashboard", },
			{"id":791, "label":"Operations" , "to": "/coh-operations", },
			{"id":792, "label":"Resources" , "to": "/coh-resources", },
			{"id":793, "label":"Finances" , "to": "/coh-finances", },
			{"id":794, "label":"Messaging" , "to": "/coh-messaging", },
			{"id":795, "label":"Maps" , "to": "/coh-maps", },
			{"id":796, "label":"Payments and Commissions", "to": "/coh-payments-and-commissions",  content: [ 
			{"id":797, "label":"Process based", "to": "/coh-process-based", } , 
			{"id":798, "label":"Total Payments", "to": "/coh-total-payments", }, ], }, 
			{"id":799, "label":"Settings", "to": "/coh-settings", },

			
			
		];
		// ZoneOperationsHead
		const MenuZoneOperationsHead = [
			{
				"id": 'Directories',
				"label": "Zone Operations Head"
			},
			

			{"id":801, "label":"Dashboard " , "to": "/zone-operations-head-dashboard", },
			{"id":802, "label":"Operations" , "to": "/zoh-operations", },
			{"id":803, "label":"Resources" , "to": "/zoh-resources", },
			{"id":804, "label":"Finances" , "to": "/zoh-finances", },
			{"id":805, "label":"Messaging" , "to": "/zoh-messaging", },
			{"id":806, "label":"Maps" , "to": "/zoh-maps", },
			{"id":807, "label":"Payments and Commissions", "to": "/zoh-payments-and-commissions",  content: [
			{"id":808, "label":"Process based", "to": "/zoh-process-based", } , 
			{"id":809, "label":"Total Payments", "to": "/zoh-total-payments", }, ], }, 
			{"id":810, "label":"Settings", "to": "/zoh-settings", },

			
			
		];
		// StateOperationsHead
		const MenuStateOperationsHead = [
			{
				"id": 'Directories',
				"label": "State Operations Head"
			},
			

			{"id":812, "label":"Dashboard " , "to": "/state-operations-head-dashboard", },
			{"id":813, "label":"Operations" , "to": "/soh-operations", },
			{"id":814, "label":"Resources" , "to": "/soh-resources", },
			{"id":815, "label":"Finances" , "to": "/soh-finances", },
			{"id":816, "label":"Messaging" , "to": "/soh-messaging", },
			{"id":817, "label":"Maps" , "to": "/soh-maps", },
			{"id":818, "label":"Payments and Commissions", "to": "/soh-payments-and-commissions",  content: [  
			{"id":819, "label":"Process based", "to": "/soh-process-based", }  ,
			{"id":820, "label":"Total Payments", "to": "/soh-total-payments", }, ], }, 
			{"id":821, "label":"Settings", "to": "/soh-settings", },

			
			
		];
		// RegionalOperHead
		const MenuRegionalOperHead = [
			{
				"id": 'Directories',
				"label": "Regional Operations Head"
			},
			

			{"id":823, "label":"Dashboard " , "to": "/regional-oper-head-dashboard", },
			{"id":824, "label":"Operations" , "to": "/roh-operations", },
			{"id":825, "label":"Resources" , "to": "/roh-resources", },
			{"id":826, "label":"Finances" , "to": "/roh-finances", },
			{"id":827, "label":"Messaging" , "to": "/roh-messaging", },
			{"id":828, "label":"Maps" , "to": "/roh-maps", },
			{"id":829, "label":"Payments and Commissions", "to": "/roh-payments-and-commissions", content: [  
			{"id":830, "label":"Process based", "to": "/roh-process-based", }  ,
			{"id":831, "label":"Total Payments", "to": "/roh-total-payments", }, ], }, 
			{"id":832, "label":"Settings", "to": "/roh-settings", },

			
			
		];
		// DistrictHeadBranchHead
		const MenuDistrictHeadBranchHead = [
			{
				"id": 'Directories',
				"label": "District Head Branch Head"
			},
			

			{"id":834, "label":"Dashboard " , "to": "/district-head-branch-head-dashboard", },
			{"id":835, "label":"Operations" , "to": "/dhbh-operations", },
			{"id":836, "label":"Resources" , "to": "/dhbh-resources", },
			{"id":837, "label":"Finances" , "to": "/dhbh-finances", },
			{"id":838, "label":"Messaging" , "to": "/dhbh-messaging", },
			{"id":839, "label":"Maps" , "to": "/dhbh-maps", },
			{"id":840, "label":"Payments and Commissions", "to": "/dhbh-payments-and-commissions", content: [
			{"id":841, "label":"Process based", "to": "/dhbh-process-based", }  ,
			{"id":842, "label":"Total Payments", "to": "/dhbh-total-payments", }, ], }, 
			{"id":843, "label":"Settings", "to": "/dhbh-settings", },			
			
		];
		// ProcessHead
		const MenuProcessHead = [
			{
				"id": 'Directories',
				"label": "Process Head"
			},
			
			{"id":641, "label":"Dashboard", "to": "/process-head-dashboard", },
			{"id":642, "label":"Leave Requests", "to": "/ph-leave-requests", content: [
			{"id":643, "label":"Manage Leaves", "to": "/ph-manage-leaves", },
			{"id":644, "label":"Leaves Type", "to": "/ph-leaves-type", }, ], }, 
			{"id":645, "label":"Processes", "to": "/ph-processes", content: [
			{"id":646, "label":"New Process", "to": "/ph-new-process", },
			{"id":647, "label":"Process flow", "to": "/ph-process-flow", },
			{"id":648, "label":"Process Management", "to": "/ph-process-management", },
			{"id":649, "label":"Completed Process", "to": "/ph-completed-process", }, ], }, 
			{"id":650, "label":"Complacencies ", "to": "/ph-complacencies",  content: [
			{"id":651, "label":"Process", "to": "/ph-process", },
			{"id":652, "label":"Client", "to": "/ph-client", }, ], }, 
			{"id":653, "label":"Reports ", "to": "/ph-reports", content: [
			{"id":654, "label":"Daily ", "to": "/ph-daily", },
			{"id":655, "label":"Monthly ", "to": "/ph-monthly", },
			{"id":656, "label":"Yearly", "to": "/ph-yearly", },
			{"id":657, "label":"Process ", "to": "/ph-process", }, ], }, 
			{"id":658, "label":"Payments and Commissions" , "to": "/ph-payments-and-commissions", },
			{"id":659, "label":"Messaging " , "to": "/ph-messaging", },
			{"id":660, "label":"Settings", "to": "/ph-settings", },
			
		];
		// ProcessManager
		const MenuProcessManager = [
			{
				"id": 'Directories',
				"label": "Process Manager"
			},
			
			{"id":660, "label":"Dashboard", "to": "/process-manager-dashboard", },
			{"id":661, "label":"Leave Requests", "to": "/pm-leave-requests", content: [
			{"id":662, "label":"Manage Leaves", "to": "/pm-manage-leaves", },
			{"id":663, "label":"Leaves Type", "to": "/pm-leaves-type", }, ], }, 
			{"id":664, "label":"Processes", "to": "/pm-processes", content: [
			{"id":665, "label":"New Process", "to": "/pm-new-process", },
			{"id":666, "label":"Process flow", "to": "/pm-process-flow", },
			{"id":667, "label":"Process Management", "to": "/pm-process-management", },
			{"id":668, "label":"Completed Process", "to": "/pm-completed-process", }, ], }, 
			{"id":669, "label":"Complacencies ", "to": "/pm-complacencies", content: [
			{"id":670, "label":"Process", "to": "/pm-process", },
			{"id":671, "label":"Client", "to": "/pm-client", }, ], }, 
			{"id":672, "label":"Reports ", "to": "/pm-reports", content: [
			{"id":673, "label":"Daily ", "to": "/pm-daily", },
			{"id":674, "label":"Monthly ", "to": "/pm-monthly", },
			{"id":675, "label":"Yearly", "to": "/pm-yearly", },
			{"id":676, "label":"Process ", "to": "/pm-process", }, ], }, 
			{"id":677, "label":"Payments and Commissions" , "to": "/pm-payments-and-commissions", },
			{"id":678, "label":"Messaging " , "to": "/pm-messaging", },
			{"id":679, "label":"Settings", "to": "/pm-settings", },

			
			
		];
		// ITHead
		const MenuITHead = [
			{
				"id": 'Directories',
				"label": "IT Head"
			},			

			{"id":349, "label":"Dashboard", "to": "/it-head-dashboard", }, 
			{"id":350, "label":"Inventory Control " , "to": "/ith-inventory-control ", }, 
			{"id":351, "label":"Ware Houses", "to": "/ith-ware-houses ", }, 
			{"id":352, "label":"Products", "to": "/ith-products ", content: [ 
			{"id":353, "label":"Out Of Stock", "to": "/ith-out-of-stock ", }  , 
			{"id":354, "label":"Expired Products", "to": "/ith-expired-products ", }  , 
			{"id":355, "label":"Product Tax", "to": "/ith-product-tax ", }, ], }, 
			{"id":356, "label":"Billing Invoices" , "to": "/ith-billing-invoices ", }, 
			{"id":357, "label":"Invoice Payments"  , "to": "/ith-invoice-payments ", }, 
			{"id":358, "label":"Tax Type", "to": "/ith-tax-type ", content: [  
			{"id":359, "label":"Product Category", "to": "/ith-product-category ", }, ], }, 
			{"id":360, "label":"Suppliers", "to": "/ith-suppliers ", content: [  
			{"id":361, "label":"new supplier", "to": "/ith-new-supplier ", }  , 
			{"id":362, "label":"suppliers list", "to": "/ith-suppliers-list ", }, ], }, 
			{"id":363, "label":"Purchases", "to": "/ith-purchases ", content: [  
			{"id":364, "label":"New Purchase", "to": "/ith-new-purchase ", }  , 
			{"id":365, "label":"Purchase List", "to": "/ith-purchase-list ", }, ], }, 
			{"id":366, "label":"Sales Order", "to": "/ith-sales-order ", content: [  
			{"id":367, "label":"Sales Order List", "to": "/ith-sales-order-list ", }  , 
			{"id":368, "label":"New Order Invoice", "to": "/ith-new-order-invoice ", }  , 
			{"id":369, "label":"Manage Orders", "to": "/ith-manage-orders ", }  , 
			{"id":370, "label":"Paid Orders", "to": "/ith-paid-orders ", }  , 
			{"id":371, "label":"Unpaid Orders", "to": "/ith-unpaid-orders ", }  , 
			{"id":372, "label":"Delivered Orders", "to": "/ith-delivered-orders ", }  , 
			{"id":373, "label":"Cancelled Orders", "to": "/ith-cancelled-orders ", }  , 
			{"id":374, "label":"Credit Types", "to": "/ith-credit-types ", }, ], }, 
			{"id":375, "label":"Help Desk", "to": "/ith-help-desk ", content: [  
			{"id":376, "label":"Tickets", "to": "/ith-tickets ", }  , 
			{"id":377, "label":"Tickets List", "to": "/ith-tickets-list ", }  , 
			{"id":378, "label":"Create Ticket", "to": "/ith-create-ticket ", }  , 
			{"id":379, "label":"Support Tickets", "to": "/ith-support-tickets ", }  , 
			{"id":380, "label":"Resolved List", "to": "/ith-resolved-list ", }  , 
			{"id":381, "label":"Assign Tickets Solve", "to": "/ith-assign-tickets-solve ", }, ], }, 
			{"id":382, "label":"Leave Requests", "to": "/ith-leave-requests ", content: [  
			{"id":383, "label":"Manage Leaves", "to": "/ith-manage-leaves ", }  , 
			{"id":384, "label":"Leaves Type", "to": "/ith-leaves-type ", }, ], }, 
			{"id":385, "label":"Assets List"  , "to": "/ith-assets-list ", }, 
			{"id":386, "label":"Assets management", "to": "/ith-assets-management ", content: [  
			{"id":387, "label":"Create assert", "to": "/ith-create-assert ", }  , 
			{"id":388, "label":"Assign assert", "to": "/ith-assign-assert ", }  , 
			{"id":389, "label":"Destory assert", "to": "/ith-destory-assert ", }, ], }, 
			{"id":390, "label":"assert Category", "to": "/ith-assert-category ", content: [  
			{"id":391, "label":"New category", "to": "/ith-new-category ", }  , 
			{"id":392, "label":"Manage category", "to": "/ith-manage-category ", }, ], }, 
			{"id":393, "label":"Asset Brand", "to": "/ith-asset-brand ", content: [  
			{"id":394, "label":"New Brand", "to": "/ith-new-brand ", }  , 
			{"id":395, "label":"Manage Brand", "to": "/ith-manage-brand ", }, ], }, 
			{"id":396, "label":"Settings" , "to": "/ith-settings", }, 
			

			
		];
		// FinanceManager
		const MenuFinanceManager = [
			{
				"id": 'Directories',
				"label": "Finance Manager"
			},
			
			{"id":322, "label":"Dashboard", "to": "/finance-manager-dashboard",} , 
			{"id":323, "label":"Accounts " , "to": "/fm-accounts",} , 
			{"id":324, "label":"Deposits", "to": "/fm-deposits", content: [
			{"id":325, "label":"Deposits List", "to": "/fm-deposits-list", }  , 
			{"id":326, "label":"Categories", "to": "/fm-categories", }, ], }, 
			{"id":327, "label":"Expenses", "to": "/fm-expenses", },
			{"id":328, "label":"Transactions", "to": "/fm-transactions",},
			{"id":329, "label":"Payroll", "to": "/fm-payroll", content: [
			{"id":330, "label":"Pay Slip History", "to": "/fm-pay-slip-history", }  , 
			{"id":331, "label":"Advance Salary", "to": "/fm-advance-salary", }, ], }, 
			{"id":332, "label":"Loan", "to": "/fm-loan", content: [
			{"id":333, "label":"Loan Types", "to": "/fm-loan-types", }  , 
			{"id":334, "label":"Commission types ", "to": "/fm-commission-types", }  , 
			{"id":335, "label":"Incentive Types", "to": "/fm-incentive-types", }, ], }, 
			{"id":336, "label":"Invoices", "to": "/fm-invoices",  content: [
			{"id":337, "label":"Billing Invoices", "to": "/fm-billing-invoices", }  , 
			{"id":338, "label":"Invoice Payments", "to": "/fm-invoice-payments", }  , 
			{"id":339, "label":"Tax Type", "to": "/fm-tax-type", }, ], }, 
			{"id":340, "label":"Estimates", "to": "/fm-estimates",  content: [
			{"id":341, "label":"Manage Leaves", "to": "/fm-manage-leaves", }  , 
			{"id":342, "label":"Leaves Type", "to": "/fm-estimate-type", }, ], }, 
			{"id":343, "label":"Leave Requests", "to": "/fm-leave-requests",  content: [
			{"id":344, "label":"Manage Leaves", "to": "/fm-manage-leaves", }  , 
			{"id":345, "label":"Leaves Type", "to": "/fm-leaves-type", }, ], }, 
			{"id":346, "label":"Settings", "to": "/fm-settings", },
			
			
		];
		// HRManager
		const MenuHRManager = [
			{
				"id": 'Directories',
				"label": "HR Manager"
			},
						
			{"id": 3, "label": "Dashboard" , "to": "/hr-manager-dashboard", }, 
			{"id": 4, "label": "Employees", "to": "/hrm-employees", content: [
			{"id":5, "label":"Employees List", "to": "/hrm-employees-list", },
			{"id":6, "label":"Roles & Privileges", "to": "/hrm-roles-privileges", },
			{"id":7, "label":"Shift & Scheduling", "to": "/hrm-shift-scheduling", }, ], }, 
			{"id":8, "label":"Department", "to": "/hrm-department", content: [
			{"id":9, "label":"Department list", "to": "/hrm-department-list", },
			{"id":10, "label":"Designation list", "to": "/hrm-designation-list", }, ], }, 
			{"id":11, "label":"Resignations", "to": "/hrm-resignations",  content: [
			{"id":12, "label":"Create Resignations", "to": "/hrm-create-resignations", },
			{"id":13, "label":"Approve Resignations", "to": "/hrm-approve-resignations", },
			{"id":14, "label":"Resignation List", "to": "/hrm-resignation-list", },
			{"id":15, "label":"Employees Exit", "to": "/hrm-employees-exit", },
			{"id":16, "label":"Exit Types", "to": "/hrm-exit-types", },
			{"id":17, "label":"Exit Clearance Certificates", "to": "/hrm-exit-clearance-certificates", }, ], }, 
			{"id":18, "label":"Transfers", "to": "/hrm-transfers",  content: [
			{"id":19, "label":"Transfer List", "to": "/hrm-transfer-list", },
			{"id":20, "label":"Approve Transfers", "to": "/hrm-approve-transfers", },
			{"id":21, "label":"New Transfers", "to": "/hrm-new-transfers", }, ], }, 
			{"id":22, "label":"Complaints", "to": "/hrm-complaints",  content: [
			{"id":23, "label":"Lodge Complaint", "to": "/hrm-lodge-complaint", },
			{"id":24, "label":"Complaint List", "to": "/hrm-complaint-list", },
			{"id":25, "label":"Resolved List", "to": "/hrm-resolved-list", }, ], }, 
			{"id":26, "label":"Awards", "to": "/hrm-awards",  content: [
			{"id":27, "label":"awards", "to": "/hrm-awards", },
			{"id":28, "label":"award assign list", "to": "/hrm-award-assign-list", },
			{"id":29, "label":"award Type", "to": "/hrm-award-type", }, ], }, 
			{"id":30, "label":"Activities ", "to": "/hrm-activities",  content: [
			{"id":31, "label":"Travels", "to": "/hrm-travels", },
			{"id":32, "label":"New Business Travel ", "to": "/hrm-new-business-travel", },
			{"id":33, "label":"Travel Arrangement Type", "to": "/hrm-travel-arrangement-type", },
			{"id":34, "label":"Transport Type", "to": "/hrm-transport-type", },
			{"id":35, "label":"Payments", "to": "/hrm-payments", },
			{"id":36, "label":"Travel Management", "to": "/hrm-travel-management", },
			{"id":37, "label":"Events", "to": "/hrm-events", },
			{"id":38, "label":"New Event", "to": "/hrm-new-event", },
			{"id":39, "label":"Event Types", "to": "/hrm-event-types", },
			{"id":40, "label":"Holidays", "to": "/hrm-holidays", },
			{"id":41, "label":"New Holiday", "to": "/hrm-new-holiday", },
			{"id":42, "label":"Holiday list", "to": "/hrm-holiday-list", },
			{"id":43, "label":"Visitors", "to": "/hrm-visitors", },
			{"id":44, "label":"New visitor", "to": "/hrm-new-visitor", },
			{"id":45, "label":"Visitors List ", "to": "/hrm-visitors-list", },
			{"id":46, "label":"Conferences", "to": "/hrm-conferences", },
			{"id":47, "label":"Conf booking", "to": "/hrm-conf-booking", },
			{"id":48, "label":"Meeting List", "to": "/hrm-meeting-list", }, ], }, 
			{"id":49, "label":"Documents Manager", "to": "/hrm-documents-manager",  content: [
			{"id":50, "label":"general", "to": "/hrm-general", },
			{"id":51, "label":"Uploading files", "to": "/hrm-uploading-files", },
			{"id":52, "label":"Managing Files", "to": "/hrm-managing-files", },
			{"id":53, "label":"official", "to": "/hrm-official", },
			{"id":54, "label":"Uploading files", "to": "/hrm-uploading-files", },
			{"id":55, "label":"Managing Files", "to": "/hrm-managing-files", },
			{"id":56, "label":"Process Documents", "to": "/hrm-process-documents", },
			{"id":57, "label":"Uploading files", "to": "/hrm-uploading-files", },
			{"id":58, "label":"Managing Files", "to": "/hrm-managing-files", }, ], }, 
			{"id":59, "label":"Performance (PMS)", "to": "/hrm-performance-pms",  content: [
			{"id":60, "label":"KPI (indicators)", "to": "/hrm-kpi-indicators", },
			{"id":61, "label":"KPA (Appraisals)", "to": "/hrm-kpa-appraisals", },
			{"id":62, "label":"OKRs Tracking Goals", "to": "/hrm-okrs-tracking-goals", },
			{"id":63, "label":"Competencies", "to": "/hrm-competencies", },
			{"id":64, "label":"Technical ", "to": "/hrm-technical", },
			{"id":65, "label":"Organizational", "to": "/hrm-organizational", },
			{"id":66, "label":"Goal Types", "to": "/hrm-goal-types", },
			{"id":67, "label":"Goal Calendar", "to": "/hrm-goal-calendar", }, ], }, 
			{"id":69, "label":"Recruitment (ATS)", "to": "/hrm-recruitment-ats",  content: [
			{"id":70, "label":"New postings", "to": "/hrm-new-postings", },
			{"id":71, "label":"Candidate List", "to": "/hrm-candidate-list", },			
			{"id":73, "label":"Interviews ", "to": "/hrm-interviews", },
			{"id":74, "label":"Interviewers List", "to": "/hrm-interviewers-list", }, ], }, 
			{"id":75, "label":"Attendance", "to": "/hrm-attendance",  content: [
			{"id":76, "label":"Attendance", "to": "/hrm-attendance", },
			{"id":77, "label":"Manual Attendance", "to": "/hrm-manual-attendance", },
			{"id":78, "label":"Monthly Report", "to": "/hrm-monthly-report", },
			{"id":79, "label":"Overtime Request", "to": "/hrm-overtime-request", }, ], }, 
			{"id":80, "label":"Policies", "to": "/hrm-policies",  content: [
			{"id":81, "label":"Create new Policy ", "to": "/hrm-create-new-policy", },
			{"id":82, "label":"Policy list", "to": "/hrm-policy-list", }, ], }, 
			{"id":83, "label":"Make Announcement", "to": "/hrm-make-announcement",  content: [
			{"id":84, "label":"New Announcement ", "to": "/hrm-new-announcement", },
			{"id":85, "label":"Announcement List", "to": "/hrm-announcement-list", }, ], }, 
			{"id":86, "label":"Organisation Chart", "to": "/hrm-organisation-chart",  content: [
			{"id":87, "label":"View ", "to": "/hrm-view", },
			{"id":88, "label":"Create Chart ", "to": "/hrm-create-chart", }, ], }, 
			{"id":89, "label":"Leave Requests", "to": "/hrm-leave-requests",  content: [
			{"id":90, "label":"Manage Leaves", "to": "/hrm-manage-leaves", },
			{"id":91, "label":"Leaves Type", "to": "/hrm-leaves-type", }, ], }, 
			{"id":92, "label":"Settings", "to": "/hrm-settings", },
			
		];
		// CountryAdministrator
		const MenuCountryAdministrator = [
			{
				"id": 'Directories',
				"label": "Country Administrator"
			},
			

			{"id":857, "label":"Dashboard " , "to": "/country-administrator-dashboard", },
			{"id":858, "label":"Resources ","to": "/cua-resources", content: [  
			{"id":859, "label":"Asserts", "to": "/cua-asserts", }  ,
			{"id":860, "label":"Users ", "to": "/cua-users", }, ], }, 
			{"id":861, "label":"Country Reports " , "to": "/cua-country-reports", },
			{"id":862, "label":"Settings", "to": "/cua-settings", },
			
		];
		// MISManager
		const MenuMISManager = [
			{
				"id": 'Directories',
				"label": "MIS Manager"
			},
			

			{"id":535, "label":"Dashboard", "to": "/mis-manager-dashboard", },
			{"id":536, "label":"Inventroy Control ", "to": "/mism-inventroy-control", content: [  
			{"id":537, "label":"Ware Houses", "to": "/mism-ware-houses", }  ,
			{"id":538, "label":"Products", "to": "/mism-products", }  ,
			{"id":539, "label":"Billing Invoices", "to": "/mism-billing-invoices", }  ,
			{"id":540, "label":"Invoice Payments", "to": "/mism-invoice-payments", }  ,
			{"id":541, "label":"Tax Type", "to": "/mism-tax-type", }  ,
			{"id":542, "label":"Product Category", "to": "/mism-product-category", }  ,
			{"id":543, "label":"Suppliers", "to": "/mism-suppliers", }  ,
			{"id":544, "label":"new supplier", "to": "/mism-new-supplier", }  ,
			{"id":545, "label":"suppliers list", "to": "/mism-suppliers-list", }  ,
			{"id":546, "label":"Purchases", "to": "/mism-purchases", }  ,
			{"id":547, "label":"New Purchase", "to": "/mism-new-purchase", }  ,
			{"id":548, "label":"Purchase List", "to": "/mism-purchase-list", }  ,
			{"id":549, "label":"Sales Order", "to": "/mism-sales-order", }  ,
			{"id":550, "label":"Sales Order List", "to": "/mism-sales-order-list", }  ,
			{"id":551, "label":"New Order Invoice", "to": "/mism-new-order-invoice", }  ,
			{"id":552, "label":"Manage Orders", "to": "/mism-manage-orders", }  ,
			{"id":553, "label":"Paid Orders", "to": "/mism-paid-orders", }  ,
			{"id":554, "label":"Unpaid Orders", "to": "/mism-unpaid-orders", }  ,
			{"id":555, "label":"Delivered Orders", "to": "/mism-delivered-orders", }  ,
			{"id":556, "label":"Cancelled Orders", "to": "/mism-cancelled-orders", }  ,
			{"id":557, "label":"Credit Types", "to": "/mism-credit-types", }, ], }, 
			{"id":558, "label":"Help Desk", "to": "/mism-help-desk", content: [  
			{"id":559, "label":"Tickets", "to": "/mism-tickets", }  ,
			{"id":560, "label":"Tickets List", "to": "/mism-tickets-list", }  ,
			{"id":561, "label":"Create Ticket", "to": "/mism-create-ticket", }  ,
			{"id":562, "label":"Support Tickets", "to": "/mism-support-tickets", }  ,
			{"id":563, "label":"Resolved List", "to": "/mism-resolved-list", }  ,
			{"id":564, "label":"Assign Tickets Solved", "to": "/mism-assign-tickets-solved", }, ], }, 
			{"id":566, "label":"Leave Requests", "to": "/mism-leave-requests", content: [  
			{"id":567, "label":"Manage Leaves", "to": "/mism-manage-leaves", }  ,
			{"id":568, "label":"Leaves Type", "to": "/mism-leaves-type", }, ], }, 
			{"id":569, "label":"Asserts", "to": "/mism-asserts", content: [  
			{"id":570, "label":"Assets List", "to": "/mism-assets-list", }  ,
			{"id":571, "label":"Assets management", "to": "/mism-assets-management", }  ,
			{"id":572, "label":"Create assert", "to": "/mism-create-assert", }  ,
			{"id":573, "label":"Assign assert", "to": "/mism-assign-assert", }  ,
			{"id":574, "label":"Destory assert", "to": "/mism-destory-assert", }  ,
			{"id":575, "label":"assert Category", "to": "/mism-assert-category", }  ,
			{"id":576, "label":"New category", "to": "/mism-new-category", }  ,
			{"id":577, "label":"Manage category", "to": "/mism-manage-category", }  ,
			{"id":578, "label":"Asset Brand", "to": "/mism-asset-brand", }  ,
			{"id":579, "label":"New Brand", "to": "/mism-new-brand", }  ,
			{"id":580, "label":"Manage Brand", "to": "/mism-manage-brand", }, ], }, 
			{"id":581, "label":"Reports", "to": "/mism-reports", content: [  
			{"id":582, "label":"Daily ", "to": "/mism-daily", }  ,
			{"id":583, "label":"Monthly ", "to": "/mism-monthly", }  ,
			{"id":584, "label":"Yearly", "to": "/mism-yearly", }  ,
			{"id":585, "label":"Process ", "to": "/mism-process", }, ], }, 
			{"id":586, "label":"Settings" , "to": "/mism-settings", },
			
		];
		// ITManager
		const MenuITManager = [
			{
				"id": 'Directories',
				"label": "IT Manager"
			},			

			{"id":349, "label":"Dashboard", "to": "/it-manager-dashboard", }, 
			{"id":350, "label":"Inventory Control " , "to": "/itm-inventory-control ", }, 
			{"id":351, "label":"Ware Houses", "to": "/itm-ware-houses ", }, 
			{"id":352, "label":"Products", "to": "/itm-products ", content: [  
			{"id":353, "label":"Out Of Stock", "to": "/itm-out-of-stock ", }  , 
			{"id":354, "label":"Expired Products", "to": "/itm-expired-products ", }  , 
			{"id":355, "label":"Product Tax", "to": "/itm-product-tax ", }, ], }, 
			{"id":356, "label":"Billing Invoices" , "to": "/itm-billing-invoices ", }, 
			{"id":357, "label":"Invoice Payments"  , "to": "/itm-invoice-payments ", }, 
			{"id":358, "label":"Tax Type", "to": "/itm-tax-type ", content: [  
			{"id":359, "label":"Product Category", "to": "/itm-product-category ", }, ], }, 
			{"id":360, "label":"Suppliers", "to": "/itm-suppliers ", content: [  
			{"id":361, "label":"new supplier", "to": "/itm-new-supplier ", }  , 
			{"id":362, "label":"suppliers list", "to": "/itm-suppliers-list ", }, ], }, 
			{"id":363, "label":"Purchases", "to": "/itm-purchases ", content: [  
			{"id":364, "label":"New Purchase", "to": "/itm-new-purchase ", }  , 
			{"id":365, "label":"Purchase List", "to": "/itm-purchase-list ", }, ], }, 
			{"id":366, "label":"Sales Order", "to": "/itm-sales-order ", content: [   
			{"id":367, "label":"Sales Order List", "to": "/itm-sales-order-list ", }  , 
			{"id":368, "label":"New Order Invoice", "to": "/itm-new-order-invoice ", }  , 
			{"id":369, "label":"Manage Orders", "to": "/itm-manage-orders ", }  , 
			{"id":370, "label":"Paid Orders", "to": "/itm-paid-orders ", }  , 
			{"id":371, "label":"Unpaid Orders", "to": "/itm-unpaid-orders ", }  , 
			{"id":372, "label":"Delivered Orders", "to": "/itm-delivered-orders ", }  , 
			{"id":373, "label":"Cancelled Orders", "to": "/itm-cancelled-orders ", }  , 
			{"id":374, "label":"Credit Types", "to": "/itm-credit-types ", }, ], }, 
			{"id":375, "label":"Help Desk", "to": "/itm-help-desk ", content: [  
			{"id":376, "label":"Tickets", "to": "/itm-tickets ", }  , 
			{"id":377, "label":"Tickets List", "to": "/itm-tickets-list ", }  , 
			{"id":378, "label":"Create Ticket", "to": "/itm-create-ticket ", }  , 
			{"id":379, "label":"Support Tickets", "to": "/itm-support-tickets ", }  , 
			{"id":380, "label":"Resolved List", "to": "/itm-resolved-list ", }  , 
			{"id":381, "label":"Assign Tickets Solve", "to": "/itm-assign-tickets-solve ", }, ], }, 
			{"id":382, "label":"Leave Requests", "to": "/itm-leave-requests ", content: [  
			{"id":383, "label":"Manage Leaves", "to": "/itm-manage-leaves ", }  , 
			{"id":384, "label":"Leaves Type", "to": "/itm-leaves-type ", }, ], }, 
			{"id":385, "label":"Assets List"  , "to": "/itm-assets-list ", }, 
			{"id":386, "label":"Assets management", "to": "/itm-assets-management ", content: [  
			{"id":387, "label":"Create assert", "to": "/itm-create-assert ", }  , 
			{"id":388, "label":"Assign assert", "to": "/itm-assign-assert ", }  , 
			{"id":389, "label":"Destory assert", "to": "/itm-destory-assert ", }, ], }, 
			{"id":390, "label":"assert Category", "to": "/itm-assert-category ", content: [  
			{"id":391, "label":"New category", "to": "/itm-new-category ", }  , 
			{"id":392, "label":"Manage category", "to": "/itm-manage-category ", }, ], }, 
			{"id":393, "label":"Asset Brand", "to": "/itm-asset-brand ", content: [  
			{"id":394, "label":"New Brand", "to": "/itm-new-brand ", }  , 
			{"id":395, "label":"Manage Brand", "to": "/itm-manage-brand ", }, ], }, 
			{"id":396, "label":"Settings" , "to": "/itm-settings", }, 
			
			
		];
		// StateAdministrator
		const MenuStateAdministrator = [
			{
				"id": 'Directories',
				"label": "State Administrator"
			},
			

			{"id":864, "label":"Dashboard  ", "to": "/state-administrator-dashboard", } , 
			{"id":865, "label":"Resources ", "to": "/sa-resources", content: [  
			{"id":866, "label":"Asserts", "to": "/sa-asserts", }  ,
			{"id":867, "label":"Users ", "to": "/sa-users", }, ], }, 
			{"id":868, "label":"Maps & Track " , "to": "/maps", },
			{"id":869, "label":"state Reports " , "to": "/sa-state-reports", },
			{"id":870, "label":"Settings", "to": "/sa-settings", },

			
			
		];
		// MISExecutive
		const MenuMISExecutive = [
			{
				"id": 'Directories',
				"label": "MIS Executive"
			},			

			{"id":588, "label":"Dashboard", "to": "/mis-executive-dashboard", },
			{"id":589, "label":"Inventroy Control ", "to": "/mise-inventroy-control", content: [  
			{"id":590, "label":"Ware Houses", "to": "/mise-ware-houses", }  ,
			{"id":591, "label":"Products", "to": "/mise-products", }  ,
			{"id":592, "label":"Billing Invoices", "to": "/mise-billing-invoices", }  ,
			{"id":593, "label":"Invoice Payments", "to": "/mise-invoice-payments", }  ,
			{"id":594, "label":"Tax Type", "to": "/mise-tax-type", }  ,
			{"id":595, "label":"Product Category", "to": "/mise-product-category", }  ,
			{"id":596, "label":"Suppliers", "to": "/mise-suppliers", }  ,
			{"id":597, "label":"new supplier", "to": "/mise-new-supplier", }  ,
			{"id":598, "label":"suppliers list", "to": "/mise-suppliers-list", }  ,
			{"id":599, "label":"Purchases", "to": "/mise-purchases", }  ,
			{"id":600, "label":"New Purchase", "to": "/mise-new-purchase", }  ,
			{"id":601, "label":"Purchase List", "to": "/mise-purchase-list", }  ,
			{"id":602, "label":"Sales Order", "to": "/mise-sales-order", }  ,
			{"id":603, "label":"Sales Order List", "to": "/mise-sales-order-list", }  ,
			{"id":604, "label":"New Order Invoice", "to": "/mise-new-order-invoice", }  ,
			{"id":605, "label":"Manage Orders", "to": "/mise-manage-orders", }  ,
			{"id":606, "label":"Paid Orders", "to": "/mise-paid-orders", }  ,
			{"id":607, "label":"Unpaid Orders", "to": "/mise-unpaid-orders", }  ,
			{"id":608, "label":"Delivered Orders", "to": "/mise-delivered-orders", }  ,
			{"id":609, "label":"Cancelled Orders", "to": "/mise-cancelled-orders", }  ,
			{"id":610, "label":"Credit Types", "to": "/mise-credit-types", }, ], }, 
			{"id":611, "label":"Help Desk", "to": "/mise-help-desk", content: [  
			{"id":612, "label":"Tickets", "to": "/mise-tickets", }  ,
			{"id":613, "label":"Tickets List", "to": "/mise-tickets-list", }  ,
			{"id":614, "label":"Create Ticket", "to": "/mise-create-ticket", }  ,
			{"id":615, "label":"Support Tickets", "to": "/mise-support-tickets", }  ,
			{"id":616, "label":"Resolved List", "to": "/mise-resolved-list", }  ,
			{"id":617, "label":"Assign Tickets Solved", "to": "/mise-assign-tickets-solved", }, ], }, 
			{"id":618, "label":"Leave Requests", "to": "/mise-leave-requests", content: [  
			{"id":619, "label":"Manage Leaves", "to": "/mise-manage-leaves", }  ,
			{"id":620, "label":"Leaves Type", "to": "/mise-leaves-type", }, ], }, 
			{"id":621, "label":"Asserts", "to": "/mise-asserts", content: [  
			{"id":622, "label":"Assets List", "to": "/mise-assets-list", }  ,
			{"id":623, "label":"Assets management", "to": "/mise-assets-management", }  ,
			{"id":624, "label":"Create assert", "to": "/mise-create-assert", }  ,
			{"id":625, "label":"Assign assert", "to": "/mise-assign-assert", }  ,
			{"id":626, "label":"Destory assert", "to": "/mise-destory-assert", }  ,
			{"id":627, "label":"assert Category", "to": "/mise-assert-category", }  ,
			{"id":628, "label":"New category", "to": "/mise-new-category", }  ,
			{"id":629, "label":"Manage category", "to": "/mise-manage-category", }  ,
			{"id":630, "label":"Asset Brand", "to": "/mise-asset-brand", }  ,
			{"id":631, "label":"New Brand", "to": "/mise-new-brand", }  ,
			{"id":632, "label":"Manage Brand", "to": "/mise-manage-brand", }, ], }, 
			{"id":633, "label":"Reports", "to": "/mise-reports", content: [  
			{"id":634, "label":"Daily ", "to": "/mise-daily", }  ,
			{"id":635, "label":"Monthly ", "to": "/mise-monthly", }  ,
			{"id":636, "label":"Yearly", "to": "/mise-yearly", }  ,
			{"id":637, "label":"Process ", "to": "/mise-process", }, ], }, 
			{"id":638, "label":"Settings", "to": "/mise-settings", },

			
			
		];
		// AssertsManagerHWAdministrator
		const MenuAssertsManagerHWAdministrator = [
			{
				"id": 'Directories',
				"label": "Asserts Manager HW Administrator"
			},
			

			{"id":349, "label":"Dashboard", "to": "/asserts-manager-hw-administrator-dashboard", }, 
			{"id":350, "label":"Inventory Control " , "to": "/amhwa-inventory-control", }, 
			{"id":351, "label":"Ware Houses", "to": "/amhwa-ware-houses", }, 
			{"id":352, "label":"Products", "to": "/amhwa-products", content: [  
			{"id":353, "label":"Out Of Stock", "to": "/amhwa-out-of-stock", }  , 
			{"id":354, "label":"Expired Products", "to": "/amhwa-expired-products", }  , 
			{"id":355, "label":"Product Tax", "to": "/amhwa-product-tax", }, ], }, 
			{"id":356, "label":"Billing Invoices" , "to": "/amhwa-billing-invoices", }, 
			{"id":357, "label":"Invoice Payments"  , "to": "/amhwa-invoice-payments", }, 
			{"id":358, "label":"Tax Type", "to": "/amhwa-tax-type", content: [  
			{"id":359, "label":"Product Category", "to": "/amhwa-product-category", }, ], }, 
			{"id":360, "label":"Suppliers", "to": "/amhwa-suppliers", content: [  
			{"id":361, "label":"new supplier", "to": "/amhwa-new-supplier", }  , 
			{"id":362, "label":"suppliers list", "to": "/amhwa-suppliers-list", }, ], }, 
			{"id":363, "label":"Purchases", "to": "/amhwa-purchases", content: [  
			{"id":364, "label":"New Purchase", "to": "/amhwa-new-purchase", }  , 
			{"id":365, "label":"Purchase List", "to": "/amhwa-purchase-list", }, ], }, 
			{"id":366, "label":"Sales Order", "to": "/amhwa-sales-order", content: [   
			{"id":367, "label":"Sales Order List", "to": "/amhwa-sales-order-list", }  , 
			{"id":368, "label":"New Order Invoice", "to": "/amhwa-new-order-invoice", }  , 
			{"id":369, "label":"Manage Orders", "to": "/amhwa-manage-orders", }  , 
			{"id":370, "label":"Paid Orders", "to": "/amhwa-paid-orders", }  , 
			{"id":371, "label":"Unpaid Orders", "to": "/amhwa-unpaid-orders", }  , 
			{"id":372, "label":"Delivered Orders", "to": "/amhwa-delivered-orders", }  , 
			{"id":373, "label":"Cancelled Orders", "to": "/amhwa-cancelled-orders", }  , 
			{"id":374, "label":"Credit Types", "to": "/amhwa-credit-types", }, ], }, 
			{"id":375, "label":"Help Desk", "to": "/amhwa-help-desk", content: [  
			{"id":376, "label":"Tickets", "to": "/amhwa-tickets", }  , 
			{"id":377, "label":"Tickets List", "to": "/amhwa-tickets-list", }  , 
			{"id":378, "label":"Create Ticket", "to": "/amhwa-create-ticket", }  , 
			{"id":379, "label":"Support Tickets", "to": "/amhwa-support-tickets", }  , 
			{"id":380, "label":"Resolved List", "to": "/amhwa-resolved-list", }  , 
			{"id":381, "label":"Assign Tickets Solve", "to": "/amhwa-assign-tickets-solve", }, ], }, 
			{"id":382, "label":"Leave Requests", "to": "/amhwa-leave-requests", content: [  
			{"id":383, "label":"Manage Leaves", "to": "/amhwa-manage-leaves", }  , 
			{"id":384, "label":"Leaves Type", "to": "/amhwa-leaves-type", }, ], }, 
			{"id":385, "label":"Assets List"  , "to": "/amhwa-assets-list", }, 
			{"id":386, "label":"Assets management", "to": "/amhwa-assets-management", content: [  
			{"id":387, "label":"Create assert", "to": "/amhwa-create-assert", }  , 
			{"id":388, "label":"Assign assert", "to": "/amhwa-assign-assert", }  , 
			{"id":389, "label":"Destory assert", "to": "/amhwa-destory-assert", }, ], }, 
			{"id":390, "label":"assert Category", "to": "/amhwa-assert-category", content: [  
			{"id":391, "label":"New category", "to": "/amhwa-new-category", }  , 
			{"id":392, "label":"Manage category", "to": "/amhwa-manage-category", }, ], }, 
			{"id":393, "label":"Asset Brand", "to": "/amhwa-asset-brand", content: [  
			{"id":394, "label":"New Brand", "to": "/amhwa-new-brand", }  , 
			{"id":395, "label":"Manage Brand", "to": "/amhwa-manage-brand", }, ], }, 
			{"id":396, "label":"Settings" , "to": "/amhwa-settings", }, 
			
		];
		// TeamLeaderFieldExecutives
		const MenuTeamLeaderFieldExecutives = [
			{
				"id": 'Directories',
				"label": "Team Leader Field Executives"
			},
			
			{"id":681, "label":"Dashboard", "to": "/team-leader-field-executives-dashboard", },
			{"id":682, "label":"Leave Requests", "to": "/tlfe-leave-requests", content: [  
			{"id":683, "label":"Manage Leaves", "to": "/tlfe-manage-leaves", } ,
			{"id":684, "label":"Leaves Type", "to": "/tlfe-leaves-type", }, ], }, 
			{"id":685, "label":"Executives", "to": "/tlfe-executives", content: [  
			{"id":686, "label":"Assigned", "to": "/tlfe-assigned", },  
			{"id":687, "label":"Managed ", "to": "/tlfe-managed", }, ], }, 
			{"id":688, "label":"Cases", "to": "/tlfe-cases", content: [  
			{"id":689, "label":"Assigned ", "to": "/tlfe-assigned", }  ,
			{"id":690, "label":"Managed ", "to": "/tlfe-managed", }  ,
			{"id":691, "label":"Reassigned ", "to": "/tlfe-reassigned", }, ], }, 
			{"id":692, "label":"Disciplinary ", "to": "/tlfe-disciplinary", content: [  
			{"id":693, "label":"Actions ", "to": "/tlfe-actions", }  ,
			{"id":694, "label":"Resolutions", "to": "/tlfe-resolutions", }, ], }, 
			{"id":695, "label":"Payments and Commissions" , "to": "/tlfe-payments-and-commissions", },
			{"id":696, "label":"Messaging " , "to": "/tlfe-messaging", },
			{"id":697, "label":"Settings", "to": "/tlfe-settings", },
			
		];
		// FieldExecutive
		const MenuFieldExecutive = [
			{
				"id": 'Directories',
				"label": "Field Executive"
			},
			

			{"id":754, "label":"Dashboard", "to": "/field-executive-dashboard", },
			{"id":755, "label":"Cases", "to": "/fe-cases", content: [
			{"id":756, "label":"Assigned ", "to": "/fe-assigned", },
			{"id":757, "label":"Managed ", "to": "/fe-managed", },
			{"id":758, "label":"Reassigned ", "to": "/fe-reassigned", }, ], }, 
			{"id":759, "label":"Disciplinary ", "to": "/fe-disciplinary", content: [
			{"id":760, "label":"Actions ", "to": "/fe-actions", },
			{"id":761, "label":"Resolutions", "to": "/fe-resolutions", }, ], }, 
			{"id":762, "label":"Messaging " , "to": "/fe-messaging", },
			{"id":763, "label":"Payments and Commissions" , "to": "/fe-payments-and-commissions", },
			{"id":764, "label":"Leave Requests", "to": "/fe-leave-requests", content: [
			{"id":765, "label":"Manage Leaves", "to": "/fe-manage-leaves", },
			{"id":766, "label":"Leaves Type", "to": "/fe-leaves-type", }, ], }, 
			{"id":767, "label":"Settings", "to": "/fe-settings", },
			
		];
		// TeamLeaderPhoneBankers
		const MenuTeamLeaderPhoneBankers = [
			{
				"id": 'Directories',
				"label": "Team Leader Phone Bankers"
			},
			

			{"id":699, "label":"Dashboard", "to": "/team-leader-phone-bankers-dashboard", },
			{"id":700, "label":"Leave Requests", "to": "/tlpb-leave-requests", content: [
			{"id":701, "label":"Manage Leaves", "to": "/tlpb-manage-leaves", },
			{"id":702, "label":"Leaves Type", "to": "/tlpb-leaves-type", }, ], }, 
			{"id":703, "label":"Phone Bankers ", "to": "/tlpb-phonebanker", content: [
			{"id":704, "label":"Assigned", "to": "/tlpb-assigned", },
			{"id":705, "label":"Managed ", "to": "/tlpb-managed", }, ], }, 
			{"id":706, "label":"Cases", "to": "/tlpb-cases", content: [
			{"id":707, "label":"Assigned ", "to": "/tlpb-assigned", },
			{"id":708, "label":"Managed ", "to": "/tlpb-managed", },
			{"id":709, "label":"Reassigned ", "to": "/tlpb-reassigned", }, ], }, 
			{"id":710, "label":"Disciplinary ", "to": "/tlpb-disciplinary", content: [
			{"id":711, "label":"Actions ", "to": "/tlpb-actions", },
			{"id":712, "label":"Resolutions", "to": "/tlpb-resolutions", }, ], }, 
			{"id":713, "label":"Payments and Commissions" , "to": "/tlpb-payments-and-commissions", },
			{"id":714, "label":"Messaging " , "to": "/tlpb-messaging", },
			{"id":715, "label":"Settings", "to": "/tlpb-settings", },
			
		];
		// PhoneBanker
		const MenuPhoneBanker = [
			{
				"id": 'Directories',
				"label": "Phone Banker"
			},
			

			{"id":739, "label":"Dashboard", "to": "/phone-banker-dashboard", },
			{"id":740, "label":"Cases", "to": "/pb-cases", content: [
			{"id":741, "label":"Assigned ", "to": "/pb-assigned", },
			{"id":742, "label":"Managed ", "to": "/pb-managed", },
			{"id":743, "label":"Reassigned ", "to": "/pb-reassigned", }, ], }, 
			{"id":744, "label":"Disciplinary ", "to": "/pb-disciplinary", content: [
			{"id":745, "label":"Actions ", "to": "/pb-actions", },
			{"id":746, "label":"Resolutions", "to": "/pb-resolutions", }, ], }, 
			{"id":747, "label":"Messaging " , "to": "/pb-messaging", },
			{"id":748, "label":"Payments and Commissions" , "to": "/pb-payments-and-commissions", },
			{"id":749, "label":"Leave Requests", "to": "/pb-leave-requests", content: [
			{"id":750, "label":"Manage Leaves", "to": "/pb-manage-leaves", },
			{"id":751, "label":"Leaves Type", "to": "/pb-leaves-type", }, ], }, 
			{"id":752, "label":"Settings", "to": "/pb-settings", },
			
		];
		// TeamLeaderTracers
		const MenuTeamLeaderTracers = [
			{
				"id": 'Directories',
				"label": "TeamLeader Tracers"
			},
			

			{"id":717, "label":"Dashboard", "to": "/team-leader-tracers-dashboard", },
			{"id":718, "label":"Tracing ", "to": "/tlt-tracing", content: [
			{"id":719, "label":"Identifying ", "to": "/tlt-identifying", },
			{"id":720, "label":"Search", "to": "/tlt-search", },
			{"id":721, "label":"Results ", "to": "/tlt-results", }, ], }, 
			{"id":722, "label":"Tracers", "to": "/tlt-tracers", content: [
			{"id":723, "label":"Assigned", "to": "/tlt-assigned", },
			{"id":724, "label":"Managed ", "to": "/tlt-managed", }, ], }, 
			{"id":725, "label":"Cases", "to": "/tlt-cases", content: [
			{"id":726, "label":"Assigned ", "to": "/tlt-assigned", },
			{"id":727, "label":"Managed ", "to": "/tlt-managed", },
			{"id":728, "label":"Reassigned ", "to": "/tlt-reassigned", }, ], }, 
			{"id":729, "label":"Disciplinary ", "to": "/tlt-disciplinary", content: [
			{"id":730, "label":"Actions ", "to": "/tlt-actions", },
			{"id":731, "label":"Resolutions", "to": "/tlt-resolutions", }, ], }, 
			{"id":732, "label":"Payments and Commissions" , "to": "/tlt-payments-and-commissions", },
			{"id":733, "label":"Messaging ", "to": "/tlt-messaging", },
			{"id":734, "label":"Leave Requests", "to": "/tlt-leave-requests", content: [
			{"id":735, "label":"Manage Leaves", "to": "/tlt-manage-leaves", },
			{"id":736, "label":"Leaves Type", "to": "/tlt-leaves-type", }, ], }, 
			{"id":737, "label":"Settings", "to": "/tlt-settings", },
			
		];
		// Tracers
		const MenuTracers = [
			{
				"id": 'Directories',
				"label": "Tracers"
			},
			

			{"id":769, "label":"Dashboard", "to": "/tracers-dashboard", },
			{"id":770, "label":"Tracing ", "to": "/tc-tracing", content: [
			{"id":771, "label":"Identifying ", "to": "/tc-identifying", },
			{"id":772, "label":"Search", "to": "/tc-search", },
			{"id":773, "label":"Results ", "to": "/tc-results", }, ], }, 
			{"id":774, "label":"Cases", "to": "/tc-cases", content: [
			{"id":775, "label":"Assigned ", "to": "/tc-assigned", },
			{"id":776, "label":"Managed ", "to": "/tc-managed", },
			{"id":777, "label":"Reassigned ", "to": "/tc-reassigned", }, ], }, 
			{"id":778, "label":"Disciplinary ", "to": "/tc-disciplinary", content: [
			{"id":779, "label":"Actions ", "to": "/tc-actions", },
			{"id":780, "label":"Resolutions", "to": "/tc-resolutions", }, ], }, 
			{"id":781, "label":"Messaging " , "to": "/tc-messaging", },
			{"id":782, "label":"Payments and Commissions" , "to": "/tc-payments-and-commissions", },
			{"id":783, "label":"Leave Requests", "to": "/tc-leave-requests", content: [
			{"id":784, "label":"Manage Leaves", "to": "/tc-manage-leaves", },
			{"id":785, "label":"Leaves Type", "to": "/tc-leaves-type", }, ], }, 
			{"id":786, "label":"Settings", "to": "/tc-settings", }, 
			
			
		];

		// AccountsHead
		const MenuAccountsHead = [
			{
				"id": 'Directories',
				"label": "Accounts Head"
			},
			

			{"id":322, "label":"Dashboard", "to": "/accounts-head-dashboard",} , 
			{"id":323, "label":"Accounts " , "to": "/ah-accounts",} , 
			{"id":324, "label":"Deposits", "to": "/ah-deposits", content: [
			{"id":325, "label":"Deposits List", "to": "/ah-deposits-list", }  , 
			{"id":326, "label":"Categories", "to": "/ah-categories", }, ], }, 
			{"id":327, "label":"Expenses", "to": "/ah-expenses", },
			{"id":328, "label":"Transactions", "to": "/ah-transactions",},
			{"id":329, "label":"Payroll", "to": "/ah-payroll", content: [
			{"id":330, "label":"Pay Slip History", "to": "/ah-pay-slip-history", }  , 
			{"id":331, "label":"Advance Salary", "to": "/ah-advance-salary", }, ], }, 
			{"id":332, "label":"Loan", "to": "/ah-loan", content: [
			{"id":333, "label":"Loan Types", "to": "/ah-loan-types", }  , 
			{"id":334, "label":"Commission types", "to": "/ah-commission-types", }  , 
			{"id":335, "label":"Incentive Types", "to": "/ah-incentive-types", }, ], }, 
			{"id":336, "label":"Invoices", "to": "/ah-invoices",  content: [
			{"id":337, "label":"Billing Invoices", "to": "/ah-billing-invoices", }  , 
			{"id":338, "label":"Invoice Payments", "to": "/ah-invoice-payments", }  , 
			{"id":339, "label":"Tax Type", "to": "/ah-tax-type", }, ], }, 
			{"id":340, "label":"Estimates", "to": "/ah-estimates",  content: [
			{"id":341, "label":"Manage Leaves", "to": "/ah-manage-leaves", }  , 
			{"id":342, "label":"Leaves Type", "to": "/ah-estimate-type", }, ], }, 
			{"id":343, "label":"Leave Requests", "to": "/ah-leave-requests",  content: [
			{"id":344, "label":"Manage Leaves", "to": "/ah-manage-leaves", }  , 
			{"id":345, "label":"Leaves Type", "to": "/ah-leaves-type", }, ], }, 
			{"id":346, "label":"Settings", "to": "/ah-settings", },			
			
		];

		// OfficeAdmin
		const MenuOfficeAdmin = [
			{
				"id": 'Directories',
				"label": "Office Admin"
			},			

			{"id":227, "label":"Dashboard" , "to": "/office-admin-dashboard",   },
			{"id":228, "label":"Documents Manager", "to":  "/oa-documents-manager", },
			{"id":229, "label":"general", "to":  "/oa-general",content: [
			{"id":230, "label":"Uploading files", "to":  "/oa-uploading-files",}  , 
			{"id":231, "label":"Managing Files", "to":  "/oa-managing-files",}, ], }, 
			{"id":232, "label":"official", "to":  "/oa-official",content: [
			{"id":233, "label":"Uploading files", "to":  "/oa-uploading-files",}  , 
			{"id":234, "label":"Managing Files", "to":  "/oa-managing-files",}, ], }, 
			{"id":235, "label":"Visitors", "to":  "/oa-visitors",content: [
			{"id":236, "label":"New visitor", "to":  "/oa-new-visitor",}  , 
			{"id":237, "label":"Visitors List", "to":  "/oa-visitors-list",}  ,],}, 
			{"id":238, "label":"Leave Requests", "to":  "/oa-leave-requests",content: [ 
			{"id":239, "label":"Manage Leaves", "to":  "/oa-manage-leaves",}  , 
			{"id":240, "label":"Leaves Type", "to":  "/oa-leaves-type",}, ], }, 
			{"id":241, "label":"Settings", "to":  "/oa-settings",},	
			
			
		];
		// AccountsExecutive
		const MenuAccountsExecutive = [
			{
				"id": 'Directories',
				"label": "Accounts Executive"
			},			

			{"id":322, "label":"Dashboard", "to": "/accounts-executive-dashboard",} , 
			{"id":323, "label":"Accounts " , "to": "/ae-accounts",} , 
			{"id":324, "label":"Deposits", "to": "/ae-deposits", content: [
			{"id":325, "label":"Deposits List", "to": "/ae-deposits-list", }  , 
			{"id":326, "label":"Categories", "to": "/ae-categories", }, ], }, 
			{"id":327, "label":"Expenses", "to": "/ae-expenses", },
			{"id":328, "label":"Transactions", "to": "/ae-transactions",},
			{"id":329, "label":"Payroll", "to": "/ae-payroll", content: [
			{"id":330, "label":"Pay Slip History", "to": "/ae-pay-slip-history", }  , 
			{"id":331, "label":"Advance Salary", "to": "/ae-advance-salary", }, ], }, 
			{"id":332, "label":"Loan", "to": "/ae-loan", content: [
			{"id":333, "label":"Loan Types", "to": "/ae-loan-types", }  , 
			{"id":334, "label":"Commission types", "to": "/ae-commission-types", }  , 
			{"id":335, "label":"Incentive Types", "to": "/ae-incentive-types", }, ], }, 
			{"id":336, "label":"Invoices", "to": "/ae-invoices",  content: [
			{"id":337, "label":"Billing Invoices", "to": "/ae-billing-invoices", }  , 
			{"id":338, "label":"Invoice Payments", "to": "/ae-invoice-payments", }  , 
			{"id":339, "label":"Tax Type", "to": "/ae-tax-type", }, ], }, 
			{"id":340, "label":"Estimates", "to": "/ae-estimates",  content: [
			{"id":341, "label":"Manage Leaves", "to": "/ae-manage-leaves", }  , 
			{"id":342, "label":"Leaves Type", "to": "/ae-estimate-type", }, ], }, 
			{"id":343, "label":"Leave Requests", "to": "/ae-leave-requests",  content: [
			{"id":344, "label":"Manage Leaves", "to": "/ae-manage-leaves", }  , 
			{"id":345, "label":"Leaves Type", "to": "/ae-leaves-type", }, ], }, 
			{"id":346, "label":"Settings", "to": "/ae-settings", },			
			
		];
		// AccountsClerk
		const MenuAccountsClerk = [
			{
				"id": 'Directories',
				"label": "Accounts Clerk"
			},			
			{"id":322, "label":"Dashboard", "to": "/accounts-clerk-dashboard",} , 
			{"id":323, "label":"Accounts " , "to": "/ac-accounts",} , 
			{"id":324, "label":"Deposits", "to": "/ac-deposits", content: [
			{"id":325, "label":"Deposits List", "to": "/ac-deposits-list", }  , 
			{"id":326, "label":"Categories", "to": "/ac-categories", }, ], }, 
			{"id":327, "label":"Expenses", "to": "/ac-expenses", },
			{"id":328, "label":"Transactions", "to": "/ac-transactions",},
			{"id":329, "label":"Payroll", "to": "/ac-payroll", content: [
			{"id":330, "label":"Pay Slip History", "to": "/ac-pay-slip-history", }  , 
			{"id":331, "label":"Advance Salary", "to": "/ac-advance-salary", }, ], }, 
			{"id":332, "label":"Loan", "to": "/ac-loan", content: [
			{"id":333, "label":"Loan Types", "to": "/ac-loan-types", }  , 
			{"id":334, "label":"Commission types", "to": "/ac-commission-types", }  , 
			{"id":335, "label":"Incentive Types", "to": "/ac-incentive-types", }, ], }, 
			{"id":336, "label":"Invoices", "to": "/ac-invoices",  content: [
			{"id":337, "label":"Billing Invoices", "to": "/ac-billing-invoices", }  , 
			{"id":338, "label":"Invoice Payments", "to": "/ac-invoice-payments", }  , 
			{"id":339, "label":"Tax Type", "to": "/ac-tax-type", }, ], }, 
			{"id":340, "label":"Estimates", "to": "/ac-estimates",  content: [
			{"id":341, "label":"Manage Leaves", "to": "/ac-manage-leaves", }  , 
			{"id":342, "label":"Leaves Type", "to": "/ac-estimate-type", }, ], }, 
			{"id":343, "label":"Leave Requests", "to": "/ac-leave-requests",  content: [
			{"id":344, "label":"Manage Leaves", "to": "/ac-manage-leaves", }  , 
			{"id":345, "label":"Leaves Type", "to": "/ac-leaves-type", }, ], }, 
			{"id":346, "label":"Settings", "to": "/ac-settings", },			
			
		];
		// TrainingHead
		const MenuTrainingHead = [
			{
				"id": 'Directories',
				"label": "Training Head"
			},			

			{"id":185, "label":"Dashboard", "to": "/training-head-dashboard" } ,
			{"id":186, "label":"Leave Requests", "to": "/th-leave-requests", content: [
			{"id":187, "label":"Manage Leaves", "to": "/th-manage-leaves", },
			{"id":188, "label":"Leaves Type", "to": "/th-leaves-type", }, ], }, 
			{"id":189, "label":"Trainings", "to": "/th-trainings", content: [
			{"id":190, "label":"Training Sessions", "to": "/th-training-sessions", },
			{"id":191, "label":"Trainers", "to": "/th-trainers", },
			{"id":192, "label":"Training Skills", "to": "/th-training-skills", },
			{"id":193, "label":"Manage Trainings", "to": "/th-manage-trainings", }, ], }, 
			{"id":194, "label":"Disciplinary Cases", "to": "/th-disciplinary-cases", content: [
			{"id":195, "label":"Actions", "to": "/th-actions", },
			{"id":196, "label":"Resolutions", "to": "/th-resolutions", }, ], }, 
			{"id":197, "label":"Settings", "to": "/th-settings", },
			
			
		];
		// HRExecutive
		const MenuHRExecutive = [
			{
				"id": 'Directories',
				"label": "HR Executive"
			},
			
			{"id":94, "label":"Dashboard", "to":"hr-executive-dashboard" },  
			{"id":95, "label": "Employees", "to": "/hre-employees", content: [ 
			{"id":96, "label":"Employees List", "to": "/hre-employees-list", }, 
			{"id":97, "label":"Roles & Privileges", "to": "/hre-roles-privileges", }, 
			{"id":98, "label":"Shift & Scheduling", "to": "/hre-shift-scheduling", }, ], }, 
			{"id":99, "label":"Department", "to": "/hre-department", content: [ 
			{"id":100, "label":"Department list", "to": "/hre-department-list", }, 
			{"id":101, "label":"Designation list", "to": "/hre-designation-list", }, ], }, 
			{"id":102, "label":"Resignations", "to": "/hre-resignations",  content: [ 
			{"id":103, "label":"Create Resignations", "to": "/hre-create-resignations", }, 
			{"id":104, "label":"Approve Resignations", "to": "/hre-approve-resignations", }, 
			{"id":105, "label":"Resignation List", "to": "/hre-resignation-list", }, 
			{"id":106, "label":"Employees Exit", "to": "/hre-employees-exit", }, 
			{"id":107, "label":"Exit Types", "to": "/hre-exit-types", }, 
			{"id":108, "label":"Exit Clearance Certificates", "to": "/hre-exit-clearance-certificates", }, ], }, 
			{"id":109, "label":"Transfers", "to": "/hre-transfers",  content: [ 
			{"id":110, "label":"Transfer List", "to": "/hre-transfer-list", }, 
			{"id":111, "label":"Approve Transfers", "to": "/hre-approve-transfers", }, 
			{"id":112, "label":"New Transfers", "to": "/hre-new-transfers", }, ], }, 
			{"id":113, "label":"Complaints", "to": "/hre-complaints",  content: [ 
			{"id":114, "label":"Lodge Complaint", "to": "/hre-lodge-complaint", }, 
			{"id":115, "label":"Complaint List", "to": "/hre-complaint-list", }, 
			{"id":116, "label":"Resolved List", "to": "/hre-resolved-list", }, ], }, 
			{"id":117, "label":"Awards", "to": "/hre-awards",  content: [ 
			{"id":118, "label":"awards", "to": "/hre-awards", }, 
			{"id":119, "label":"award assign list", "to": "/hre-award-assign-list", }, 
			{"id":120, "label":"award Type", "to": "/hre-award-type", }, ], }, 
			{"id":121, "label":"Activities", "to": "/hre-activities",  content: [ 
			{"id":122, "label":"Travels", "to": "/hre-travels", }, 
			{"id":123, "label":"New Business Travel", "to": "/hre-new-business-travel", }, 
			{"id":124, "label":"Travel Arrangement Type", "to": "/hre-travel-arrangement-type", }, 
			{"id":125, "label":"Transport Type", "to": "/hre-transport-type", }, 
			{"id":126, "label":"Payments", "to": "/hre-payments", }, 
			{"id":127, "label":"Travel Management", "to": "/hre-travel-management", }, 
			{"id":128, "label":"Events", "to": "/hre-events", }, 
			{"id":129, "label":"New Event", "to": "/hre-new-event", }, 
			{"id":130, "label":"Event Types", "to": "/hre-event-types", }, 
			{"id":131, "label":"Holidays", "to": "/hre-holidays", }, 
			{"id":132, "label":"New Holiday", "to": "/hre-new-holiday", }, 
			{"id":133, "label":"Holiday list", "to": "/hre-holiday-list", }, 
			{"id":134, "label":"Visitors", "to": "/hre-visitors", }, 
			{"id":135, "label":"New visitor", "to": "/hre-new-visitor", }, 
			{"id":136, "label":"Visitors List", "to": "/hre-visitors-list", }, 
			{"id":137, "label":"Conferences", "to": "/hre-conferences", }, 
			{"id":138, "label":"Conf booking", "to": "/hre-conf-booking", }, 
			{"id":139, "label":"Meeting List", "to": "/hre-meeting-list", }, ], }, 
			{"id":140, "label":"Documents Manager", "to": "/hre-documents-manager",  content: [ 
			{"id":141, "label":"general", "to": "/hre-general", }, 
			{"id":142, "label":"Uploading files", "to": "/hre-uploading-files", }, 
			{"id":143, "label":"Managing Files", "to": "/hre-managing-files", }, 
			{"id":144, "label":"official", "to": "/hre-official", }, 
			{"id":145, "label":"Uploading files", "to": "/hre-uploading-files", }, 
			{"id":146, "label":"Managing Files", "to": "/hre-managing-files", }, 
			{"id":147, "label":"Process Documents", "to": "/hre-process-documents", }, 
			{"id":148, "label":"Uploading files", "to": "/hre-uploading-files", }, 
			{"id":149, "label":"Managing Files", "to": "/hre-managing-files", }, ], }, 
			{"id":150, "label":"Performance (PMS)", "to": "/hre-performance-pms",  content: [ 
			{"id":151, "label":"KPI (indicators)", "to": "/hre-kpi-indicators", }, 
			{"id":152, "label":"KPA (Appraisals)", "to": "/hre-kpa-appraisals", }, 
			{"id":153, "label":"OKRs Tracking Goals", "to": "/hre-okrs-tracking-goals", }, 
			{"id":154, "label":"Competencies", "to": "/hre-competencies", }, 
			{"id":155, "label":"Technical", "to": "/hre-technical", }, 
			{"id":156, "label":"Organizational", "to": "/hre-organizational", }, 
			{"id":157, "label":"Goal Types", "to": "/hre-goal-types", }, 
			{"id":158, "label":"Goal Calendar", "to": "/hre-goal-calendar", }, ], }, 
			{"id":159, "label":"Recruitment (ATS)", "to": "/hre-recruitment-ats",  content: [ 
			{"id":160, "label":"New postings", "to": "/hre-new-postings", }, 
			{"id":161, "label":"Candidate List", "to": "/hre-candidate-list", }, 			
			{"id":163, "label":"Interviews", "to": "/hre-interviews", }, 
			{"id":164, "label":"Interviewers List", "to": "/hre-interviewers-list", }, ], }, 
			{"id":165, "label":"Attendance", "to": "/hre-attendance",  content: [ 
			{"id":166, "label":"Attendance", "to": "/hre-attendance", }, 
			{"id":167, "label":"Manual Attendance", "to": "/hre-manual-attendance", }, 
			{"id":168, "label":"Monthly Report", "to": "/hre-monthly-report", }, 
			{"id":169, "label":"Overtime Request", "to": "/hre-overtime-request", }, ], }, 
			{"id":170, "label":"Policies", "to": "/hre-policies",  content: [ 
			{"id":171, "label":"Create new Policy", "to": "/hre-create-new-policy", }, 
			{"id":172, "label":"Policy list", "to": "/hre-policy-list", }, ], }, 
			{"id":173, "label":"Make Announcement", "to": "/hre-make-announcement",  content: [ 
			{"id":174, "label":"New Announcement", "to": "/hre-new-announcement", }, 
			{"id":175, "label":"Announcement List", "to": "/hre-announcement-list", }, ], }, 
			{"id":176, "label":"Organisation Chart", "to": "/hre-organisation-chart",  content: [ 
			{"id":177, "label":"View", "to": "/hre-view", }, 
			{"id":178, "label":"Create Chart", "to": "/hre-create-chart", }, ], }, 
			{"id":179, "label":"Leave Requests", "to": "/hre-leave-requests",  content: [
			{"id":180, "label":"Manage Leaves", "to": "/hre-manage-leaves", }, 
			{"id":181, "label":"Leaves Type", "to": "/hre-leaves-type", }, ], }, 
			{"id":182, "label":"Settings", "to": "/hre-settings", }, 

			
			
		];
		// TraineeTeamLeader
		const MenuTraineeTeamLeader = [
			{
				"id": 'Directories',
				"label": "Trainee Team Leader"
			},
			
			{"id":'Directories', "label":"Trainee Team Leader "}, 
			{"id":199, "label":"Dashboard" ,"to":"/trainee-team-leader-dashboard"}, 
			{"id":200, "label":"Leave Requests", "to": "/ttl-leave-requests",      content: [ 
			{"id":201, "label":"Manage Leaves", "to": "/ttl-manage-leaves",  },
			{"id":202, "label":"Leaves Type", "to": "/ttl-leaves-type",  },     ], }, 
			{"id":203, "label":"Training", "to": "/ttl-trainings",     content: [ 
			{"id":204, "label":"Training Sessions", "to": "/ttl-training-sessions",  },
			{"id":205, "label":"Trainers", "to": "/ttl-trainers",  },
			{"id":206, "label":"Training Skills", "to": "/ttl-training-skills",  } ,
			{"id":207, "label":"Manage Trainings", "to": "/ttl-manage-trainings",  }, ], }, 
			{"id":208, "label":"Disciplinary Cases", "to": "/ttl-disciplinary-cases",     content: [ 
			{"id":209, "label":"Actions", "to": "/ttl-actions",  } ,
			{"id":210, "label":"Resolutions", "to": "/ttl-resolutions",  }, ], }, 
			{"id":211, "label":"Settings", "to": "/ttl-settings",   }, 			
			
		];

		// Trainer
		const MenuTrainer = [
			{
				"id": 'Directories',
				"label": "Trainer"
			},			

			{"id":'Directories', "label":"Trainer "}, 
			{"id":213, "label":"Dashboard","to":"/trainer-dashboard"}, 
			{"id":214, "label":"Training", "to": "/tr-trainings", content: [  
			{"id":215, "label":"Training Sessions", "to": "/tr-training-sessions", } ,
			{"id":216, "label":"Trainers", "to": "/tr-trainers", } ,
			{"id":217, "label":"Training Skills", "to": "/tr-training-skills", } ,
			{"id":218, "label":"Manage Trainings", "to": "/tr-manage-trainings", }, ], }, 
			{"id":219, "label":"Leave Requests", "to": "/tr-leave-requests", content: [  
			{"id":220, "label":"Manage Leaves", "to": "/tr-manage-leaves", } ,
			{"id":221, "label":"Leaves Type", "to": "/tr-leaves-type", }  , ], }, 
			{"id":222, "label":"Disciplinary Cases", "to": "/tr-disciplinary-cases", content: [
			{"id":223, "label":"Actions", "to": "/tr-actions", }  ,
			{"id":224, "label":"Resolutions", "to": "/tr-resolutions", }, ], }, 
			{"id":225, "label":"Settings" , "to": "/tr-settings", },
			
			
		];
		// SuperAdministrator
		const MenuSuperAdministrator = [
			{
				"id": 'Directories',
				"label": "Super Administrator"
			},			

			{"id":'Directories', "label":"Super Administrator "}, 			
			{"id":887, "label":"Dashboard", "to": "/super-administrator-dashboard", },
			{"id":888, "label":"Company", "to": "/sua-company", content: [  
			{"id":889, "label":"Company Details", "to": "/sua-company-details", } ,
			{"id":890, "label":"Backup company data", "to": "/sua-backup-company-data", },
			{"id":891, "label":"Restore company data", "to": "/sua-restore-company-data", }, ], }, 
			{"id":892, "label":"Basic", "to": "/sua-basic", content: [  
			{"id":893, "label":"Currency", "to": "/sua-currency", },
			{"id":894, "label":"Language", "to": "/sua-language", },
			{"id":895, "label":"Taxes", "to": "/sua-taxes", }, ], }, 
			{"id":896, "label":"System", "to": "/sua-system", content: [  
			{"id":897, "label":"Log", "to": "/sua-log", }  ,
			{"id":898, "label":"Location Log", "to": "/sua-location-log", }  ,
			{"id":899, "label":"Backup", "to": "/sua-backup", }  ,
			{"id":900, "label":"restore", "to": "/sua-restore", }, ], }, 
			{"id":901, "label":"Company based Reports", "to": "/sua-company-based-reports", content: [  
			{"id":902, "label":"Service Reports", "to": "/sua-service-reports", } ,
			{"id":903, "label":"Maintenance Reports", "to": "/sua-maintenance-reports", }, ], }, 
			{"id":904, "label":"Settings", "to": "/sua-settings", },			
			
		];


		const { isOpenRightSidebar, isOpenUserMenu } = this.state
		const { darkMinSidebar, istoggleLeftMenu, friendListOpen, statisticsOpen, statisticsClose, friendListClose } = this.props
		const pageHeading = Routes.filter((route) => route.path === this.props.location.pathname)
		
		return (
			<>
				<div className={`${istoggleLeftMenu ? "offcanvas-active" : ""}`}>
					<div style={this.state.parentlink === 'login' ? masterNone : masterBlock}>
						<div id="header_top" className={`header_top ${darkMinSidebar && 'dark'}`}>
							<div className="container">
								<div className="hleft">
									<NavLink
										to="/"
										onClick={() => this.handler('hr', 'dashboard')}
										className="header-brand"
									>
										{/* <i className="fe fe-command brand-logo" /> */}
										<img src= {"assets/images/slogo.png"} height="30" width ="30" alt="Company Logo" />
										
									</NavLink>
									{/* <div className="dropdown">
										<NavLink to="/page-search" className="nav-link icon">
											<i className="fa fa-search" />
										</NavLink>
										<NavLink to="/app-calendar" className="nav-link icon app_inbox">
											<i className="fa fa-calendar" />
										</NavLink>
										<NavLink to="/app-contact" className="nav-link icon xs-hide">
											<i className="fa fa-id-card-o" />
										</NavLink>
										<NavLink to="/app-chat" className="nav-link icon xs-hide">
											<i className="fa fa-comments-o" />
										</NavLink>
										<NavLink to="/app-filemanager" className="nav-link icon app_file xs-hide">
											<i className="fa fa-folder-o" />
										</NavLink>
									</div> */}
								</div>
								<div className="hright">
									<div className="dropdown">
										{/* <a href="#!" class="nav-link icon theme_btn">
										<i
											class="fa fa-paint-brush"
											data-toggle="tooltip"
											data-placement="right"
											title="Themes"
										></i>
									</a> 
										<span className="nav-link icon settingbar" onClick={this.toggleRightSidebar}>
											<i
												className="fa fa-gear fa-spin"
												data-toggle="tooltip"
												data-placement="right"
												title="Settings"
											/>
										</span>*/}
										<p className="nav-link user_btn" onClick={this.toggleUserMenu}>
											<img
												className="avatar"
												src="/assets/images/user.png"
												alt="fake_alr"
												data-toggle="tooltip"
												data-placement="right"
												title="User Menu"
											/>
										</p>
										<p className="nav-link icon menu_toggle" onClick={() => this.toggleLeftMenu(!istoggleLeftMenu)}>
											<i className="fa  fa-align-left" />
										</p>
									</div>
								</div>
							</div>
						</div>
						{/* <div id="rightsidebar" className={`right_sidebar ${isOpenRightSidebar && "open"}`}>
							<span className="p-3 settingbar float-right" onClick={this.toggleRightSidebar}>
								<i className="fa fa-close" />
							</span>
							<ul className="nav nav-tabs" role="tablist">
								<li className="nav-item">
									<a className="nav-link active" data-toggle="tab" href="#Settings" aria-expanded="true">
										Settings
								</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" data-toggle="tab" href="#activity" aria-expanded="false">
										Activity
								</a>
								</li>
							</ul>
							<div className="tab-content">
								<div
									role="tabpanel"
									className="tab-pane vivify fadeIn active"
									id="Settings"
									aria-expanded="true"
								>
									<div className="mb-4">
										<h6 className="font-14 font-weight-bold text-muted">Font Style</h6>
										<div className="custom-controls-stacked font_setting">
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="font"
													defaultChecked
													defaultValue="font-opensans"
													onChange={() => this.handleFont('font-opensans')}
												/>
												<span className="custom-control-label">Open Sans Font</span>
											</label>
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="font"
													defaultValue="font-montserrat"
													onChange={() => this.handleFont('font-montserrat')}
												/>
												<span className="custom-control-label">Montserrat Google Font</span>
											</label>
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="font"
													onChange={() => this.handleFont('font-roboto')}
												/>
												<span className="custom-control-label">Robot Google Font</span>
											</label>
										</div>
									</div>
									<div className="mb-4">
										<h6 className="font-14 font-weight-bold text-muted">Selected Menu Icon</h6>
										<div className="custom-controls-stacked arrow_option">
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="marrow"
													defaultValue="arrow-a"
													onChange={() => this.handleMenuIcon('list-a')}
												/>
												<span className="custom-control-label">A</span>
											</label>
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="marrow"
													defaultValue="arrow-b"
													onChange={() => this.handleMenuIcon('list-b')}
												/>
												<span className="custom-control-label">B</span>
											</label>
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="marrow"
													defaultValue="arrow-c"
													defaultChecked
													onChange={() => this.handleMenuIcon('list-c')}
												/>
												<span className="custom-control-label">C</span>
											</label>
										</div>

										<h6 className="font-14 font-weight-bold mt-4 text-muted">SubMenu List Icon</h6>
										<div className="custom-controls-stacked list_option">
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="listicon"
													defaultValue="list-a"
													defaultChecked
													onChange={() => this.handleSubMenuIcon('list-a')}
												/>
												<span className="custom-control-label">A</span>
											</label>
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="listicon"
													defaultValue="list-b"
													onChange={() => this.handleSubMenuIcon('list-b')}
												/>
												<span className="custom-control-label">B</span>
											</label>
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="listicon"
													defaultValue="list-c"
													onChange={() => this.handleSubMenuIcon('list-c')}
												/>
												<span className="custom-control-label">C</span>
											</label>
										</div>
									</div>
									<div>
										<h6 className="font-14 font-weight-bold mt-4 text-muted">General Settings</h6>
										<ul className="setting-list list-unstyled mt-1 setting_switch">
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Night Mode</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-darkmode"
														onChange={(e) => this.handleDarkMode(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Fix Navbar top</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-fixnavbar"
														onChange={(e) => this.handleFixNavbar(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Header Dark</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-pageheader"
														onChange={(e) => this.handleDarkHeader(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Min Sidebar Dark</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-min_sidebar"
														onChange={(e) => this.handleMinSidebar(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Sidebar Dark</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-sidebar"
														onChange={(e) => this.handleSidebar(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Icon Color</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-iconcolor"
														onChange={(e) => this.handleIconColor(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Gradient Color</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-gradient"
														onChange={(e) => this.handleGradientColor(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>

											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">RTL Support</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-rtl"
														onChange={(e) => this.handleRtl(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>

										</ul>
									</div>
									<hr />
									<div className="form-group">
										<label className="d-block">
											Storage <span className="float-right">77%</span>
										</label>
										<div className="progress progress-sm">
											<div
												className="progress-bar"
												role="progressbar"
												aria-valuenow={77}
												aria-valuemin={0}
												aria-valuemax={100}
												style={{ width: '77%' }}
											/>
										</div>
										<button type="button" className="btn btn-primary btn-block mt-3">
											Upgrade Storage
									</button>
									</div>
								</div>
								<div role="tabpanel" className="tab-pane vivify fadeIn" id="activity" aria-expanded="false">
									<ul className="new_timeline mt-3">
										<li>
											<div className="bullet pink" />
											<div className="time">11:00am</div>
											<div className="desc">
												<h3>Attendance</h3>
												<h4>Computer Class</h4>
											</div>
										</li>
										<li>
											<div className="bullet pink" />
											<div className="time">11:30am</div>
											<div className="desc">
												<h3>Added an interest</h3>
												<h4>"Volunteer Activities"</h4>
											</div>
										</li>
										<li>
											<div className="bullet green" />
											<div className="time">12:00pm</div>
											<div className="desc">
												<h3>Developer Team</h3>
												<h4>Hangouts</h4>
												<ul className="list-unstyled team-info margin-0 p-t-5">
													<li>
														<img src="/assets/images/xs/avatar1.jpg" alt="Avatar" />
													</li>
													<li>
														<img src="/assets/images/xs/avatar2.jpg" alt="Avatar" />
													</li>
													<li>
														<img src="/assets/images/xs/avatar3.jpg" alt="Avatar" />
													</li>
													<li>
														<img src="/assets/images/xs/avatar4.jpg" alt="Avatar" />
													</li>
												</ul>
											</div>
										</li>
										<li>
											<div className="bullet green" />
											<div className="time">2:00pm</div>
											<div className="desc">
												<h3>Responded to need</h3>
												<a href="#!">"In-Kind Opportunity"</a>
											</div>
										</li>
										<li>
											<div className="bullet orange" />
											<div className="time">1:30pm</div>
											<div className="desc">
												<h3>Lunch Break</h3>
											</div>
										</li>
										<li>
											<div className="bullet green" />
											<div className="time">2:38pm</div>
											<div className="desc">
												<h3>Finish</h3>
												<h4>Go to Home</h4>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div> */}
						<div className="theme_div">
							<div className="card">
								<div className="card-body">
									<ul className="list-group list-unstyled">
										<li className="list-group-item mb-2">
											<p>Default Theme</p>
											<a href="../main/index.html">
												<img
													src="/assets/images/themes/default.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Night Mode Theme</p>
											<a href="../dark/index.html">
												<img
													src="/assets/images/themes/dark.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>RTL Version</p>
											<a href="../rtl/index.html">
												<img
													src="/assets/images/themes/rtl.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Theme Version2</p>
											<a href="../theme2/index.html">
												<img
													src="/assets/images/themes/theme2.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Theme Version3</p>
											<a href="../theme3/index.html">
												<img
													src="/assets/images/themes/theme3.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Theme Version4</p>
											<a href="../theme4/index.html">
												<img
													src="/assets/images/themes/theme4.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Horizontal Version</p>
											<a href="../horizontal/index.html">
												<img
													src="/assets/images/themes/horizontal.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className={`user_div ${isOpenUserMenu && 'open'}`}>
							<h5 className="brand-name mb-4">
								DebtCare
							<p className="user_btn" onClick={this.toggleUserMenu}>
									<i className="icon-logout" />
								</p>
							</h5>
							<div className="card">
								<div className="card-body">
									<div className="media">
										<img
											className="avatar avatar-xl mr-3"
											src="/assets/images/user.png"
											alt="avatar"
										/>
										<div className="media-body">
											<h5 className="m-0">Sara Hopkins</h5>
											<p className="text-muted mb-0">Webdeveloper</p>
											<ul className="social-links list-inline mb-0 mt-2">
												<li className="list-inline-item">
													<a
														href="#!"
														title="fake_title"
														data-toggle="tooltip"
														data-original-title="Facebook"
													>
														<i className="fa fa-facebook" />
													</a>
												</li>
												<li className="list-inline-item">
													<a
														href="#!"
														title="fake_title"
														data-toggle="tooltip"
														data-original-title="Twitter"
													>
														<i className="fa fa-twitter" />
													</a>
												</li>
												<li className="list-inline-item">
													<a
														href="#!"
														title="fake_title"
														data-toggle="tooltip"
														data-original-title={1234567890}
													>
														<i className="fa fa-phone" />
													</a>
												</li>
												<li className="list-inline-item">
													<a
														href="#!"
														title="fake_title"
														data-toggle="tooltip"
														data-original-title="@skypename"
													>
														<i className="fa fa-skype" />
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							{statisticsClose ?
								<div className={`card ${statisticsOpen ? 'card-collapsed' : ""}`}>
									< div className="card-header">
										<h3 className="card-title">Statistics</h3>
										<div className="card-options">
											<span className="card-options-collapse" data-toggle="card-collapse" onClick={() => this.handleStatistics(!statisticsOpen)}>
												<i className="fe fe-chevron-up" />
											</span>
											<span className="card-options-remove" data-toggle="card-remove" onClick={() => this.closeStatistics(false)}>
												<i className="fe fe-x" />
											</span>
										</div>
									</div>
									<div className="card-body">
										<div className="text-center">
											<div className="row">
												<div className="col-6 pb-3">
													<label className="mb-0">Balance</label>
													<h4 className="font-30 font-weight-bold">$545</h4>
												</div>
												<div className="col-6 pb-3">
													<label className="mb-0">Growth</label>
													<h4 className="font-30 font-weight-bold">27%</h4>
												</div>
											</div>
										</div>
										<div className="form-group">
											<label className="d-block">
												Total Income<span className="float-right">77%</span>
											</label>
											<div className="progress progress-xs">
												<div
													className="progress-bar bg-blue"
													role="progressbar"
													aria-valuenow={77}
													aria-valuemin={0}
													aria-valuemax={100}
													style={{ width: '77%' }}
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="d-block">
												Total Expenses <span className="float-right">50%</span>
											</label>
											<div className="progress progress-xs">
												<div
													className="progress-bar bg-danger"
													role="progressbar"
													aria-valuenow={50}
													aria-valuemin={0}
													aria-valuemax={100}
													style={{ width: '50%' }}
												/>
											</div>
										</div>
										<div className="form-group mb-0">
											<label className="d-block">
												Gross Profit <span className="float-right">23%</span>
											</label>
											<div className="progress progress-xs">
												<div
													className="progress-bar bg-green"
													role="progressbar"
													aria-valuenow={23}
													aria-valuemin={0}
													aria-valuemax={100}
													style={{ width: '23%' }}
												/>
											</div>
										</div>
									</div>
								</div> : ""}
							{friendListClose ?
								<div className={`card ${friendListOpen ? 'card-collapsed' : ""}`}>
									<div className="card-header">
										<h3 className="card-title">Friends</h3>
										<div className="card-options">
											<span className="card-options-collapse" data-toggle="card-collapse" onClick={() => this.handleFriendList(!friendListOpen)}>
												<i className="fe fe-chevron-up" />
											</span>
											<span className="card-options-remove" data-toggle="card-remove" onClick={() => this.closeFriendList(false)}>
												<i className="fe fe-x" />
											</span>
										</div>
									</div>
									<div className="card-body">
										<ul className="right_chat list-unstyled">
											<li className="online">
												<a href="#!">
													<div className="media">
														<img className="media-object " src="../assets/images/xs/avatar4.jpg" alt="fake_alr" />
														<div className="media-body">
															<span className="name">Donald Gardner</span>
															<span className="message">Designer, Blogger</span>
															<span className="badge badge-outline status" />
														</div>
													</div>
												</a>
											</li>
											<li className="online">
												<a href="#!">
													<div className="media">
														<img
															className="media-object "
															src="/assets/images/xs/avatar5.jpg"
															alt="fake_alr"
														/>
														<div className="media-body">
															<span className="name">Wendy Keen</span>
															<span className="message">Java Developer</span>
															<span className="badge badge-outline status" />
														</div>
													</div>
												</a>
											</li>
											<li className="offline">
												<a href="#!">
													<div className="media">
														<img
															className="media-object "
															src="/assets/images/xs/avatar2.jpg"
															alt="fake_alr"
														/>
														<div className="media-body">
															<span className="name">Matt Rosales</span>
															<span className="message">CEO, Epic Theme</span>
															<span className="badge badge-outline status" />
														</div>
													</div>
												</a>
											</li>
										</ul>
									</div>
								</div>
								: ""}
							<div className="card b-none">
								<ul className="list-group">
									<li className="list-group-item d-flex">
										<div className="box-icon sm rounded bg-blue">
											<i className="fa fa-credit-card" />{' '}
										</div>
										<div className="ml-3">
											<div>+$29 New sale</div>
											<a href="#!">Admin Template</a>
											<div className="text-muted font-12">5 min ago</div>
										</div>
									</li>
									<li className="list-group-item d-flex">
										<div className="box-icon sm rounded bg-pink">
											<i className="fa fa-upload" />{' '}
										</div>
										<div className="ml-3">
											<div>Project Update</div>
											<a href="#!">New HTML page</a>
											<div className="text-muted font-12">10 min ago</div>
										</div>
									</li>
									<li className="list-group-item d-flex">
										<div className="box-icon sm rounded bg-teal">
											<i className="fa fa-file-word-o" />{' '}
										</div>
										<div className="ml-3">
											<div>You edited the file</div>
											<a href="#!">reposrt.doc</a>
											<div className="text-muted font-12">11 min ago</div>
										</div>
									</li>
									<li className="list-group-item d-flex">
										<div className="box-icon sm rounded bg-cyan">
											<i className="fa fa-user" />{' '}
										</div>
										<div className="ml-3">
											<div>New user</div>
											<a href="#!">Puffin web - view</a>
											<div className="text-muted font-12">17 min ago</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div id="left-sidebar" className="sidebar ">
							<h5 className="brand-name">DebtCare</h5>
							<nav id="left-sidebar-nav" className="sidebar-nav">
								<MetisMenu className=""

//  ( x === 1 ) ? <Component2 /> :
//         ( x === 2 ) ? <Component3 /> :

									content={
										(tes === 'SystemAdministrator')? MenuSystemAdministrator :
										(tes === 'CompanyAdministrator')? MenuCompanyAdministrator :
										(tes === 'CompanyOwnerPartner')? MenuCompanyOwnerPartner :
										(tes === 'CountryOperationsHead')? MenuCountryOperationsHead :
										(tes === 'ZoneOperationsHead')? MenuZoneOperationsHead :
										(tes === 'StateOperationsHead')? MenuStateOperationsHead :
										(tes === 'RegionalOperHead')? MenuRegionalOperHead :
										(tes === 'DistrictHeadBranchHead')? MenuDistrictHeadBranchHead :
										(tes === 'ProcessHead')? MenuProcessHead :
										(tes === 'ProcessManager')? MenuProcessManager :
										(tes === 'ITHead')? MenuITHead :
										(tes === 'FinanceManager')? MenuFinanceManager :
										(tes === 'HRManager')? MenuHRManager :
										(tes === 'CountryAdministrator')? MenuCountryAdministrator :
										(tes === 'MISManager')? MenuMISManager :
										(tes === 'ITManager')? MenuITManager :
										(tes === 'StateAdministrator')? MenuStateAdministrator :
										(tes === 'MISExecutive')? MenuMISExecutive :
										(tes === 'AssertsManagerHWAdministrator')? MenuAssertsManagerHWAdministrator :
										(tes === 'TeamLeaderFieldExecutives')? MenuTeamLeaderFieldExecutives :
										(tes === 'FieldExecutive')? MenuFieldExecutive :
										(tes === 'TeamLeaderPhoneBankers')? MenuTeamLeaderPhoneBankers :
										(tes === 'PhoneBanker')? MenuPhoneBanker :
										(tes === 'TeamLeaderTracers')? MenuTeamLeaderTracers :
										(tes === 'Tracers')? MenuTracers :
										(tes === 'AccountsHead')? MenuAccountsHead :
										(tes === 'OfficeAdmin')? MenuOfficeAdmin :
										(tes === 'AccountsExecutive')? MenuAccountsExecutive :
										(tes === 'AccountsClerk')? MenuAccountsClerk :
										(tes === 'TrainingHead')? MenuTrainingHead :
										(tes === 'HRExecutive')? MenuHRExecutive :
										(tes === 'TraineeTeamLeader')? MenuTraineeTeamLeader :
										(tes === 'Trainer')? MenuTrainer :
										(tes === 'SuperAdministrator')? MenuSuperAdministrator : content}
									noBuiltInClassNames={true}
									classNameContainer={(e) => this.toggleSubMenu(e)}
									classNameContainerVisible="in"
									classNameItemActive="active"
									classNameLinkActive="active"
									// classNameItemHasActiveChild="active"
									classNameItemHasVisibleChild="active"
									classNameLink="has-arrow arrow-c"
									// classNameIcon
									// classNameStateIcon

									iconNamePrefix=""
									// iconNameStateHidden=""
									LinkComponent={(e) => <DefaultLink itemProps={e} />}
								// toggleSubMenu={this.toggleSubMenu}
								/>

							</nav>
						</div>
					</div>

					<div className="page">
						<Header dataFromParent={this.props.dataFromParent} dataFromSubParent={pageHeading[0].pageTitle} />
						<Switch>
							{Routes.map((layout, i) => {
								return <Route key={i} exact={layout.exact} path={layout.path} component={layout.component}></Route>
							})}
							{/* <Dashboard action={this.handler} dataFromParent={'dark'} /> */}
							{/* <Route exact path="/hr-users" component={Users}>
					</Route>
					<Route exact path="/hr-department">
						<Departments dataFromParent={'dark'} />
					</Route>
					<Route exact path="/hr-employee">
						<Employee dataFromParent={'dark'} />
					</Route>
					<Route exact path="/hr-events">
						<Events dataFromParent={'dark'} />
					</Route>
					<Route exact path="/hr-holidays">
						<Holidays dataFromParent={'dark'} />
					</Route>
					<Route exact path="/hr-activities">
						<Activities dataFromParent={'dark'} />
					</Route>
					<Route exact path="/hr-payroll">
						<Payroll dataFromParent={'dark'} />
					</Route>
					<Route exact path="/hr-accounts">
						<Accounts dataFromParent={'dark'} />
					</Route>
					<Route exact path="/hr-report">
						<Report dataFromParent={'dark'} />
					</Route>

					<Route exact path="/project-dashboard">
						<ProjectDashboard dataFromParent={'dark'} />
					</Route>
					<Route exact path="/project-list">
						<ProjectList dataFromParent={'dark'} />
					</Route>
					<Route exact path="/project-taskboard">
						<Taskboard dataFromParent={'dark'} />
					</Route>
					<Route exact path="/project-ticket">
						<TicketList dataFromParent={'dark'} />
					</Route>
					<Route exact path="/project-ticket-details">
						<TicketDetails dataFromParent={'dark'} />
					</Route>
					<Route exact path="/project-clients">
						<Clients dataFromParent={'dark'} />
					</Route>
					<Route exact path="/project-todo">
						<TodoList dataFromParent={'dark'} />
					</Route>

					<Route exact path="/jobportal-dashboard">
						<JobPortalDashboard dataFromParent={'dark'} />
					</Route>
					<Route exact path="/jobportal-applicants">
						<Applicants dataFromParent={'dark'} />
					</Route>
					<Route exact path="/jobportal-positions">
						<Positions dataFromParent={'dark'} />
					</Route>
					<Route exact path="/jobportal-resumes">
						<Resumes dataFromParent={'dark'} />
					</Route>
					<Route exact path="/jobportal-settings">
						<Settings dataFromParent={'dark'} />
					</Route>

					<Route path="/login" component={Login} />
					<Route path="/signup" component={SignUp} />
					<Route path="/forgotpassword" component={ForgotPassword} />
					<Route path="/notfound" component={NotFound} />
					<Route path="/internalserver" component={InternalServer} />

					<Route exact path="/icons">
						<Icons dataFromParent={''} />
					</Route>
					<Route exact path="/icons-feather">
						<IconsFeather dataFromParent={''} />
					</Route>
					<Route exact path="/icons-line">
						<IconsLine dataFromParent={''} />
					</Route>
					<Route exact path="/icons-flag">
						<IconsFlags dataFromParent={''} />
					</Route>
					<Route exact path="/icons-payments">
						<IconsPayments dataFromParent={''} />
					</Route>

					<Route exact path="/charts">
						<Charts dataFromParent={''} />
					</Route>
					<Route exact path="/charts-e">
						<ChartsE dataFromParent={''} />
					</Route>
					<Route exact path="/charts-c3">
						<ChartsC3 dataFromParent={''} />
					</Route>
					<Route exact path="/charts-knob">
						<ChartsKnob dataFromParent={''} />
					</Route>
					<Route exact path="/charts-sparkline">
						<ChartsSparkline dataFromParent={''} />
					</Route>

					<Route exact path="/forms">
						<Forms dataFromParent={''} />
					</Route>
					<Route exact path="/form-advanced">
						<FormAdvanced dataFromParent={''} />
					</Route>
					<Route exact path="/form-validation">
						<FormValidation dataFromParent={''} />
					</Route>
					<Route exact path="/form-wizard">
						<FormWizard dataFromParent={''} />
					</Route>
					<Route exact path="/form-summernote">
						<FormSummernote dataFromParent={''} />
					</Route>

					<Route exact path="/gallery">
						<Gallery dataFromParent={'dark'} />
					</Route>
					<Route exact path="/maps">
						<Maps dataFromParent={'dark'} />
					</Route> */}

							{/* <Route exact path="/tables">
						<Tables dataFromParent={''} />
					</Route>
					<Route exact path="/tables-datatable">
						<DataTables dataFromParent={''} />
					</Route>
					<Route exact path="/tables-color">
						<TablesColor dataFromParent={''} />
					</Route>
					<Route exact path="/tables-basic">
						<TablesBasic dataFromParent={''} />
					</Route>

					<Route exact path="/widgets">
						<Widgets dataFromParent={'dark'} />
					</Route>
					<Route exact path="/w-card">
						<WCard dataFromParent={'dark'} />
					</Route>
					<Route exact path="/w-statistics">
						<WStatistics dataFromParent={'dark'} />
					</Route>
					<Route exact path="/w-data">
						<WData dataFromParent={'dark'} />
					</Route>
					<Route exact path="/w-social">
						<WSocial dataFromParent={'dark'} />
					</Route>
					<Route exact path="/w-other">
						<WOther dataFromParent={'dark'} />
					</Route>

					<Route exact path="/page-search">
						<Search dataFromParent={''} />
					</Route>
					<Route exact path="/profile">
						<Profile dataFromParent={'dark'} />
					</Route>

					<Route exact path="/app-calendar">
						<AppCalender dataFromParent={'dark'} />
					</Route>
					<Route exact path="/app-contact">
						<AppContact dataFromParent={'dark'} />
					</Route>
					<Route exact path="/app-chat">
						<AppChart dataFromParent={'dark'} />
					</Route>
					<Route exact path="/app-filemanager">
						<AppFileManager dataFromParent={'dark'} />
					</Route>
					<Route exact path="/app-setting">
						<AppSetting dataFromParent={'dark'} />
					</Route> */}
						</Switch>
						<Footer />
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	darkMinSidebar: state.settings.isMinSidebar,
	statisticsOpen: state.settings.isStatistics,
	friendListOpen: state.settings.isFriendList,
	statisticsClose: state.settings.isStatisticsClose,
	friendListClose: state.settings.isFriendListClose,
	istoggleLeftMenu: state.settings.isToggleLeftMenu
})

const mapDispatchToProps = dispatch => ({
	darkModeAction: (e) => dispatch(darkModeAction(e)),
	darkHeaderAction: (e) => dispatch(darkHeaderAction(e)),
	fixNavbarAction: (e) => dispatch(fixNavbarAction(e)),
	darkMinSidebarAction: (e) => dispatch(darkMinSidebarAction(e)),
	darkSidebarAction: (e) => dispatch(darkSidebarAction(e)),
	iconColorAction: (e) => dispatch(iconColorAction(e)),
	gradientColorAction: (e) => dispatch(gradientColorAction(e)),
	rtlAction: (e) => dispatch(rtlAction(e)),
	fontAction: (e) => dispatch(fontAction(e)),
	subMenuIconAction: (e) => dispatch(subMenuIconAction(e)),
	menuIconAction: (e) => dispatch(menuIconAction(e)),
	boxLayoutAction: (e) => dispatch(boxLayoutAction(e)),
	statisticsAction: (e) => dispatch(statisticsAction(e)),
	friendListAction: (e) => dispatch(friendListAction(e)),
	statisticsCloseAction: (e) => dispatch(statisticsCloseAction(e)),
	friendListCloseAction: (e) => dispatch(friendListCloseAction(e)),
	toggleLeftMenuAction: (e) => dispatch(toggleLeftMenuAction(e))
})
export default connect(mapStateToProps, mapDispatchToProps)(Menu);

