import React from 'react'

function Activities() {
  return (
    <div>
      Activities
Activities
    </div>
  )
}

export default Activities
