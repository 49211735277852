import React from 'react'

function Categories() {
  return (
    <div>
      Categories
Categories
    </div>
  )
}

export default Categories
