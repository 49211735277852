import React from 'react'

function restore() {
  return (
    <div>
      restore
    </div>
  )
}

export default restore
