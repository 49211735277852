import React from 'react'

function Invoices() {
  return (
    <div>
      Invoices
Invoices
    </div>
  )
}

export default Invoices
