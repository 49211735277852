import React from 'react'

function Executives() {
  return (
    <div>
      Executives
Executives
    </div>
  )
}

export default Executives
