import React from 'react'

function Meeting_List() {
  return (
    <div>
      Meeting_List
Meeting_List
    </div>
  )
}

export default Meeting_List
