import React from 'react'

function Log() {
  return (
    <div>
      Log
Log
    </div>
  )
}

export default Log
