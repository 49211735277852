import React from 'react'

function Assigned() {
  return (
    <div>
      Assigned
Assigned
    </div>
  )
}

export default Assigned
