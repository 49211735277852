// import React from 'react'

// function Non_Contactable() {
//   return (
//     <div>
//       Non_Contactable
//     </div>
//   )
// }

// export default Non_Contactable


import React, { Component } from 'react';
import CountUp from 'react-countup';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import {
	statisticsAction,
	statisticsCloseAction
} from '../../../../../../actions/settingsAction';



class Non_Contactable extends Component {
  constructor(props) {
		super(props);
		this.handleStatistics = this.handleStatistics.bind(this);
		this.closeStatistics = this.closeStatistics.bind(this);
		this.sparkline1 = React.createRef();
		this.sparkline2 = React.createRef();
		this.sparkline3 = React.createRef();
		this.sparkline4 = React.createRef();
	}
	handleStatistics(e) {
		this.props.statisticsAction(e)
	}
	closeStatistics(e) {
		this.props.statisticsCloseAction(e)
	}

    render() {
        const { fixNavbar, statisticsOpen, statisticsClose  } = this.props;
        
        return (
            <>
                <div className={`section-body ${fixNavbar ? "marginTop" : ""}`}>
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center">
                            <ul className="nav nav-tabs page-header-tab">
								<li className="nav-item"><NavLink to="/sya-codes-dp" className="nav-link ">All DP Codes </NavLink></li>
                                <li className="nav-item"><NavLink to="/sya-contactable" className="nav-link">Contactable </NavLink></li>
                                <li className="nav-item"><NavLink to="/sya-non-contactable" className="nav-link active">Non-Contactable</NavLink></li>
                                <li className="nav-item"><NavLink to="/sya-code-matching-list" className="nav-link">DP Codes Matching</NavLink></li>
                                {/* <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="/#" role="button" aria-haspopup="true" aria-expanded="false">More</a>
                                    <div className="dropdown-menu">
                                        <NavLink to="/tables-color" className="dropdown-item">Table Color</NavLink>
                                        <NavLink to="/tables-basic" className="dropdown-item">Other</NavLink>
                                    </div>
                                </li> */}
                            </ul>
                            
                        </div>
                    </div>
                </div>
                <div className="section-body">
              
							<div className="container-fluid">
								<div className="tab-content">
									<div className="tab-pane fade show active" id="Employee-list" role="tabpanel">
										<div className="card">
											<div className="card-header">
												<h3 className="card-title">Contactable DP Codes List</h3>
												<div className="card-options">
													<form>
														<div className="input-group">
															<input
																type="text"
																className="form-control form-control-sm"
																placeholder="Search something..."
																name="s"
															/>
															<span className="input-group-btn ml-2">
																<button className="btn btn-icon btn-sm" type="submit">
																	<span className="fe fe-search" />
																</button>
															</span>
														</div>
													</form>
                          <div className="header-action">
                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal"><i className="fe fe-plus mr-2" />Add Code</button>
                            </div>
												</div>
                        
											</div>
											<div className="card-body">
												<div className="table-responsive">
													<table className="table table-hover table-striped table-vcenter text-nowrap mb-0">
														<thead>
															<tr>
																<th>#</th>
																<th>Code Name</th>
																<th>Short Code</th>
																{/* <th>Phone</th>
																<th>Join Date</th>
																<th>Role</th> */}
																<th>Action</th>
															</tr>
														</thead>
														<tbody>
                                                        <tr>
																<td className="w40">
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td className="d-flex">
																	{/* <span
																		className="avatar avatar-indigo"
																		data-toggle="tooltip"
																		data-original-title="Avatar Name"
																	>
																		MN
																	</span> */}
																	<div className="ml-3">
																		<h6 className="mb-0">RINGING NO RESPONSE</h6>
																		<span className="text-muted">
																		NON-CONTACTABLE DP
																		</span>
																	</div>
																</td>
																<td>
																	<span>RNR</span>
																</td>
																{/* <td>
																	<span>+ 264-625-2583</span>
																</td>
																<td>24 Feb, 2019</td>
																<td>Android Developer</td> */}
																<td>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="View"
																	>
																		<i className="fa fa-eye" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="Edit"
																	>
																		<i className="fa fa-edit" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm js-sweetalert"
																		title="Delete"
																		data-type="confirm"
																	>
																		<i className="fa fa-trash-o text-danger" />
																	</button>
																</td>
															</tr>
															<tr>
																<td className="w40">
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td className="d-flex">
																	{/* <img
																		className="avatar"
																		src="../assets/images/xs/avatar2.jpg"
																		data-toggle="tooltip"
																		data-original-title="Avatar Name"
																		alt="fake_url"
																	/> */}
																	<div className="ml-3">
																		<h6 className="mb-0">SWITCHED OFF</h6>
																		<span className="text-muted">
																		NON-CONTACTABLE DP
																		</span>
																	</div>
																</td>
																<td>
																	<span>SO</span>
																</td>
																{/* <td>
																	<span>+ 264-625-2589</span>
																</td>
																<td>28 Jun, 2015</td>
																<td>IOS Developer</td> */}
																<td>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="View"
																	>
																		<i className="fa fa-eye" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="Edit"
																	>
																		<i className="fa fa-edit" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm js-sweetalert"
																		title="Delete"
																		data-type="confirm"
																	>
																		<i className="fa fa-trash-o text-danger" />
																	</button>
																</td>
															</tr>
															<tr>
																<td className="w40">
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td className="d-flex">
																	{/* <img
																		className="avatar"
																		src="../assets/images/xs/avatar2.jpg"
																		data-toggle="tooltip"
																		data-original-title="Avatar Name"
																		alt="fake_url"
																	/> */}
																	<div className="ml-3">
																		<h6 className="mb-0">WRONG NUMBER</h6>
																		<span className="text-muted">
																		NON-CONTACTABLE DP 
																		</span>
																	</div>
																</td>
																<td>
																	<span>WN</span>
																</td>
																{/* <td>
																	<span>+ 264-625-2356</span>
																</td>
																<td>28 Jun, 2015</td>
																<td>Team Leader</td> */}
																<td>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="View"
																	>
																		<i className="fa fa-eye" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="Edit"
																	>
																		<i className="fa fa-edit" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm js-sweetalert"
																		title="Delete"
																		data-type="confirm"
																	>
																		<i className="fa fa-trash-o text-danger" />
																	</button>
																</td>
															</tr>
															<tr>
																<td className="w40">
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td className="d-flex">
																	{/* <img
																		className="avatar"
																		src="../assets/images/xs/avatar2.jpg"
																		data-toggle="tooltip"
																		data-original-title="Avatar Name"
																		alt="fake_url"
																	/> */}
																	<div className="ml-3">
																		<h6 className="mb-0">CALL DISCONNECT</h6>
																		<span className="text-muted">
																		NON-CONTACTABLE DP  
																		</span>
																	</div>
																</td>
																<td>
																	<span>CD</span>
																</td>
																{/* <td>
																	<span>+ 264-625-2589</span>
																</td>
																<td>28 Jun, 2015</td>
																<td>IOS Developer</td> */}
																<td>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="View"
																	>
																		<i className="fa fa-eye" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="Edit"
																	>
																		<i className="fa fa-edit" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm js-sweetalert"
																		title="Delete"
																		data-type="confirm"
																	>
																		<i className="fa fa-trash-o text-danger" />
																	</button>
																</td>
															</tr>
															<tr>
																<td className="w40">
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td className="d-flex">
																	{/* <img
																		className="avatar"
																		src="../assets/images/xs/avatar2.jpg"
																		data-toggle="tooltip"
																		data-original-title="Avatar Name"
																		alt="fake_url"
																	/> */}
																	<div className="ml-3">
																		<h6 className="mb-0">NO CONTACT NUMBER</h6>
																		<span className="text-muted">
																		NON-CONTACTABLE DP  
																		</span>
																	</div>
																</td>
																<td>
																	<span>NCN</span>
																</td>
																{/* <td>
																	<span>+ 264-625-2356</span>
																</td>
																<td>28 Jun, 2015</td>
																<td>Team Leader</td> */}
																<td>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="View"
																	>
																		<i className="fa fa-eye" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="Edit"
																	>
																		<i className="fa fa-edit" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm js-sweetalert"
																		title="Delete"
																		data-type="confirm"
																	>
																		<i className="fa fa-trash-o text-danger" />
																	</button>
																</td>
															</tr>
															<tr>
																<td className="w40">
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td className="d-flex">
																	{/* <img
																		className="avatar"
																		src="../assets/images/xs/avatar2.jpg"
																		data-toggle="tooltip"
																		data-original-title="Avatar Name"
																		alt="fake_url"
																	/> */}
																	<div className="ml-3">
																		<h6 className="mb-0">BROKEN PTP</h6>
																		<span className="text-muted">
																		NON-CONTACTABLE DP 
																		</span>
																	</div>
																</td>
																<td>
																	<span>BPTP</span>
																</td>
																{/* <td>
																	<span>+ 264-625-2589</span>
																</td>
																<td>28 Jun, 2015</td>
																<td>IOS Developer</td> */}
																<td>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="View"
																	>
																		<i className="fa fa-eye" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="Edit"
																	>
																		<i className="fa fa-edit" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm js-sweetalert"
																		title="Delete"
																		data-type="confirm"
																	>
																		<i className="fa fa-trash-o text-danger" />
																	</button>
																</td>
															</tr>
															<tr>
																<td className="w40">
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td className="d-flex">
																	{/* <img
																		className="avatar"
																		src="../assets/images/xs/avatar2.jpg"
																		data-toggle="tooltip"
																		data-original-title="Avatar Name"
																		alt="fake_url"
																	/> */}
																	<div className="ml-3">
																		<h6 className="mb-0">BROKEN SETTELEMENT</h6>
																		<span className="text-muted">
																		NON-CONTACTABLE DP 
																		</span>
																	</div>
																</td>
																<td>
																	<span>BS</span>
																</td>
																{/* <td>
																	<span>+ 264-625-2356</span>
																</td>
																<td>28 Jun, 2015</td>
																<td>Team Leader</td> */}
																<td>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="View"
																	>
																		<i className="fa fa-eye" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="Edit"
																	>
																		<i className="fa fa-edit" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm js-sweetalert"
																		title="Delete"
																		data-type="confirm"
																	>
																		<i className="fa fa-trash-o text-danger" />
																	</button>
																</td>
															</tr>
															<tr>
																<td className="w40">
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td className="d-flex">
																	{/* <img
																		className="avatar"
																		src="../assets/images/xs/avatar2.jpg"
																		data-toggle="tooltip"
																		data-original-title="Avatar Name"
																		alt="fake_url"
																	/> */}
																	<div className="ml-3">
																		<h6 className="mb-0">REFUSED TO PAY</h6>
																		<span className="text-muted">
																		NON-CONTACTABLE DP 
																		</span>
																	</div>
																</td>
																<td>
																	<span>RTP</span>
																</td>
																{/* <td>
																	<span>+ 264-625-2589</span>
																</td>
																<td>28 Jun, 2015</td>
																<td>IOS Developer</td> */}
																<td>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="View"
																	>
																		<i className="fa fa-eye" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="Edit"
																	>
																		<i className="fa fa-edit" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm js-sweetalert"
																		title="Delete"
																		data-type="confirm"
																	>
																		<i className="fa fa-trash-o text-danger" />
																	</button>
																</td>
															</tr>
															<tr>
																<td className="w40">
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td className="d-flex">
																	{/* <img
																		className="avatar"
																		src="../assets/images/xs/avatar2.jpg"
																		data-toggle="tooltip"
																		data-original-title="Avatar Name"
																		alt="fake_url"
																	/> */}
																	<div className="ml-3">
																		<h6 className="mb-0">ADDRESS SKIP</h6>
																		<span className="text-muted">
																		NON-CONTACTABLE DP 
																		</span>
																	</div>
																</td>
																<td>
																	<span>AS</span>
																</td>
																{/* <td>
																	<span>+ 264-625-2356</span>
																</td>
																<td>28 Jun, 2015</td>
																<td>Team Leader</td> */}
																<td>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="View"
																	>
																		<i className="fa fa-eye" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="Edit"
																	>
																		<i className="fa fa-edit" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm js-sweetalert"
																		title="Delete"
																		data-type="confirm"
																	>
																		<i className="fa fa-trash-o text-danger" />
																	</button>
																</td>
															</tr>
															<tr>
																<td className="w40">
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td className="d-flex">
																	{/* <img
																		className="avatar"
																		src="../assets/images/xs/avatar2.jpg"
																		data-toggle="tooltip"
																		data-original-title="Avatar Name"
																		alt="fake_url"
																	/> */}
																	<div className="ml-3">
																		<h6 className="mb-0">ADDRESS NOT FOUND</h6>
																		<span className="text-muted">
																		NON-CONTACTABLE DP 
																		</span>
																	</div>
																</td>
																<td>
																	<span>ANF</span>
																</td>
																{/* <td>
																	<span>+ 264-625-2589</span>
																</td>
																<td>28 Jun, 2015</td>
																<td>IOS Developer</td> */}
																<td>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="View"
																	>
																		<i className="fa fa-eye" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="Edit"
																	>
																		<i className="fa fa-edit" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm js-sweetalert"
																		title="Delete"
																		data-type="confirm"
																	>
																		<i className="fa fa-trash-o text-danger" />
																	</button>
																</td>
															</tr>
															<tr>
																<td className="w40">
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td className="d-flex">
																	{/* <img
																		className="avatar"
																		src="../assets/images/xs/avatar2.jpg"
																		data-toggle="tooltip"
																		data-original-title="Avatar Name"
																		alt="fake_url"
																	/> */}
																	<div className="ml-3">
																		<h6 className="mb-0">WRONG ADDRESS</h6>
																		<span className="text-muted">
																		NON-CONTACTABLE DP
																		</span>
																	</div>
																</td>
																<td>
																	<span>WA</span>
																</td>
																{/* <td>
																	<span>+ 264-625-2356</span>
																</td>
																<td>28 Jun, 2015</td>
																<td>Team Leader</td> */}
																<td>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="View"
																	>
																		<i className="fa fa-eye" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="Edit"
																	>
																		<i className="fa fa-edit" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm js-sweetalert"
																		title="Delete"
																		data-type="confirm"
																	>
																		<i className="fa fa-trash-o text-danger" />
																	</button>
																</td>
															</tr>
															
														</tbody>
													</table>
												</div>
												<ul className="pagination mt-2">
													<li className="page-item">
													<a className="page-link" href="fake_url;">
														Previous
													</a>
													</li>
													<li className="page-item active">
													<a className="page-link" href="fake_url;">
														1
													</a>
													</li>
													<li className="page-item">
													<a className="page-link" href="fake_url;">
														2
													</a>
													</li>
													<li className="page-item">
													<a className="page-link" href="fake_url;">
														3
													</a>
													</li>
													<li className="page-item">
													<a className="page-link" href="fake_url;">
														Next
													</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div className="tab-pane fade" id="Employee-view" role="tabpanel">
										<div className="row">
											<div className="col-lg-4 col-md-12">
												<div className="card">
													<div className="card-body">
														<div className="media mb-4">
															<img
																className="avatar avatar-xl mr-3"
																src="../assets/images/sm/avatar1.jpg"
																alt="avatar"
															/>
															<div className="media-body">
																<h5 className="m-0">Sara Hopkins</h5>
																<p className="text-muted mb-0">Webdeveloper</p>
																<ul className="social-links list-inline mb-0 mt-2">
																	<li className="list-inline-item">
																		<a
																			href="fake_url"
																			data-toggle="tooltip"
																			data-original-title="Facebook"
																		>
																			<i className="fa fa-facebook" />
																		</a>
																	</li>
																	<li className="list-inline-item">
																		<a
																			href="fake_url"
																			data-toggle="tooltip"
																			data-original-title="Twitter"
																		>
																			<i className="fa fa-twitter" />
																		</a>
																	</li>
																	<li className="list-inline-item">
																		<a
																			href="fake_url"
																			data-toggle="tooltip"
																			data-original-title={1234567890}
																		>
																			<i className="fa fa-phone" />
																		</a>
																	</li>
																	<li className="list-inline-item">
																		<a
																			href="fake_url"
																			data-toggle="tooltip"
																			data-original-title="@skypename"
																		>
																			<i className="fa fa-skype" />
																		</a>
																	</li>
																</ul>
															</div>
														</div>
														<p className="mb-4">
															Contrary to popular belief, Lorem Ipsum is not simply random
															text. It has roots in a piece of classical Latin literature
															from 45 BC, making it over 2000 years old.
														</p>
														<button className="btn btn-outline-primary btn-sm">
															<span className="fa fa-twitter" /> Follow
														</button>
													</div>
												</div>
												{statisticsClose ?
													<div className={`card ${statisticsOpen ? 'card-collapsed' : ""}`}>
														<div className="card-header">
															<h3 className="card-title">Statistics</h3>
															<div className="card-options">
																<span
																	className="card-options-collapse"
																	data-toggle="card-collapse" onClick={() => this.handleStatistics(!statisticsOpen)}
																>
																	<i className="fe fe-chevron-up" alt="fake_url" />
																</span>
																<span
																	className="card-options-remove"
																	data-toggle="card-remove" onClick={() => this.closeStatistics(false)}
																>
																	<i className="fe fe-x" />
																</span>
															</div>
														</div>
														<div className="card-body">
															<div className="text-center">
																<div className="row">
																	<div className="col-6 pb-3">
																		<label className="mb-0">Project</label>
																		<h4 className="font-30 font-weight-bold">45</h4>
																	</div>
																	<div className="col-6 pb-3">
																		<label className="mb-0">Growth</label>
																		<h4 className="font-30 font-weight-bold">87%</h4>
																	</div>
																</div>
															</div>
															<div className="form-group">
																<label className="d-block">
																	Laravel<span className="float-right">77%</span>
																</label>
																<div className="progress progress-xs">
																	<div
																		className="progress-bar bg-blue"
																		role="progressbar"
																		aria-valuenow={77}
																		aria-valuemin={0}
																		aria-valuemax={100}
																		style={{ width: '77%' }}
																	/>
																</div>
															</div>
															<div className="form-group">
																<label className="d-block">
																	HTML<span className="float-right">50%</span>
																</label>
																<div className="progress progress-xs">
																	<div
																		className="progress-bar bg-danger"
																		role="progressbar"
																		aria-valuenow={50}
																		aria-valuemin={0}
																		aria-valuemax={100}
																		style={{ width: '50%' }}
																	/>
																</div>
															</div>
															<div className="form-group mb-0">
																<label className="d-block">
																	Photoshop <span className="float-right">23%</span>
																</label>
																<div className="progress progress-xs">
																	<div
																		className="progress-bar bg-green"
																		role="progressbar"
																		aria-valuenow={23}
																		aria-valuemin={0}
																		aria-valuemax={100}
																		style={{ width: '23%' }}
																	/>
																</div>
															</div>
														</div>
													</div>
													: ""}
											</div>
											<div className="col-lg-8 col-md-12">
												<div className="card">
													<div className="card-body">
														<ul className="new_timeline mt-3">
															<li>
																<div className="bullet pink" />
																<div className="time">11:00am</div>
																<div className="desc">
																	<h3>Attendance</h3>
																	<h4>Computer Class</h4>
																</div>
															</li>
															<li>
																<div className="bullet pink" />
																<div className="time">11:30am</div>
																<div className="desc">
																	<h3>Added an interest</h3>
																	<h4>“Volunteer Activities”</h4>
																	<p>
																		Contrary to popular belief, Lorem Ipsum is not
																		simply random text. It has roots in a piece of
																		classical Latin literature from 45 BC, making it
																		over 2000 years old.
																	</p>
																</div>
															</li>
															<li>
																<div className="bullet green" />
																<div className="time">12:00pm</div>
																<div className="desc">
																	<h3>Developer Team</h3>
																	<h4>Hangouts</h4>
																	<ul className="list-unstyled team-info margin-0 p-t-5">
																		<li>
																			<img
																				src="../assets/images/xs/avatar1.jpg"
																				alt="Avatar"
																			/>
																		</li>
																		<li>
																			<img
																				src="../assets/images/xs/avatar2.jpg"
																				alt="Avatar"
																			/>
																		</li>
																		<li>
																			<img
																				src="../assets/images/xs/avatar3.jpg"
																				alt="Avatar"
																			/>
																		</li>
																		<li>
																			<img
																				src="../assets/images/xs/avatar4.jpg"
																				alt="Avatar"
																			/>
																		</li>
																	</ul>
																</div>
															</li>
															<li>
																<div className="bullet green" />
																<div className="time">2:00pm</div>
																<div className="desc">
																	<h3>Responded to need</h3>
																	<a href="fake_url">“In-Kind Opportunity”</a>
																</div>
															</li>
															<li>
																<div className="bullet orange" />
																<div className="time">1:30pm</div>
																<div className="desc">
																	<h3>Lunch Break</h3>
																</div>
															</li>
															<li>
																<div className="bullet green" />
																<div className="time">2:38pm</div>
																<div className="desc">
																	<h3>Finish</h3>
																	<h4>Go to Home</h4>
																</div>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="tab-pane fade" id="Employee-Request" role="tabpanel">
										<div className="card">
											<div className="card-body">
												<div className="table-responsive">
													<table className="table table-hover table-striped table-vcenter text-nowrap mb-0">
														<thead>
															<tr>
																<th>#</th>
																<th>Name</th>
																<th>Employee ID</th>
																<th>Leave Type</th>
																<th>Date</th>
																<th>Reason</th>
																<th>Action</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td className="width45">
																	<span
																		className="avatar avatar-orange"
																		data-toggle="tooltip"
																		title="Avatar Name"
																	>
																		DB
																	</span>
																</td>
																<td>
																	<div className="font-15">Marshall Nichols</div>
																</td>
																<td>
																	<span>LA-8150</span>
																</td>
																<td>
																	<span>Casual Leave</span>
																</td>
																<td>24 July, 2019 to 26 July, 2019</td>
																<td>Going to Family Function</td>
																<td>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="Approved"
																	>
																		<i className="fa fa-check text-success" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm js-sweetalert"
																		title="Delete"
																		data-type="confirm"
																	>
																		<i className="fa fa-trash-o text-danger" />
																	</button>
																</td>
															</tr>
															<tr>
																<td className="width45">
																	<span
																		className="avatar avatar-pink"
																		data-toggle="tooltip"
																		title="Avatar Name"
																	>
																		GC
																	</span>
																</td>
																<td>
																	<div className="font-15">Gary Camara</div>
																</td>
																<td>
																	<span>LA-8795</span>
																</td>
																<td>
																	<span>Medical Leave</span>
																</td>
																<td>20 July, 2019 to 26 July, 2019</td>
																<td>Going to Development</td>
																<td>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="Approved"
																	>
																		<i className="fa fa-check text-success" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm js-sweetalert"
																		title="Delete"
																		data-type="confirm"
																	>
																		<i className="fa fa-trash-o text-danger" />
																	</button>
																</td>
															</tr>
															<tr>
																<td className="width45">
																	<img
																		className="avatar"
																		src="../assets/images/xs/avatar1.jpg"
																		data-toggle="tooltip"
																		title="Avatar Name"
																		alt="fake_url"
																	/>
																</td>
																<td>
																	<div className="font-15">Maryam Amiri</div>
																</td>
																<td>
																	<span>LA-0258</span>
																</td>
																<td>
																	<span>Casual Leave</span>
																</td>
																<td>21 July, 2019 to 26 July, 2019</td>
																<td>Attend Birthday party</td>
																<td>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="Approved"
																	>
																		<i className="fa fa-check text-success" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm js-sweetalert"
																		title="Delete"
																		data-type="confirm"
																	>
																		<i className="fa fa-trash-o text-danger" />
																	</button>
																</td>
															</tr>
															<tr>
																<td className="width45">
																	<img
																		className="avatar"
																		src="../assets/images/xs/avatar2.jpg"
																		data-toggle="tooltip"
																		title="Avatar Name"
																		alt="fake_url"
																	/>
																</td>
																<td>
																	<div className="font-15">Frank Camly</div>
																</td>
																<td>
																	<span>LA-1515</span>
																</td>
																<td>
																	<span>Casual Leave</span>
																</td>
																<td>11 Aug, 2019 to 21 Aug, 2019</td>
																<td>Going to Holiday 1</td>
																<td>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm"
																		title="Approved"
																	>
																		<i className="fa fa-check text-success" />
																	</button>
																	<button
																		type="button"
																		className="btn btn-icon btn-sm js-sweetalert"
																		title="Delete"
																		data-type="confirm"
																	>
																		<i className="fa fa-trash-o text-danger" />
																	</button>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
                        
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(Non_Contactable);