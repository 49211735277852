import React from 'react'

function Manual_Attendance() {
  return (
    <div>
      Manual_Attendance
Manual_Attendance
    </div>
  )
}

export default Manual_Attendance
