import React from 'react'

function Approve_Resignations() {
  return (
    <div>
      Approve_Resignations
Approve_Resignations
    </div>
  )
}

export default Approve_Resignations
