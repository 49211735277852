import React from 'react'

function Estimates() {
  return (
    <div>
      Estimates
Estimates
    </div>
  )
}

export default Estimates
