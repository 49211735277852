import React from 'react'

function Resolutions() {
  return (
    <div>
      Resolutions
Resolutions
    </div>
  )
}

export default Resolutions
