import React from 'react'

function Trainings() {
  return (
    <div>
      Trainings
Trainings
    </div>
  )
}

export default Trainings
