import React from 'react'

function Expenses() {
  return (
    <div>
      Expenses
Expenses
    </div>
  )
}

export default Expenses
