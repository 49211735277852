import React from 'react'

function Interviewers_List() {
  return (
    <div>
      Interviewers_List
Interviewers_List
    </div>
  )
}

export default Interviewers_List
