import React from 'react'

function Leave_Requests() {
  return (
    <div>
      Leave_Requests
Leave_Requests
    </div>
  )
}

export default Leave_Requests
