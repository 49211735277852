import React from 'react'

function Process() {
  return (
    <div>
      Process
Process
    </div>
  )
}

export default Process
