import React from 'react'

function Create_new_Policy() {
  return (
    <div>
      Create_new_Policy
Create_new_Policy
    </div>
  )
}

export default Create_new_Policy
