import React from 'react'

function New_Purchase() {
  return (
    <div>
      New_Purchase
New_Purchase
    </div>
  )
}

export default New_Purchase
