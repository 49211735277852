import React from 'react'

function Organisation_Chart() {
  return (
    <div>
      Organisation_Chart
Organisation_Chart
    </div>
  )
}

export default Organisation_Chart
