import React from 'react'

function Identifying() {
  return (
    <div>
      Identifying
Identifying
    </div>
  )
}

export default Identifying
