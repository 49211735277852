import React from 'react'

function Attendance() {
  return (
    <div>
Attendance
Attendance
    </div>
  )
}

export default Attendance
