import React from 'react'

function Finances() {
  return (
    <div>
      Finances
Finances
    </div>
  )
}

export default Finances
