import React from 'react'

function New_Brand() {
  return (
    <div>
      New_Brand
New_Brand
    </div>
  )
}

export default New_Brand
