import React from 'react'

function official() {
  return (
    <div>
      official
official
    </div>
  )
}

export default official
