import React from 'react'

function Company_Details() {
  return (
    <div>
      Company_Details
    </div>
  )
}

export default Company_Details
