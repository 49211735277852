import React from 'react'

function Purchase_List() {
  return (
    <div>
      Purchase_List
Purchase_List
    </div>
  )
}

export default Purchase_List
