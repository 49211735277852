import React from 'react'

function Process_based() {
  return (
    <div>
      Process_based
Process_based
    </div>
  )
}

export default Process_based
