import React from 'react'

function Sales_Order() {
  return (
    <div>
      Sales_Order
Sales_Order
    </div>
  )
}

export default Sales_Order
