import React, { Component } from 'react';

import Menu from './Menu';
//import Menu_AccountsClerk from "../../layouts/AccountsClerk/Menu";


export default class Layout extends Component {

	// initialise th local state 
	// push the data to local state 
	
	
	render() {
		const userLocal = JSON.parse(localStorage.getItem('user'));	
		// alert("before" + user.id);
		
		
		
		if (localStorage.getItem('user') ===null) { 
			//alert("inside");
			//Redirect ("/login");
			
			this.props.history.push("/login");
			
		}else {
			
				// TODO: get the roles and route to the Individual role 
				// TODO: if the roles are of mobile then list the error.
			// alert(userLocal.accessToken);
			// alert(userLocal.username);
			// alert(userLocal.roles[0]);
			// alert(userLocal.email);
			//TODO:  get the access token

			// if (userLocal.roles[0] ==="FIELDEXECUTIVE"){
			//  	//this.props.history.push("/signup");
				 
			// 	 <Menu_AccountsClerk {...this.props} />
			// }
		}
		return (

			<div id="main_content">	
					
				<Menu {...this.props} />
			</div>
		);
	}
}
