import React from 'react'

function Advance_Salary() {
  return (
    <div>
      Advance_Salary
Advance_Salary
    </div>
  )
}

export default Advance_Salary
