import React from 'react'

function Settings() {
  return (
    <div>
      Su A Settings
    </div>
  )
}

export default Settings
