import React from 'react'

function Billing_Invoices() {
  return (
    <div>
      Billing_Invoices
Billing_Invoices
    </div>
  )
}

export default Billing_Invoices
