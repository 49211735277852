import React from 'react'

function new_supplier() {
  return (
    <div>
      new_supplier
new_supplier
    </div>
  )
}

export default new_supplier
