import React from 'react'

function Ware_Houses() {
  return (
    <div>
      Ware_Houses
Ware_Houses
    </div>
  )
}

export default Ware_Houses
