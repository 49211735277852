import React from 'react'

function Asserts() {
  return (
    <div>
      Asserts
Asserts
    </div>
  )
}

export default Asserts
