import React from 'react'

function Resignation_List() {
  return (
    <div>
      Resignation_List
Resignation_List
    </div>
  )
}

export default Resignation_List
