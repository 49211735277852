import React from 'react'

function Tracing() {
  return (
    <div>
      Tracing
Tracing
    </div>
  )
}

export default Tracing
