import React from 'react'

function New_category() {
  return (
    <div>
      New_category
New_category
    </div>
  )
}

export default New_category
