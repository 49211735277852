import React from 'react'

function Language() {
  return (
    <div>
      Language
    </div>
  )
}

export default Language
