import React from 'react'

function Monthly() {
  return (
    <div>
      Monthly
Monthly
    </div>
  )
}

export default Monthly
