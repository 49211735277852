import React from 'react'

function Suppliers() {
  return (
    <div>
      Suppliers
Suppliers
    </div>
  )
}

export default Suppliers
