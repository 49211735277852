import React from 'react'

function Exit_Types() {
  return (
    <div>
      Exit_Types
Exit_Types
    </div>
  )
}

export default Exit_Types
