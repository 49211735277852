import React from 'react'

function Events() {
  return (
    <div>
      Events
Events
    </div>
  )
}

export default Events
