import React from 'react'

function Yearly() {
  return (
    <div>
      Yearly
Yearly
    </div>
  )
}

export default Yearly
