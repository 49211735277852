import React from 'react'

function Product_Category() {
  return (
    <div>
      Product_Category
Product_Category
    </div>
  )
}

export default Product_Category
