import React from 'react'

function Resources() {
  return (
    <div>
      Resources
    </div>
  )
}

export default Resources
