import React from 'react'

function Pay_Slip_History() {
  return (
    <div>
      Pay_Slip_History
Pay_Slip_History
    </div>
  )
}

export default Pay_Slip_History
