import React from 'react'

function Delivered_Orders() {
  return (
    <div>
      Delivered_Orders
Delivered_Orders
    </div>
  )
}

export default Delivered_Orders
