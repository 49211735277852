import React from 'react'

function Commission_types() {
  return (
    <div>
      Commission_types
Commission_types
    </div>
  )
}

export default Commission_types
