import React from 'react'

function Inventory_Control() {
  return (
    <div>
      Inventory_Control
Inventory_Control
    </div>
  )
}

export default Inventory_Control
