import React from 'react'

function Client() {
  return (
    <div>
      Client
Client
    </div>
  )
}

export default Client
