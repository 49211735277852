import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class SignUp extends Component {
	render() {
		return (
			<div className="auth">
				<div>
				<div className="form-group">
								
								<input type="text" className="form-control" placeholder="Enter EmployeeID" />
							</div>
							<div className="form-group">
								
								<input type="email" className="form-control" placeholder="Enter email" />
							</div>
							<div className="form-group">
								
								<input type="password" className="form-control" placeholder="Password" />
							</div>
							<div className="form-group">
							
							<label className="form-label">Role</label>
                                                <select className="form-control custom-select">
												<option value>SystemAdministrator</option>
												<option value>CompanyAdministrator</option>
												<option value>CompanyOwnerPartner</option>
												<option value>CountryOperationsHead</option>
												<option value>ZoneOperHead</option>
												<option value>StateOperHead</option>
												<option value>RegionalOperHead</option>
												<option value>DistrictHeadBranchHead</option>
												<option value>ProcessHead</option>
												<option value>ProcessManager</option>
												<option value>ITHead</option>
												<option value>FinanceManager</option>
												<option value>HRManager</option>
												<option value>CountryAdministrator</option>
												<option value>MISManager</option>
												<option value>ITManager</option>
												<option value>StateAdministrator</option>
												<option value>MISExecutive</option>
												<option value>AssertsManagerHWAdministrator</option>
												<option value>TeamLeaderFieldExecutives</option>
												<option value>FieldExecutive</option>
												<option value>TeamLeaderPhoneBankers</option>
												<option value>PhoneBanker</option>
												<option value>TeamLeaderTracers</option>
												<option value>Tracers</option>
												<option value>AccountsHead</option>
												<option value>AccountsExecutive</option>
												<option value>AccountsClerk</option>
												<option value>TrainingHead</option>
												<option value>HRExecutive</option>
												<option value>TraineeTeamLeader</option>
												<option value>Trainer</option>


                                                </select>
							</div>
							<div className="form-footer">
								<Link className="btn btn-primary btn-block" to="/login">
									Create
								</Link>
							</div>
				</div>
				<div>

				</div>
			</div>
		);
	}
}
