import React from 'react'

function Organizational() {
  return (
    <div>
      Organizational
Organizational
    </div>
  )
}

export default Organizational
