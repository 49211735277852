import React from 'react'

function Credit_Types() {
  return (
    <div>
      Credit_Types
Credit_Types
    </div>
  )
}

export default Credit_Types
