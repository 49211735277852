import React from 'react'

function Payments_and_Commissions() {
  return (
    <div>
      Payments_and_Commissions
Payments_and_Commissions
    </div>
  )
}

export default Payments_and_Commissions
