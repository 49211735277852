import React from 'react'

function Deposits() {
  return (
    <div>
      Deposits
Deposits
    </div>
  )
}

export default Deposits
