import React from 'react'

function New_Transfers() {
  return (
    <div>
      New_Transfers
New_Transfers
    </div>
  )
}

export default New_Transfers
