import React from 'react'

function Restore_company_data() {
  return (
    <div>
      Restore_company_data
    </div>
  )
}

export default Restore_company_data
