import React from 'react'

function Create_Resignations() {
  return (
    <div>
      Create_Resignations
Create_Resignations
    </div>
  )
}

export default Create_Resignations
