import React from 'react'

function Assets_management() {
  return (
    <div>
      Assets_management
Assets_management
    </div>
  )
}

export default Assets_management
