import React from 'react'

function Manage_Leaves() {
  return (
    <div>
      Manage_Leaves
Manage_Leaves
    </div>
  )
}

export default Manage_Leaves
