import React from 'react'

function Invoice_Payments() {
  return (
    <div>
      Invoice_Payments
Invoice_Payments
    </div>
  )
}

export default Invoice_Payments
