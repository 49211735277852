import React from 'react'

function Results() {
  return (
    <div>
      Results
Results
    </div>
  )
}

export default Results
