import React from 'react'

function Goal_Types() {
  return (
    <div>
      Goal_Types
Goal_Types
    </div>
  )
}

export default Goal_Types
