import React from 'react'

function general() {
  return (
    <div>
      general
general
    </div>
  )
}

export default general
