import React from 'react'

function Assets_List() {
  return (
    <div>
      Assets_List
Assets_List
    </div>
  )
}

export default Assets_List
