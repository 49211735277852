import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class ForgotPassword extends Component {
	render() {
		return (
			<div className="auth">
				<div className="auth_left">
					<div className="card">
						<div className="text-center mb-5">
							<Link className="header-brand" to="/">
								{/* <i className="fe fe-command brand-logo" /> */}
								<img src= {"assets/images/slogo.png"} height="50" width ="50" alt="Company Logo" />
							</Link>
						</div>
						<div className="card-body">
							<div className="card-title">Forgot password</div>
							<p className="text-muted">
								Enter your email address and your password will be reset and emailed to you.
							</p>
							<div className="form-group">
								<label className="form-label" htmlFor="exampleInputEmail1">
									Email address
								</label>
								<input
									type="email"
									className="form-control"
									id="exampleInputEmail1"
									aria-describedby="emailHelp"
									placeholder="Enter email"
								/>
							</div>
							<div className="form-footer">
								<Link className="btn btn-primary btn-block" to="/login">
									Send me new password
								</Link>
							</div>
						</div>
						<div className="text-center text-muted">
							Forget it, <Link to="/login">Send me Back</Link> to the Sign in screen.
						</div>
					</div>
				</div>
				<div className="auth_right">
					<div className="carousel slide" data-ride="carousel" data-interval={3000}>
						<div className="carousel-inner">
							<div className="carousel-item active">
								<img src="assets/images/slide2.jpg" className="img-fluid" alt="login page" />
								<div className="px-4 mt-4">
									<h4>Derbe</h4>
									<p>A FinTech Platform , Gateway to Crisis Management .</p>
								</div>
							</div>
							<div className="carousel-item">
								<img src="assets/images/slide3.jpg" className="img-fluid" alt="login page" />
								<div className="px-4 mt-4">
									<h4>Employee to Entrepreneur</h4>
									<p>Transforms an Employee into an Entrepreneur Financial World.</p>
								</div>
							</div>
							<div className="carousel-item">
								<img src="assets/images/slide1.jpg" className="img-fluid" alt="login page" />
								<div className="px-4 mt-4">
									<h4>Start-up recognized by the DPIIT,ISO 9001:2015 certified</h4>
									<p>
									Ministry of Commerce & Industry and Ministry of Micro, Small
									and Medium Enterprises.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
