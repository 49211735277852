import React from 'react'

function Maps() {
  return (
    <div>
      Maps
Maps
    </div>
  )
}

export default Maps
