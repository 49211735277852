import React from 'react'

function Training_Sessions() {
  return (
    <div>
      Training_Sessions
Training_Sessions
    </div>
  )
}

export default Training_Sessions
