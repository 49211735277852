import React from 'react'

function Assign_Tickets_Solve() {
  return (
    <div>
      Assign_Tickets_Solve
Assign_Tickets_Solve
    </div>
  )
}

export default Assign_Tickets_Solve
