import React from 'react'

function Designation_list() {
  return (
    <div>
      Designation_list
Designation_list
    </div>
  )
}

export default Designation_list
