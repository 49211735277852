import React from 'react'

function Manage_Trainings() {
  return (
    <div>
      Manage_Trainings
Manage_Trainings
    </div>
  )
}

export default Manage_Trainings
