import React from 'react'

function New_Order_Invoice() {
  return (
    <div>
      New_Order_Invoice
New_Order_Invoice
    </div>
  )
}

export default New_Order_Invoice
