import React from 'react'

function New_Process() {
  return (
    <div>
New_Process
New_Process
    </div>
  )
}

export default New_Process
