import React from 'react'

function Awards() {
  return (
    <div>
      Awards
Awards
    </div>
  )
}

export default Awards
