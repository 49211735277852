import React from 'react'

function Uploading_files() {
  return (
    <div>
      Uploading_files
Uploading_files
    </div>
  )
}

export default Uploading_files
