import React from 'react'

function Create_assert() {
  return (
    <div>
      Create_assert
Create_assert
    </div>
  )
}

export default Create_assert
