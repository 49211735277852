import React from 'react'

function New_visitor() {
  return (
    <div>
      New_visitor
New_visitor
    </div>
  )
}

export default New_visitor
