import React from 'react'

function New_postings() {
  return (
    <div>
      New_postings
New_postings
    </div>
  )
}

export default New_postings
