import React from 'react'

function Interviews() {
  return (
    <div>
      Interviews
Interviews
    </div>
  )
}

export default Interviews
