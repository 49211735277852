import React from 'react'

function Employees_List() {
  return (
    <div>
      Employees_List
Employees_List
    </div>
  )
}

export default Employees_List
