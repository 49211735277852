import React from 'react'

function Approve_Transfers() {
  return (
    <div>
      Approve_Transfers
Approve_Transfers
    </div>
  )
}

export default Approve_Transfers
