
import React from 'react'

function Basic() {
  return (
    <div>
      Basic
    </div>
  )
}

export default Basic
