import React from 'react'

function suppliers_list() {
  return (
    <div>
      suppliers_list
suppliers_list
    </div>
  )
}

export default suppliers_list
