import React from 'react'

function Exit_Clearance_Certificates() {
  return (
    <div>
      Exit_Clearance_Certificates
Exit_Clearance_Certificates
    </div>
  )
}

export default Exit_Clearance_Certificates
