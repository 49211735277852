import React from 'react'

function award_assign_list() {
  return (
    <div>
      award_assign_list
award_assign_list
    </div>
  )
}

export default award_assign_list
