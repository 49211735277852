import React from 'react'

function Policies() {
  return (
    <div>
      Policies
Policies
    </div>
  )
}

export default Policies
