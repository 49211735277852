import React from 'react'

function Monthly_Report() {
  return (
    <div>
      Monthly_Report
Monthly_Report
    </div>
  )
}

export default Monthly_Report
