import React from 'react'

function Lodge_Complaint() {
  return (
    <div>
      Lodge_Complaint
Lodge_Complaint
    </div>
  )
}

export default Lodge_Complaint
