import React from 'react'

function awards() {
  return (
    <div>
      awards
awards
    </div>
  )
}

export default awards
