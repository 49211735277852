import React from 'react'

function KPA_Appraisals() {
  return (
    <div>
      KPA_Appraisals
KPA_Appraisals
    </div>
  )
}

export default KPA_Appraisals
