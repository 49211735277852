import React from 'react'

function Sales_Order_List() {
  return (
    <div>
      Sales_Order_List
Sales_Order_List
    </div>
  )
}

export default Sales_Order_List
