import React from 'react'

function OKRs_Tracking_Goals() {
  return (
    <div>
      OKRs_Tracking_Goals
OKRs_Tracking_Goals
    </div>
  )
}

export default OKRs_Tracking_Goals
