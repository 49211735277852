import React from 'react'

function Complacencies() {
  return (
    <div>
      Complacencies
Complacencies
    </div>
  )
}

export default Complacencies
