import React from 'react'

function Candidate_List() {
  return (
    <div>
      Candidate_List
Candidate_List
    </div>
  )
}

export default Candidate_List
