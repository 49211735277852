import React from 'react'

function KPI_indicators() {
  return (
    <div>
      KPI_indicators
KPI_indicators
    </div>
  )
}

export default KPI_indicators
