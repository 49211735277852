import React from 'react'

function Leaves_Type() {
  return (
    <div>
      Leaves_Type
Leaves_Type
    </div>
  )
}

export default Leaves_Type
