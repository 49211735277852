import React from 'react'

function Policy_list() {
  return (
    <div>
      Policy_list
Policy_list
    </div>
  )
}

export default Policy_list
