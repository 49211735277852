import React from 'react'

function Loan_Types() {
  return (
    <div>
      Loan_Types
Loan_Types
    </div>
  )
}

export default Loan_Types
