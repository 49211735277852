import React from 'react'

function Process_Management() {
  return (
    <div>
      Process_Management
Process_Management
    </div>
  )
}

export default Process_Management
