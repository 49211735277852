import React from 'react'

function Completed_Process() {
  return (
    <div>
      Completed_Process
Completed_Process
    </div>
  )
}

export default Completed_Process
