import React from 'react'

function Managing_Files() {
  return (
    <div>
      Managing_Files
Managing_Files
    </div>
  )
}

export default Managing_Files
