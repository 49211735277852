import React from 'react'

function Documents_Manager() {
  return (
    <div>
      Documents_Manager
Documents_Manager
    </div>
  )
}

export default Documents_Manager
