import React from 'react'

function award_Type() {
  return (
    <div>
      award_Type
award_Type
    </div>
  )
}

export default award_Type
