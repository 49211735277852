import React from 'react'

function Total_Payments() {
  return (
    <div>
      Total_Payments
Total_Payments
    </div>
  )
}

export default Total_Payments
