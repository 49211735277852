import React from 'react'

function Create_Chart() {
  return (
    <div>
      Create_Chart
Create_Chart
    </div>
  )
}

export default Create_Chart
