import React from 'react'

function Taxes() {
  return (
    <div>
      Taxes
    </div>
  )
}

export default Taxes
