import axios from "axios";
import { Redirect } from "react-router-dom";

//const API_URL = "http://192.168.43.105:8080/api/auth/";
const API_URL = "http://192.168.0.131:8080/api/auth/";

class AuthService {
  login(username, password,history) {
    return axios
      .post(API_URL + "signin", { username, password})
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
         // this.props.history.push('/layout');
         // history.push('/layout');
          //Redirect('/layout');
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
    });
  }
}

export default new AuthService();