import React from 'react'

function Estimate_Type() {
  return (
    <div>
      Estimate_Type
Estimate_Type
    </div>
  )
}

export default Estimate_Type
