import React from 'react'

function Cancelled_Orders() {
  return (
    <div>
      Cancelled_Orders
Cancelled_Orders
    </div>
  )
}

export default Cancelled_Orders
