import React from 'react'

function Manage_Orders() {
  return (
    <div>
      Manage_Orders
Manage_Orders
    </div>
  )
}

export default Manage_Orders
