import React, { Component, useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Connect } from "react-redux";
import {
  Link,
  Redirect,
  withRouter,
  useHistory,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import settings from "../../reducers/settings";
//import { useNavigate } from "react-router-dom";

import ukflag_r from "../../assets/images/ukflag_r.png";
import usflag_r from "../../assets/images/usflag_r.png";
import indflag_r from "../../assets/images/IndMapFlag.png";
import annonaqclogo from "../../assets/images/annonaqclogo.png";

import slide1 from "../../assets/images/slide1.jpg";
import slide2 from "../../assets/images/slide2.jpg";
import slide3 from "../../assets/images/slide3.jpg";
import slide4 from "../../assets/images/slide4.jpg";

import ns_logo from "../../assets/images/ns_logo.png";
import kl_logo from "../../assets/images/kl_logo.png";
import dc_logo from "../../assets/images/dc_logo.png";
import slogo from "../../assets/images/slogo.png";
const initializeState = () => JSON.parse(window.localStorage.getItem("user"));
// class Login extends Component {
const Login = (props) => {
  const location = useLocation();
  const history = useHistory();
  //render() {
  const [country, setCountry] = useState("United States America");
  const [subDomain, setSubDomain] = useState("");
  const [flag, setFlag] = useState("");
  const [logo, setLogo] = useState("");
  const [empId, setEmpId] = useState("");
  const [pass, setPass] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const user = useSelector((state) => state.auth.user);

  //user? useSelector((state) => state.auth.user):useState(initializeState());
  //const [user] = useState(initializeState());
  //const navigate = useNavigate();

  const { dispatch } = useDispatch();

  const countryOnChange = (e) => {
    const country = e.target.value;
    setCountry(country);   
    
  };

  const subDomainOnChange = (e) => {
    const subDomain = e.target.value;
    setSubDomain(subDomain);
   
  };
  const onChangeempId = (e) => {
    const empId = e.target.value;
    setEmpId(empId);
  };

  const onChangePassword = (e) => {
    const pass = e.target.value;
    setPass(pass);
  };

  useEffect(() => {
    // need to get the domain details and the client ip and location details
    //alert(window.location.host);
    if (window.location.host === "localhost:3000") {
      // on select test
      if (country === "United States America") {
        setFlag(usflag_r);
        setLogo(annonaqclogo);
        //alert(window.location.host);
      } else if (country === "India") {
        setFlag(indflag_r);
        setLogo(annonaqclogo);
        // alert("countr");
      } else if (country === "United Kingdom") {
        setFlag(ukflag_r);
        setLogo(annonaqclogo);
        // alert("countr");
      } else {
        setFlag(slogo);
        setLogo(annonaqclogo);
      }

      if (subDomain === "DebtCare") {
        setLogo(dc_logo);
      } else if (subDomain === "Numel") {
        setLogo(ns_logo);
      } else if (subDomain === "KL") {
        setLogo(kl_logo);
      } else {
        setLogo(annonaqclogo);
      }
    } else {
      //alert(window.location.host);
      //alert(window.location.hostname.split(".")[1]);
      if (window.location.host.split(".")[0] === "annonaqc") {
        if (window.location.hostname.split(".")[1] === "com") {
          setFlag(indflag_r);
        } else if (window.location.hostname.split(".")[1] === "us") {
          setFlag(usflag_r);
        } else if (window.location.hostname.split(".")[1] === "uk") {
          setFlag(ukflag_r);
        }else{
          setFlag(indflag_r);       
        }
         setLogo(annonaqclogo);
      } else {
        if (window.location.hostname.split(".")[2] === "com") {
          setFlag(indflag_r);
        } else if (window.location.hostname.split(".")[2] === "us") {
          setFlag(usflag_r);
        } else if (window.location.hostname.split(".")[2] === "uk") {
          setFlag(ukflag_r);
        }else {
          setFlag(indflag_r);
        }

        if (window.location.hostname.split(".")[0] === "hunsah") {
          setLogo(dc_logo);        }
       
        if (window.location.hostname.split(".")[0] === "kl") {
          setLogo(kl_logo);
        }
        if (window.location.hostname.split(".")[0] === "admin") {
          setFlag(slogo);
          setLogo(annonaqclogo);
        }
        //alert("dont have subdoamins");
      }
    }

    if (localStorage.getItem("user")) {
      //<Redirect to="/" />
      props.history.push("/");
    }
    // Update the document title using the browser API
    // document.title = `You clicked ${count} times`;
  });

  const handleRegister = (e) => {
    e.preventDefault();
    // checking the login from API steps

    alert("login" + empId + " , " + pass);
    //setIsLoading = true;
    props.login(empId, pass, props.history);
    //alert(window.location.host);
  };

  return (
    <div className="auth">
      <div className="auth_left">
        <div className="card">
          <div className="text-center mb-2">
            <Link className="header-brand" to="/">
              {/* <i className="fe fe-command brand-logo" /> */}
              <img src={flag} height="50" width="50" alt="" />
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className="card-body">
            <form onSubmit={handleRegister}>
              <div className="card-title">Login to your account</div>
              
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="empId"
                  aria-describedby="emailHelp"
                  placeholder="Enter Code"
                  value={empId}
                  onChange={onChangeempId}
                  required={true}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  id="pass"
                  placeholder="Password"
                  value={pass}
                  onChange={onChangePassword}
                  required={true}
                />
                <label className="form-label">
                  <Link className="float-right small" to="/forgotpassword">
                    I forgot password
                  </Link>
                </label>
              </div>
              <div className="form-group">
                <label className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" />
                  <span className="custom-control-label">Remember me</span>
                </label>
              </div>
              <div>
                {
                  // user.roles.map(role =>role)
                }{" "}
              </div>
              <div className="form-footer">
                <button className="btn btn-primary btn-block" type="submit">
                  Click to login
                </button>
              </div>
              <div className="text-center mb-2">
                <label className="form-label center">
                  <Link className="float-middle small" to="/">
                    <span className="center">Version 1.03417</span>
                  </Link>
                </label>
              </div>

              

             
            </form>
          </div>

          {/* <div className="text-center text-muted">
							Don't have account yet? <Link to="/signup">Sign Up</Link>
						</div> */}
        </div>
      </div>
      <div className="auth_right">
        <div
          className="carousel slide"
          data-ride="carousel"
          data-interval={3000}
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={slide1} className="img-fluid" alt="login page" />
              <div className="px-4 mt-4">
                <h4>AnnonaQC</h4>
                <p>A FinTech Platform , Sevices of Finantial Management .</p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={slide2} className="img-fluid" alt="login page" />
              <div className="px-4 mt-4">
                <h4>BAAS - Banking As A Service</h4>
                <p>
                  {" "}
                  
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={slide3} className="img-fluid" alt="login page" />
              <div className="px-4 mt-4">
                <h4>
                  WAAS - Wealth As A Service{" "}
                </h4>
                <p>
                  
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  //}
};

function mapStateToProps(state) {
  console.log("mapStateToProps", state);
  return {
    userdata: state.user,
    // page: state.page
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: (empId, pass) => dispatch(login(empId, pass)),
    // pageActions: bindActionCreators(pageActions, dispatch),
    // getUser: bindActionCreators(getUser, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));

