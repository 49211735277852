import React from 'react'

function Resolved_List() {
  return (
    <div>
      Resolved_List
Resolved_List
    </div>
  )
}

export default Resolved_List
