import React from 'react'

function Trainers() {
  return (
    <div>
      Trainers
Trainers
    </div>
  )
}

export default Trainers
