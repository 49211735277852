import React from 'react'

function Manage_Brand() {
  return (
    <div>
      Manage_Brand
Manage_Brand
    </div>
  )
}

export default Manage_Brand
