import React from 'react'

function Transport_Type() {
  return (
    <div>
      Transport_Type
Transport_Type
    </div>
  )
}

export default Transport_Type
