import React from 'react'

function Reassigned() {
  return (
    <div>
      Reassigned
Reassigned
    </div>
  )
}

export default Reassigned
