import React from 'react'

function Deposits_List() {
  return (
    <div>
      Deposits_List
Deposits_List
    </div>
  )
}

export default Deposits_List
