import React from 'react'

function Loan() {
  return (
    <div>
      Loan
Loan
    </div>
  )
}

export default Loan
