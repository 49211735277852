// Import all components here
// import React, { Component } from 'react'
import Dashboard from "./HRMS/Dashboard/Dashboard";
import Users from "./HRMS/Users/Users";
import Departments from "./HRMS/Departments/Departments";
import Employee from "./HRMS/Employee/Employee";
import Holidays from "./HRMS/Holidays/Holidays";
import Events from "./HRMS/Events/Events";
import Activities from "./HRMS/Activities/Activities";
import Payroll from "./HRMS/Payroll/Payroll";
import Report from "./HRMS/Report/Report";
import Accounts from "./HRMS/Accounts/Accounts";
import ProjectDashboard from "./Project/Dashboard/Dashboard";
import ProjectList from "./Project/ProjectList/Projectlist";
import Taskboard from "./Project/Taskboard/Taskboard";
import TicketList from "./Project/TicketList/TicketList";
import TicketDetails from "./Project/TicketDetails/TicketDetails";
import Clients from "./Project/Clients/Clients";
import TodoList from "./Project/TodoList/TodoList";
import JobPortalDashboard from "./JobPortal/Dashboard/Dashboard";
import Applicants from "./JobPortal/Applicants/Applicants";
import Positions from "./JobPortal/Positions/Positions";
import Resumes from "./JobPortal/Resumes/Resumes";
import Settings from "./JobPortal/Settings/Settings";
import Icons from "./Icons/icons";
import IconsFeather from "./Icons/IconsFeather";
import IconsLine from "./Icons/IconsLine";
import IconsFlags from "./Icons/IconsFlags";
import IconsPayments from "./Icons/IconsPayments";
import Charts from "./Charts/Charts";
import ChartsE from "./Charts/ChartsE";
import ChartsC3 from "./Charts/ChartsC3";
import ChartsKnob from "./Charts/ChartsKnob";
import ChartsSparkline from "./Charts/ChartsSparkline";
import Forms from "./Forms/Forms";
import FormAdvanced from "./Forms/FormAdvanced";
import FormValidation from "./Forms/FormValidation";
import FormWizard from "./Forms/FormWizard";
import FormSummernote from "./Forms/FormSummernote";
import Tables from "./Tables/Tables";
import DataTables from "./Tables/DataTables";
import TablesColor from "./Tables/TablesColor";
import TablesBasic from "./Tables/TablesBasic";
import Widgets from "./Widgets/Widgets";
import WCard from "./Widgets/WCard";
import WStatistics from "./Widgets/WStatistics";
import WData from "./Widgets/WData";
import WSocial from "./Widgets/WSocial";
import WOther from "./Widgets/WOther";
import Search from "./Search/Search";
import Profile from "./Profile/Profile";
import AppCalendar from "./AppPages/AppCalendar";
import AppContact from "./AppPages/AppContact";
import AppChart from "./AppPages/AppChart";
import AppFilemanager from "./AppPages/AppFilemanager";
import AppSetting from "./AppPages/AppSetting";
import Maps from "./Maps/Maps";
import Gallery from "./Gallery/Gallery";
import Login from "./Authentication/login";
import Signup from "./Authentication/signup";
import ForgotPassword from "./Authentication/forgotpassword";
import NotFound from "./Authentication/404";
import InternalServer from "./Authentication/500";

// derbe Routes

//DashboardsList

// HR Manager
import HR_Manager_Dashboard from "./derbePages/Human_Resource_Dept/HR_Manager/Dashboard/Dashboard";
// HR Executive
import HR_Executive_Dashboard from "./derbePages/Human_Resource_Dept/HR_Executive/Dashboard/Dashboard";
// Training Head
import Training_Head_Dashboard from "./derbePages/Human_Resource_Dept/Training_Head/Dashboard/Dashboard";
// Trainee Team Leader
import Trainee_Team_Leader_Dashboard from "./derbePages/Human_Resource_Dept/Trainee_Team_Leader/Dashboard/Dashboard";
// Trainer
import Trainer_Dashboard from "./derbePages/Human_Resource_Dept/Trainer/Dashboard/Dashboard";
// Office Admin
import Office_Admin_Dashboard from "./derbePages/Human_Resource_Dept/Office_Admin/Dashboard/Dashboard";
// Finance Manager
import Finance_Manager_Dashboard from "./derbePages/Finance_Dept/Finance_Manager/Dashboard/Dashboard";
// Accounts Head
import Accounts_Head_Dashboard from "./derbePages/Finance_Dept/Accounts_Head/Dashboard/Dashboard";
// Accounts Executive
import Accounts_Executive_Dashboard from "./derbePages/Finance_Dept/Accounts_Executive/Dashboard/Dashboard";
// Accounts Clerk
import Accounts_Clerk_Dashboard from "./derbePages/Finance_Dept/Accounts_Clerk/Dashboard/Dashboard";
// IT Head
import IT_Head_Dashboard from "./derbePages/Assert_Management_IT_Dept/IT_Head/Dashboard/Dashboard";
// IT Manager
import IT_Manager_Dashboard from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Dashboard/Dashboard";
// Asserts Manager HW Administrator
import Asserts_Manager_HW_Administrator_Dashboard from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Dashboard/Dashboard";
// Administrator
import Administrator_Dashboard from "./derbePages/Assert_Management_IT_Dept/Administrator/Dashboard/Dashboard";
// MIS Manager
import MIS_Manager_Dashboard from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Dashboard/Dashboard";
// MIS Executive
import MIS_Executive_Dashboard from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Dashboard/Dashboard";
// Process Head
import Process_Head_Dashboard from "./derbePages/Process_Management_Dept/Process_Head/Dashboard/Dashboard";
// Process Manager
import Process_Manager_Dashboard from "./derbePages/Process_Management_Dept/Process_Manager/Dashboard/Dashboard";
// Team Leader Field Executives
import Team_Leader_Field_Executives_Dashboard from "./derbePages/Process_Management_Dept/Team_Leader_Field_Executives/Dashboard/Dashboard";
// Team Leader Phone Bankers
import Team_Leader_Phone_Bankers_Dashboard from "./derbePages/Process_Management_Dept/Team_Leader_Phone_Bankers/Dashboard/Dashboard";
// Team Leader Tracers
import Team_Leader_Tracers_Dashboard from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Dashboard/Dashboard";
// Phone Banker
import Phone_Banker_Dashboard from "./derbePages/Process_Management_Dept/Phone_Banker/Dashboard/Dashboard";
// Field Executive
import Field_Executive_Dashboard from "./derbePages/Process_Management_Dept/Field_Executive/Dashboard/Dashboard";
// Tracers
import Tracers_Dashboard from "./derbePages/Process_Management_Dept/Tracers/Dashboard/Dashboard";
// Country Operations Head
import Country_Operations_Head_Dashboard from "./derbePages/Business_Administration/Country_Operations_Head/Dashboard/Dashboard";
// Zone Operations Head
import Zone_Operations_Head_Dashboard from "./derbePages/Business_Administration/Zone_Operations_Head/Dashboard/Dashboard";
// State Operations Head
import State_Operations_Head_Dashboard from "./derbePages/Business_Administration/State_Operations_Head/Dashboard/Dashboard";
// Regional Oper Head
import Regional_Oper_Head_Dashboard from "./derbePages/Business_Administration/Regional_Oper_Head/Dashboard/Dashboard";
// District Head Branch Head
import District_Head_Branch_Head_Dashboard from "./derbePages/Business_Administration/District_Head_Branch_Head/Dashboard/Dashboard";
// Company Owner Partner
import Company_Owner_Partner_Dashboard from "./derbePages/Business_Administration/Company_Owner_Partner/Dashboard/Dashboard";
// Country Administrator
import Country_Administrator_Dashboard from "./derbePages/System/Country_Administrator/Dashboard/Dashboard";
// State Administrator
import State_Administrator_Dashboard from "./derbePages/System/State_Administrator/Dashboard/Dashboard";
// Company Administrator
import Company_Administrator_Dashboard from "./derbePages/System/Company_Administrator/Dashboard/Dashboard";
// System Administrator
import System_Administrator_Dashboard from "./derbePages/System/System_Administrator/Dashboard/Dashboard";
// Super Administrator
import Super_Administrator_Dashboard from "./derbePages/System/Super_Administrator/Dashboard/Dashboard";

//  Routes Grouping

// HR Manager - HRM

import HRM_Employees from "./derbePages/Human_Resource_Dept/HR_Manager/Employees/Employees";
import HRM_Employees_List from "./derbePages/Human_Resource_Dept/HR_Manager/Employees/Employees_List/Employees_List";
import HRM_Roles_Privileges from "./derbePages/Human_Resource_Dept/HR_Manager/Employees/Roles_Privileges/Roles_Privileges";
import HRM_Shift_Scheduling from "./derbePages/Human_Resource_Dept/HR_Manager/Employees/Shift_Scheduling/Shift_Scheduling";
import HRM_Department from "./derbePages/Human_Resource_Dept/HR_Manager/Department/Department";
import HRM_Department_list from "./derbePages/Human_Resource_Dept/HR_Manager/Department/Department_list/Department_list";
import HRM_Designation_list from "./derbePages/Human_Resource_Dept/HR_Manager/Department/Designation_list/Designation_list";
import HRM_Resignations from "./derbePages/Human_Resource_Dept/HR_Manager/Resignations/Resignations";
import HRM_Create_Resignations from "./derbePages/Human_Resource_Dept/HR_Manager/Resignations/Create_Resignations/Create_Resignations";
import HRM_Approve_Resignations from "./derbePages/Human_Resource_Dept/HR_Manager/Resignations/Approve_Resignations/Approve_Resignations";
import HRM_Resignation_List from "./derbePages/Human_Resource_Dept/HR_Manager/Resignations/Resignation_List/Resignation_List";
import HRM_Employees_Exit from "./derbePages/Human_Resource_Dept/HR_Manager/Resignations/Employees_Exit/Employees_Exit";
import HRM_Exit_Types from "./derbePages/Human_Resource_Dept/HR_Manager/Resignations/Exit_Types/Exit_Types";
import HRM_Exit_Clearance_Certificates from "./derbePages/Human_Resource_Dept/HR_Manager/Resignations/Exit_Clearance_Certificates/Exit_Clearance_Certificates";
import HRM_Transfers from "./derbePages/Human_Resource_Dept/HR_Manager/Transfers/Transfers";
import HRM_Transfer_List from "./derbePages/Human_Resource_Dept/HR_Manager/Transfers/Transfer_List/Transfer_List";
import HRM_Approve_Transfers from "./derbePages/Human_Resource_Dept/HR_Manager/Transfers/Approve_Transfers/Approve_Transfers";
import HRM_New_Transfers from "./derbePages/Human_Resource_Dept/HR_Manager/Transfers/New_Transfers/New_Transfers";
import HRM_Complaints from "./derbePages/Human_Resource_Dept/HR_Manager/Complaints/Complaints";
import HRM_Lodge_Complaint from "./derbePages/Human_Resource_Dept/HR_Manager/Complaints/Lodge_Complaint/Lodge_Complaint";
import HRM_Complaint_List from "./derbePages/Human_Resource_Dept/HR_Manager/Complaints/Complaint_List/Complaint_List";
import HRM_Resolved_List from "./derbePages/Human_Resource_Dept/HR_Manager/Complaints/Resolved_List/Resolved_List";
import HRM_Awards from "./derbePages/Human_Resource_Dept/HR_Manager/Awards/Awards";
import HRM_awards from "./derbePages/Human_Resource_Dept/HR_Manager/Awards/awards/awards";
import HRM_award_assign_list from "./derbePages/Human_Resource_Dept/HR_Manager/Awards/award_assign_list/award_assign_list";
import HRM_award_Type from "./derbePages/Human_Resource_Dept/HR_Manager/Awards/award_Type/award_Type";
import HRM_Activities from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/Activities";
import HRM_Travels from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/Travels/Travels";
import HRM_New_Business_Travel from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/New_Business_Travel/New_Business_Travel";
import HRM_Travel_Arrangement_Type from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/Travel_Arrangement_Type/Travel_Arrangement_Type";
import HRM_Transport_Type from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/Transport_Type/Transport_Type";
import HRM_Payments from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/Payments/Payments";
import HRM_Travel_Management from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/Travel_Management/Travel_Management";
import HRM_Events from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/Events/Events";
import HRM_New_Event from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/New_Event/New_Event";
import HRM_Event_Types from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/Event_Types/Event_Types";
import HRM_Holidays from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/Holidays/Holidays";
import HRM_New_Holiday from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/New_Holiday/New_Holiday";
import HRM_Holiday_list from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/Holiday_list/Holiday_list";
import HRM_Visitors from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/Visitors/Visitors";
import HRM_New_visitor from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/New_visitor/New_visitor";
import HRM_Visitors_List from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/Visitors_List/Visitors_List";
import HRM_Conferences from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/Conferences/Conferences";
import HRM_Conf_booking from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/Conf_booking/Conf_booking";
import HRM_Meeting_List from "./derbePages/Human_Resource_Dept/HR_Manager/Activities/Meeting_List/Meeting_List";
import HRM_Documents_Manager from "./derbePages/Human_Resource_Dept/HR_Manager/Documents_Manager/Documents_Manager";
import HRM_general from "./derbePages/Human_Resource_Dept/HR_Manager/Documents_Manager/general/general";
import HRM_Uploading_files from "./derbePages/Human_Resource_Dept/HR_Manager/Documents_Manager/general/Uploading_files";
import HRM_Managing_Files from "./derbePages/Human_Resource_Dept/HR_Manager/Documents_Manager/general/Managing_Files";
import HRM_official from "./derbePages/Human_Resource_Dept/HR_Manager/Documents_Manager/official/official";
import HRM_OUploading_files from "./derbePages/Human_Resource_Dept/HR_Manager/Documents_Manager/official/Uploading_files";
import HRM_OManaging_Files from "./derbePages/Human_Resource_Dept/HR_Manager/Documents_Manager/official/Managing_Files";
import HRM_Process_Documents from "./derbePages/Human_Resource_Dept/HR_Manager/Documents_Manager/Process_Documents/Process_Documents";
import HRM_PUploading_files from "./derbePages/Human_Resource_Dept/HR_Manager/Documents_Manager/Process_Documents/Uploading_files";
import HRM_PManaging_Files from "./derbePages/Human_Resource_Dept/HR_Manager/Documents_Manager/Process_Documents/Managing_Files";
import HRM_Performance_PMS from "./derbePages/Human_Resource_Dept/HR_Manager/Performance_PMS/Performance_PMS";
import HRM_KPI_indicators from "./derbePages/Human_Resource_Dept/HR_Manager/Performance_PMS/KPI_indicators/KPI_indicators";
import HRM_KPA_Appraisals from "./derbePages/Human_Resource_Dept/HR_Manager/Performance_PMS/KPA_Appraisals/KPA_Appraisals";
import HRM_OKRs_Tracking_Goals from "./derbePages/Human_Resource_Dept/HR_Manager/Performance_PMS/OKRs_Tracking_Goals/OKRs_Tracking_Goals";
import HRM_Competencies from "./derbePages/Human_Resource_Dept/HR_Manager/Performance_PMS/Competencies/Competencies";
import HRM_Technical from "./derbePages/Human_Resource_Dept/HR_Manager/Performance_PMS/Technical/Technical";
import HRM_Organizational from "./derbePages/Human_Resource_Dept/HR_Manager/Performance_PMS/Organizational/Organizational";
import HRM_Goal_Types from "./derbePages/Human_Resource_Dept/HR_Manager/Performance_PMS/Goal_Types/Goal_Types";
import HRM_Goal_Calendar from "./derbePages/Human_Resource_Dept/HR_Manager/Performance_PMS/Goal_Calendar/Goal_Calendar";
import HRM_Recruitment_ATS from "./derbePages/Human_Resource_Dept/HR_Manager/Recruitment_ATS/Recruitment_ATS";
import HRM_New_postings from "./derbePages/Human_Resource_Dept/HR_Manager/Recruitment_ATS/New_postings/New_postings";
import HRM_Candidate_List from "./derbePages/Human_Resource_Dept/HR_Manager/Recruitment_ATS/Candidate_List/Candidate_List";
import HRM_Interviews from "./derbePages/Human_Resource_Dept/HR_Manager/Recruitment_ATS/Interviews/Interviews";
import HRM_Interviewers_List from "./derbePages/Human_Resource_Dept/HR_Manager/Recruitment_ATS/Interviewers_List/Interviewers_List";
import HRM_Attendance from "./derbePages/Human_Resource_Dept/HR_Manager/Attendance/Attendance";
import HRM_Manual_Attendance from "./derbePages/Human_Resource_Dept/HR_Manager/Attendance/Manual_Attendance/Manual_Attendance";
import HRM_Monthly_Report from "./derbePages/Human_Resource_Dept/HR_Manager/Attendance/Monthly_Report/Monthly_Report";
import HRM_Overtime_Request from "./derbePages/Human_Resource_Dept/HR_Manager/Attendance/Overtime_Request/Overtime_Request";
import HRM_Policies from "./derbePages/Human_Resource_Dept/HR_Manager/Policies/Policies";
import HRM_Create_new_Policy from "./derbePages/Human_Resource_Dept/HR_Manager/Policies/Create_new_Policy/Create_new_Policy";
import HRM_Policy_list from "./derbePages/Human_Resource_Dept/HR_Manager/Policies/Policy_list/Policy_list";
import HRM_Make_Announcement from "./derbePages/Human_Resource_Dept/HR_Manager/Make_Announcement/Make_Announcement";
import HRM_New_Announcement from "./derbePages/Human_Resource_Dept/HR_Manager/Make_Announcement/New_Announcement/New_Announcement";
import HRM_Announcement_List from "./derbePages/Human_Resource_Dept/HR_Manager/Make_Announcement/Announcement_List/Announcement_List";
import HRM_Organisation_Chart from "./derbePages/Human_Resource_Dept/HR_Manager/Organisation_Chart/Organisation_Chart";
import HRM_View from "./derbePages/Human_Resource_Dept/HR_Manager/Organisation_Chart/View/View";
import HRM_Create_Chart from "./derbePages/Human_Resource_Dept/HR_Manager/Organisation_Chart/Create_Chart/Create_Chart";
import HRM_Leave_Requests from "./derbePages/Human_Resource_Dept/HR_Manager/Leave_Requests/Leave_Requests";
import HRM_Manage_Leaves from "./derbePages/Human_Resource_Dept/HR_Manager/Leave_Requests/Manage_Leaves/Manage_Leaves";
import HRM_Leaves_Type from "./derbePages/Human_Resource_Dept/HR_Manager/Leave_Requests/Leaves_Type/Leaves_Type";
import HRM_Settings from "./derbePages/Human_Resource_Dept/HR_Manager/Settings/Settings";

// HR Executive - HRE

import HRE_Employees from "./derbePages/Human_Resource_Dept/HR_Executive/Employees/Employees";
import HRE_Employees_List from "./derbePages/Human_Resource_Dept/HR_Executive/Employees/Employees_List/Employees_List";
import HRE_Roles_Privileges from "./derbePages/Human_Resource_Dept/HR_Executive/Employees/Roles_Privileges/Roles_Privileges";
import HRE_Shift_Scheduling from "./derbePages/Human_Resource_Dept/HR_Executive/Employees/Shift_Scheduling/Shift_Scheduling";
import HRE_Department from "./derbePages/Human_Resource_Dept/HR_Executive/Department/Department";
import HRE_Department_list from "./derbePages/Human_Resource_Dept/HR_Executive/Department/Department_list/Department_list";
import HRE_Designation_list from "./derbePages/Human_Resource_Dept/HR_Executive/Department/Designation_list/Designation_list";
import HRE_Resignations from "./derbePages/Human_Resource_Dept/HR_Executive/Resignations/Resignations";
import HRE_Create_Resignations from "./derbePages/Human_Resource_Dept/HR_Executive/Resignations/Create_Resignations/Create_Resignations";
import HRE_Approve_Resignations from "./derbePages/Human_Resource_Dept/HR_Executive/Resignations/Approve_Resignations/Approve_Resignations";
import HRE_Resignation_List from "./derbePages/Human_Resource_Dept/HR_Executive/Resignations/Resignation_List/Resignation_List";
import HRE_Employees_Exit from "./derbePages/Human_Resource_Dept/HR_Executive/Resignations/Employees_Exit/Employees_Exit";
import HRE_Exit_Types from "./derbePages/Human_Resource_Dept/HR_Executive/Resignations/Exit_Types/Exit_Types";
import HRE_Exit_Clearance_Certificates from "./derbePages/Human_Resource_Dept/HR_Executive/Resignations/Exit_Clearance_Certificates/Exit_Clearance_Certificates";
import HRE_Transfers from "./derbePages/Human_Resource_Dept/HR_Executive/Transfers/Transfers";
import HRE_Transfer_List from "./derbePages/Human_Resource_Dept/HR_Executive/Transfers/Transfer_List/Transfer_List";
import HRE_Approve_Transfers from "./derbePages/Human_Resource_Dept/HR_Executive/Transfers/Approve_Transfers/Approve_Transfers";
import HRE_New_Transfers from "./derbePages/Human_Resource_Dept/HR_Executive/Transfers/New_Transfers/New_Transfers";
import HRE_Complaints from "./derbePages/Human_Resource_Dept/HR_Executive/Complaints/Complaints";
import HRE_Lodge_Complaint from "./derbePages/Human_Resource_Dept/HR_Executive/Complaints/Lodge_Complaint/Lodge_Complaint";
import HRE_Complaint_List from "./derbePages/Human_Resource_Dept/HR_Executive/Complaints/Complaint_List/Complaint_List";
import HRE_Resolved_List from "./derbePages/Human_Resource_Dept/HR_Executive/Complaints/Resolved_List/Resolved_List";
import HRE_Awards from "./derbePages/Human_Resource_Dept/HR_Executive/Awards/Awards";
import HRE_awards from "./derbePages/Human_Resource_Dept/HR_Executive/Awards/awards/awards";
import HRE_award_assign_list from "./derbePages/Human_Resource_Dept/HR_Executive/Awards/award_assign_list/award_assign_list";
import HRE_award_Type from "./derbePages/Human_Resource_Dept/HR_Executive/Awards/award_Type/award_Type";
import HRE_Activities from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/Activities";
import HRE_Travels from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/Travels/Travels";
import HRE_New_Business_Travel from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/New_Business_Travel/New_Business_Travel";
import HRE_Travel_Arrangement_Type from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/Travel_Arrangement_Type/Travel_Arrangement_Type";
import HRE_Transport_Type from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/Transport_Type/Transport_Type";
import HRE_Payments from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/Payments/Payments";
import HRE_Travel_Management from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/Travel_Management/Travel_Management";
import HRE_Events from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/Events/Events";
import HRE_New_Event from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/New_Event/New_Event";
import HRE_Event_Types from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/Event_Types/Event_Types";
import HRE_Holidays from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/Holidays/Holidays";
import HRE_New_Holiday from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/New_Holiday/New_Holiday";
import HRE_Holiday_list from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/Holiday_list/Holiday_list";
import HRE_Visitors from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/Visitors/Visitors";
import HRE_New_visitor from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/New_visitor/New_visitor";
import HRE_Visitors_List from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/Visitors_List/Visitors_List";
import HRE_Conferences from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/Conferences/Conferences";
import HRE_Conf_booking from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/Conf_booking/Conf_booking";
import HRE_Meeting_List from "./derbePages/Human_Resource_Dept/HR_Executive/Activities/Meeting_List/Meeting_List";
import HRE_Documents_Manager from "./derbePages/Human_Resource_Dept/HR_Executive/Documents_Manager/Documents_Manager";
import HRE_general from "./derbePages/Human_Resource_Dept/HR_Executive/Documents_Manager/general/general";
import HRE_Uploading_files from "./derbePages/Human_Resource_Dept/HR_Executive/Documents_Manager/general/Uploading_files";
import HRE_Managing_Files from "./derbePages/Human_Resource_Dept/HR_Executive/Documents_Manager/general/Managing_Files";
import HRE_official from "./derbePages/Human_Resource_Dept/HR_Executive/Documents_Manager/official/official";
import HRE_OUploading_files from "./derbePages/Human_Resource_Dept/HR_Executive/Documents_Manager/official/Uploading_files";
import HRE_OManaging_Files from "./derbePages/Human_Resource_Dept/HR_Executive/Documents_Manager/official/Managing_Files";
import HRE_Process_Documents from "./derbePages/Human_Resource_Dept/HR_Executive/Documents_Manager/Process_Documents/Process_Documents";
import HRE_PUploading_files from "./derbePages/Human_Resource_Dept/HR_Executive/Documents_Manager/Process_Documents/Uploading_files";
import HRE_PManaging_Files from "./derbePages/Human_Resource_Dept/HR_Executive/Documents_Manager/Process_Documents/Managing_Files";
import HRE_Performance_PMS from "./derbePages/Human_Resource_Dept/HR_Executive/Performance_PMS/Performance_PMS";
import HRE_KPI_indicators from "./derbePages/Human_Resource_Dept/HR_Executive/Performance_PMS/KPI_indicators/KPI_indicators";
import HRE_KPA_Appraisals from "./derbePages/Human_Resource_Dept/HR_Executive/Performance_PMS/KPA_Appraisals/KPA_Appraisals";
import HRE_OKRs_Tracking_Goals from "./derbePages/Human_Resource_Dept/HR_Executive/Performance_PMS/OKRs_Tracking_Goals/OKRs_Tracking_Goals";
import HRE_Competencies from "./derbePages/Human_Resource_Dept/HR_Executive/Performance_PMS/Competencies/Competencies";
import HRE_Technical from "./derbePages/Human_Resource_Dept/HR_Executive/Performance_PMS/Technical/Technical";
import HRE_Organizational from "./derbePages/Human_Resource_Dept/HR_Executive/Performance_PMS/Organizational/Organizational";
import HRE_Goal_Types from "./derbePages/Human_Resource_Dept/HR_Executive/Performance_PMS/Goal_Types/Goal_Types";
import HRE_Goal_Calendar from "./derbePages/Human_Resource_Dept/HR_Executive/Performance_PMS/Goal_Calendar/Goal_Calendar";
import HRE_Recruitment_ATS from "./derbePages/Human_Resource_Dept/HR_Executive/Recruitment_ATS/Recruitment_ATS";
import HRE_New_postings from "./derbePages/Human_Resource_Dept/HR_Executive/Recruitment_ATS/New_postings/New_postings";
import HRE_Candidate_List from "./derbePages/Human_Resource_Dept/HR_Executive/Recruitment_ATS/Candidate_List/Candidate_List";
import HRE_Interviews from "./derbePages/Human_Resource_Dept/HR_Executive/Recruitment_ATS/Interviews/Interviews";
import HRE_Interviewers_List from "./derbePages/Human_Resource_Dept/HR_Executive/Recruitment_ATS/Interviewers_List/Interviewers_List";
import HRE_Attendance from "./derbePages/Human_Resource_Dept/HR_Executive/Attendance/Attendance";
import HRE_Manual_Attendance from "./derbePages/Human_Resource_Dept/HR_Executive/Attendance/Manual_Attendance/Manual_Attendance";
import HRE_Monthly_Report from "./derbePages/Human_Resource_Dept/HR_Executive/Attendance/Monthly_Report/Monthly_Report";
import HRE_Overtime_Request from "./derbePages/Human_Resource_Dept/HR_Executive/Attendance/Overtime_Request/Overtime_Request";
import HRE_Policies from "./derbePages/Human_Resource_Dept/HR_Executive/Policies/Policies";
import HRE_Create_new_Policy from "./derbePages/Human_Resource_Dept/HR_Executive/Policies/Create_new_Policy/Create_new_Policy";
import HRE_Policy_list from "./derbePages/Human_Resource_Dept/HR_Executive/Policies/Policy_list/Policy_list";
import HRE_Make_Announcement from "./derbePages/Human_Resource_Dept/HR_Executive/Make_Announcement/Make_Announcement";
import HRE_New_Announcement from "./derbePages/Human_Resource_Dept/HR_Executive/Make_Announcement/New_Announcement/New_Announcement";
import HRE_Announcement_List from "./derbePages/Human_Resource_Dept/HR_Executive/Make_Announcement/Announcement_List/Announcement_List";
import HRE_Organisation_Chart from "./derbePages/Human_Resource_Dept/HR_Executive/Organisation_Chart/Organisation_Chart";
import HRE_View from "./derbePages/Human_Resource_Dept/HR_Executive/Organisation_Chart/View/View";
import HRE_Create_Chart from "./derbePages/Human_Resource_Dept/HR_Executive/Organisation_Chart/Create_Chart/Create_Chart";
import HRE_Leave_Requests from "./derbePages/Human_Resource_Dept/HR_Executive/Leave_Requests/Leave_Requests";
import HRE_Manage_Leaves from "./derbePages/Human_Resource_Dept/HR_Executive/Leave_Requests/Manage_Leaves/Manage_Leaves";
import HRE_Leaves_Type from "./derbePages/Human_Resource_Dept/HR_Executive/Leave_Requests/Leaves_Type/Leaves_Type";
import HRE_Settings from "./derbePages/Human_Resource_Dept/HR_Executive/Settings/Settings";

// Training Head -TH

import TH_Leave_Requests from "./derbePages/Human_Resource_Dept/Training_Head/Leave_Requests/Leave_Requests";
import TH_Manage_Leaves from "./derbePages/Human_Resource_Dept/Training_Head/Leave_Requests/Manage_Leaves/Manage_Leaves";
import TH_Leaves_Type from "./derbePages/Human_Resource_Dept/Training_Head/Leave_Requests/Leaves_Type/Leaves_Type";
import TH_Trainings from "./derbePages/Human_Resource_Dept/Training_Head/Trainings/Trainings";
import TH_Training_Sessions from "./derbePages/Human_Resource_Dept/Training_Head/Trainings/Training_Sessions/Training_Sessions";
import TH_Trainers from "./derbePages/Human_Resource_Dept/Training_Head/Trainings/Trainers/Trainers";
import TH_Training_Skills from "./derbePages/Human_Resource_Dept/Training_Head/Trainings/Training_Skills/Training_Skills";
import TH_Manage_Trainings from "./derbePages/Human_Resource_Dept/Training_Head/Trainings/Manage_Trainings/Manage_Trainings";
import TH_Disciplinary_Cases from "./derbePages/Human_Resource_Dept/Training_Head/Disciplinary_Cases/Disciplinary_Cases";
import TH_Actions from "./derbePages/Human_Resource_Dept/Training_Head/Disciplinary_Cases/Actions/Actions";
import TH_Resolutions from "./derbePages/Human_Resource_Dept/Training_Head/Disciplinary_Cases/Resolutions/Resolutions";
import TH_Settings from "./derbePages/Human_Resource_Dept/Training_Head/Settings/Settings";

// Trainee Team Leader -TTL

import TTL_Leave_Requests from "./derbePages/Human_Resource_Dept/Trainee_Team_Leader/Leave_Requests/Leave_Requests";
import TTL_Manage_Leaves from "./derbePages/Human_Resource_Dept/Trainee_Team_Leader/Leave_Requests/Manage_Leaves/Manage_Leaves";
import TTL_Leaves_Type from "./derbePages/Human_Resource_Dept/Trainee_Team_Leader/Leave_Requests/Leaves_Type/Leaves_Type";
import TTL_Trainings from "./derbePages/Human_Resource_Dept/Trainee_Team_Leader/Trainings/Trainings";
import TTL_Training_Sessions from "./derbePages/Human_Resource_Dept/Trainee_Team_Leader/Trainings/Training_Sessions/Training_Sessions";
import TTL_Trainers from "./derbePages/Human_Resource_Dept/Trainee_Team_Leader/Trainings/Trainers/Trainers";
import TTL_Training_Skills from "./derbePages/Human_Resource_Dept/Trainee_Team_Leader/Trainings/Training_Skills/Training_Skills";
import TTL_Manage_Trainings from "./derbePages/Human_Resource_Dept/Trainee_Team_Leader/Trainings/Manage_Trainings/Manage_Trainings";
import TTL_Disciplinary_Cases from "./derbePages/Human_Resource_Dept/Trainee_Team_Leader/Disciplinary_Cases/Disciplinary_Cases";
import TTL_Actions from "./derbePages/Human_Resource_Dept/Trainee_Team_Leader/Disciplinary_Cases/Actions/Actions";
import TTL_Resolutions from "./derbePages/Human_Resource_Dept/Trainee_Team_Leader/Disciplinary_Cases/Resolutions/Resolutions";
import TTL_Settings from "./derbePages/Human_Resource_Dept/Trainee_Team_Leader/Settings/Settings";

// Trainer -TR

import TR_Leave_Requests from "./derbePages/Human_Resource_Dept/Trainer/Leave_Requests/Leave_Requests";
import TR_Manage_Leaves from "./derbePages/Human_Resource_Dept/Trainer/Leave_Requests/Manage_Leaves/Manage_Leaves";
import TR_Leaves_Type from "./derbePages/Human_Resource_Dept/Trainer/Leave_Requests/Leaves_Type/Leaves_Type";
import TR_Trainings from "./derbePages/Human_Resource_Dept/Trainer/Trainings/Trainings";
import TR_Training_Sessions from "./derbePages/Human_Resource_Dept/Trainer/Trainings/Training_Sessions/Training_Sessions";
import TR_Trainers from "./derbePages/Human_Resource_Dept/Trainer/Trainings/Trainers/Trainers";
import TR_Training_Skills from "./derbePages/Human_Resource_Dept/Trainer/Trainings/Training_Skills/Training_Skills";
import TR_Manage_Trainings from "./derbePages/Human_Resource_Dept/Trainer/Trainings/Manage_Trainings/Manage_Trainings";
import TR_Disciplinary_Cases from "./derbePages/Human_Resource_Dept/Trainer/Disciplinary_Cases/Disciplinary_Cases";
import TR_Actions from "./derbePages/Human_Resource_Dept/Trainer/Disciplinary_Cases/Actions/Actions";
import TR_Resolutions from "./derbePages/Human_Resource_Dept/Trainer/Disciplinary_Cases/Resolutions/Resolutions";
import TR_Settings from "./derbePages/Human_Resource_Dept/Trainer/Settings/Settings";

// Office Admin -OA

import OA_Documents_Manager from "./derbePages/Human_Resource_Dept/Office_Admin/Documents_Manager/Documents_Manager";
import OA_general from "./derbePages/Human_Resource_Dept/Office_Admin/general/general";
import OA_Uploading_files from "./derbePages/Human_Resource_Dept/Office_Admin/general/Uploading_files/Uploading_files";
import OA_Managing_Files from "./derbePages/Human_Resource_Dept/Office_Admin/general/Managing_Files/Managing_Files";
import OA_official from "./derbePages/Human_Resource_Dept/Office_Admin/official/official";
import OA_OUploading_files from "./derbePages/Human_Resource_Dept/Office_Admin/official/Uploading_files/Uploading_files";
import OA_OManaging_Files from "./derbePages/Human_Resource_Dept/Office_Admin/official/Managing_Files/Managing_Files";
import OA_Visitors from "./derbePages/Human_Resource_Dept/Office_Admin/Visitors/Visitors";
import OA_New_visitor from "./derbePages/Human_Resource_Dept/Office_Admin/Visitors/New_visitor/New_visitor";
import OA_Visitors_List from "./derbePages/Human_Resource_Dept/Office_Admin/Visitors/Visitors_List/Visitors_List";
import OA_Leave_Requests from "./derbePages/Human_Resource_Dept/Office_Admin/Leave_Requests/Leave_Requests";
import OA_Manage_Leaves from "./derbePages/Human_Resource_Dept/Office_Admin/Leave_Requests/Manage_Leaves/Manage_Leaves";
import OA_Leaves_Type from "./derbePages/Human_Resource_Dept/Office_Admin/Leave_Requests/Leaves_Type/Leaves_Type";
import OA_Settings from "./derbePages/Human_Resource_Dept/Office_Admin/Settings/Settings";

// Finance Manager -FM

import FM_Accounts from "./derbePages/Finance_Dept/Finance_Manager/Accounts/Accounts";
import FM_Deposits from "./derbePages/Finance_Dept/Finance_Manager/Deposits/Deposits";
import FM_Deposits_List from "./derbePages/Finance_Dept/Finance_Manager/Deposits/Deposits_List/Deposits_List";
import FM_Categories from "./derbePages/Finance_Dept/Finance_Manager/Deposits/Categories/Categories";
import FM_Expenses from "./derbePages/Finance_Dept/Finance_Manager/Expenses/Expenses";
import FM_Transactions from "./derbePages/Finance_Dept/Finance_Manager/Transactions/Transactions";
import FM_Payroll from "./derbePages/Finance_Dept/Finance_Manager/Payroll/Payroll";
import FM_Pay_Slip_History from "./derbePages/Finance_Dept/Finance_Manager/Payroll/Pay_Slip_History/Pay_Slip_History";
import FM_Advance_Salary from "./derbePages/Finance_Dept/Finance_Manager/Payroll/Advance_Salary/Advance_Salary";
import FM_Loan from "./derbePages/Finance_Dept/Finance_Manager/Loan/Loan";
import FM_Loan_Types from "./derbePages/Finance_Dept/Finance_Manager/Loan/Loan_Types/Loan_Types";
import FM_Commission_types from "./derbePages/Finance_Dept/Finance_Manager/Loan/Commission_types/Commission_types";
import FM_Incentive_Types from "./derbePages/Finance_Dept/Finance_Manager/Loan/Incentive_Types/Incentive_Types";
import FM_Invoices from "./derbePages/Finance_Dept/Finance_Manager/Invoices/Invoices";
import FM_Billing_Invoices from "./derbePages/Finance_Dept/Finance_Manager/Invoices/Billing_Invoices/Billing_Invoices";
import FM_Invoice_Payments from "./derbePages/Finance_Dept/Finance_Manager/Invoices/Invoice_Payments/Invoice_Payments";
import FM_Tax_Type from "./derbePages/Finance_Dept/Finance_Manager/Invoices/Tax_Type/Tax_Type";
import FM_Estimates from "./derbePages/Finance_Dept/Finance_Manager/Estimates/Estimates";
import FM_Estimate_Type from "./derbePages/Finance_Dept/Finance_Manager/Estimates/Estimate_Type/Estimate_Type";
import FM_Leave_Requests from "./derbePages/Finance_Dept/Finance_Manager/Leave_Requests/Leave_Requests";
import FM_Manage_Leaves from "./derbePages/Finance_Dept/Finance_Manager/Leave_Requests/Manage_Leaves/Manage_Leaves";
import FM_Leaves_Type from "./derbePages/Finance_Dept/Finance_Manager/Leave_Requests/Leaves_Type/Leaves_Type";
import FM_Settings from "./derbePages/Finance_Dept/Finance_Manager/Settings/Settings";

// Accounts Head -AH

import AH_Accounts from "./derbePages/Finance_Dept/Accounts_Head/Accounts/Accounts";
import AH_Deposits from "./derbePages/Finance_Dept/Accounts_Head/Deposits/Deposits";
import AH_Deposits_List from "./derbePages/Finance_Dept/Accounts_Head/Deposits/Deposits_List/Deposits_List";
import AH_Categories from "./derbePages/Finance_Dept/Accounts_Head/Deposits/Categories/Categories";
import AH_Expenses from "./derbePages/Finance_Dept/Accounts_Head/Expenses/Expenses";
import AH_Transactions from "./derbePages/Finance_Dept/Accounts_Head/Transactions/Transactions";
import AH_Payroll from "./derbePages/Finance_Dept/Accounts_Head/Payroll/Payroll";
import AH_Pay_Slip_History from "./derbePages/Finance_Dept/Accounts_Head/Payroll/Pay_Slip_History/Pay_Slip_History";
import AH_Advance_Salary from "./derbePages/Finance_Dept/Accounts_Head/Payroll/Advance_Salary/Advance_Salary";
import AH_Loan from "./derbePages/Finance_Dept/Accounts_Head/Loan/Loan";
import AH_Loan_Types from "./derbePages/Finance_Dept/Accounts_Head/Loan/Loan_Types/Loan_Types";
import AH_Commission_types from "./derbePages/Finance_Dept/Accounts_Head/Loan/Commission_types/Commission_types";
import AH_Incentive_Types from "./derbePages/Finance_Dept/Accounts_Head/Loan/Incentive_Types/Incentive_Types";
import AH_Invoices from "./derbePages/Finance_Dept/Accounts_Head/Invoices/Invoices";
import AH_Billing_Invoices from "./derbePages/Finance_Dept/Accounts_Head/Invoices/Billing_Invoices/Billing_Invoices";
import AH_Invoice_Payments from "./derbePages/Finance_Dept/Accounts_Head/Invoices/Invoice_Payments/Invoice_Payments";
import AH_Tax_Type from "./derbePages/Finance_Dept/Accounts_Head/Invoices/Tax_Type/Tax_Type";
import AH_Estimates from "./derbePages/Finance_Dept/Accounts_Head/Estimates/Estimates";
import AH_Estimate_Type from "./derbePages/Finance_Dept/Accounts_Head/Estimates/Estimate_Type/Estimate_Type";
import AH_Leave_Requests from "./derbePages/Finance_Dept/Accounts_Head/Leave_Requests/Leave_Requests";
import AH_Manage_Leaves from "./derbePages/Finance_Dept/Accounts_Head/Leave_Requests/Manage_Leaves/Manage_Leaves";
import AH_Leaves_Type from "./derbePages/Finance_Dept/Accounts_Head/Leave_Requests/Leaves_Type/Leaves_Type";
import AH_Settings from "./derbePages/Finance_Dept/Accounts_Head/Settings/Settings";

// Accounts Executive -AE

import AE_Accounts from "./derbePages/Finance_Dept/Accounts_Executive/Accounts/Accounts";
import AE_Deposits from "./derbePages/Finance_Dept/Accounts_Executive/Deposits/Deposits";
import AE_Deposits_List from "./derbePages/Finance_Dept/Accounts_Executive/Deposits/Deposits_List/Deposits_List";
import AE_Categories from "./derbePages/Finance_Dept/Accounts_Executive/Deposits/Categories/Categories";
import AE_Expenses from "./derbePages/Finance_Dept/Accounts_Executive/Expenses/Expenses";
import AE_Transactions from "./derbePages/Finance_Dept/Accounts_Executive/Transactions/Transactions";
import AE_Payroll from "./derbePages/Finance_Dept/Accounts_Executive/Payroll/Payroll";
import AE_Pay_Slip_History from "./derbePages/Finance_Dept/Accounts_Executive/Payroll/Pay_Slip_History/Pay_Slip_History";
import AE_Advance_Salary from "./derbePages/Finance_Dept/Accounts_Executive/Payroll/Advance_Salary/Advance_Salary";
import AE_Loan from "./derbePages/Finance_Dept/Accounts_Executive/Loan/Loan";
import AE_Loan_Types from "./derbePages/Finance_Dept/Accounts_Executive/Loan/Loan_Types/Loan_Types";
import AE_Commission_types from "./derbePages/Finance_Dept/Accounts_Executive/Loan/Commission_types/Commission_types";
import AE_Incentive_Types from "./derbePages/Finance_Dept/Accounts_Executive/Loan/Incentive_Types/Incentive_Types";
import AE_Invoices from "./derbePages/Finance_Dept/Accounts_Executive/Invoices/Invoices";
import AE_Billing_Invoices from "./derbePages/Finance_Dept/Accounts_Executive/Invoices/Billing_Invoices/Billing_Invoices";
import AE_Invoice_Payments from "./derbePages/Finance_Dept/Accounts_Executive/Invoices/Invoice_Payments/Invoice_Payments";
import AE_Tax_Type from "./derbePages/Finance_Dept/Accounts_Executive/Invoices/Tax_Type/Tax_Type";
import AE_Estimates from "./derbePages/Finance_Dept/Accounts_Executive/Estimates/Estimates";
import AE_Estimate_Type from "./derbePages/Finance_Dept/Accounts_Executive/Estimates/Estimate_Type/Estimate_Type";
import AE_Leave_Requests from "./derbePages/Finance_Dept/Accounts_Executive/Leave_Requests/Leave_Requests";
import AE_Manage_Leaves from "./derbePages/Finance_Dept/Accounts_Executive/Leave_Requests/Manage_Leaves/Manage_Leaves";
import AE_Leaves_Type from "./derbePages/Finance_Dept/Accounts_Executive/Leave_Requests/Leaves_Type/Leaves_Type";
import AE_Settings from "./derbePages/Finance_Dept/Accounts_Executive/Settings/Settings";

// Accounts Clerk -AC

import AC_Accounts from "./derbePages/Finance_Dept/Accounts_Clerk/Accounts/Accounts";
import AC_Deposits from "./derbePages/Finance_Dept/Accounts_Clerk/Deposits/Deposits";
import AC_Deposits_List from "./derbePages/Finance_Dept/Accounts_Clerk/Deposits/Deposits_List/Deposits_List";
import AC_Categories from "./derbePages/Finance_Dept/Accounts_Clerk/Deposits/Categories/Categories";
import AC_Expenses from "./derbePages/Finance_Dept/Accounts_Clerk/Expenses/Expenses";
import AC_Transactions from "./derbePages/Finance_Dept/Accounts_Clerk/Transactions/Transactions";
import AC_Payroll from "./derbePages/Finance_Dept/Accounts_Clerk/Payroll/Payroll";
import AC_Pay_Slip_History from "./derbePages/Finance_Dept/Accounts_Clerk/Payroll/Pay_Slip_History/Pay_Slip_History";
import AC_Advance_Salary from "./derbePages/Finance_Dept/Accounts_Clerk/Payroll/Advance_Salary/Advance_Salary";
import AC_Loan from "./derbePages/Finance_Dept/Accounts_Clerk/Loan/Loan";
import AC_Loan_Types from "./derbePages/Finance_Dept/Accounts_Clerk/Loan/Loan_Types/Loan_Types";
import AC_Commission_types from "./derbePages/Finance_Dept/Accounts_Clerk/Loan/Commission_types/Commission_types";
import AC_Incentive_Types from "./derbePages/Finance_Dept/Accounts_Clerk/Loan/Incentive_Types/Incentive_Types";
import AC_Invoices from "./derbePages/Finance_Dept/Accounts_Clerk/Invoices/Invoices";
import AC_Billing_Invoices from "./derbePages/Finance_Dept/Accounts_Clerk/Invoices/Billing_Invoices/Billing_Invoices";
import AC_Invoice_Payments from "./derbePages/Finance_Dept/Accounts_Clerk/Invoices/Invoice_Payments/Invoice_Payments";
import AC_Tax_Type from "./derbePages/Finance_Dept/Accounts_Clerk/Invoices/Tax_Type/Tax_Type";
import AC_Estimates from "./derbePages/Finance_Dept/Accounts_Clerk/Estimates/Estimates";
import AC_Estimate_Type from "./derbePages/Finance_Dept/Accounts_Clerk/Estimates/Estimate_Type/Estimate_Type";
import AC_Leave_Requests from "./derbePages/Finance_Dept/Accounts_Clerk/Leave_Requests/Leave_Requests";
import AC_Manage_Leaves from "./derbePages/Finance_Dept/Accounts_Clerk/Leave_Requests/Manage_Leaves/Manage_Leaves";
import AC_Leaves_Type from "./derbePages/Finance_Dept/Accounts_Clerk/Leave_Requests/Leaves_Type/Leaves_Type";
import AC_Settings from "./derbePages/Finance_Dept/Accounts_Clerk/Settings/Settings";

// IT Head ITH

import ITH_Inventory_Control from "./derbePages/Assert_Management_IT_Dept/IT_Head/Inventory_Control/Inventory_Control";
import ITH_Ware_Houses from "./derbePages/Assert_Management_IT_Dept/IT_Head/Ware_Houses/Ware_Houses";
import ITH_Products from "./derbePages/Assert_Management_IT_Dept/IT_Head/Products/Products";
import ITH_Out_Of_Stock from "./derbePages/Assert_Management_IT_Dept/IT_Head/Products/Out_Of_Stock/Out_Of_Stock";
import ITH_Expired_Products from "./derbePages/Assert_Management_IT_Dept/IT_Head/Products/Expired_Products/Expired_Products";
import ITH_Product_Tax from "./derbePages/Assert_Management_IT_Dept/IT_Head/Products/Product_Tax/Product_Tax";
import ITH_Billing_Invoices from "./derbePages/Assert_Management_IT_Dept/IT_Head/Billing_Invoices/Billing_Invoices";
import ITH_Invoice_Payments from "./derbePages/Assert_Management_IT_Dept/IT_Head/Invoice_Payments/Invoice_Payments";
import ITH_Tax_Type from "./derbePages/Assert_Management_IT_Dept/IT_Head/Tax_Type/Tax_Type";
import ITH_Product_Category from "./derbePages/Assert_Management_IT_Dept/IT_Head/Tax_Type/Product_Category/Product_Category";
import ITH_Suppliers from "./derbePages/Assert_Management_IT_Dept/IT_Head/Suppliers/Suppliers";
import ITH_new_supplier from "./derbePages/Assert_Management_IT_Dept/IT_Head/Suppliers/new_supplier/new_supplier";
import ITH_suppliers_list from "./derbePages/Assert_Management_IT_Dept/IT_Head/Suppliers/suppliers_list/suppliers_list";
import ITH_Purchases from "./derbePages/Assert_Management_IT_Dept/IT_Head/Purchases/Purchases";
import ITH_New_Purchase from "./derbePages/Assert_Management_IT_Dept/IT_Head/Purchases/New_Purchase/New_Purchase";
import ITH_Purchase_List from "./derbePages/Assert_Management_IT_Dept/IT_Head/Purchases/Purchase_List/Purchase_List";
import ITH_Sales_Order from "./derbePages/Assert_Management_IT_Dept/IT_Head/Sales_Order/Sales_Order";
import ITH_Sales_Order_List from "./derbePages/Assert_Management_IT_Dept/IT_Head/Sales_Order/Sales_Order_List/Sales_Order_List";
import ITH_New_Order_Invoice from "./derbePages/Assert_Management_IT_Dept/IT_Head/Sales_Order/New_Order_Invoice/New_Order_Invoice";
import ITH_Manage_Orders from "./derbePages/Assert_Management_IT_Dept/IT_Head/Sales_Order/Manage_Orders/Manage_Orders";
import ITH_Paid_Orders from "./derbePages/Assert_Management_IT_Dept/IT_Head/Sales_Order/Paid_Orders/Paid_Orders";
import ITH_Unpaid_Orders from "./derbePages/Assert_Management_IT_Dept/IT_Head/Sales_Order/Unpaid_Orders/Unpaid_Orders";
import ITH_Delivered_Orders from "./derbePages/Assert_Management_IT_Dept/IT_Head/Sales_Order/Delivered_Orders/Delivered_Orders";
import ITH_Cancelled_Orders from "./derbePages/Assert_Management_IT_Dept/IT_Head/Sales_Order/Cancelled_Orders/Cancelled_Orders";
import ITH_Credit_Types from "./derbePages/Assert_Management_IT_Dept/IT_Head/Sales_Order/Credit_Types/Credit_Types";
import ITH_Help_Desk from "./derbePages/Assert_Management_IT_Dept/IT_Head/Help_Desk/Help_Desk";
import ITH_Tickets from "./derbePages/Assert_Management_IT_Dept/IT_Head/Help_Desk/Tickets/Tickets";
import ITH_Tickets_List from "./derbePages/Assert_Management_IT_Dept/IT_Head/Help_Desk/Tickets_List/Tickets_List";
import ITH_Create_Ticket from "./derbePages/Assert_Management_IT_Dept/IT_Head/Help_Desk/Create_Ticket/Create_Ticket";
import ITH_Support_Tickets from "./derbePages/Assert_Management_IT_Dept/IT_Head/Help_Desk/Support_Tickets/Support_Tickets";
import ITH_Resolved_List from "./derbePages/Assert_Management_IT_Dept/IT_Head/Help_Desk/Resolved_List/Resolved_List";
import ITH_Assign_Tickets_Solve from "./derbePages/Assert_Management_IT_Dept/IT_Head/Help_Desk/Assign_Tickets_Solve/Assign_Tickets_Solve";
import ITH_Leave_Requests from "./derbePages/Assert_Management_IT_Dept/IT_Head/Leave_Requests/Leave_Requests";
import ITH_Manage_Leaves from "./derbePages/Assert_Management_IT_Dept/IT_Head/Leave_Requests/Manage_Leaves/Manage_Leaves";
import ITH_Leaves_Type from "./derbePages/Assert_Management_IT_Dept/IT_Head/Leave_Requests/Leaves_Type/Leaves_Type";
import ITH_Assets_List from "./derbePages/Assert_Management_IT_Dept/IT_Head/Assets_List/Assets_List";
import ITH_Assets_management from "./derbePages/Assert_Management_IT_Dept/IT_Head/Assets_management/Assets_management";
import ITH_Create_assert from "./derbePages/Assert_Management_IT_Dept/IT_Head/Assets_management/Create_assert/Create_assert";
import ITH_Assign_assert from "./derbePages/Assert_Management_IT_Dept/IT_Head/Assets_management/Assign_assert/Assign_assert";
import ITH_Destory_assert from "./derbePages/Assert_Management_IT_Dept/IT_Head/Assets_management/Destory_assert/Destory_assert";
import ITH_assert_Category from "./derbePages/Assert_Management_IT_Dept/IT_Head/assert_Category/assert_Category";
import ITH_New_category from "./derbePages/Assert_Management_IT_Dept/IT_Head/assert_Category/New_category/New_category";
import ITH_Manage_category from "./derbePages/Assert_Management_IT_Dept/IT_Head/assert_Category/Manage_category/Manage_category";
import ITH_Asset_Brand from "./derbePages/Assert_Management_IT_Dept/IT_Head/Asset_Brand/Asset_Brand";
import ITH_New_Brand from "./derbePages/Assert_Management_IT_Dept/IT_Head/Asset_Brand/New_Brand/New_Brand";
import ITH_Manage_Brand from "./derbePages/Assert_Management_IT_Dept/IT_Head/Asset_Brand/Manage_Brand/Manage_Brand";
import ITH_Settings from "./derbePages/Assert_Management_IT_Dept/IT_Head/Settings/Settings";

// IT Manager ITM

import ITM_Inventory_Control from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Inventory_Control/Inventory_Control";
import ITM_Ware_Houses from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Ware_Houses/Ware_Houses";
import ITM_Products from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Products/Products";
import ITM_Out_Of_Stock from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Products/Out_Of_Stock/Out_Of_Stock";
import ITM_Expired_Products from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Products/Expired_Products/Expired_Products";
import ITM_Product_Tax from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Products/Product_Tax/Product_Tax";
import ITM_Billing_Invoices from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Billing_Invoices/Billing_Invoices";
import ITM_Invoice_Payments from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Invoice_Payments/Invoice_Payments";
import ITM_Tax_Type from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Tax_Type/Tax_Type";
import ITM_Product_Category from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Tax_Type/Product_Category/Product_Category";
import ITM_Suppliers from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Suppliers/Suppliers";
import ITM_new_supplier from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Suppliers/new_supplier/new_supplier";
import ITM_suppliers_list from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Suppliers/suppliers_list/suppliers_list";
import ITM_Purchases from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Purchases/Purchases";
import ITM_New_Purchase from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Purchases/New_Purchase/New_Purchase";
import ITM_Purchase_List from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Purchases/Purchase_List/Purchase_List";
import ITM_Sales_Order from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Sales_Order/Sales_Order";
import ITM_Sales_Order_List from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Sales_Order/Sales_Order_List/Sales_Order_List";
import ITM_New_Order_Invoice from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Sales_Order/New_Order_Invoice/New_Order_Invoice";
import ITM_Manage_Orders from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Sales_Order/Manage_Orders/Manage_Orders";
import ITM_Paid_Orders from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Sales_Order/Paid_Orders/Paid_Orders";
import ITM_Unpaid_Orders from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Sales_Order/Unpaid_Orders/Unpaid_Orders";
import ITM_Delivered_Orders from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Sales_Order/Delivered_Orders/Delivered_Orders";
import ITM_Cancelled_Orders from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Sales_Order/Cancelled_Orders/Cancelled_Orders";
import ITM_Credit_Types from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Sales_Order/Credit_Types/Credit_Types";
import ITM_Help_Desk from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Help_Desk/Help_Desk";
import ITM_Tickets from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Help_Desk/Tickets/Tickets";
import ITM_Tickets_List from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Help_Desk/Tickets_List/Tickets_List";
import ITM_Create_Ticket from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Help_Desk/Create_Ticket/Create_Ticket";
import ITM_Support_Tickets from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Help_Desk/Support_Tickets/Support_Tickets";
import ITM_Resolved_List from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Help_Desk/Resolved_List/Resolved_List";
import ITM_Assign_Tickets_Solve from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Help_Desk/Assign_Tickets_Solve/Assign_Tickets_Solve";
import ITM_Leave_Requests from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Leave_Requests/Leave_Requests";
import ITM_Manage_Leaves from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Leave_Requests/Manage_Leaves/Manage_Leaves";
import ITM_Leaves_Type from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Leave_Requests/Leaves_Type/Leaves_Type";
import ITM_Assets_List from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Assets_List/Assets_List";
import ITM_Assets_management from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Assets_management/Assets_management";
import ITM_Create_assert from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Assets_management/Create_assert/Create_assert";
import ITM_Assign_assert from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Assets_management/Assign_assert/Assign_assert";
import ITM_Destory_assert from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Assets_management/Destory_assert/Destory_assert";
import ITM_assert_Category from "./derbePages/Assert_Management_IT_Dept/IT_Manager/assert_Category/assert_Category";
import ITM_New_category from "./derbePages/Assert_Management_IT_Dept/IT_Manager/assert_Category/New_category/New_category";
import ITM_Manage_category from "./derbePages/Assert_Management_IT_Dept/IT_Manager/assert_Category/Manage_category/Manage_category";
import ITM_Asset_Brand from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Asset_Brand/Asset_Brand";
import ITM_New_Brand from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Asset_Brand/New_Brand/New_Brand";
import ITM_Manage_Brand from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Asset_Brand/Manage_Brand/Manage_Brand";
import ITM_Settings from "./derbePages/Assert_Management_IT_Dept/IT_Manager/Settings/Settings";

// Asserts Manager HW Administrator AMHWA

import AMHWA_Inventory_Control from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Inventory_Control/Inventory_Control";
import AMHWA_Ware_Houses from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Ware_Houses/Ware_Houses";
import AMHWA_Products from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Products/Products";
import AMHWA_Out_Of_Stock from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Products/Out_Of_Stock/Out_Of_Stock";
import AMHWA_Expired_Products from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Products/Expired_Products/Expired_Products";
import AMHWA_Product_Tax from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Products/Product_Tax/Product_Tax";
import AMHWA_Billing_Invoices from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Billing_Invoices/Billing_Invoices";
import AMHWA_Invoice_Payments from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Invoice_Payments/Invoice_Payments";
import AMHWA_Tax_Type from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Tax_Type/Tax_Type";
import AMHWA_Product_Category from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Tax_Type/Product_Category/Product_Category";
import AMHWA_Suppliers from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Suppliers/Suppliers";
import AMHWA_new_supplier from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Suppliers/new_supplier/new_supplier";
import AMHWA_suppliers_list from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Suppliers/suppliers_list/suppliers_list";
import AMHWA_Purchases from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Purchases/Purchases";
import AMHWA_New_Purchase from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Purchases/New_Purchase/New_Purchase";
import AMHWA_Purchase_List from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Purchases/Purchase_List/Purchase_List";
import AMHWA_Sales_Order from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Sales_Order/Sales_Order";
import AMHWA_Sales_Order_List from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Sales_Order/Sales_Order_List/Sales_Order_List";
import AMHWA_New_Order_Invoice from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Sales_Order/New_Order_Invoice/New_Order_Invoice";
import AMHWA_Manage_Orders from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Sales_Order/Manage_Orders/Manage_Orders";
import AMHWA_Paid_Orders from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Sales_Order/Paid_Orders/Paid_Orders";
import AMHWA_Unpaid_Orders from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Sales_Order/Unpaid_Orders/Unpaid_Orders";
import AMHWA_Delivered_Orders from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Sales_Order/Delivered_Orders/Delivered_Orders";
import AMHWA_Cancelled_Orders from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Sales_Order/Cancelled_Orders/Cancelled_Orders";
import AMHWA_Credit_Types from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Sales_Order/Credit_Types/Credit_Types";
import AMHWA_Help_Desk from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Help_Desk/Help_Desk";
import AMHWA_Tickets from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Help_Desk/Tickets/Tickets";
import AMHWA_Tickets_List from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Help_Desk/Tickets_List/Tickets_List";
import AMHWA_Create_Ticket from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Help_Desk/Create_Ticket/Create_Ticket";
import AMHWA_Support_Tickets from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Help_Desk/Support_Tickets/Support_Tickets";
import AMHWA_Resolved_List from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Help_Desk/Resolved_List/Resolved_List";
import AMHWA_Assign_Tickets_Solve from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Help_Desk/Assign_Tickets_Solve/Assign_Tickets_Solve";
import AMHWA_Leave_Requests from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Leave_Requests/Leave_Requests";
import AMHWA_Manage_Leaves from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Leave_Requests/Manage_Leaves/Manage_Leaves";
import AMHWA_Leaves_Type from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Leave_Requests/Leaves_Type/Leaves_Type";
import AMHWA_Assets_List from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Assets_List/Assets_List";
import AMHWA_Assets_management from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Assets_management/Assets_management";
import AMHWA_Create_assert from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Assets_management/Create_assert/Create_assert";
import AMHWA_Assign_assert from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Assets_management/Assign_assert/Assign_assert";
import AMHWA_Destory_assert from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Assets_management/Destory_assert/Destory_assert";
import AMHWA_assert_Category from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/assert_Category/assert_Category";
import AMHWA_New_category from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/assert_Category/New_category/New_category";
import AMHWA_Manage_category from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/assert_Category/Manage_category/Manage_category";
import AMHWA_Asset_Brand from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Asset_Brand/Asset_Brand";
import AMHWA_New_Brand from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Asset_Brand/New_Brand/New_Brand";
import AMHWA_Manage_Brand from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Asset_Brand/Manage_Brand/Manage_Brand";
import AMHWA_Settings from "./derbePages/Assert_Management_IT_Dept/Asserts_Manager_HW_Administrator/Settings/Settings";

// Administrator A

import A_Maps from "./derbePages/Assert_Management_IT_Dept/Administrator/Maps/Maps";
import A_Inventory_Control from "./derbePages/Assert_Management_IT_Dept/Administrator/Inventory_Control/Inventory_Control";
import A_Ware_Houses from "./derbePages/Assert_Management_IT_Dept/Administrator/Ware_Houses/Ware_Houses";
import A_Products from "./derbePages/Assert_Management_IT_Dept/Administrator/Products/Products";
import A_Out_Of_Stock from "./derbePages/Assert_Management_IT_Dept/Administrator/Products/Out_Of_Stock/Out_Of_Stock";
import A_Expired_Products from "./derbePages/Assert_Management_IT_Dept/Administrator/Products/Expired_Products/Expired_Products";
import A_Product_Tax from "./derbePages/Assert_Management_IT_Dept/Administrator/Products/Product_Tax/Product_Tax";
import A_Billing_Invoices from "./derbePages/Assert_Management_IT_Dept/Administrator/Billing_Invoices/Billing_Invoices";
import A_Invoice_Payments from "./derbePages/Assert_Management_IT_Dept/Administrator/Invoice_Payments/Invoice_Payments";
import A_Tax_Type from "./derbePages/Assert_Management_IT_Dept/Administrator/Tax_Type/Tax_Type";
import A_Product_Category from "./derbePages/Assert_Management_IT_Dept/Administrator/Tax_Type/Product_Category/Product_Category";
import A_Suppliers from "./derbePages/Assert_Management_IT_Dept/Administrator/Suppliers/Suppliers";
import A_new_supplier from "./derbePages/Assert_Management_IT_Dept/Administrator/Suppliers/new_supplier/new_supplier";
import A_suppliers_list from "./derbePages/Assert_Management_IT_Dept/Administrator/Suppliers/suppliers_list/suppliers_list";
import A_Purchases from "./derbePages/Assert_Management_IT_Dept/Administrator/Purchases/Purchases";
import A_New_Purchase from "./derbePages/Assert_Management_IT_Dept/Administrator/Purchases/New_Purchase/New_Purchase";
import A_Purchase_List from "./derbePages/Assert_Management_IT_Dept/Administrator/Purchases/Purchase_List/Purchase_List";
import A_Sales_Order from "./derbePages/Assert_Management_IT_Dept/Administrator/Sales_Order/Sales_Order";
import A_Sales_Order_List from "./derbePages/Assert_Management_IT_Dept/Administrator/Sales_Order/Sales_Order_List/Sales_Order_List";
import A_New_Order_Invoice from "./derbePages/Assert_Management_IT_Dept/Administrator/Sales_Order/New_Order_Invoice/New_Order_Invoice";
import A_Manage_Orders from "./derbePages/Assert_Management_IT_Dept/Administrator/Sales_Order/Manage_Orders/Manage_Orders";
import A_Paid_Orders from "./derbePages/Assert_Management_IT_Dept/Administrator/Sales_Order/Paid_Orders/Paid_Orders";
import A_Unpaid_Orders from "./derbePages/Assert_Management_IT_Dept/Administrator/Sales_Order/Unpaid_Orders/Unpaid_Orders";
import A_Delivered_Orders from "./derbePages/Assert_Management_IT_Dept/Administrator/Sales_Order/Delivered_Orders/Delivered_Orders";
import A_Cancelled_Orders from "./derbePages/Assert_Management_IT_Dept/Administrator/Sales_Order/Cancelled_Orders/Cancelled_Orders";
import A_Credit_Types from "./derbePages/Assert_Management_IT_Dept/Administrator/Sales_Order/Credit_Types/Credit_Types";
import A_Help_Desk from "./derbePages/Assert_Management_IT_Dept/Administrator/Help_Desk/Help_Desk";
import A_Tickets from "./derbePages/Assert_Management_IT_Dept/Administrator/Help_Desk/Tickets/Tickets";
import A_Tickets_List from "./derbePages/Assert_Management_IT_Dept/Administrator/Help_Desk/Tickets_List/Tickets_List";
import A_Create_Ticket from "./derbePages/Assert_Management_IT_Dept/Administrator/Help_Desk/Create_Ticket/Create_Ticket";
import A_Support_Tickets from "./derbePages/Assert_Management_IT_Dept/Administrator/Help_Desk/Support_Tickets/Support_Tickets";
import A_Resolved_List from "./derbePages/Assert_Management_IT_Dept/Administrator/Help_Desk/Resolved_List/Resolved_List";
import A_Assign_Tickets_Solve from "./derbePages/Assert_Management_IT_Dept/Administrator/Help_Desk/Assign_Tickets_Solve/Assign_Tickets_Solve";
import A_Leave_Requests from "./derbePages/Assert_Management_IT_Dept/Administrator/Leave_Requests/Leave_Requests";
import A_Manage_Leaves from "./derbePages/Assert_Management_IT_Dept/Administrator/Leave_Requests/Manage_Leaves/Manage_Leaves";
import A_Leaves_Type from "./derbePages/Assert_Management_IT_Dept/Administrator/Leave_Requests/Leaves_Type/Leaves_Type";
import A_Assets_List from "./derbePages/Assert_Management_IT_Dept/Administrator/Assets_List/Assets_List";
import A_Assets_management from "./derbePages/Assert_Management_IT_Dept/Administrator/Assets_management/Assets_management";
import A_Create_assert from "./derbePages/Assert_Management_IT_Dept/Administrator/Assets_management/Create_assert/Create_assert";
import A_Assign_assert from "./derbePages/Assert_Management_IT_Dept/Administrator/Assets_management/Assign_assert/Assign_assert";
import A_Destory_assert from "./derbePages/Assert_Management_IT_Dept/Administrator/Assets_management/Destory_assert/Destory_assert";
import A_assert_Category from "./derbePages/Assert_Management_IT_Dept/Administrator/assert_Category/assert_Category";
import A_New_category from "./derbePages/Assert_Management_IT_Dept/Administrator/assert_Category/New_category/New_category";
import A_Manage_category from "./derbePages/Assert_Management_IT_Dept/Administrator/assert_Category/Manage_category/Manage_category";
import A_Asset_Brand from "./derbePages/Assert_Management_IT_Dept/Administrator/Asset_Brand/Asset_Brand";
import A_New_Brand from "./derbePages/Assert_Management_IT_Dept/Administrator/Asset_Brand/New_Brand/New_Brand";
import A_Manage_Brand from "./derbePages/Assert_Management_IT_Dept/Administrator/Asset_Brand/Manage_Brand/Manage_Brand";
import A_Settings from "./derbePages/Assert_Management_IT_Dept/Administrator/Settings/Settings";

// MIS Manager MISM

import MISM_Inventroy_Control from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Inventory_Control/Inventory_Control";
import MISM_Ware_Houses from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Ware_Houses/Ware_Houses";
import MISM_Products from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Products/Products";
import MISM_Out_Of_Stock from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Products/Out_Of_Stock/Out_Of_Stock";
import MISM_Expired_Products from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Products/Expired_Products/Expired_Products";
import MISM_Product_Tax from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Products/Product_Tax/Product_Tax";
import MISM_Billing_Invoices from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Billing_Invoices/Billing_Invoices";
import MISM_Invoice_Payments from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Invoice_Payments/Invoice_Payments";
import MISM_Tax_Type from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Tax_Type/Tax_Type";
import MISM_Product_Category from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Tax_Type/Product_Category/Product_Category";
import MISM_Suppliers from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Suppliers/Suppliers";
import MISM_new_supplier from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Suppliers/new_supplier/new_supplier";
import MISM_suppliers_list from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Suppliers/suppliers_list/suppliers_list";
import MISM_Purchases from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Purchases/Purchases";
import MISM_New_Purchase from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Purchases/New_Purchase/New_Purchase";
import MISM_Purchase_List from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Purchases/Purchase_List/Purchase_List";
import MISM_Sales_Order from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Sales_Order/Sales_Order";
import MISM_Sales_Order_List from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Sales_Order/Sales_Order_List/Sales_Order_List";
import MISM_New_Order_Invoice from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Sales_Order/New_Order_Invoice/New_Order_Invoice";
import MISM_Manage_Orders from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Sales_Order/Manage_Orders/Manage_Orders";
import MISM_Paid_Orders from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Sales_Order/Paid_Orders/Paid_Orders";
import MISM_Unpaid_Orders from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Sales_Order/Unpaid_Orders/Unpaid_Orders";
import MISM_Delivered_Orders from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Sales_Order/Delivered_Orders/Delivered_Orders";
import MISM_Cancelled_Orders from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Sales_Order/Cancelled_Orders/Cancelled_Orders";
import MISM_Credit_Types from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Sales_Order/Credit_Types/Credit_Types";
import MISM_Help_Desk from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Help_Desk/Help_Desk";
import MISM_Tickets from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Help_Desk/Tickets/Tickets";
import MISM_Tickets_List from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Help_Desk/Tickets_List/Tickets_List";
import MISM_Create_Ticket from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Help_Desk/Create_Ticket/Create_Ticket";
import MISM_Support_Tickets from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Help_Desk/Support_Tickets/Support_Tickets";
import MISM_Resolved_List from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Help_Desk/Resolved_List/Resolved_List";
import MISM_Assign_Tickets_Solved from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Help_Desk/Assign_Tickets_Solve/Assign_Tickets_Solve";
import MISM_Leave_Requests from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Leave_Requests/Leave_Requests";
import MISM_Manage_Leaves from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Leave_Requests/Manage_Leaves/Manage_Leaves";
import MISM_Leaves_Type from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Leave_Requests/Leaves_Type/Leaves_Type";
import MISM_Asserts from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Asserts/asserts";
import MISM_Assets_List from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Assets_List/Assets_List";
import MISM_Assets_management from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Assets_management/Assets_management";
import MISM_Create_assert from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Assets_management/Create_assert/Create_assert";
import MISM_Assign_assert from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Assets_management/Assign_assert/Assign_assert";
import MISM_Destory_assert from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Assets_management/Destory_assert/Destory_assert";
import MISM_assert_Category from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/assert_Category/assert_Category";
import MISM_New_category from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/assert_Category/New_category/New_category";
import MISM_Manage_category from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/assert_Category/Manage_category/Manage_category";
import MISM_Asset_Brand from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Asset_Brand/Asset_Brand";
import MISM_New_Brand from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Asset_Brand/New_Brand/New_Brand";
import MISM_Manage_Brand from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Asset_Brand/Manage_Brand/Manage_Brand";
import MISM_Reports from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Reports/Reports";
import MISM_Daily from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Reports/Daily/Daily";
import MISM_Monthly from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Reports/Monthly/Monthly";
import MISM_Yearly from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Reports/Yearly/Yearly";
import MISM_Process from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Reports/Process/Process";
import MISM_Settings from "./derbePages/Assert_Management_IT_Dept/MIS_Manager/Settings/Settings";

// MIS Executive MISE

import MISE_Inventroy_Control from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Inventory_Control/Inventory_Control";
import MISE_Ware_Houses from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Ware_Houses/Ware_Houses";
import MISE_Products from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Products/Products";
import MISE_Out_Of_Stock from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Products/Out_Of_Stock/Out_Of_Stock";
import MISE_Expired_Products from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Products/Expired_Products/Expired_Products";
import MISE_Product_Tax from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Products/Product_Tax/Product_Tax";
import MISE_Billing_Invoices from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Billing_Invoices/Billing_Invoices";
import MISE_Invoice_Payments from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Invoice_Payments/Invoice_Payments";
import MISE_Tax_Type from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Tax_Type/Tax_Type";
import MISE_Product_Category from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Tax_Type/Product_Category/Product_Category";
import MISE_Suppliers from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Suppliers/Suppliers";
import MISE_new_supplier from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Suppliers/new_supplier/new_supplier";
import MISE_suppliers_list from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Suppliers/suppliers_list/suppliers_list";
import MISE_Purchases from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Purchases/Purchases";
import MISE_New_Purchase from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Purchases/New_Purchase/New_Purchase";
import MISE_Purchase_List from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Purchases/Purchase_List/Purchase_List";
import MISE_Sales_Order from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Sales_Order/Sales_Order";
import MISE_Sales_Order_List from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Sales_Order/Sales_Order_List/Sales_Order_List";
import MISE_New_Order_Invoice from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Sales_Order/New_Order_Invoice/New_Order_Invoice";
import MISE_Manage_Orders from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Sales_Order/Manage_Orders/Manage_Orders";
import MISE_Paid_Orders from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Sales_Order/Paid_Orders/Paid_Orders";
import MISE_Unpaid_Orders from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Sales_Order/Unpaid_Orders/Unpaid_Orders";
import MISE_Delivered_Orders from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Sales_Order/Delivered_Orders/Delivered_Orders";
import MISE_Cancelled_Orders from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Sales_Order/Cancelled_Orders/Cancelled_Orders";
import MISE_Credit_Types from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Sales_Order/Credit_Types/Credit_Types";
import MISE_Help_Desk from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Help_Desk/Help_Desk";
import MISE_Tickets from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Help_Desk/Tickets/Tickets";
import MISE_Tickets_List from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Help_Desk/Tickets_List/Tickets_List";
import MISE_Create_Ticket from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Help_Desk/Create_Ticket/Create_Ticket";
import MISE_Support_Tickets from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Help_Desk/Support_Tickets/Support_Tickets";
import MISE_Resolved_List from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Help_Desk/Resolved_List/Resolved_List";
import MISE_Assign_Tickets_Solved from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Help_Desk/Assign_Tickets_Solve/Assign_Tickets_Solve";
import MISE_Leave_Requests from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Leave_Requests/Leave_Requests";
import MISE_Manage_Leaves from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Leave_Requests/Manage_Leaves/Manage_Leaves";
import MISE_Leaves_Type from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Leave_Requests/Leaves_Type/Leaves_Type";
import MISE_Asserts from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Asserts/asserts";
import MISE_Assets_List from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Assets_List/Assets_List";
import MISE_Assets_management from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Assets_management/Assets_management";
import MISE_Create_assert from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Assets_management/Create_assert/Create_assert";
import MISE_Assign_assert from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Assets_management/Assign_assert/Assign_assert";
import MISE_Destory_assert from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Assets_management/Destory_assert/Destory_assert";
import MISE_assert_Category from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/assert_Category/assert_Category";
import MISE_New_category from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/assert_Category/New_category/New_category";
import MISE_Manage_category from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/assert_Category/Manage_category/Manage_category";
import MISE_Asset_Brand from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Asset_Brand/Asset_Brand";
import MISE_New_Brand from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Asset_Brand/New_Brand/New_Brand";
import MISE_Manage_Brand from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Asset_Brand/Manage_Brand/Manage_Brand";
import MISE_Reports from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Reports/Reports";
import MISE_Daily from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Reports/Daily/Daily";
import MISE_Monthly from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Reports/Monthly/Monthly";
import MISE_Yearly from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Reports/Yearly/Yearly";
import MISE_Process from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Reports/Process/Process";
import MISE_Settings from "./derbePages/Assert_Management_IT_Dept/MIS_Executive/Settings/Settings";

// Process Head PH

import PH_Leave_Requests from "./derbePages/Process_Management_Dept/Process_Head/Leave_Requests/Leave_Requests";
import PH_Manage_Leaves from "./derbePages/Process_Management_Dept/Process_Head/Leave_Requests/Manage_Leaves/Manage_Leaves";
import PH_Leaves_Type from "./derbePages/Process_Management_Dept/Process_Head/Leave_Requests/Leaves_Type/Leaves_Type";
import PH_Processes from "./derbePages/Process_Management_Dept/Process_Head/Processes/Processes";
import PH_New_Process from "./derbePages/Process_Management_Dept/Process_Head/Processes/New_Process/New_Process";
import PH_Process_flow from "./derbePages/Process_Management_Dept/Process_Head/Processes/Process_flow/Process_flow";
import PH_Process_Management from "./derbePages/Process_Management_Dept/Process_Head/Processes/Process_Management/Process_Management";
import PH_Completed_Process from "./derbePages/Process_Management_Dept/Process_Head/Processes/Completed_Process/Completed_Process";
import PH_Complacencies from "./derbePages/Process_Management_Dept/Process_Head/Complacencies/Complacencies";
import PH_Process from "./derbePages/Process_Management_Dept/Process_Head/Complacencies/Process/Process";
import PH_Client from "./derbePages/Process_Management_Dept/Process_Head/Complacencies/Client/Client";
import PH_Reports from "./derbePages/Process_Management_Dept/Process_Head/Reports/Reports";
import PH_Daily from "./derbePages/Process_Management_Dept/Process_Head/Reports/Daily/Daily";
import PH_Monthly from "./derbePages/Process_Management_Dept/Process_Head/Reports/Monthly/Monthly";
import PH_Yearly from "./derbePages/Process_Management_Dept/Process_Head/Reports/Yearly/Yearly";
import PH_RProcess from "./derbePages/Process_Management_Dept/Process_Head/Reports/Process/Process";
import PH_Payments_and_Commissions from "./derbePages/Process_Management_Dept/Process_Head/Payments_Commissions/Payments_and_Commissions";
import PH_Messaging from "./derbePages/Process_Management_Dept/Process_Head/Messaging/Messaging";
import PH_Settings from "./derbePages/Process_Management_Dept/Process_Head/Settings/Settings";

// Process Manager PM

import PM_Leave_Requests from "./derbePages/Process_Management_Dept/Process_Manager/Leave_Requests/Leave_Requests";
import PM_Manage_Leaves from "./derbePages/Process_Management_Dept/Process_Manager/Leave_Requests/Manage_Leaves/Manage_Leaves";
import PM_Leaves_Type from "./derbePages/Process_Management_Dept/Process_Manager/Leave_Requests/Leaves_Type/Leaves_Type";
import PM_Processes from "./derbePages/Process_Management_Dept/Process_Manager/Processes/Processes";
import PM_New_Process from "./derbePages/Process_Management_Dept/Process_Manager/Processes/New_Process/New_Process";
import PM_Process_flow from "./derbePages/Process_Management_Dept/Process_Manager/Processes/Process_flow/Process_flow";
import PM_Process_Management from "./derbePages/Process_Management_Dept/Process_Manager/Processes/Process_Management/Process_Management";
import PM_Completed_Process from "./derbePages/Process_Management_Dept/Process_Manager/Processes/Completed_Process/Completed_Process";
import PM_Complacencies from "./derbePages/Process_Management_Dept/Process_Manager/Complacencies/Complacencies";
import PM_Process from "./derbePages/Process_Management_Dept/Process_Manager/Complacencies/Process/Process";
import PM_Client from "./derbePages/Process_Management_Dept/Process_Manager/Complacencies/Client/Client";
import PM_Reports from "./derbePages/Process_Management_Dept/Process_Manager/Reports/Reports";
import PM_Daily from "./derbePages/Process_Management_Dept/Process_Manager/Reports/Daily/Daily";
import PM_Monthly from "./derbePages/Process_Management_Dept/Process_Manager/Reports/Monthly/Monthly";
import PM_Yearly from "./derbePages/Process_Management_Dept/Process_Manager/Reports/Yearly/Yearly";
import PM_RProcess from "./derbePages/Process_Management_Dept/Process_Manager/Reports/Process/Process";
import PM_Payments_and_Commissions from "./derbePages/Process_Management_Dept/Process_Manager/Payments_Commissions/Payments_and_Commissions";
import PM_Messaging from "./derbePages/Process_Management_Dept/Process_Manager/Messaging/Messaging";
import PM_Settings from "./derbePages/Process_Management_Dept/Process_Manager/Settings/Settings";

// Team Leader Field Executives TLFE

import TLFE_Leave_Requests from "./derbePages/Process_Management_Dept/Team_Leader_Field_Executives/Leave_Requests/Leave_Requests";
import TLFE_Manage_Leaves from "./derbePages/Process_Management_Dept/Team_Leader_Field_Executives/Leave_Requests/Manage_Leaves/Manage_Leaves";
import TLFE_Leaves_Type from "./derbePages/Process_Management_Dept/Team_Leader_Field_Executives/Leave_Requests/Leaves_Type/Leaves_Type";
import TLFE_Executives from "./derbePages/Process_Management_Dept/Team_Leader_Field_Executives/Executives/Executives";
import TLFE_Assigned from "./derbePages/Process_Management_Dept/Team_Leader_Field_Executives/Executives/Assigned/Assigned";
import TLFE_Managed from "./derbePages/Process_Management_Dept/Team_Leader_Field_Executives/Executives/Managed/Managed";
import TLFE_Cases from "./derbePages/Process_Management_Dept/Team_Leader_Field_Executives/Cases/Cases";
import TLFE_cases_Assigned from "./derbePages/Process_Management_Dept/Team_Leader_Field_Executives/Cases/Assigned/Assigned";
import TLFE_cases_Managed from "./derbePages/Process_Management_Dept/Team_Leader_Field_Executives/Cases/Managed/Managed";
import TLFE_Reassigned from "./derbePages/Process_Management_Dept/Team_Leader_Field_Executives/Cases/Reassigned/Reassigned";
import TLFE_Disciplinary from "./derbePages/Process_Management_Dept/Team_Leader_Field_Executives/Disciplinary/Disciplinary";
import TLFE_Actions from "./derbePages/Process_Management_Dept/Team_Leader_Field_Executives/Disciplinary/Actions/Actions";
import TLFE_Resolutions from "./derbePages/Process_Management_Dept/Team_Leader_Field_Executives/Disciplinary/Resolutions/Resolutions";
import TLFE_Payments_and_Commissions from "./derbePages/Process_Management_Dept/Team_Leader_Field_Executives/Payments_Commissions/Payments_and_Commissions";
import TLFE_Messaging from "./derbePages/Process_Management_Dept/Team_Leader_Field_Executives/Messaging/Messaging";
import TLFE_Settings from "./derbePages/Process_Management_Dept/Team_Leader_Field_Executives/Settings/Settings";

// Team Leader Phone Bankers TLPB

import TLPB_Leave_Requests from "./derbePages/Process_Management_Dept/Team_Leader_Phone_Bankers/Leave_Requests/Leave_Requests";
import TLPB_Manage_Leaves from "./derbePages/Process_Management_Dept/Team_Leader_Phone_Bankers/Leave_Requests/Manage_Leaves/Manage_Leaves";
import TLPB_Leaves_Type from "./derbePages/Process_Management_Dept/Team_Leader_Phone_Bankers/Leave_Requests/Leaves_Type/Leaves_Type";
import TLPB_PhoneBanker from "./derbePages/Process_Management_Dept/Team_Leader_Phone_Bankers/Phone_Bankers/Phone_bankers";
import TLPB_Assigned from "./derbePages/Process_Management_Dept/Team_Leader_Phone_Bankers/Phone_Bankers/Assigned/Assigned";
import TLPB_Managed from "./derbePages/Process_Management_Dept/Team_Leader_Phone_Bankers/Phone_Bankers/Managed/Managed";
import TLPB_Cases from "./derbePages/Process_Management_Dept/Team_Leader_Phone_Bankers/Cases/Cases";
import TLPB_cases_Assigned from "./derbePages/Process_Management_Dept/Team_Leader_Phone_Bankers/Cases/Assigned/Assigned";
import TLPB_cases_Managed from "./derbePages/Process_Management_Dept/Team_Leader_Phone_Bankers/Cases/Managed/Managed";
import TLPB_Reassigned from "./derbePages/Process_Management_Dept/Team_Leader_Phone_Bankers/Cases/Reassigned/Reassigned";
import TLPB_Disciplinary from "./derbePages/Process_Management_Dept/Team_Leader_Phone_Bankers/Disciplinary/Disciplinary";
import TLPB_Actions from "./derbePages/Process_Management_Dept/Team_Leader_Phone_Bankers/Disciplinary/Actions/Actions";
import TLPB_Resolutions from "./derbePages/Process_Management_Dept/Team_Leader_Phone_Bankers/Disciplinary/Resolutions/Resolutions";
import TLPB_Payments_and_Commissions from "./derbePages/Process_Management_Dept/Team_Leader_Phone_Bankers/Payments_Commissions/Payments_and_Commissions";
import TLPB_Messaging from "./derbePages/Process_Management_Dept/Team_Leader_Phone_Bankers/Messaging/Messaging";
import TLPB_Settings from "./derbePages/Process_Management_Dept/Team_Leader_Phone_Bankers/Settings/Settings";

// Team Leader Tracers TLT

import TLT_Tracing from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Tracing/Tracing";
import TLT_Identifying from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Tracing/Identifying/Identifying";
import TLT_Search from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Tracing/Search/Search";
import TLT_Results from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Tracing/Results/Results";
import TLT_Tracers from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Tracers/Tracers";
import TLT_Assigned from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Tracers/Assigned/Assigned";
import TLT_Managed from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Tracers/Managed/Managed";
import TLT_Cases from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Cases/Cases";
import TLT_cases_Assigned from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Cases/Assigned/Assigned";
import TLT_cases_Managed from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Cases/Managed/Managed";
import TLT_Reassigned from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Cases/Reassigned/Reassigned";
import TLT_Disciplinary from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Disciplinary/Disciplinary";
import TLT_Actions from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Disciplinary/Actions/Actions";
import TLT_Resolutions from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Disciplinary/Resolutions/Resolutions";
import TLT_Payments_and_Commissions from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Payments_Commissions/Payments_and_Commissions";
import TLT_Messaging from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Messaging/Messaging";
import TLT_Leave_Requests from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Leave_Requests/Leave_Requests";
import TLT_Manage_Leaves from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Leave_Requests/Manage_Leaves/Manage_Leaves";
import TLT_Leaves_Type from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Leave_Requests/Leaves_Type/Leaves_Type";
import TLT_Settings from "./derbePages/Process_Management_Dept/Team_Leader_Tracers/Settings/Settings";

// Phone Banker PB

import PB_Cases from "./derbePages/Process_Management_Dept/Phone_Banker/Cases/Cases";
import PB_Assigned from "./derbePages/Process_Management_Dept/Phone_Banker/Cases/Assigned/Assigned";
import PB_Managed from "./derbePages/Process_Management_Dept/Phone_Banker/Cases/Managed/Managed";
import PB_Reassigned from "./derbePages/Process_Management_Dept/Phone_Banker/Cases/Reassigned/Reassigned";
import PB_Disciplinary from "./derbePages/Process_Management_Dept/Phone_Banker/Disciplinary/Disciplinary";
import PB_Actions from "./derbePages/Process_Management_Dept/Phone_Banker/Disciplinary/Actions/Actions";
import PB_Resolutions from "./derbePages/Process_Management_Dept/Phone_Banker/Disciplinary/Resolutions/Resolutions";
import PB_Messaging from "./derbePages/Process_Management_Dept/Phone_Banker/Messaging/Messaging";
import PB_Payments_and_Commissions from "./derbePages/Process_Management_Dept/Phone_Banker/Payments_Commissions/Payments_and_Commissions";
import PB_Leave_Requests from "./derbePages/Process_Management_Dept/Phone_Banker/Leave_Requests/Leave_Requests";
import PB_Manage_Leaves from "./derbePages/Process_Management_Dept/Phone_Banker/Leave_Requests/Manage_Leaves/Manage_Leaves";
import PB_Leaves_Type from "./derbePages/Process_Management_Dept/Phone_Banker/Leave_Requests/Leaves_Type/Leaves_Type";
import PB_Settings from "./derbePages/Process_Management_Dept/Phone_Banker/Settings/Settings";

// Field Executive FE

import FE_Cases from "./derbePages/Process_Management_Dept/Field_Executive/Cases/Cases";
import FE_Assigned from "./derbePages/Process_Management_Dept/Field_Executive/Cases/Assigned/Assigned";
import FE_Managed from "./derbePages/Process_Management_Dept/Field_Executive/Cases/Managed/Managed";
import FE_Reassigned from "./derbePages/Process_Management_Dept/Field_Executive/Cases/Reassigned/Reassigned";
import FE_Disciplinary from "./derbePages/Process_Management_Dept/Field_Executive/Disciplinary/Disciplinary";
import FE_Actions from "./derbePages/Process_Management_Dept/Field_Executive/Disciplinary/Actions/Actions";
import FE_Resolutions from "./derbePages/Process_Management_Dept/Field_Executive/Disciplinary/Resolutions/Resolutions";
import FE_Messaging from "./derbePages/Process_Management_Dept/Field_Executive/Messaging/Messaging";
import FE_Payments_and_Commissions from "./derbePages/Process_Management_Dept/Field_Executive/Payments_Commissions/Payments_and_Commissions";
import FE_Leave_Requests from "./derbePages/Process_Management_Dept/Field_Executive/Leave_Requests/Leave_Requests";
import FE_Manage_Leaves from "./derbePages/Process_Management_Dept/Field_Executive/Leave_Requests/Manage_Leaves/Manage_Leaves";
import FE_Leaves_Type from "./derbePages/Process_Management_Dept/Field_Executive/Leave_Requests/Leaves_Type/Leaves_Type";
import FE_Settings from "./derbePages/Process_Management_Dept/Field_Executive/Settings/Settings";

// Tracers -TC

import TC_Tracing from "./derbePages/Process_Management_Dept/Tracers/Tracing/Tracing";
import TC_Identifying from "./derbePages/Process_Management_Dept/Tracers/Tracing/Identifying/Identifying";
import TC_Search from "./derbePages/Process_Management_Dept/Tracers/Tracing/Search/Search";
import TC_Results from "./derbePages/Process_Management_Dept/Tracers/Tracing/Results/Results";
import TC_Cases from "./derbePages/Process_Management_Dept/Tracers/cases/Cases";
import TC_Assigned from "./derbePages/Process_Management_Dept/Tracers/cases/Assigned/Assigned";
import TC_Managed from "./derbePages/Process_Management_Dept/Tracers/cases/Managed/Managed";
import TC_Reassigned from "./derbePages/Process_Management_Dept/Tracers/cases/Reassigned/Reassigned";
import TC_Disciplinary from "./derbePages/Process_Management_Dept/Tracers/Disciplinary/Disciplinary";
import TC_Actions from "./derbePages/Process_Management_Dept/Tracers/Disciplinary/Actions/Actions";
import TC_Resolutions from "./derbePages/Process_Management_Dept/Tracers/Disciplinary/Resolutions/Resolutions";
import TC_Messaging from "./derbePages/Process_Management_Dept/Tracers/Messaging/Messaging";
import TC_Payments_and_Commissions from "./derbePages/Process_Management_Dept/Tracers/Payments_Commissions/Payments_and_Commissions";
import TC_Leave_Requests from "./derbePages/Process_Management_Dept/Tracers/Leave_Requests/Leave_Requests";
import TC_Manage_Leaves from "./derbePages/Process_Management_Dept/Tracers/Leave_Requests/Manage_Leaves/Manage_Leaves";
import TC_Leaves_Type from "./derbePages/Process_Management_Dept/Tracers/Leave_Requests/Leaves_Type/Leaves_Type";
import TC_Settings from "./derbePages/Process_Management_Dept/Tracers/Settings/Settings";

// Country Operations Head COH

import COH_Operations from "./derbePages/Business_Administration/Country_Operations_Head/Operations/Operations";
import COH_Resources from "./derbePages/Business_Administration/Country_Operations_Head/Resources/Resources";
import COH_Finances from "./derbePages/Business_Administration/Country_Operations_Head/Finances/Finances";
import COH_Messaging from "./derbePages/Business_Administration/Country_Operations_Head/Messaging/Messaging";
import COH_Maps from "./derbePages/Business_Administration/Country_Operations_Head/Maps/Maps";
import COH_Payments_and_Commissions from "./derbePages/Business_Administration/Country_Operations_Head/Payments_Commissions/Payments_and_Commissions";
import COH_Process_based from "./derbePages/Business_Administration/Country_Operations_Head/Payments_Commissions/Process_based/Process_based";
import COH_Total_Payments from "./derbePages/Business_Administration/Country_Operations_Head/Payments_Commissions/Total_Payments/Total_Payments";
import COH_Settings from "./derbePages/Business_Administration/Country_Operations_Head/Settings/Settings";

// Zone Operations Head ZOH

import ZOH_Operations from "./derbePages/Business_Administration/Zone_Operations_Head/Operations/Operations";
import ZOH_Resources from "./derbePages/Business_Administration/Zone_Operations_Head/Resources/Resources";
import ZOH_Finances from "./derbePages/Business_Administration/Zone_Operations_Head/Finances/Finances";
import ZOH_Messaging from "./derbePages/Business_Administration/Zone_Operations_Head/Messaging/Messaging";
import ZOH_Maps from "./derbePages/Business_Administration/Zone_Operations_Head/Maps/Maps";
import ZOH_Payments_and_Commissions from "./derbePages/Business_Administration/Zone_Operations_Head/Payments_Commissions/Payments_and_Commissions";
import ZOH_Process_based from "./derbePages/Business_Administration/Zone_Operations_Head/Payments_Commissions/Process_based/Process_based";
import ZOH_Total_Payments from "./derbePages/Business_Administration/Zone_Operations_Head/Payments_Commissions/Total_Payments/Total_Payments";
import ZOH_Settings from "./derbePages/Business_Administration/Zone_Operations_Head/Settings/Settings";

// State Operations Head SOH

import SOH_Operations from "./derbePages/Business_Administration/State_Operations_Head/Operations/Operations";
import SOH_Resources from "./derbePages/Business_Administration/State_Operations_Head/Resources/Resources";
import SOH_Finances from "./derbePages/Business_Administration/State_Operations_Head/Finances/Finances";
import SOH_Messaging from "./derbePages/Business_Administration/State_Operations_Head/Messaging/Messaging";
import SOH_Maps from "./derbePages/Business_Administration/State_Operations_Head/Maps/Maps";
import SOH_Payments_and_Commissions from "./derbePages/Business_Administration/State_Operations_Head/Payments_Commissions/Payments_and_Commissions";
import SOH_Process_based from "./derbePages/Business_Administration/State_Operations_Head/Payments_Commissions/Process_based/Process_based";
import SOH_Total_Payments from "./derbePages/Business_Administration/State_Operations_Head/Payments_Commissions/Total_Payments/Total_Payments";
import SOH_Settings from "./derbePages/Business_Administration/State_Operations_Head/Settings/Settings";

// Regional Oper Head ROH

import ROH_Operations from "./derbePages/Business_Administration/Regional_Oper_Head/Operations/Operations";
import ROH_Resources from "./derbePages/Business_Administration/Regional_Oper_Head/Resources/Resources";
import ROH_Finances from "./derbePages/Business_Administration/Regional_Oper_Head/Finances/Finances";
import ROH_Messaging from "./derbePages/Business_Administration/Regional_Oper_Head/Messaging/Messaging";
import ROH_Maps from "./derbePages/Business_Administration/Regional_Oper_Head/Maps/Maps";
import ROH_Payments_and_Commissions from "./derbePages/Business_Administration/Regional_Oper_Head/Payments_Commissions/Payments_and_Commissions";
import ROH_Process_based from "./derbePages/Business_Administration/Regional_Oper_Head/Payments_Commissions/Process_based/Process_based";
import ROH_Total_Payments from "./derbePages/Business_Administration/Regional_Oper_Head/Payments_Commissions/Total_Payments/Total_Payments";
import ROH_Settings from "./derbePages/Business_Administration/Regional_Oper_Head/Settings/Settings";

// District Head Branch Head DHBH

import DHBH_Operations from "./derbePages/Business_Administration/District_Head_Branch_Head/Operations/Operations";
import DHBH_Resources from "./derbePages/Business_Administration/District_Head_Branch_Head/Resources/Resources";
import DHBH_Finances from "./derbePages/Business_Administration/District_Head_Branch_Head/Finances/Finances";
import DHBH_Messaging from "./derbePages/Business_Administration/District_Head_Branch_Head/Messaging/Messaging";
import DHBH_Maps from "./derbePages/Business_Administration/District_Head_Branch_Head/Maps/Maps";
import DHBH_Payments_and_Commissions from "./derbePages/Business_Administration/District_Head_Branch_Head/Payments_Commissions/Payments_and_Commissions";
import DHBH_Process_based from "./derbePages/Business_Administration/District_Head_Branch_Head/Payments_Commissions/Process_based/Process_based";
import DHBH_Total_Payments from "./derbePages/Business_Administration/District_Head_Branch_Head/Payments_Commissions/Total_Payments/Total_Payments";
import DHBH_Settings from "./derbePages/Business_Administration/District_Head_Branch_Head/Settings/Settings";

// Company Owner Partner COP

import COP_Operations from "./derbePages/Business_Administration/Company_Owner_Partner/Operations/Operations";
import COP_Resources from "./derbePages/Business_Administration/Company_Owner_Partner/Resources/Resources";
import COP_Finances from "./derbePages/Business_Administration/Company_Owner_Partner/Finances/Finances";
import COP_Messaging from "./derbePages/Business_Administration/Company_Owner_Partner/Messaging/Messaging";
import COP_Maps from "./derbePages/Business_Administration/Company_Owner_Partner/Maps/Maps";
import COP_Payments_and_Commissions from "./derbePages/Business_Administration/Company_Owner_Partner/Payments_Commissions/Payments_and_Commissions";
import COP_Process_based from "./derbePages/Business_Administration/Company_Owner_Partner/Payments_Commissions/Process_based/Process_based";
import COP_Total_Payments from "./derbePages/Business_Administration/Company_Owner_Partner/Payments_Commissions/Total_Payments/Total_Payments";
import COP_Settings from "./derbePages/Business_Administration/Company_Owner_Partner/Settings/Settings";

// Country Administrator CuA

import CuA_Resources from "./derbePages/System/Country_Administrator/Resources/Resources";
import CuA_Asserts from "./derbePages/System/Country_Administrator/Resources/Asserts/Asserts";
import CuA_Users from "./derbePages/System/Country_Administrator/Resources/Users/Users";
import CuA_Country_Reports from "./derbePages/System/Country_Administrator/Country_Reports/Country_Reports";
import CuA_Settings from "./derbePages/System/Country_Administrator/Settings/Settings";

// State Administrator SA

import SA_Resources from "./derbePages/System/State_Administrator/Resources/Resources";
import SA_Asserts from "./derbePages/System/State_Administrator/Resources/Asserts/Asserts";
import SA_Users from "./derbePages/System/State_Administrator/Resources/Users/Users";
import SA_State_Reports from "./derbePages/System/State_Administrator/State_Reports/State_Reports";
import SA_Settings from "./derbePages/System/State_Administrator/Settings/Settings";

// Company Administrator CoA

import COA_Resources from "./derbePages/System/Company_Administrator/Resources/Resources";
import COA_Asserts from "./derbePages/System/Company_Administrator/Resources/Asserts/Asserts";
import COA_Users from "./derbePages/System/Company_Administrator/Resources/Users/Users";
import COA_Maps from "./derbePages/System/Company_Administrator/Maps_Tracks/Maps_Tracks";
import COA_Company_Reports from "./derbePages/System/Company_Administrator/Company_Reports/Company_Reports";
import COA_Settings from "./derbePages/System/Company_Administrator/Settings/Settings";

// System Administrator SyA

import SyA_Codes_DP from "./derbePages/System/System_Administrator/Codes_DP/Codes_DP";
import SyA_Contactable from "./derbePages/System/System_Administrator/Codes_DP/Contactable/Contactable";
import SyA_Non_Contactable from "./derbePages/System/System_Administrator/Codes_DP/Non_Contactable/Non_Contactable";
import SyA_Code_Matching_List from "./derbePages/System/System_Administrator/Codes_DP/Code_Matching_List/Code_Matching_List";
import SyA_System_Management from "./derbePages/System/System_Administrator/System_Management/System_Management";
import SyA_Settings from "./derbePages/System/System_Administrator/Settings/Settings";

// Super Administrator SuA

import SuA_Company from "./derbePages/System/Super_Administrator/Company/Company";
import SuA_Company_Details from "./derbePages/System/Super_Administrator/Company/Company_Details/Company_Details";
import SuA_Backup_company_data from "./derbePages/System/Super_Administrator/Company/Backup_company_data/Backup_company_data";
import SuA_Restore_company_data from "./derbePages/System/Super_Administrator/Company/Restore_company_data/Restore_company_data";
import SuA_Basic from "./derbePages/System/Super_Administrator/Basic/Basic";
import SuA_Currency from "./derbePages/System/Super_Administrator/Basic/Currency/Currency";
import SuA_Language from "./derbePages/System/Super_Administrator/Basic/Language/Language";
import SuA_Taxes from "./derbePages/System/Super_Administrator/Basic/Taxes/Taxes";
import SuA_System from "./derbePages/System/Super_Administrator/System/System";
import SuA_Log from "./derbePages/System/Super_Administrator/System/Log/Log";
import SuA_Location_Log from "./derbePages/System/Super_Administrator/System/Location_Log/Location_Log";
import SuA_Backup from "./derbePages/System/Super_Administrator/System/Backup/Backup";
import SuA_restore from "./derbePages/System/Super_Administrator/System/restore/restore";
import SuA_Company_based_Reports from "./derbePages/System/Super_Administrator/Company_Reports/Company_Reports";
import SuA_Service_Reports from "./derbePages/System/Super_Administrator/Company_Reports/Service_Reports/Service_Reports";
import SuA_Maintenance_Reports from "./derbePages/System/Super_Administrator/Company_Reports/Maintenance_Reports/Maintenance_Reports";
import SuA_Settings from "./derbePages/System/Super_Administrator/Settings/Settings";

/************************************************* */

const Routes = [
  // {
  //     path: "/",
  //     name: 'login',
  //     exact: true,
  //     pageTitle: "Login",
  //     component: Login
  // },
  {
    path: "/",
    name: "dashboard",
    exact: true,
    pageTitle: "HR Dashboard",
    component: Dashboard,
  },
  {
    path: "/hr-users",
    name: "hr-users",
    exact: true,
    pageTitle: "Users",
    component: Users,
  },
  {
    path: "/hr-department",
    name: "department",
    exact: true,
    pageTitle: "Departments",
    component: Departments,
  },
  {
    path: "/hr-employee",
    name: "employee",
    exact: true,
    pageTitle: "Employee",
    component: Employee,
  },
  {
    path: "/hr-activities",
    name: "activities",
    exact: true,
    pageTitle: "Activities",
    component: Activities,
  },
  {
    path: "/hr-holidays",
    name: "holidays",
    exact: true,
    pageTitle: "Holidays",
    component: Holidays,
  },
  {
    path: "/hr-events",
    name: "events",
    exact: true,
    pageTitle: "Events",
    component: Events,
  },
  {
    path: "/hr-payroll",
    name: "payroll",
    exact: true,
    pageTitle: "Payroll",
    component: Payroll,
  },
  {
    path: "/hr-accounts",
    name: "accounts",
    exact: true,
    pageTitle: "Accounts",
    component: Accounts,
  },
  {
    path: "/hr-report",
    name: "report",
    exact: true,
    pageTitle: "Report",
    component: Report,
  },
  // add new routes here

  //project

  {
    path: "/project-dashboard",
    name: "projectDashboard",
    exact: true,
    pageTitle: "'Project Dashboard",
    component: ProjectDashboard,
  },
  {
    path: "/project-list",
    name: "project-list",
    exact: true,
    pageTitle: "Project",
    component: ProjectList,
  },

  {
    path: "/project-taskboard",
    name: "project-taskboard",
    exact: true,
    pageTitle: "Taskboard",
    component: Taskboard,
  },

  {
    path: "/project-ticket",
    name: "project-ticket",
    exact: true,
    pageTitle: "Ticket List",
    component: TicketList,
  },

  {
    path: "/project-ticket-details",
    name: "project-ticket-details",
    exact: true,
    pageTitle: "Ticket Details",
    component: TicketDetails,
  },
  {
    path: "/project-clients",
    name: "project-clients",
    exact: true,
    pageTitle: "Clients",
    component: Clients,
  },

  {
    path: "/project-todo",
    name: "project-todo",
    exact: true,
    pageTitle: "Todo List",
    component: TodoList,
  },

  //job portal

  {
    path: "/jobportal-dashboard",
    name: "jobportalDashboard",
    exact: true,
    pageTitle: "Job Dashboard",
    component: JobPortalDashboard,
  },
  {
    path: "/jobportal-positions",
    name: "jobportalPositions",
    exact: true,
    pageTitle: "Job Positions",
    component: Positions,
  },
  {
    path: "/jobportal-applicants",
    name: "jobportalpplicants",
    exact: true,
    pageTitle: "Job Applicants",
    component: Applicants,
  },
  {
    path: "/jobportal-resumes",
    name: "jobportalResumes",
    exact: true,
    pageTitle: "Job Resumes",
    component: Resumes,
  },
  {
    path: "/jobportal-settings",
    name: "jobportalSettings",
    exact: true,
    pageTitle: "Job Settings",
    component: Settings,
  },

  {
    path: "/login",
    name: "login",
    exact: true,
    pageTitle: "Tables",
    component: Login,
  },
  {
    path: "/signup",
    name: "signup",
    exact: true,
    pageTitle: "Tables",
    component: Signup,
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    exact: true,
    pageTitle: "Tables",
    component: ForgotPassword,
  },
  {
    path: "/notfound",
    name: "notfound",
    exact: true,
    pageTitle: "Tables",
    component: NotFound,
  },
  {
    path: "/internalserver",
    name: "internalserver",
    exact: true,
    pageTitle: "Tables",
    component: InternalServer,
  },
  {
    path: "/icons",
    name: "icons",
    exact: true,
    pageTitle: "Icons",
    component: Icons,
  },
  {
    path: "/icons-feather",
    name: "icons-feather",
    exact: true,
    pageTitle: "Icons",
    component: IconsFeather,
  },
  {
    path: "/icons-line",
    name: "icons-line",
    exact: true,
    pageTitle: "Icons",
    component: IconsLine,
  },
  {
    path: "/icons-flag",
    name: "icons-flag",
    exact: true,
    pageTitle: "Icons",
    component: IconsFlags,
  },
  {
    path: "/icons-payments",
    name: "icons-payments",
    exact: true,
    pageTitle: "Icons",
    component: IconsPayments,
  },
  {
    path: "/charts",
    name: "charts",
    exact: true,
    pageTitle: "Charts",
    component: Charts,
  },
  {
    path: "/charts-e",
    name: "charts-e",
    exact: true,
    pageTitle: "Charts",
    component: ChartsE,
  },
  {
    path: "/charts-c3",
    name: "charts-c3",
    exact: true,
    pageTitle: "Charts",
    component: ChartsC3,
  },
  {
    path: "/charts-knob",
    name: "charts-knob",
    exact: true,
    pageTitle: "Charts",
    component: ChartsKnob,
  },
  {
    path: "/charts-sparkline",
    name: "charts-sparkline",
    exact: true,
    pageTitle: "Charts",
    component: ChartsSparkline,
  },

  {
    path: "/forms",
    name: "forms",
    exact: true,
    pageTitle: "Forms Elements",
    component: Forms,
  },
  {
    path: "/form-advanced",
    name: "form-advanced",
    exact: true,
    pageTitle: "Forms Elements",
    component: FormAdvanced,
  },
  {
    path: "/form-validation",
    name: "form-validation",
    exact: true,
    pageTitle: "Forms Elements",
    component: FormValidation,
  },
  {
    path: "/form-wizard",
    name: "form-wizard",
    exact: true,
    pageTitle: "Forms Elements",
    component: FormWizard,
  },
  {
    path: "/form-summernote",
    name: "form-summernote",
    exact: true,
    pageTitle: "Forms Elements",
    component: FormSummernote,
  },

  {
    path: "/tables",
    name: "tables",
    exact: true,
    pageTitle: "Tables",
    component: Tables,
  },
  {
    path: "/tables-datatable",
    name: "tables-datatable",
    exact: true,
    pageTitle: "Tables",
    component: DataTables,
  },
  {
    path: "/tables-color",
    name: "tables-color",
    exact: true,
    pageTitle: "Tables",
    component: TablesColor,
  },
  {
    path: "/tables-basic",
    name: "tables-basic",
    exact: true,
    pageTitle: "Tables",
    component: TablesBasic,
  },

  {
    path: "/widgets",
    name: "widgets",
    exact: true,
    pageTitle: "Widgets",
    component: Widgets,
  },
  {
    path: "/w-card",
    name: "w-card",
    exact: true,
    pageTitle: "Widgets",
    component: WCard,
  },
  {
    path: "/w-statistics",
    name: "w-statistics",
    exact: true,
    pageTitle: "Widgets",
    component: WStatistics,
  },
  {
    path: "/w-data",
    name: "w-data",
    exact: true,
    pageTitle: "Widgets",
    component: WData,
  },
  {
    path: "/w-social",
    name: "w-social",
    exact: true,
    pageTitle: "Widgets",
    component: WSocial,
  },
  {
    path: "/w-other",
    name: "w-other",
    exact: true,
    pageTitle: "Widgets",
    component: WOther,
  },
  {
    path: "/page-search",
    name: "page-search",
    exact: true,
    pageTitle: "Search",
    component: Search,
  },
  {
    path: "/app-calendar",
    name: "app-calendar",
    exact: true,
    pageTitle: "Calendar",
    component: AppCalendar,
  },
  {
    path: "/app-contact",
    name: "app-contact",
    exact: true,
    pageTitle: "Contact",
    component: AppContact,
  },
  {
    path: "/app-chat",
    name: "app-chat",
    exact: true,
    pageTitle: "Friends Group",
    component: AppChart,
  },
  {
    path: "/app-filemanager",
    name: "app-filemanager",
    exact: true,
    pageTitle: "File Manager",
    component: AppFilemanager,
  },
  {
    path: "/app-setting",
    name: "app-setting",
    exact: true,
    pageTitle: "App Setting",
    component: AppSetting,
  },
  {
    path: "/profile",
    name: "profile",
    exact: true,
    pageTitle: "My Profile",
    component: Profile,
  },
  {
    path: "/maps",
    name: "maps",
    exact: true,
    pageTitle: "Vector Maps",
    component: Maps,
  },
  {
    path: "/gallery",
    name: "gallery",
    exact: true,
    pageTitle: "Image Gallery",
    component: Gallery,
  },
  // derbe pages path
  {
    path: "/hrm-employees",
    name: "hrm-employees",
    exact: true,
    pageTitle: "HRM Employees",
    component: HRM_Employees,
  },
  {
    path: "/hrm-employees-list",
    name: "hrm-employees-list",
    exact: true,
    pageTitle: "HRM Employees List",
    component: HRM_Employees_List,
  },
  {
    path: "/hrm-roles-privileges",
    name: "hrm-roles-privileges",
    exact: true,
    pageTitle: "HRM Roles Privileges",
    component: HRM_Roles_Privileges,
  },
  {
    path: "/hrm-shift-scheduling",
    name: "hrm-shift-scheduling",
    exact: true,
    pageTitle: "HRM Shift Scheduling",
    component: HRM_Shift_Scheduling,
  },
  {
    path: "/hrm-department",
    name: "hrm-department",
    exact: true,
    pageTitle: "HRM Department",
    component: HRM_Department,
  },
  {
    path: "/hrm-department-list",
    name: "hrm-department-list",
    exact: true,
    pageTitle: "HRM Department list",
    component: HRM_Department_list,
  },
  {
    path: "/hrm-designation-list",
    name: "hrm-designation-list",
    exact: true,
    pageTitle: "HRM Designation list",
    component: HRM_Designation_list,
  },
  {
    path: "/hrm-resignations",
    name: "hrm-resignations",
    exact: true,
    pageTitle: "HRM Resignations",
    component: HRM_Resignations,
  },
  {
    path: "/hrm-create-resignations",
    name: "hrm-create-resignations",
    exact: true,
    pageTitle: "HRM Create Resignations",
    component: HRM_Create_Resignations,
  },
  {
    path: "/hrm-approve-resignations",
    name: "hrm-approve-resignations",
    exact: true,
    pageTitle: "HRM Approve Resignations",
    component: HRM_Approve_Resignations,
  },
  {
    path: "/hrm-resignation-list",
    name: "hrm-resignation-list",
    exact: true,
    pageTitle: "HRM Resignation List",
    component: HRM_Resignation_List,
  },
  {
    path: "/hrm-employees-exit",
    name: "hrm-employees-exit",
    exact: true,
    pageTitle: "HRM Employees Exit",
    component: HRM_Employees_Exit,
  },
  {
    path: "/hrm-exit-types",
    name: "hrm-exit-types",
    exact: true,
    pageTitle: "HRM Exit Types",
    component: HRM_Exit_Types,
  },
  {
    path: "/hrm-exit-clearance-certificates",
    name: "hrm-exit-clearance-certificates",
    exact: true,
    pageTitle: "HRM Exit Clearance Certificates",
    component: HRM_Exit_Clearance_Certificates,
  },
  {
    path: "/hrm-transfers",
    name: "hrm-transfers",
    exact: true,
    pageTitle: "HRM Transfers",
    component: HRM_Transfers,
  },
  {
    path: "/hrm-transfer-list",
    name: "hrm-transfer-list",
    exact: true,
    pageTitle: "HRM Transfer List",
    component: HRM_Transfer_List,
  },
  {
    path: "/hrm-approve-transfers",
    name: "hrm-approve-transfers",
    exact: true,
    pageTitle: "HRM Approve Transfers",
    component: HRM_Approve_Transfers,
  },
  {
    path: "/hrm-new-transfers",
    name: "hrm-new-transfers",
    exact: true,
    pageTitle: "HRM New Transfers",
    component: HRM_New_Transfers,
  },
  {
    path: "/hrm-complaints",
    name: "hrm-complaints",
    exact: true,
    pageTitle: "HRM Complaints",
    component: HRM_Complaints,
  },
  {
    path: "/hrm-lodge-complaint",
    name: "hrm-lodge-complaint",
    exact: true,
    pageTitle: "HRM Lodge Complaint",
    component: HRM_Lodge_Complaint,
  },
  {
    path: "/hrm-complaint-list",
    name: "hrm-complaint-list",
    exact: true,
    pageTitle: "HRM Complaint List",
    component: HRM_Complaint_List,
  },
  {
    path: "/hrm-resolved-list",
    name: "hrm-resolved-list",
    exact: true,
    pageTitle: "HRM Resolved List",
    component: HRM_Resolved_List,
  },
  {
    path: "/hrm-awards",
    name: "hrm-awards",
    exact: true,
    pageTitle: "HRM Awards",
    component: HRM_Awards,
  },
  {
    path: "/hrm-awards",
    name: "hrm-awards",
    exact: true,
    pageTitle: "HRM awards",
    component: HRM_awards,
  },
  {
    path: "/hrm-award-assign-list",
    name: "hrm-award-assign-list",
    exact: true,
    pageTitle: "HRM award assign list",
    component: HRM_award_assign_list,
  },
  {
    path: "/hrm-award-type",
    name: "hrm-award-type",
    exact: true,
    pageTitle: "HRM award Type",
    component: HRM_award_Type,
  },
  {
    path: "/hrm-activities",
    name: "hrm-activities",
    exact: true,
    pageTitle: "HRM Activities",
    component: HRM_Activities,
  },
  {
    path: "/hrm-travels",
    name: "hrm-travels",
    exact: true,
    pageTitle: "HRM Travels",
    component: HRM_Travels,
  },
  {
    path: "/hrm-new-business-travel",
    name: "hrm-new-business-travel",
    exact: true,
    pageTitle: "HRM New Business Travel",
    component: HRM_New_Business_Travel,
  },
  {
    path: "/hrm-travel-arrangement-type",
    name: "hrm-travel-arrangement-type",
    exact: true,
    pageTitle: "HRM Travel Arrangement Type",
    component: HRM_Travel_Arrangement_Type,
  },
  {
    path: "/hrm-transport-type",
    name: "hrm-transport-type",
    exact: true,
    pageTitle: "HRM Transport Type",
    component: HRM_Transport_Type,
  },
  {
    path: "/hrm-payments",
    name: "hrm-payments",
    exact: true,
    pageTitle: "HRM Payments",
    component: HRM_Payments,
  },
  {
    path: "/hrm-travel-management",
    name: "hrm-travel-management",
    exact: true,
    pageTitle: "HRM Travel Management",
    component: HRM_Travel_Management,
  },
  {
    path: "/hrm-events",
    name: "hrm-events",
    exact: true,
    pageTitle: "HRM Events",
    component: HRM_Events,
  },
  {
    path: "/hrm-new-event",
    name: "hrm-new-event",
    exact: true,
    pageTitle: "HRM New Event",
    component: HRM_New_Event,
  },
  {
    path: "/hrm-event-types",
    name: "hrm-event-types",
    exact: true,
    pageTitle: "HRM Event Types",
    component: HRM_Event_Types,
  },
  {
    path: "/hrm-holidays",
    name: "hrm-holidays",
    exact: true,
    pageTitle: "HRM Holidays",
    component: HRM_Holidays,
  },
  {
    path: "/hrm-new-holiday",
    name: "hrm-new-holiday",
    exact: true,
    pageTitle: "HRM New Holiday",
    component: HRM_New_Holiday,
  },
  {
    path: "/hrm-holiday-list",
    name: "hrm-holiday-list",
    exact: true,
    pageTitle: "HRM Holiday list",
    component: HRM_Holiday_list,
  },
  {
    path: "/hrm-visitors",
    name: "hrm-visitors",
    exact: true,
    pageTitle: "HRM Visitors",
    component: HRM_Visitors,
  },
  {
    path: "/hrm-new-visitor",
    name: "hrm-new-visitor",
    exact: true,
    pageTitle: "HRM New visitor",
    component: HRM_New_visitor,
  },
  {
    path: "/hrm-visitors-list",
    name: "hrm-visitors-list",
    exact: true,
    pageTitle: "HRM Visitors List",
    component: HRM_Visitors_List,
  },
  {
    path: "/hrm-conferences",
    name: "hrm-conferences",
    exact: true,
    pageTitle: "HRM Conferences",
    component: HRM_Conferences,
  },
  {
    path: "/hrm-conf-booking",
    name: "hrm-conf-booking",
    exact: true,
    pageTitle: "HRM Conf booking",
    component: HRM_Conf_booking,
  },
  {
    path: "/hrm-meeting-list",
    name: "hrm-meeting-list",
    exact: true,
    pageTitle: "HRM Meeting List",
    component: HRM_Meeting_List,
  },
  {
    path: "/hrm-documents-manager",
    name: "hrm-documents-manager",
    exact: true,
    pageTitle: "HRM Documents Manager",
    component: HRM_Documents_Manager,
  },
  {
    path: "/hrm-general",
    name: "hrm-general",
    exact: true,
    pageTitle: "HRM general",
    component: HRM_general,
  },
  {
    path: "/hrm-uploading-files",
    name: "hrm-uploading-files",
    exact: true,
    pageTitle: "HRM Uploading files",
    component: HRM_Uploading_files,
  },
  {
    path: "/hrm-managing-files",
    name: "hrm-managing-files",
    exact: true,
    pageTitle: "HRM Managing Files",
    component: HRM_Managing_Files,
  },
  {
    path: "/hrm-official",
    name: "hrm-official",
    exact: true,
    pageTitle: "HRM official",
    component: HRM_official,
  },
  {
    path: "/hrm-uploading-files",
    name: "hrm-uploading-files",
    exact: true,
    pageTitle: "HRM Uploading files",
    component: HRM_Uploading_files,
  },
  {
    path: "/hrm-managing-files",
    name: "hrm-managing-files",
    exact: true,
    pageTitle: "HRM Managing Files",
    component: HRM_Managing_Files,
  },
  {
    path: "/hrm-process-documents",
    name: "hrm-process-documents",
    exact: true,
    pageTitle: "HRM Process Documents",
    component: HRM_Process_Documents,
  },
  {
    path: "/hrm-uploading-files",
    name: "hrm-uploading-files",
    exact: true,
    pageTitle: "HRM Uploading files",
    component: HRM_Uploading_files,
  },
  {
    path: "/hrm-managing-files",
    name: "hrm-managing-files",
    exact: true,
    pageTitle: "HRM Managing Files",
    component: HRM_Managing_Files,
  },
  {
    path: "/hrm-performance-pms",
    name: "hrm-performance-pms",
    exact: true,
    pageTitle: "HRM Performance PMS",
    component: HRM_Performance_PMS,
  },
  {
    path: "/hrm-kpi-indicators",
    name: "hrm-kpi-indicators",
    exact: true,
    pageTitle: "HRM KPI indicators",
    component: HRM_KPI_indicators,
  },
  {
    path: "/hrm-kpa-appraisals",
    name: "hrm-kpa-appraisals",
    exact: true,
    pageTitle: "HRM KPA Appraisals",
    component: HRM_KPA_Appraisals,
  },
  {
    path: "/hrm-okrs-tracking-goals",
    name: "hrm-okrs-tracking-goals",
    exact: true,
    pageTitle: "HRM OKRs Tracking Goals",
    component: HRM_OKRs_Tracking_Goals,
  },
  {
    path: "/hrm-competencies",
    name: "hrm-competencies",
    exact: true,
    pageTitle: "HRM Competencies",
    component: HRM_Competencies,
  },
  {
    path: "/hrm-technical",
    name: "hrm-technical",
    exact: true,
    pageTitle: "HRM Technical",
    component: HRM_Technical,
  },
  {
    path: "/hrm-organizational",
    name: "hrm-organizational",
    exact: true,
    pageTitle: "HRM Organizational",
    component: HRM_Organizational,
  },
  {
    path: "/hrm-goal-types",
    name: "hrm-goal-types",
    exact: true,
    pageTitle: "HRM Goal Types",
    component: HRM_Goal_Types,
  },
  {
    path: "/hrm-goal-calendar",
    name: "hrm-goal-calendar",
    exact: true,
    pageTitle: "HRM Goal Calendar",
    component: HRM_Goal_Calendar,
  },
  {
    path: "/hrm-recruitment-ats",
    name: "hrm-recruitment-ats",
    exact: true,
    pageTitle: "HRM Recruitment ATS",
    component: HRM_Recruitment_ATS,
  },
  {
    path: "/hrm-new-postings",
    name: "hrm-new-postings",
    exact: true,
    pageTitle: "HRM New postings",
    component: HRM_New_postings,
  },
  {
    path: "/hrm-candidate-list",
    name: "hrm-candidate-list",
    exact: true,
    pageTitle: "HRM Candidate List",
    component: HRM_Candidate_List,
  },
  {
    path: "/hrm-interviews",
    name: "hrm-interviews",
    exact: true,
    pageTitle: "HRM Interviews",
    component: HRM_Interviews,
  },
  {
    path: "/hrm-interviews",
    name: "hrm-interviews",
    exact: true,
    pageTitle: "HRM Interviews",
    component: HRM_Interviews,
  },
  {
    path: "/hrm-interviewers-list",
    name: "hrm-interviewers-list",
    exact: true,
    pageTitle: "HRM Interviewers List",
    component: HRM_Interviewers_List,
  },
  {
    path: "/hrm-attendance",
    name: "hrm-attendance",
    exact: true,
    pageTitle: "HRM Attendance",
    component: HRM_Attendance,
  },
  {
    path: "/hrm-attendance",
    name: "hrm-attendance",
    exact: true,
    pageTitle: "HRM Attendance",
    component: HRM_Attendance,
  },
  {
    path: "/hrm-manual-attendance",
    name: "hrm-manual-attendance",
    exact: true,
    pageTitle: "HRM Manual Attendance",
    component: HRM_Manual_Attendance,
  },
  {
    path: "/hrm-monthly-report",
    name: "hrm-monthly-report",
    exact: true,
    pageTitle: "HRM Monthly Report",
    component: HRM_Monthly_Report,
  },
  {
    path: "/hrm-overtime-request",
    name: "hrm-overtime-request",
    exact: true,
    pageTitle: "HRM Overtime Request",
    component: HRM_Overtime_Request,
  },
  {
    path: "/hrm-policies",
    name: "hrm-policies",
    exact: true,
    pageTitle: "HRM Policies",
    component: HRM_Policies,
  },
  {
    path: "/hrm-create-new-policy",
    name: "hrm-create-new-policy",
    exact: true,
    pageTitle: "HRM Create new Policy",
    component: HRM_Create_new_Policy,
  },
  {
    path: "/hrm-policy-list",
    name: "hrm-policy-list",
    exact: true,
    pageTitle: "HRM Policy list",
    component: HRM_Policy_list,
  },
  {
    path: "/hrm-make-announcement",
    name: "hrm-make-announcement",
    exact: true,
    pageTitle: "HRM Make Announcement",
    component: HRM_Make_Announcement,
  },
  {
    path: "/hrm-new-announcement",
    name: "hrm-new-announcement",
    exact: true,
    pageTitle: "HRM New Announcement",
    component: HRM_New_Announcement,
  },
  {
    path: "/hrm-announcement-list",
    name: "hrm-announcement-list",
    exact: true,
    pageTitle: "HRM Announcement List",
    component: HRM_Announcement_List,
  },
  {
    path: "/hrm-organisation-chart",
    name: "hrm-organisation-chart",
    exact: true,
    pageTitle: "HRM Organisation Chart",
    component: HRM_Organisation_Chart,
  },
  {
    path: "/hrm-view",
    name: "hrm-view",
    exact: true,
    pageTitle: "HRM View",
    component: HRM_View,
  },
  {
    path: "/hrm-create-chart",
    name: "hrm-create-chart",
    exact: true,
    pageTitle: "HRM Create Chart",
    component: HRM_Create_Chart,
  },
  {
    path: "/hrm-leave-requests",
    name: "hrm-leave-requests",
    exact: true,
    pageTitle: "HRM Leave Requests",
    component: HRM_Leave_Requests,
  },
  {
    path: "/hrm-manage-leaves",
    name: "hrm-manage-leaves",
    exact: true,
    pageTitle: "HRM Manage Leaves",
    component: HRM_Manage_Leaves,
  },
  {
    path: "/hrm-leaves-type",
    name: "hrm-leaves-type",
    exact: true,
    pageTitle: "HRM Leaves Type",
    component: HRM_Leaves_Type,
  },
  {
    path: "/hrm-settings",
    name: "hrm-settings",
    exact: true,
    pageTitle: "HRM Settings",
    component: HRM_Settings,
  },
  {
    path: "/hre-employees",
    name: "hre-employees",
    exact: true,
    pageTitle: "HRE Employees",
    component: HRE_Employees,
  },
  {
    path: "/hre-employees-list",
    name: "hre-employees-list",
    exact: true,
    pageTitle: "HRE Employees List",
    component: HRE_Employees_List,
  },
  {
    path: "/hre-roles-privileges",
    name: "hre-roles-privileges",
    exact: true,
    pageTitle: "HRE Roles Privileges",
    component: HRE_Roles_Privileges,
  },
  {
    path: "/hre-shift-scheduling",
    name: "hre-shift-scheduling",
    exact: true,
    pageTitle: "HRE Shift Scheduling",
    component: HRE_Shift_Scheduling,
  },
  {
    path: "/hre-department",
    name: "hre-department",
    exact: true,
    pageTitle: "HRE Department",
    component: HRE_Department,
  },
  {
    path: "/hre-department-list",
    name: "hre-department-list",
    exact: true,
    pageTitle: "HRE Department list",
    component: HRE_Department_list,
  },
  {
    path: "/hre-designation-list",
    name: "hre-designation-list",
    exact: true,
    pageTitle: "HRE Designation list",
    component: HRE_Designation_list,
  },
  {
    path: "/hre-resignations",
    name: "hre-resignations",
    exact: true,
    pageTitle: "HRE Resignations",
    component: HRE_Resignations,
  },
  {
    path: "/hre-create-resignations",
    name: "hre-create-resignations",
    exact: true,
    pageTitle: "HRE Create Resignations",
    component: HRE_Create_Resignations,
  },
  {
    path: "/hre-approve-resignations",
    name: "hre-approve-resignations",
    exact: true,
    pageTitle: "HRE Approve Resignations",
    component: HRE_Approve_Resignations,
  },
  {
    path: "/hre-resignation-list",
    name: "hre-resignation-list",
    exact: true,
    pageTitle: "HRE Resignation List",
    component: HRE_Resignation_List,
  },
  {
    path: "/hre-employees-exit",
    name: "hre-employees-exit",
    exact: true,
    pageTitle: "HRE Employees Exit",
    component: HRE_Employees_Exit,
  },
  {
    path: "/hre-exit-types",
    name: "hre-exit-types",
    exact: true,
    pageTitle: "HRE Exit Types",
    component: HRE_Exit_Types,
  },
  {
    path: "/hre-exit-clearance-certificates",
    name: "hre-exit-clearance-certificates",
    exact: true,
    pageTitle: "HRE Exit Clearance Certificates",
    component: HRE_Exit_Clearance_Certificates,
  },
  {
    path: "/hre-transfers",
    name: "hre-transfers",
    exact: true,
    pageTitle: "HRE Transfers",
    component: HRE_Transfers,
  },
  {
    path: "/hre-transfer-list",
    name: "hre-transfer-list",
    exact: true,
    pageTitle: "HRE Transfer List",
    component: HRE_Transfer_List,
  },
  {
    path: "/hre-approve-transfers",
    name: "hre-approve-transfers",
    exact: true,
    pageTitle: "HRE Approve Transfers",
    component: HRE_Approve_Transfers,
  },
  {
    path: "/hre-new-transfers",
    name: "hre-new-transfers",
    exact: true,
    pageTitle: "HRE New Transfers",
    component: HRE_New_Transfers,
  },
  {
    path: "/hre-complaints",
    name: "hre-complaints",
    exact: true,
    pageTitle: "HRE Complaints",
    component: HRE_Complaints,
  },
  {
    path: "/hre-lodge-complaint",
    name: "hre-lodge-complaint",
    exact: true,
    pageTitle: "HRE Lodge Complaint",
    component: HRE_Lodge_Complaint,
  },
  {
    path: "/hre-complaint-list",
    name: "hre-complaint-list",
    exact: true,
    pageTitle: "HRE Complaint List",
    component: HRE_Complaint_List,
  },
  {
    path: "/hre-resolved-list",
    name: "hre-resolved-list",
    exact: true,
    pageTitle: "HRE Resolved List",
    component: HRE_Resolved_List,
  },
  {
    path: "/hre-awards",
    name: "hre-awards",
    exact: true,
    pageTitle: "HRE Awards",
    component: HRE_Awards,
  },
  {
    path: "/hre-awards",
    name: "hre-awards",
    exact: true,
    pageTitle: "HRE awards",
    component: HRE_awards,
  },
  {
    path: "/hre-award-assign-list",
    name: "hre-award-assign-list",
    exact: true,
    pageTitle: "HRE award assign list",
    component: HRE_award_assign_list,
  },
  {
    path: "/hre-award-type",
    name: "hre-award-type",
    exact: true,
    pageTitle: "HRE award Type",
    component: HRE_award_Type,
  },
  {
    path: "/hre-activities",
    name: "hre-activities",
    exact: true,
    pageTitle: "HRE Activities",
    component: HRE_Activities,
  },
  {
    path: "/hre-travels",
    name: "hre-travels",
    exact: true,
    pageTitle: "HRE Travels",
    component: HRE_Travels,
  },
  {
    path: "/hre-new-business-travel",
    name: "hre-new-business-travel",
    exact: true,
    pageTitle: "HRE New Business Travel",
    component: HRE_New_Business_Travel,
  },
  {
    path: "/hre-travel-arrangement-type",
    name: "hre-travel-arrangement-type",
    exact: true,
    pageTitle: "HRE Travel Arrangement Type",
    component: HRE_Travel_Arrangement_Type,
  },
  {
    path: "/hre-transport-type",
    name: "hre-transport-type",
    exact: true,
    pageTitle: "HRE Transport Type",
    component: HRE_Transport_Type,
  },
  {
    path: "/hre-payments",
    name: "hre-payments",
    exact: true,
    pageTitle: "HRE Payments",
    component: HRE_Payments,
  },
  {
    path: "/hre-travel-management",
    name: "hre-travel-management",
    exact: true,
    pageTitle: "HRE Travel Management",
    component: HRE_Travel_Management,
  },
  {
    path: "/hre-events",
    name: "hre-events",
    exact: true,
    pageTitle: "HRE Events",
    component: HRE_Events,
  },
  {
    path: "/hre-new-event",
    name: "hre-new-event",
    exact: true,
    pageTitle: "HRE New Event",
    component: HRE_New_Event,
  },
  {
    path: "/hre-event-types",
    name: "hre-event-types",
    exact: true,
    pageTitle: "HRE Event Types",
    component: HRE_Event_Types,
  },
  {
    path: "/hre-holidays",
    name: "hre-holidays",
    exact: true,
    pageTitle: "HRE Holidays",
    component: HRE_Holidays,
  },
  {
    path: "/hre-new-holiday",
    name: "hre-new-holiday",
    exact: true,
    pageTitle: "HRE New Holiday",
    component: HRE_New_Holiday,
  },
  {
    path: "/hre-holiday-list",
    name: "hre-holiday-list",
    exact: true,
    pageTitle: "HRE Holiday list",
    component: HRE_Holiday_list,
  },
  {
    path: "/hre-visitors",
    name: "hre-visitors",
    exact: true,
    pageTitle: "HRE Visitors",
    component: HRE_Visitors,
  },
  {
    path: "/hre-new-visitor",
    name: "hre-new-visitor",
    exact: true,
    pageTitle: "HRE New visitor",
    component: HRE_New_visitor,
  },
  {
    path: "/hre-visitors-list",
    name: "hre-visitors-list",
    exact: true,
    pageTitle: "HRE Visitors List",
    component: HRE_Visitors_List,
  },
  {
    path: "/hre-conferences",
    name: "hre-conferences",
    exact: true,
    pageTitle: "HRE Conferences",
    component: HRE_Conferences,
  },
  {
    path: "/hre-conf-booking",
    name: "hre-conf-booking",
    exact: true,
    pageTitle: "HRE Conf booking",
    component: HRE_Conf_booking,
  },
  {
    path: "/hre-meeting-list",
    name: "hre-meeting-list",
    exact: true,
    pageTitle: "HRE Meeting List",
    component: HRE_Meeting_List,
  },
  {
    path: "/hre-documents-manager",
    name: "hre-documents-manager",
    exact: true,
    pageTitle: "HRE Documents Manager",
    component: HRE_Documents_Manager,
  },
  {
    path: "/hre-general",
    name: "hre-general",
    exact: true,
    pageTitle: "HRE general",
    component: HRE_general,
  },
  {
    path: "/hre-uploading-files",
    name: "hre-uploading-files",
    exact: true,
    pageTitle: "HRE Uploading files",
    component: HRE_Uploading_files,
  },
  {
    path: "/hre-managing-files",
    name: "hre-managing-files",
    exact: true,
    pageTitle: "HRE Managing Files",
    component: HRE_Managing_Files,
  },
  {
    path: "/hre-official",
    name: "hre-official",
    exact: true,
    pageTitle: "HRE official",
    component: HRE_official,
  },
  {
    path: "/hre-uploading-files",
    name: "hre-uploading-files",
    exact: true,
    pageTitle: "HRE Uploading files",
    component: HRE_Uploading_files,
  },
  {
    path: "/hre-managing-files",
    name: "hre-managing-files",
    exact: true,
    pageTitle: "HRE Managing Files",
    component: HRE_Managing_Files,
  },
  {
    path: "/hre-process-documents",
    name: "hre-process-documents",
    exact: true,
    pageTitle: "HRE Process Documents",
    component: HRE_Process_Documents,
  },
  {
    path: "/hre-uploading-files",
    name: "hre-uploading-files",
    exact: true,
    pageTitle: "HRE Uploading files",
    component: HRE_Uploading_files,
  },
  {
    path: "/hre-managing-files",
    name: "hre-managing-files",
    exact: true,
    pageTitle: "HRE Managing Files",
    component: HRE_Managing_Files,
  },
  {
    path: "/hre-performance-pms",
    name: "hre-performance-pms",
    exact: true,
    pageTitle: "HRE Performance PMS",
    component: HRE_Performance_PMS,
  },
  {
    path: "/hre-kpi-indicators",
    name: "hre-kpi-indicators",
    exact: true,
    pageTitle: "HRE KPI indicators",
    component: HRE_KPI_indicators,
  },
  {
    path: "/hre-kpa-appraisals",
    name: "hre-kpa-appraisals",
    exact: true,
    pageTitle: "HRE KPA Appraisals",
    component: HRE_KPA_Appraisals,
  },
  {
    path: "/hre-okrs-tracking-goals",
    name: "hre-okrs-tracking-goals",
    exact: true,
    pageTitle: "HRE OKRs Tracking Goals",
    component: HRE_OKRs_Tracking_Goals,
  },
  {
    path: "/hre-competencies",
    name: "hre-competencies",
    exact: true,
    pageTitle: "HRE Competencies",
    component: HRE_Competencies,
  },
  {
    path: "/hre-technical",
    name: "hre-technical",
    exact: true,
    pageTitle: "HRE Technical",
    component: HRE_Technical,
  },
  {
    path: "/hre-organizational",
    name: "hre-organizational",
    exact: true,
    pageTitle: "HRE Organizational",
    component: HRE_Organizational,
  },
  {
    path: "/hre-goal-types",
    name: "hre-goal-types",
    exact: true,
    pageTitle: "HRE Goal Types",
    component: HRE_Goal_Types,
  },
  {
    path: "/hre-goal-calendar",
    name: "hre-goal-calendar",
    exact: true,
    pageTitle: "HRE Goal Calendar",
    component: HRE_Goal_Calendar,
  },
  {
    path: "/hre-recruitment-ats",
    name: "hre-recruitment-ats",
    exact: true,
    pageTitle: "HRE Recruitment ATS",
    component: HRE_Recruitment_ATS,
  },
  {
    path: "/hre-new-postings",
    name: "hre-new-postings",
    exact: true,
    pageTitle: "HRE New postings",
    component: HRE_New_postings,
  },
  {
    path: "/hre-candidate-list",
    name: "hre-candidate-list",
    exact: true,
    pageTitle: "HRE Candidate List",
    component: HRE_Candidate_List,
  },
  {
    path: "/hre-interviews",
    name: "hre-interviews",
    exact: true,
    pageTitle: "HRE Interviews",
    component: HRE_Interviews,
  },
  {
    path: "/hre-interviews",
    name: "hre-interviews",
    exact: true,
    pageTitle: "HRE Interviews",
    component: HRE_Interviews,
  },
  {
    path: "/hre-interviewers-list",
    name: "hre-interviewers-list",
    exact: true,
    pageTitle: "HRE Interviewers List",
    component: HRE_Interviewers_List,
  },
  {
    path: "/hre-attendance",
    name: "hre-attendance",
    exact: true,
    pageTitle: "HRE Attendance",
    component: HRE_Attendance,
  },
  {
    path: "/hre-attendance",
    name: "hre-attendance",
    exact: true,
    pageTitle: "HRE Attendance",
    component: HRE_Attendance,
  },
  {
    path: "/hre-manual-attendance",
    name: "hre-manual-attendance",
    exact: true,
    pageTitle: "HRE Manual Attendance",
    component: HRE_Manual_Attendance,
  },
  {
    path: "/hre-monthly-report",
    name: "hre-monthly-report",
    exact: true,
    pageTitle: "HRE Monthly Report",
    component: HRE_Monthly_Report,
  },
  {
    path: "/hre-overtime-request",
    name: "hre-overtime-request",
    exact: true,
    pageTitle: "HRE Overtime Request",
    component: HRE_Overtime_Request,
  },
  {
    path: "/hre-policies",
    name: "hre-policies",
    exact: true,
    pageTitle: "HRE Policies",
    component: HRE_Policies,
  },
  {
    path: "/hre-create-new-policy",
    name: "hre-create-new-policy",
    exact: true,
    pageTitle: "HRE Create new Policy",
    component: HRE_Create_new_Policy,
  },
  {
    path: "/hre-policy-list",
    name: "hre-policy-list",
    exact: true,
    pageTitle: "HRE Policy list",
    component: HRE_Policy_list,
  },
  {
    path: "/hre-make-announcement",
    name: "hre-make-announcement",
    exact: true,
    pageTitle: "HRE Make Announcement",
    component: HRE_Make_Announcement,
  },
  {
    path: "/hre-new-announcement",
    name: "hre-new-announcement",
    exact: true,
    pageTitle: "HRE New Announcement",
    component: HRE_New_Announcement,
  },
  {
    path: "/hre-announcement-list",
    name: "hre-announcement-list",
    exact: true,
    pageTitle: "HRE Announcement List",
    component: HRE_Announcement_List,
  },
  {
    path: "/hre-organisation-chart",
    name: "hre-organisation-chart",
    exact: true,
    pageTitle: "HRE Organisation Chart",
    component: HRE_Organisation_Chart,
  },
  {
    path: "/hre-view",
    name: "hre-view",
    exact: true,
    pageTitle: "HRE View",
    component: HRE_View,
  },
  {
    path: "/hre-create-chart",
    name: "hre-create-chart",
    exact: true,
    pageTitle: "HRE Create Chart",
    component: HRE_Create_Chart,
  },
  {
    path: "/hre-leave-requests",
    name: "hre-leave-requests",
    exact: true,
    pageTitle: "HRE Leave Requests",
    component: HRE_Leave_Requests,
  },
  {
    path: "/hre-manage-leaves",
    name: "hre-manage-leaves",
    exact: true,
    pageTitle: "HRE Manage Leaves",
    component: HRE_Manage_Leaves,
  },
  {
    path: "/hre-leaves-type",
    name: "hre-leaves-type",
    exact: true,
    pageTitle: "HRE Leaves Type",
    component: HRE_Leaves_Type,
  },
  {
    path: "/hre-settings",
    name: "hre-settings",
    exact: true,
    pageTitle: "HRE Settings",
    component: HRE_Settings,
  },
  {
    path: "/th-leave-requests",
    name: "th-leave-requests",
    exact: true,
    pageTitle: "TH Leave Requests",
    component: TH_Leave_Requests,
  },
  {
    path: "/th-manage-leaves",
    name: "th-manage-leaves",
    exact: true,
    pageTitle: "TH Manage Leaves",
    component: TH_Manage_Leaves,
  },
  {
    path: "/th-leaves-type",
    name: "th-leaves-type",
    exact: true,
    pageTitle: "TH Leaves Type",
    component: TH_Leaves_Type,
  },
  {
    path: "/th-trainings",
    name: "th-trainings",
    exact: true,
    pageTitle: "TH Trainings",
    component: TH_Trainings,
  },
  {
    path: "/th-training-sessions",
    name: "th-training-sessions",
    exact: true,
    pageTitle: "TH Training Sessions",
    component: TH_Training_Sessions,
  },
  {
    path: "/th-trainers",
    name: "th-trainers",
    exact: true,
    pageTitle: "TH Trainers",
    component: TH_Trainers,
  },
  {
    path: "/th-training-skills",
    name: "th-training-skills",
    exact: true,
    pageTitle: "TH Training Skills",
    component: TH_Training_Skills,
  },
  {
    path: "/th-manage-trainings",
    name: "th-manage-trainings",
    exact: true,
    pageTitle: "TH Manage Trainings",
    component: TH_Manage_Trainings,
  },
  {
    path: "/th-disciplinary-cases",
    name: "th-disciplinary-cases",
    exact: true,
    pageTitle: "TH Disciplinary Cases",
    component: TH_Disciplinary_Cases,
  },
  {
    path: "/th-actions",
    name: "th-actions",
    exact: true,
    pageTitle: "TH Actions",
    component: TH_Actions,
  },
  {
    path: "/th-resolutions",
    name: "th-resolutions",
    exact: true,
    pageTitle: "TH Resolutions",
    component: TH_Resolutions,
  },
  {
    path: "/th-settings",
    name: "th-settings",
    exact: true,
    pageTitle: "TH Settings",
    component: TH_Settings,
  },
  {
    path: "/ttl-leave-requests",
    name: "ttl-leave-requests",
    exact: true,
    pageTitle: "TTL Leave Requests",
    component: TTL_Leave_Requests,
  },
  {
    path: "/ttl-manage-leaves",
    name: "ttl-manage-leaves",
    exact: true,
    pageTitle: "TTL Manage Leaves",
    component: TTL_Manage_Leaves,
  },
  {
    path: "/ttl-leaves-type",
    name: "ttl-leaves-type",
    exact: true,
    pageTitle: "TTL Leaves Type",
    component: TTL_Leaves_Type,
  },
  {
    path: "/ttl-trainings",
    name: "ttl-trainings",
    exact: true,
    pageTitle: "TTL Trainings",
    component: TTL_Trainings,
  },
  {
    path: "/ttl-training-sessions",
    name: "ttl-training-sessions",
    exact: true,
    pageTitle: "TTL Training Sessions",
    component: TTL_Training_Sessions,
  },
  {
    path: "/ttl-trainers",
    name: "ttl-trainers",
    exact: true,
    pageTitle: "TTL Trainers",
    component: TTL_Trainers,
  },
  {
    path: "/ttl-training-skills",
    name: "ttl-training-skills",
    exact: true,
    pageTitle: "TTL Training Skills",
    component: TTL_Training_Skills,
  },
  {
    path: "/ttl-manage-trainings",
    name: "ttl-manage-trainings",
    exact: true,
    pageTitle: "TTL Manage Trainings",
    component: TTL_Manage_Trainings,
  },
  {
    path: "/ttl-disciplinary-cases",
    name: "ttl-disciplinary-cases",
    exact: true,
    pageTitle: "TTL Disciplinary Cases",
    component: TTL_Disciplinary_Cases,
  },
  {
    path: "/ttl-actions",
    name: "ttl-actions",
    exact: true,
    pageTitle: "TTL Actions",
    component: TTL_Actions,
  },
  {
    path: "/ttl-resolutions",
    name: "ttl-resolutions",
    exact: true,
    pageTitle: "TTL Resolutions",
    component: TTL_Resolutions,
  },
  {
    path: "/ttl-settings",
    name: "ttl-settings",
    exact: true,
    pageTitle: "TTL Settings",
    component: TTL_Settings,
  },
  {
    path: "/tr-leave-requests",
    name: "tr-leave-requests",
    exact: true,
    pageTitle: "TR Leave Requests",
    component: TR_Leave_Requests,
  },
  {
    path: "/tr-manage-leaves",
    name: "tr-manage-leaves",
    exact: true,
    pageTitle: "TR Manage Leaves",
    component: TR_Manage_Leaves,
  },
  {
    path: "/tr-leaves-type",
    name: "tr-leaves-type",
    exact: true,
    pageTitle: "TR Leaves Type",
    component: TR_Leaves_Type,
  },
  {
    path: "/tr-trainings",
    name: "tr-trainings",
    exact: true,
    pageTitle: "TR Trainings",
    component: TR_Trainings,
  },
  {
    path: "/tr-training-sessions",
    name: "tr-training-sessions",
    exact: true,
    pageTitle: "TR Training Sessions",
    component: TR_Training_Sessions,
  },
  {
    path: "/tr-trainers",
    name: "tr-trainers",
    exact: true,
    pageTitle: "TR Trainers",
    component: TR_Trainers,
  },
  {
    path: "/tr-training-skills",
    name: "tr-training-skills",
    exact: true,
    pageTitle: "TR Training Skills",
    component: TR_Training_Skills,
  },
  {
    path: "/tr-manage-trainings",
    name: "tr-manage-trainings",
    exact: true,
    pageTitle: "TR Manage Trainings",
    component: TR_Manage_Trainings,
  },
  {
    path: "/tr-disciplinary-cases",
    name: "tr-disciplinary-cases",
    exact: true,
    pageTitle: "TR Disciplinary Cases",
    component: TR_Disciplinary_Cases,
  },
  {
    path: "/tr-actions",
    name: "tr-actions",
    exact: true,
    pageTitle: "TR Actions",
    component: TR_Actions,
  },
  {
    path: "/tr-resolutions",
    name: "tr-resolutions",
    exact: true,
    pageTitle: "TR Resolutions",
    component: TR_Resolutions,
  },
  {
    path: "/tr-settings",
    name: "tr-settings",
    exact: true,
    pageTitle: "TR Settings",
    component: TR_Settings,
  },
  {
    path: "/oa-documents-manager",
    name: "oa-documents-manager",
    exact: true,
    pageTitle: "OA Documents Manager",
    component: OA_Documents_Manager,
  },
  {
    path: "/oa-general",
    name: "oa-general",
    exact: true,
    pageTitle: "OA general",
    component: OA_general,
  },
  {
    path: "/oa-uploading-files",
    name: "oa-uploading-files",
    exact: true,
    pageTitle: "OA Uploading files",
    component: OA_Uploading_files,
  },
  {
    path: "/oa-managing-files",
    name: "oa-managing-files",
    exact: true,
    pageTitle: "OA Managing Files",
    component: OA_Managing_Files,
  },
  {
    path: "/oa-official",
    name: "oa-official",
    exact: true,
    pageTitle: "OA official",
    component: OA_official,
  },
  {
    path: "/oa-uploading-files",
    name: "oa-uploading-files",
    exact: true,
    pageTitle: "OA Uploading files",
    component: OA_Uploading_files,
  },
  {
    path: "/oa-managing-files",
    name: "oa-managing-files",
    exact: true,
    pageTitle: "OA Managing Files",
    component: OA_Managing_Files,
  },
  {
    path: "/oa-visitors",
    name: "oa-visitors",
    exact: true,
    pageTitle: "OA Visitors",
    component: OA_Visitors,
  },
  {
    path: "/oa-new-visitor",
    name: "oa-new-visitor",
    exact: true,
    pageTitle: "OA New visitor",
    component: OA_New_visitor,
  },
  {
    path: "/oa-visitors-list",
    name: "oa-visitors-list",
    exact: true,
    pageTitle: "OA Visitors List",
    component: OA_Visitors_List,
  },
  {
    path: "/oa-leave-requests",
    name: "oa-leave-requests",
    exact: true,
    pageTitle: "OA Leave Requests",
    component: OA_Leave_Requests,
  },
  {
    path: "/oa-manage-leaves",
    name: "oa-manage-leaves",
    exact: true,
    pageTitle: "OA Manage Leaves",
    component: OA_Manage_Leaves,
  },
  {
    path: "/oa-leaves-type",
    name: "oa-leaves-type",
    exact: true,
    pageTitle: "OA Leaves Type",
    component: OA_Leaves_Type,
  },
  {
    path: "/oa-settings",
    name: "oa-settings",
    exact: true,
    pageTitle: "OA Settings",
    component: OA_Settings,
  },
  {
    path: "/fm-accounts",
    name: "fm-accounts ",
    exact: true,
    pageTitle: "FM Accounts ",
    component: FM_Accounts,
  },
  {
    path: "/fm-deposits",
    name: "fm-deposits ",
    exact: true,
    pageTitle: "FM Deposits ",
    component: FM_Deposits,
  },
  {
    path: "/fm-deposits-list",
    name: "fm-deposits-list ",
    exact: true,
    pageTitle: "FM Deposits List ",
    component: FM_Deposits_List,
  },
  {
    path: "/fm-categories",
    name: "fm-categories ",
    exact: true,
    pageTitle: "FM Categories ",
    component: FM_Categories,
  },
  {
    path: "/fm-expenses",
    name: "fm-expenses ",
    exact: true,
    pageTitle: "FM Expenses ",
    component: FM_Expenses,
  },
  {
    path: "/fm-transactions",
    name: "fm-transactions ",
    exact: true,
    pageTitle: "FM Transactions ",
    component: FM_Transactions,
  },
  {
    path: "/fm-payroll",
    name: "fm-payroll ",
    exact: true,
    pageTitle: "FM Payroll ",
    component: FM_Payroll,
  },
  {
    path: "/fm-pay-slip-history",
    name: "fm-pay-slip-history ",
    exact: true,
    pageTitle: "FM Pay Slip History ",
    component: FM_Pay_Slip_History,
  },
  {
    path: "/fm-advance-salary",
    name: "fm-advance-salary ",
    exact: true,
    pageTitle: "FM Advance Salary ",
    component: FM_Advance_Salary,
  },
  {
    path: "/fm-loan",
    name: "fm-loan ",
    exact: true,
    pageTitle: "FM Loan ",
    component: FM_Loan,
  },
  {
    path: "/fm-loan-types",
    name: "fm-loan-types ",
    exact: true,
    pageTitle: "FM Loan Types ",
    component: FM_Loan_Types,
  },
  {
    path: "/fm-commission-types",
    name: "fm-commission-types ",
    exact: true,
    pageTitle: "FM Commission types ",
    component: FM_Commission_types,
  },
  {
    path: "/fm-incentive-types",
    name: "fm-incentive-types ",
    exact: true,
    pageTitle: "FM Incentive Types ",
    component: FM_Incentive_Types,
  },
  {
    path: "/fm-invoices",
    name: "fm-invoices ",
    exact: true,
    pageTitle: "FM Invoices ",
    component: FM_Invoices,
  },
  {
    path: "/fm-billing-invoices",
    name: "fm-billing-invoices ",
    exact: true,
    pageTitle: "FM Billing Invoices ",
    component: FM_Billing_Invoices,
  },
  {
    path: "/fm-invoice-payments",
    name: "fm-invoice-payments ",
    exact: true,
    pageTitle: "FM Invoice Payments ",
    component: FM_Invoice_Payments,
  },
  {
    path: "/fm-tax-type",
    name: "fm-tax-type ",
    exact: true,
    pageTitle: "FM Tax Type ",
    component: FM_Tax_Type,
  },
  {
    path: "/fm-estimates",
    name: "fm-estimates ",
    exact: true,
    pageTitle: "FM Estimates ",
    component: FM_Estimates,
  },
  {
    path: "/fm-manage-leaves",
    name: "fm-manage-leaves ",
    exact: true,
    pageTitle: "FM Manage Leaves ",
    component: FM_Manage_Leaves,
  },
  {
    path: "/fm-estimate-type",
    name: "fm-estimate-type ",
    exact: true,
    pageTitle: "FM Estimate Type ",
    component: FM_Estimate_Type,
  },
  {
    path: "/fm-leave-requests",
    name: "fm-leave-requests ",
    exact: true,
    pageTitle: "FM Leave Requests ",
    component: FM_Leave_Requests,
  },
  {
    path: "/fm-manage-leaves",
    name: "fm-manage-leaves ",
    exact: true,
    pageTitle: "FM Manage Leaves ",
    component: FM_Manage_Leaves,
  },
  {
    path: "/fm-leaves-type",
    name: "fm-leaves-type ",
    exact: true,
    pageTitle: "FM Leaves Type ",
    component: FM_Leaves_Type,
  },
  {
    path: "/fm-settings",
    name: "fm-settings ",
    exact: true,
    pageTitle: "FM Settings ",
    component: FM_Settings,
  },
  {
    path: "/ah-accounts",
    name: "ah-accounts ",
    exact: true,
    pageTitle: "AH Accounts ",
    component: AH_Accounts,
  },
  {
    path: "/ah-deposits",
    name: "ah-deposits ",
    exact: true,
    pageTitle: "AH Deposits ",
    component: AH_Deposits,
  },
  {
    path: "/ah-deposits-list",
    name: "ah-deposits-list ",
    exact: true,
    pageTitle: "AH Deposits List ",
    component: AH_Deposits_List,
  },
  {
    path: "/ah-categories",
    name: "ah-categories ",
    exact: true,
    pageTitle: "AH Categories ",
    component: AH_Categories,
  },
  {
    path: "/ah-expenses",
    name: "ah-expenses ",
    exact: true,
    pageTitle: "AH Expenses ",
    component: AH_Expenses,
  },
  {
    path: "/ah-transactions",
    name: "ah-transactions ",
    exact: true,
    pageTitle: "AH Transactions ",
    component: AH_Transactions,
  },
  {
    path: "/ah-payroll",
    name: "ah-payroll ",
    exact: true,
    pageTitle: "AH Payroll ",
    component: AH_Payroll,
  },
  {
    path: "/ah-pay-slip-history",
    name: "ah-pay-slip-history ",
    exact: true,
    pageTitle: "AH Pay Slip History ",
    component: AH_Pay_Slip_History,
  },
  {
    path: "/ah-advance-salary",
    name: "ah-advance-salary ",
    exact: true,
    pageTitle: "AH Advance Salary ",
    component: AH_Advance_Salary,
  },
  {
    path: "/ah-loan",
    name: "ah-loan ",
    exact: true,
    pageTitle: "AH Loan ",
    component: AH_Loan,
  },
  {
    path: "/ah-loan-types",
    name: "ah-loan-types ",
    exact: true,
    pageTitle: "AH Loan Types ",
    component: AH_Loan_Types,
  },
  {
    path: "/ah-commission-types",
    name: "ah-commission-types ",
    exact: true,
    pageTitle: "AH Commission types ",
    component: AH_Commission_types,
  },
  {
    path: "/ah-incentive-types",
    name: "ah-incentive-types ",
    exact: true,
    pageTitle: "AH Incentive Types ",
    component: AH_Incentive_Types,
  },
  {
    path: "/ah-invoices",
    name: "ah-invoices ",
    exact: true,
    pageTitle: "AH Invoices ",
    component: AH_Invoices,
  },
  {
    path: "/ah-billing-invoices",
    name: "ah-billing-invoices ",
    exact: true,
    pageTitle: "AH Billing Invoices ",
    component: AH_Billing_Invoices,
  },
  {
    path: "/ah-invoice-payments",
    name: "ah-invoice-payments ",
    exact: true,
    pageTitle: "AH Invoice Payments ",
    component: AH_Invoice_Payments,
  },
  {
    path: "/ah-tax-type",
    name: "ah-tax-type ",
    exact: true,
    pageTitle: "AH Tax Type ",
    component: AH_Tax_Type,
  },
  {
    path: "/ah-estimates",
    name: "ah-estimates ",
    exact: true,
    pageTitle: "AH Estimates ",
    component: AH_Estimates,
  },
  {
    path: "/ah-manage-leaves",
    name: "ah-manage-leaves ",
    exact: true,
    pageTitle: "AH Manage Leaves ",
    component: AH_Manage_Leaves,
  },
  {
    path: "/ah-estimate-type",
    name: "ah-estimate-type ",
    exact: true,
    pageTitle: "AH Estimate Type ",
    component: AH_Estimate_Type,
  },
  {
    path: "/ah-leave-requests",
    name: "ah-leave-requests ",
    exact: true,
    pageTitle: "AH Leave Requests ",
    component: AH_Leave_Requests,
  },
  {
    path: "/ah-manage-leaves",
    name: "ah-manage-leaves ",
    exact: true,
    pageTitle: "AH Manage Leaves ",
    component: AH_Manage_Leaves,
  },
  {
    path: "/ah-leaves-type",
    name: "ah-leaves-type ",
    exact: true,
    pageTitle: "AH Leaves Type ",
    component: AH_Leaves_Type,
  },
  {
    path: "/ah-settings",
    name: "ah-settings ",
    exact: true,
    pageTitle: "AH Settings ",
    component: AH_Settings,
  },
  {
    path: "/ae-accounts",
    name: "ae-accounts ",
    exact: true,
    pageTitle: "AE Accounts ",
    component: AE_Accounts,
  },
  {
    path: "/ae-deposits",
    name: "ae-deposits ",
    exact: true,
    pageTitle: "AE Deposits ",
    component: AE_Deposits,
  },
  {
    path: "/ae-deposits-list",
    name: "ae-deposits-list ",
    exact: true,
    pageTitle: "AE Deposits List ",
    component: AE_Deposits_List,
  },
  {
    path: "/ae-categories",
    name: "ae-categories ",
    exact: true,
    pageTitle: "AE Categories ",
    component: AE_Categories,
  },
  {
    path: "/ae-expenses",
    name: "ae-expenses ",
    exact: true,
    pageTitle: "AE Expenses ",
    component: AE_Expenses,
  },
  {
    path: "/ae-transactions",
    name: "ae-transactions ",
    exact: true,
    pageTitle: "AE Transactions ",
    component: AE_Transactions,
  },
  {
    path: "/ae-payroll",
    name: "ae-payroll ",
    exact: true,
    pageTitle: "AE Payroll ",
    component: AE_Payroll,
  },
  {
    path: "/ae-pay-slip-history",
    name: "ae-pay-slip-history ",
    exact: true,
    pageTitle: "AE Pay Slip History ",
    component: AE_Pay_Slip_History,
  },
  {
    path: "/ae-advance-salary",
    name: "ae-advance-salary ",
    exact: true,
    pageTitle: "AE Advance Salary ",
    component: AE_Advance_Salary,
  },
  {
    path: "/ae-loan",
    name: "ae-loan ",
    exact: true,
    pageTitle: "AE Loan ",
    component: AE_Loan,
  },
  {
    path: "/ae-loan-types",
    name: "ae-loan-types ",
    exact: true,
    pageTitle: "AE Loan Types ",
    component: AE_Loan_Types,
  },
  {
    path: "/ae-commission-types",
    name: "ae-commission-types ",
    exact: true,
    pageTitle: "AE Commission types ",
    component: AE_Commission_types,
  },
  {
    path: "/ae-incentive-types",
    name: "ae-incentive-types ",
    exact: true,
    pageTitle: "AE Incentive Types ",
    component: AE_Incentive_Types,
  },
  {
    path: "/ae-invoices",
    name: "ae-invoices ",
    exact: true,
    pageTitle: "AE Invoices ",
    component: AE_Invoices,
  },
  {
    path: "/ae-billing-invoices",
    name: "ae-billing-invoices ",
    exact: true,
    pageTitle: "AE Billing Invoices ",
    component: AE_Billing_Invoices,
  },
  {
    path: "/ae-invoice-payments",
    name: "ae-invoice-payments ",
    exact: true,
    pageTitle: "AE Invoice Payments ",
    component: AE_Invoice_Payments,
  },
  {
    path: "/ae-tax-type",
    name: "ae-tax-type ",
    exact: true,
    pageTitle: "AE Tax Type ",
    component: AE_Tax_Type,
  },
  {
    path: "/ae-estimates",
    name: "ae-estimates ",
    exact: true,
    pageTitle: "AE Estimates ",
    component: AE_Estimates,
  },
  {
    path: "/ae-manage-leaves",
    name: "ae-manage-leaves ",
    exact: true,
    pageTitle: "AE Manage Leaves ",
    component: AE_Manage_Leaves,
  },
  {
    path: "/ae-estimate-type",
    name: "ae-estimate-type ",
    exact: true,
    pageTitle: "AE Estimate Type ",
    component: AE_Estimate_Type,
  },
  {
    path: "/ae-leave-requests",
    name: "ae-leave-requests ",
    exact: true,
    pageTitle: "AE Leave Requests ",
    component: AE_Leave_Requests,
  },
  {
    path: "/ae-manage-leaves",
    name: "ae-manage-leaves ",
    exact: true,
    pageTitle: "AE Manage Leaves ",
    component: AE_Manage_Leaves,
  },
  {
    path: "/ae-leaves-type",
    name: "ae-leaves-type ",
    exact: true,
    pageTitle: "AE Leaves Type ",
    component: AE_Leaves_Type,
  },
  {
    path: "/ae-settings",
    name: "ae-settings ",
    exact: true,
    pageTitle: "AE Settings ",
    component: AE_Settings,
  },
  {
    path: "/ac-accounts",
    name: "ac-accounts ",
    exact: true,
    pageTitle: "AC Accounts ",
    component: AC_Accounts,
  },
  {
    path: "/ac-deposits",
    name: "ac-deposits ",
    exact: true,
    pageTitle: "AC Deposits ",
    component: AC_Deposits,
  },
  {
    path: "/ac-deposits-list",
    name: "ac-deposits-list ",
    exact: true,
    pageTitle: "AC Deposits List ",
    component: AC_Deposits_List,
  },
  {
    path: "/ac-categories",
    name: "ac-categories ",
    exact: true,
    pageTitle: "AC Categories ",
    component: AC_Categories,
  },
  {
    path: "/ac-expenses",
    name: "ac-expenses ",
    exact: true,
    pageTitle: "AC Expenses ",
    component: AC_Expenses,
  },
  {
    path: "/ac-transactions",
    name: "ac-transactions ",
    exact: true,
    pageTitle: "AC Transactions ",
    component: AC_Transactions,
  },
  {
    path: "/ac-payroll",
    name: "ac-payroll ",
    exact: true,
    pageTitle: "AC Payroll ",
    component: AC_Payroll,
  },
  {
    path: "/ac-pay-slip-history",
    name: "ac-pay-slip-history ",
    exact: true,
    pageTitle: "AC Pay Slip History ",
    component: AC_Pay_Slip_History,
  },
  {
    path: "/ac-advance-salary",
    name: "ac-advance-salary ",
    exact: true,
    pageTitle: "AC Advance Salary ",
    component: AC_Advance_Salary,
  },
  {
    path: "/ac-loan",
    name: "ac-loan ",
    exact: true,
    pageTitle: "AC Loan ",
    component: AC_Loan,
  },
  {
    path: "/ac-loan-types",
    name: "ac-loan-types ",
    exact: true,
    pageTitle: "AC Loan Types ",
    component: AC_Loan_Types,
  },
  {
    path: "/ac-commission-types",
    name: "ac-commission-types ",
    exact: true,
    pageTitle: "AC Commission types ",
    component: AC_Commission_types,
  },
  {
    path: "/ac-incentive-types",
    name: "ac-incentive-types ",
    exact: true,
    pageTitle: "AC Incentive Types ",
    component: AC_Incentive_Types,
  },
  {
    path: "/ac-invoices",
    name: "ac-invoices ",
    exact: true,
    pageTitle: "AC Invoices ",
    component: AC_Invoices,
  },
  {
    path: "/ac-billing-invoices",
    name: "ac-billing-invoices ",
    exact: true,
    pageTitle: "AC Billing Invoices ",
    component: AC_Billing_Invoices,
  },
  {
    path: "/ac-invoice-payments",
    name: "ac-invoice-payments ",
    exact: true,
    pageTitle: "AC Invoice Payments ",
    component: AC_Invoice_Payments,
  },
  {
    path: "/ac-tax-type",
    name: "ac-tax-type ",
    exact: true,
    pageTitle: "AC Tax Type ",
    component: AC_Tax_Type,
  },
  {
    path: "/ac-estimates",
    name: "ac-estimates ",
    exact: true,
    pageTitle: "AC Estimates ",
    component: AC_Estimates,
  },
  {
    path: "/ac-manage-leaves",
    name: "ac-manage-leaves ",
    exact: true,
    pageTitle: "AC Manage Leaves ",
    component: AC_Manage_Leaves,
  },
  {
    path: "/ac-estimate-type",
    name: "ac-estimate-type ",
    exact: true,
    pageTitle: "AC Estimate Type ",
    component: AC_Estimate_Type,
  },
  {
    path: "/ac-leave-requests",
    name: "ac-leave-requests ",
    exact: true,
    pageTitle: "AC Leave Requests ",
    component: AC_Leave_Requests,
  },
  {
    path: "/ac-manage-leaves",
    name: "ac-manage-leaves ",
    exact: true,
    pageTitle: "AC Manage Leaves ",
    component: AC_Manage_Leaves,
  },
  {
    path: "/ac-leaves-type",
    name: "ac-leaves-type ",
    exact: true,
    pageTitle: "AC Leaves Type ",
    component: AC_Leaves_Type,
  },
  {
    path: "/ac-settings",
    name: "ac-settings ",
    exact: true,
    pageTitle: "AC Settings ",
    component: AC_Settings,
  },
  {
    path: "/ith-inventory-control ",
    name: "ith-inventory-control  ",
    exact: true,
    pageTitle: "ITH Inventory Control  ",
    component: ITH_Inventory_Control,
  },
  {
    path: "/ith-ware-houses ",
    name: "ith-ware-houses  ",
    exact: true,
    pageTitle: "ITH Ware Houses  ",
    component: ITH_Ware_Houses,
  },
  {
    path: "/ith-products ",
    name: "ith-products  ",
    exact: true,
    pageTitle: "ITH Products  ",
    component: ITH_Products,
  },
  {
    path: "/ith-out-of-stock ",
    name: "ith-out-of-stock  ",
    exact: true,
    pageTitle: "ITH Out Of Stock  ",
    component: ITH_Out_Of_Stock,
  },
  {
    path: "/ith-expired-products ",
    name: "ith-expired-products  ",
    exact: true,
    pageTitle: "ITH Expired Products  ",
    component: ITH_Expired_Products,
  },
  {
    path: "/ith-product-tax ",
    name: "ith-product-tax  ",
    exact: true,
    pageTitle: "ITH Product Tax  ",
    component: ITH_Product_Tax,
  },
  {
    path: "/ith-billing-invoices ",
    name: "ith-billing-invoices  ",
    exact: true,
    pageTitle: "ITH Billing Invoices  ",
    component: ITH_Billing_Invoices,
  },
  {
    path: "/ith-invoice-payments ",
    name: "ith-invoice-payments  ",
    exact: true,
    pageTitle: "ITH Invoice Payments  ",
    component: ITH_Invoice_Payments,
  },
  {
    path: "/ith-tax-type ",
    name: "ith-tax-type  ",
    exact: true,
    pageTitle: "ITH Tax Type  ",
    component: ITH_Tax_Type,
  },
  {
    path: "/ith-product-category ",
    name: "ith-product-category  ",
    exact: true,
    pageTitle: "ITH Product Category  ",
    component: ITH_Product_Category,
  },
  {
    path: "/ith-suppliers ",
    name: "ith-suppliers  ",
    exact: true,
    pageTitle: "ITH Suppliers  ",
    component: ITH_Suppliers,
  },
  {
    path: "/ith-new-supplier ",
    name: "ith-new-supplier  ",
    exact: true,
    pageTitle: "ITH new supplier  ",
    component: ITH_new_supplier,
  },
  {
    path: "/ith-suppliers-list ",
    name: "ith-suppliers-list  ",
    exact: true,
    pageTitle: "ITH suppliers list  ",
    component: ITH_suppliers_list,
  },
  {
    path: "/ith-purchases ",
    name: "ith-purchases  ",
    exact: true,
    pageTitle: "ITH Purchases  ",
    component: ITH_Purchases,
  },
  {
    path: "/ith-new-purchase ",
    name: "ith-new-purchase  ",
    exact: true,
    pageTitle: "ITH New Purchase  ",
    component: ITH_New_Purchase,
  },
  {
    path: "/ith-purchase-list ",
    name: "ith-purchase-list  ",
    exact: true,
    pageTitle: "ITH Purchase List  ",
    component: ITH_Purchase_List,
  },
  {
    path: "/ith-sales-order ",
    name: "ith-sales-order  ",
    exact: true,
    pageTitle: "ITH Sales Order  ",
    component: ITH_Sales_Order,
  },
  {
    path: "/ith-sales-order-list ",
    name: "ith-sales-order-list  ",
    exact: true,
    pageTitle: "ITH Sales Order List  ",
    component: ITH_Sales_Order_List,
  },
  {
    path: "/ith-new-order-invoice ",
    name: "ith-new-order-invoice  ",
    exact: true,
    pageTitle: "ITH New Order Invoice  ",
    component: ITH_New_Order_Invoice,
  },
  {
    path: "/ith-manage-orders ",
    name: "ith-manage-orders  ",
    exact: true,
    pageTitle: "ITH Manage Orders  ",
    component: ITH_Manage_Orders,
  },
  {
    path: "/ith-paid-orders ",
    name: "ith-paid-orders  ",
    exact: true,
    pageTitle: "ITH Paid Orders  ",
    component: ITH_Paid_Orders,
  },
  {
    path: "/ith-unpaid-orders ",
    name: "ith-unpaid-orders  ",
    exact: true,
    pageTitle: "ITH Unpaid Orders  ",
    component: ITH_Unpaid_Orders,
  },
  {
    path: "/ith-delivered-orders ",
    name: "ith-delivered-orders  ",
    exact: true,
    pageTitle: "ITH Delivered Orders  ",
    component: ITH_Delivered_Orders,
  },
  {
    path: "/ith-cancelled-orders ",
    name: "ith-cancelled-orders  ",
    exact: true,
    pageTitle: "ITH Cancelled Orders  ",
    component: ITH_Cancelled_Orders,
  },
  {
    path: "/ith-credit-types ",
    name: "ith-credit-types  ",
    exact: true,
    pageTitle: "ITH Credit Types  ",
    component: ITH_Credit_Types,
  },
  {
    path: "/ith-help-desk ",
    name: "ith-help-desk  ",
    exact: true,
    pageTitle: "ITH Help Desk  ",
    component: ITH_Help_Desk,
  },
  {
    path: "/ith-tickets ",
    name: "ith-tickets  ",
    exact: true,
    pageTitle: "ITH Tickets  ",
    component: ITH_Tickets,
  },
  {
    path: "/ith-tickets-list ",
    name: "ith-tickets-list  ",
    exact: true,
    pageTitle: "ITH Tickets List  ",
    component: ITH_Tickets_List,
  },
  {
    path: "/ith-create-ticket ",
    name: "ith-create-ticket  ",
    exact: true,
    pageTitle: "ITH Create Ticket  ",
    component: ITH_Create_Ticket,
  },
  {
    path: "/ith-support-tickets ",
    name: "ith-support-tickets  ",
    exact: true,
    pageTitle: "ITH Support Tickets  ",
    component: ITH_Support_Tickets,
  },
  {
    path: "/ith-resolved-list ",
    name: "ith-resolved-list  ",
    exact: true,
    pageTitle: "ITH Resolved List  ",
    component: ITH_Resolved_List,
  },
  {
    path: "/ith-assign-tickets-solve ",
    name: "ith-assign-tickets-solve  ",
    exact: true,
    pageTitle: "ITH Assign Tickets Solve  ",
    component: ITH_Assign_Tickets_Solve,
  },
  {
    path: "/ith-leave-requests ",
    name: "ith-leave-requests  ",
    exact: true,
    pageTitle: "ITH Leave Requests  ",
    component: ITH_Leave_Requests,
  },
  {
    path: "/ith-manage-leaves ",
    name: "ith-manage-leaves  ",
    exact: true,
    pageTitle: "ITH Manage Leaves  ",
    component: ITH_Manage_Leaves,
  },
  {
    path: "/ith-leaves-type ",
    name: "ith-leaves-type  ",
    exact: true,
    pageTitle: "ITH Leaves Type  ",
    component: ITH_Leaves_Type,
  },
  {
    path: "/ith-assets-list ",
    name: "ith-assets-list  ",
    exact: true,
    pageTitle: "ITH Assets List  ",
    component: ITH_Assets_List,
  },
  {
    path: "/ith-assets-management ",
    name: "ith-assets-management  ",
    exact: true,
    pageTitle: "ITH Assets management  ",
    component: ITH_Assets_management,
  },
  {
    path: "/ith-create-assert ",
    name: "ith-create-assert  ",
    exact: true,
    pageTitle: "ITH Create assert  ",
    component: ITH_Create_assert,
  },
  {
    path: "/ith-assign-assert ",
    name: "ith-assign-assert  ",
    exact: true,
    pageTitle: "ITH Assign assert  ",
    component: ITH_Assign_assert,
  },
  {
    path: "/ith-destory-assert ",
    name: "ith-destory-assert  ",
    exact: true,
    pageTitle: "ITH Destory assert  ",
    component: ITH_Destory_assert,
  },
  {
    path: "/ith-assert-category ",
    name: "ith-assert-category  ",
    exact: true,
    pageTitle: "ITH assert Category  ",
    component: ITH_assert_Category,
  },
  {
    path: "/ith-new-category ",
    name: "ith-new-category  ",
    exact: true,
    pageTitle: "ITH New category  ",
    component: ITH_New_category,
  },
  {
    path: "/ith-manage-category ",
    name: "ith-manage-category  ",
    exact: true,
    pageTitle: "ITH Manage category  ",
    component: ITH_Manage_category,
  },
  {
    path: "/ith-asset-brand ",
    name: "ith-asset-brand  ",
    exact: true,
    pageTitle: "ITH Asset Brand  ",
    component: ITH_Asset_Brand,
  },
  {
    path: "/ith-new-brand ",
    name: "ith-new-brand  ",
    exact: true,
    pageTitle: "ITH New Brand  ",
    component: ITH_New_Brand,
  },
  {
    path: "/ith-manage-brand ",
    name: "ith-manage-brand  ",
    exact: true,
    pageTitle: "ITH Manage Brand  ",
    component: ITH_Manage_Brand,
  },
  {
    path: "/ith-settings ",
    name: "ith-settings  ",
    exact: true,
    pageTitle: "ITH Settings  ",
    component: ITH_Settings,
  },
  {
    path: "/itm-inventory-control",
    name: "itm-inventory-control",
    exact: true,
    pageTitle: "ITM Inventory Control",
    component: ITM_Inventory_Control,
  },
  {
    path: "/itm-ware-houses",
    name: "itm-ware-houses",
    exact: true,
    pageTitle: "ITM Ware Houses",
    component: ITM_Ware_Houses,
  },
  {
    path: "/itm-products",
    name: "itm-products",
    exact: true,
    pageTitle: "ITM Products",
    component: ITM_Products,
  },
  {
    path: "/itm-out-of-stock",
    name: "itm-out-of-stock",
    exact: true,
    pageTitle: "ITM Out Of Stock",
    component: ITM_Out_Of_Stock,
  },
  {
    path: "/itm-expired-products",
    name: "itm-expired-products",
    exact: true,
    pageTitle: "ITM Expired Products",
    component: ITM_Expired_Products,
  },
  {
    path: "/itm-product-tax",
    name: "itm-product-tax",
    exact: true,
    pageTitle: "ITM Product Tax",
    component: ITM_Product_Tax,
  },
  {
    path: "/itm-billing-invoices",
    name: "itm-billing-invoices",
    exact: true,
    pageTitle: "ITM Billing Invoices",
    component: ITM_Billing_Invoices,
  },
  {
    path: "/itm-invoice-payments",
    name: "itm-invoice-payments",
    exact: true,
    pageTitle: "ITM Invoice Payments",
    component: ITM_Invoice_Payments,
  },
  {
    path: "/itm-tax-type",
    name: "itm-tax-type",
    exact: true,
    pageTitle: "ITM Tax Type",
    component: ITM_Tax_Type,
  },
  {
    path: "/itm-product-category",
    name: "itm-product-category",
    exact: true,
    pageTitle: "ITM Product Category",
    component: ITM_Product_Category,
  },
  {
    path: "/itm-suppliers",
    name: "itm-suppliers",
    exact: true,
    pageTitle: "ITM Suppliers",
    component: ITM_Suppliers,
  },
  {
    path: "/itm-new-supplier",
    name: "itm-new-supplier",
    exact: true,
    pageTitle: "ITM new supplier",
    component: ITM_new_supplier,
  },
  {
    path: "/itm-suppliers-list",
    name: "itm-suppliers-list",
    exact: true,
    pageTitle: "ITM suppliers list",
    component: ITM_suppliers_list,
  },
  {
    path: "/itm-purchases",
    name: "itm-purchases",
    exact: true,
    pageTitle: "ITM Purchases",
    component: ITM_Purchases,
  },
  {
    path: "/itm-new-purchase",
    name: "itm-new-purchase",
    exact: true,
    pageTitle: "ITM New Purchase",
    component: ITM_New_Purchase,
  },
  {
    path: "/itm-purchase-list",
    name: "itm-purchase-list",
    exact: true,
    pageTitle: "ITM Purchase List",
    component: ITM_Purchase_List,
  },
  {
    path: "/itm-sales-order",
    name: "itm-sales-order",
    exact: true,
    pageTitle: "ITM Sales Order",
    component: ITM_Sales_Order,
  },
  {
    path: "/itm-sales-order-list",
    name: "itm-sales-order-list",
    exact: true,
    pageTitle: "ITM Sales Order List",
    component: ITM_Sales_Order_List,
  },
  {
    path: "/itm-new-order-invoice",
    name: "itm-new-order-invoice",
    exact: true,
    pageTitle: "ITM New Order Invoice",
    component: ITM_New_Order_Invoice,
  },
  {
    path: "/itm-manage-orders",
    name: "itm-manage-orders",
    exact: true,
    pageTitle: "ITM Manage Orders",
    component: ITM_Manage_Orders,
  },
  {
    path: "/itm-paid-orders",
    name: "itm-paid-orders",
    exact: true,
    pageTitle: "ITM Paid Orders",
    component: ITM_Paid_Orders,
  },
  {
    path: "/itm-unpaid-orders",
    name: "itm-unpaid-orders",
    exact: true,
    pageTitle: "ITM Unpaid Orders",
    component: ITM_Unpaid_Orders,
  },
  {
    path: "/itm-delivered-orders",
    name: "itm-delivered-orders",
    exact: true,
    pageTitle: "ITM Delivered Orders",
    component: ITM_Delivered_Orders,
  },
  {
    path: "/itm-cancelled-orders",
    name: "itm-cancelled-orders",
    exact: true,
    pageTitle: "ITM Cancelled Orders",
    component: ITM_Cancelled_Orders,
  },
  {
    path: "/itm-credit-types",
    name: "itm-credit-types",
    exact: true,
    pageTitle: "ITM Credit Types",
    component: ITM_Credit_Types,
  },
  {
    path: "/itm-help-desk",
    name: "itm-help-desk",
    exact: true,
    pageTitle: "ITM Help Desk",
    component: ITM_Help_Desk,
  },
  {
    path: "/itm-tickets",
    name: "itm-tickets",
    exact: true,
    pageTitle: "ITM Tickets",
    component: ITM_Tickets,
  },
  {
    path: "/itm-tickets-list",
    name: "itm-tickets-list",
    exact: true,
    pageTitle: "ITM Tickets List",
    component: ITM_Tickets_List,
  },
  {
    path: "/itm-create-ticket",
    name: "itm-create-ticket",
    exact: true,
    pageTitle: "ITM Create Ticket",
    component: ITM_Create_Ticket,
  },
  {
    path: "/itm-support-tickets",
    name: "itm-support-tickets",
    exact: true,
    pageTitle: "ITM Support Tickets",
    component: ITM_Support_Tickets,
  },
  {
    path: "/itm-resolved-list",
    name: "itm-resolved-list",
    exact: true,
    pageTitle: "ITM Resolved List",
    component: ITM_Resolved_List,
  },
  {
    path: "/itm-assign-tickets-solve",
    name: "itm-assign-tickets-solve",
    exact: true,
    pageTitle: "ITM Assign Tickets Solve",
    component: ITM_Assign_Tickets_Solve,
  },
  {
    path: "/itm-leave-requests",
    name: "itm-leave-requests",
    exact: true,
    pageTitle: "ITM Leave Requests",
    component: ITM_Leave_Requests,
  },
  {
    path: "/itm-manage-leaves",
    name: "itm-manage-leaves",
    exact: true,
    pageTitle: "ITM Manage Leaves",
    component: ITM_Manage_Leaves,
  },
  {
    path: "/itm-leaves-type",
    name: "itm-leaves-type",
    exact: true,
    pageTitle: "ITM Leaves Type",
    component: ITM_Leaves_Type,
  },
  {
    path: "/itm-assets-list",
    name: "itm-assets-list",
    exact: true,
    pageTitle: "ITM Assets List",
    component: ITM_Assets_List,
  },
  {
    path: "/itm-assets-management",
    name: "itm-assets-management",
    exact: true,
    pageTitle: "ITM Assets management",
    component: ITM_Assets_management,
  },
  {
    path: "/itm-create-assert",
    name: "itm-create-assert",
    exact: true,
    pageTitle: "ITM Create assert",
    component: ITM_Create_assert,
  },
  {
    path: "/itm-assign-assert",
    name: "itm-assign-assert",
    exact: true,
    pageTitle: "ITM Assign assert",
    component: ITM_Assign_assert,
  },
  {
    path: "/itm-destory-assert",
    name: "itm-destory-assert",
    exact: true,
    pageTitle: "ITM Destory assert",
    component: ITM_Destory_assert,
  },
  {
    path: "/itm-assert-category",
    name: "itm-assert-category",
    exact: true,
    pageTitle: "ITM assert Category",
    component: ITM_assert_Category,
  },
  {
    path: "/itm-new-category",
    name: "itm-new-category",
    exact: true,
    pageTitle: "ITM New category",
    component: ITM_New_category,
  },
  {
    path: "/itm-manage-category",
    name: "itm-manage-category",
    exact: true,
    pageTitle: "ITM Manage category",
    component: ITM_Manage_category,
  },
  {
    path: "/itm-asset-brand",
    name: "itm-asset-brand",
    exact: true,
    pageTitle: "ITM Asset Brand",
    component: ITM_Asset_Brand,
  },
  {
    path: "/itm-new-brand",
    name: "itm-new-brand",
    exact: true,
    pageTitle: "ITM New Brand",
    component: ITM_New_Brand,
  },
  {
    path: "/itm-manage-brand",
    name: "itm-manage-brand",
    exact: true,
    pageTitle: "ITM Manage Brand",
    component: ITM_Manage_Brand,
  },
  {
    path: "/itm-settings",
    name: "itm-settings",
    exact: true,
    pageTitle: "ITM Settings",
    component: ITM_Settings,
  },
  {
    path: "/amhwa-inventory-control",
    name: "amhwa-inventory-control",
    exact: true,
    pageTitle: "AMHWA Inventory Control",
    component: AMHWA_Inventory_Control,
  },
  {
    path: "/amhwa-ware-houses",
    name: "amhwa-ware-houses",
    exact: true,
    pageTitle: "AMHWA Ware Houses",
    component: AMHWA_Ware_Houses,
  },
  {
    path: "/amhwa-products",
    name: "amhwa-products",
    exact: true,
    pageTitle: "AMHWA Products",
    component: AMHWA_Products,
  },
  {
    path: "/amhwa-out-of-stock",
    name: "amhwa-out-of-stock",
    exact: true,
    pageTitle: "AMHWA Out Of Stock",
    component: AMHWA_Out_Of_Stock,
  },
  {
    path: "/amhwa-expired-products",
    name: "amhwa-expired-products",
    exact: true,
    pageTitle: "AMHWA Expired Products",
    component: AMHWA_Expired_Products,
  },
  {
    path: "/amhwa-product-tax",
    name: "amhwa-product-tax",
    exact: true,
    pageTitle: "AMHWA Product Tax",
    component: AMHWA_Product_Tax,
  },
  {
    path: "/amhwa-billing-invoices",
    name: "amhwa-billing-invoices",
    exact: true,
    pageTitle: "AMHWA Billing Invoices",
    component: AMHWA_Billing_Invoices,
  },
  {
    path: "/amhwa-invoice-payments",
    name: "amhwa-invoice-payments",
    exact: true,
    pageTitle: "AMHWA Invoice Payments",
    component: AMHWA_Invoice_Payments,
  },
  {
    path: "/amhwa-tax-type",
    name: "amhwa-tax-type",
    exact: true,
    pageTitle: "AMHWA Tax Type",
    component: AMHWA_Tax_Type,
  },
  {
    path: "/amhwa-product-category",
    name: "amhwa-product-category",
    exact: true,
    pageTitle: "AMHWA Product Category",
    component: AMHWA_Product_Category,
  },
  {
    path: "/amhwa-suppliers",
    name: "amhwa-suppliers",
    exact: true,
    pageTitle: "AMHWA Suppliers",
    component: AMHWA_Suppliers,
  },
  {
    path: "/amhwa-new-supplier",
    name: "amhwa-new-supplier",
    exact: true,
    pageTitle: "AMHWA new supplier",
    component: AMHWA_new_supplier,
  },
  {
    path: "/amhwa-suppliers-list",
    name: "amhwa-suppliers-list",
    exact: true,
    pageTitle: "AMHWA suppliers list",
    component: AMHWA_suppliers_list,
  },
  {
    path: "/amhwa-purchases",
    name: "amhwa-purchases",
    exact: true,
    pageTitle: "AMHWA Purchases",
    component: AMHWA_Purchases,
  },
  {
    path: "/amhwa-new-purchase",
    name: "amhwa-new-purchase",
    exact: true,
    pageTitle: "AMHWA New Purchase",
    component: AMHWA_New_Purchase,
  },
  {
    path: "/amhwa-purchase-list",
    name: "amhwa-purchase-list",
    exact: true,
    pageTitle: "AMHWA Purchase List",
    component: AMHWA_Purchase_List,
  },
  {
    path: "/amhwa-sales-order",
    name: "amhwa-sales-order",
    exact: true,
    pageTitle: "AMHWA Sales Order",
    component: AMHWA_Sales_Order,
  },
  {
    path: "/amhwa-sales-order-list",
    name: "amhwa-sales-order-list",
    exact: true,
    pageTitle: "AMHWA Sales Order List",
    component: AMHWA_Sales_Order_List,
  },
  {
    path: "/amhwa-new-order-invoice",
    name: "amhwa-new-order-invoice",
    exact: true,
    pageTitle: "AMHWA New Order Invoice",
    component: AMHWA_New_Order_Invoice,
  },
  {
    path: "/amhwa-manage-orders",
    name: "amhwa-manage-orders",
    exact: true,
    pageTitle: "AMHWA Manage Orders",
    component: AMHWA_Manage_Orders,
  },
  {
    path: "/amhwa-paid-orders",
    name: "amhwa-paid-orders",
    exact: true,
    pageTitle: "AMHWA Paid Orders",
    component: AMHWA_Paid_Orders,
  },
  {
    path: "/amhwa-unpaid-orders",
    name: "amhwa-unpaid-orders",
    exact: true,
    pageTitle: "AMHWA Unpaid Orders",
    component: AMHWA_Unpaid_Orders,
  },
  {
    path: "/amhwa-delivered-orders",
    name: "amhwa-delivered-orders",
    exact: true,
    pageTitle: "AMHWA Delivered Orders",
    component: AMHWA_Delivered_Orders,
  },
  {
    path: "/amhwa-cancelled-orders",
    name: "amhwa-cancelled-orders",
    exact: true,
    pageTitle: "AMHWA Cancelled Orders",
    component: AMHWA_Cancelled_Orders,
  },
  {
    path: "/amhwa-credit-types",
    name: "amhwa-credit-types",
    exact: true,
    pageTitle: "AMHWA Credit Types",
    component: AMHWA_Credit_Types,
  },
  {
    path: "/amhwa-help-desk",
    name: "amhwa-help-desk",
    exact: true,
    pageTitle: "AMHWA Help Desk",
    component: AMHWA_Help_Desk,
  },
  {
    path: "/amhwa-tickets",
    name: "amhwa-tickets",
    exact: true,
    pageTitle: "AMHWA Tickets",
    component: AMHWA_Tickets,
  },
  {
    path: "/amhwa-tickets-list",
    name: "amhwa-tickets-list",
    exact: true,
    pageTitle: "AMHWA Tickets List",
    component: AMHWA_Tickets_List,
  },
  {
    path: "/amhwa-create-ticket",
    name: "amhwa-create-ticket",
    exact: true,
    pageTitle: "AMHWA Create Ticket",
    component: AMHWA_Create_Ticket,
  },
  {
    path: "/amhwa-support-tickets",
    name: "amhwa-support-tickets",
    exact: true,
    pageTitle: "AMHWA Support Tickets",
    component: AMHWA_Support_Tickets,
  },
  {
    path: "/amhwa-resolved-list",
    name: "amhwa-resolved-list",
    exact: true,
    pageTitle: "AMHWA Resolved List",
    component: AMHWA_Resolved_List,
  },
  {
    path: "/amhwa-assign-tickets-solve",
    name: "amhwa-assign-tickets-solve",
    exact: true,
    pageTitle: "AMHWA Assign Tickets Solve",
    component: AMHWA_Assign_Tickets_Solve,
  },
  {
    path: "/amhwa-leave-requests",
    name: "amhwa-leave-requests",
    exact: true,
    pageTitle: "AMHWA Leave Requests",
    component: AMHWA_Leave_Requests,
  },
  {
    path: "/amhwa-manage-leaves",
    name: "amhwa-manage-leaves",
    exact: true,
    pageTitle: "AMHWA Manage Leaves",
    component: AMHWA_Manage_Leaves,
  },
  {
    path: "/amhwa-leaves-type",
    name: "amhwa-leaves-type",
    exact: true,
    pageTitle: "AMHWA Leaves Type",
    component: AMHWA_Leaves_Type,
  },
  {
    path: "/amhwa-assets-list",
    name: "amhwa-assets-list",
    exact: true,
    pageTitle: "AMHWA Assets List",
    component: AMHWA_Assets_List,
  },
  {
    path: "/amhwa-assets-management",
    name: "amhwa-assets-management",
    exact: true,
    pageTitle: "AMHWA Assets management",
    component: AMHWA_Assets_management,
  },
  {
    path: "/amhwa-create-assert",
    name: "amhwa-create-assert",
    exact: true,
    pageTitle: "AMHWA Create assert",
    component: AMHWA_Create_assert,
  },
  {
    path: "/amhwa-assign-assert",
    name: "amhwa-assign-assert",
    exact: true,
    pageTitle: "AMHWA Assign assert",
    component: AMHWA_Assign_assert,
  },
  {
    path: "/amhwa-destory-assert",
    name: "amhwa-destory-assert",
    exact: true,
    pageTitle: "AMHWA Destory assert",
    component: AMHWA_Destory_assert,
  },
  {
    path: "/amhwa-assert-category",
    name: "amhwa-assert-category",
    exact: true,
    pageTitle: "AMHWA assert Category",
    component: AMHWA_assert_Category,
  },
  {
    path: "/amhwa-new-category",
    name: "amhwa-new-category",
    exact: true,
    pageTitle: "AMHWA New category",
    component: AMHWA_New_category,
  },
  {
    path: "/amhwa-manage-category",
    name: "amhwa-manage-category",
    exact: true,
    pageTitle: "AMHWA Manage category",
    component: AMHWA_Manage_category,
  },
  {
    path: "/amhwa-asset-brand",
    name: "amhwa-asset-brand",
    exact: true,
    pageTitle: "AMHWA Asset Brand",
    component: AMHWA_Asset_Brand,
  },
  {
    path: "/amhwa-new-brand",
    name: "amhwa-new-brand",
    exact: true,
    pageTitle: "AMHWA New Brand",
    component: AMHWA_New_Brand,
  },
  {
    path: "/amhwa-manage-brand",
    name: "amhwa-manage-brand",
    exact: true,
    pageTitle: "AMHWA Manage Brand",
    component: AMHWA_Manage_Brand,
  },
  {
    path: "/amhwa-settings",
    name: "amhwa-settings",
    exact: true,
    pageTitle: "AMHWA Settings",
    component: AMHWA_Settings,
  },
  {
    path: "/a-maps",
    name: "a-maps",
    exact: true,
    pageTitle: "A Maps",
    component: A_Maps,
  },
  {
    path: "/a-inventory-control",
    name: "a-inventory-control",
    exact: true,
    pageTitle: "A Inventory Control",
    component: A_Inventory_Control,
  },
  {
    path: "/a-ware-houses",
    name: "a-ware-houses",
    exact: true,
    pageTitle: "A Ware Houses",
    component: A_Ware_Houses,
  },
  {
    path: "/a-products",
    name: "a-products",
    exact: true,
    pageTitle: "A Products",
    component: A_Products,
  },
  {
    path: "/a-out-of-stock",
    name: "a-out-of-stock",
    exact: true,
    pageTitle: "A Out Of Stock",
    component: A_Out_Of_Stock,
  },
  {
    path: "/a-expired-products",
    name: "a-expired-products",
    exact: true,
    pageTitle: "A Expired Products",
    component: A_Expired_Products,
  },
  {
    path: "/a-product-tax",
    name: "a-product-tax",
    exact: true,
    pageTitle: "A Product Tax",
    component: A_Product_Tax,
  },
  {
    path: "/a-billing-invoices",
    name: "a-billing-invoices",
    exact: true,
    pageTitle: "A Billing Invoices",
    component: A_Billing_Invoices,
  },
  {
    path: "/a-invoice-payments",
    name: "a-invoice-payments",
    exact: true,
    pageTitle: "A Invoice Payments",
    component: A_Invoice_Payments,
  },
  {
    path: "/a-tax-type",
    name: "a-tax-type",
    exact: true,
    pageTitle: "A Tax Type",
    component: A_Tax_Type,
  },
  {
    path: "/a-product-category",
    name: "a-product-category",
    exact: true,
    pageTitle: "A Product Category",
    component: A_Product_Category,
  },
  {
    path: "/a-suppliers",
    name: "a-suppliers",
    exact: true,
    pageTitle: "A Suppliers",
    component: A_Suppliers,
  },
  {
    path: "/a-new-supplier",
    name: "a-new-supplier",
    exact: true,
    pageTitle: "A new supplier",
    component: A_new_supplier,
  },
  {
    path: "/a-suppliers-list",
    name: "a-suppliers-list",
    exact: true,
    pageTitle: "A suppliers list",
    component: A_suppliers_list,
  },
  {
    path: "/a-purchases",
    name: "a-purchases",
    exact: true,
    pageTitle: "A Purchases",
    component: A_Purchases,
  },
  {
    path: "/a-new-purchase",
    name: "a-new-purchase",
    exact: true,
    pageTitle: "A New Purchase",
    component: A_New_Purchase,
  },
  {
    path: "/a-purchase-list",
    name: "a-purchase-list",
    exact: true,
    pageTitle: "A Purchase List",
    component: A_Purchase_List,
  },
  {
    path: "/a-sales-order",
    name: "a-sales-order",
    exact: true,
    pageTitle: "A Sales Order",
    component: A_Sales_Order,
  },
  {
    path: "/a-sales-order-list",
    name: "a-sales-order-list",
    exact: true,
    pageTitle: "A Sales Order List",
    component: A_Sales_Order_List,
  },
  {
    path: "/a-new-order-invoice",
    name: "a-new-order-invoice",
    exact: true,
    pageTitle: "A New Order Invoice",
    component: A_New_Order_Invoice,
  },
  {
    path: "/a-manage-orders",
    name: "a-manage-orders",
    exact: true,
    pageTitle: "A Manage Orders",
    component: A_Manage_Orders,
  },
  {
    path: "/a-paid-orders",
    name: "a-paid-orders",
    exact: true,
    pageTitle: "A Paid Orders",
    component: A_Paid_Orders,
  },
  {
    path: "/a-unpaid-orders",
    name: "a-unpaid-orders",
    exact: true,
    pageTitle: "A Unpaid Orders",
    component: A_Unpaid_Orders,
  },
  {
    path: "/a-delivered-orders",
    name: "a-delivered-orders",
    exact: true,
    pageTitle: "A Delivered Orders",
    component: A_Delivered_Orders,
  },
  {
    path: "/a-cancelled-orders",
    name: "a-cancelled-orders",
    exact: true,
    pageTitle: "A Cancelled Orders",
    component: A_Cancelled_Orders,
  },
  {
    path: "/a-credit-types",
    name: "a-credit-types",
    exact: true,
    pageTitle: "A Credit Types",
    component: A_Credit_Types,
  },
  {
    path: "/a-help-desk",
    name: "a-help-desk",
    exact: true,
    pageTitle: "A Help Desk",
    component: A_Help_Desk,
  },
  {
    path: "/a-tickets",
    name: "a-tickets",
    exact: true,
    pageTitle: "A Tickets",
    component: A_Tickets,
  },
  {
    path: "/a-tickets-list",
    name: "a-tickets-list",
    exact: true,
    pageTitle: "A Tickets List",
    component: A_Tickets_List,
  },
  {
    path: "/a-create-ticket",
    name: "a-create-ticket",
    exact: true,
    pageTitle: "A Create Ticket",
    component: A_Create_Ticket,
  },
  {
    path: "/a-support-tickets",
    name: "a-support-tickets",
    exact: true,
    pageTitle: "A Support Tickets",
    component: A_Support_Tickets,
  },
  {
    path: "/a-resolved-list",
    name: "a-resolved-list",
    exact: true,
    pageTitle: "A Resolved List",
    component: A_Resolved_List,
  },
  {
    path: "/a-assign-tickets-solve",
    name: "a-assign-tickets-solve",
    exact: true,
    pageTitle: "A Assign Tickets Solve",
    component: A_Assign_Tickets_Solve,
  },
  {
    path: "/a-leave-requests",
    name: "a-leave-requests",
    exact: true,
    pageTitle: "A Leave Requests",
    component: A_Leave_Requests,
  },
  {
    path: "/a-manage-leaves",
    name: "a-manage-leaves",
    exact: true,
    pageTitle: "A Manage Leaves",
    component: A_Manage_Leaves,
  },
  {
    path: "/a-leaves-type",
    name: "a-leaves-type",
    exact: true,
    pageTitle: "A Leaves Type",
    component: A_Leaves_Type,
  },
  {
    path: "/a-assets-list",
    name: "a-assets-list",
    exact: true,
    pageTitle: "A Assets List",
    component: A_Assets_List,
  },
  {
    path: "/a-assets-management",
    name: "a-assets-management",
    exact: true,
    pageTitle: "A Assets management",
    component: A_Assets_management,
  },
  {
    path: "/a-create-assert",
    name: "a-create-assert",
    exact: true,
    pageTitle: "A Create assert",
    component: A_Create_assert,
  },
  {
    path: "/a-assign-assert",
    name: "a-assign-assert",
    exact: true,
    pageTitle: "A Assign assert",
    component: A_Assign_assert,
  },
  {
    path: "/a-destory-assert",
    name: "a-destory-assert",
    exact: true,
    pageTitle: "A Destory assert",
    component: A_Destory_assert,
  },
  {
    path: "/a-assert-category",
    name: "a-assert-category",
    exact: true,
    pageTitle: "A assert Category",
    component: A_assert_Category,
  },
  {
    path: "/a-new-category",
    name: "a-new-category",
    exact: true,
    pageTitle: "A New category",
    component: A_New_category,
  },
  {
    path: "/a-manage-category",
    name: "a-manage-category",
    exact: true,
    pageTitle: "A Manage category",
    component: A_Manage_category,
  },
  {
    path: "/a-asset-brand",
    name: "a-asset-brand",
    exact: true,
    pageTitle: "A Asset Brand",
    component: A_Asset_Brand,
  },
  {
    path: "/a-new-brand",
    name: "a-new-brand",
    exact: true,
    pageTitle: "A New Brand",
    component: A_New_Brand,
  },
  {
    path: "/a-manage-brand",
    name: "a-manage-brand",
    exact: true,
    pageTitle: "A Manage Brand",
    component: A_Manage_Brand,
  },
  {
    path: "/a-settings",
    name: "a-settings",
    exact: true,
    pageTitle: "A Settings",
    component: A_Settings,
  },
  {
    path: "/mism-inventroy-control",
    name: "mism-inventroy-control",
    exact: true,
    pageTitle: "MISM Inventroy Control",
    component: MISM_Inventroy_Control,
  },
  {
    path: "/mism-ware-houses",
    name: "mism-ware-houses",
    exact: true,
    pageTitle: "MISM Ware Houses",
    component: MISM_Ware_Houses,
  },
  {
    path: "/mism-products",
    name: "mism-products",
    exact: true,
    pageTitle: "MISM Products",
    component: MISM_Products,
  },
  {
    path: "/mism-billing-invoices",
    name: "mism-billing-invoices",
    exact: true,
    pageTitle: "MISM Billing Invoices",
    component: MISM_Billing_Invoices,
  },
  {
    path: "/mism-invoice-payments",
    name: "mism-invoice-payments",
    exact: true,
    pageTitle: "MISM Invoice Payments",
    component: MISM_Invoice_Payments,
  },
  {
    path: "/mism-tax-type",
    name: "mism-tax-type",
    exact: true,
    pageTitle: "MISM Tax Type",
    component: MISM_Tax_Type,
  },
  {
    path: "/mism-product-category",
    name: "mism-product-category",
    exact: true,
    pageTitle: "MISM Product Category",
    component: MISM_Product_Category,
  },
  {
    path: "/mism-suppliers",
    name: "mism-suppliers",
    exact: true,
    pageTitle: "MISM Suppliers",
    component: MISM_Suppliers,
  },
  {
    path: "/mism-new-supplier",
    name: "mism-new-supplier",
    exact: true,
    pageTitle: "MISM new supplier",
    component: MISM_new_supplier,
  },
  {
    path: "/mism-suppliers-list",
    name: "mism-suppliers-list",
    exact: true,
    pageTitle: "MISM suppliers list",
    component: MISM_suppliers_list,
  },
  {
    path: "/mism-purchases",
    name: "mism-purchases",
    exact: true,
    pageTitle: "MISM Purchases",
    component: MISM_Purchases,
  },
  {
    path: "/mism-new-purchase",
    name: "mism-new-purchase",
    exact: true,
    pageTitle: "MISM New Purchase",
    component: MISM_New_Purchase,
  },
  {
    path: "/mism-purchase-list",
    name: "mism-purchase-list",
    exact: true,
    pageTitle: "MISM Purchase List",
    component: MISM_Purchase_List,
  },
  {
    path: "/mism-sales-order",
    name: "mism-sales-order",
    exact: true,
    pageTitle: "MISM Sales Order",
    component: MISM_Sales_Order,
  },
  {
    path: "/mism-sales-order-list",
    name: "mism-sales-order-list",
    exact: true,
    pageTitle: "MISM Sales Order List",
    component: MISM_Sales_Order_List,
  },
  {
    path: "/mism-new-order-invoice",
    name: "mism-new-order-invoice",
    exact: true,
    pageTitle: "MISM New Order Invoice",
    component: MISM_New_Order_Invoice,
  },
  {
    path: "/mism-manage-orders",
    name: "mism-manage-orders",
    exact: true,
    pageTitle: "MISM Manage Orders",
    component: MISM_Manage_Orders,
  },
  {
    path: "/mism-paid-orders",
    name: "mism-paid-orders",
    exact: true,
    pageTitle: "MISM Paid Orders",
    component: MISM_Paid_Orders,
  },
  {
    path: "/mism-unpaid-orders",
    name: "mism-unpaid-orders",
    exact: true,
    pageTitle: "MISM Unpaid Orders",
    component: MISM_Unpaid_Orders,
  },
  {
    path: "/mism-delivered-orders",
    name: "mism-delivered-orders",
    exact: true,
    pageTitle: "MISM Delivered Orders",
    component: MISM_Delivered_Orders,
  },
  {
    path: "/mism-cancelled-orders",
    name: "mism-cancelled-orders",
    exact: true,
    pageTitle: "MISM Cancelled Orders",
    component: MISM_Cancelled_Orders,
  },
  {
    path: "/mism-credit-types",
    name: "mism-credit-types",
    exact: true,
    pageTitle: "MISM Credit Types",
    component: MISM_Credit_Types,
  },
  {
    path: "/mism-help-desk",
    name: "mism-help-desk",
    exact: true,
    pageTitle: "MISM Help Desk",
    component: MISM_Help_Desk,
  },
  {
    path: "/mism-tickets",
    name: "mism-tickets",
    exact: true,
    pageTitle: "MISM Tickets",
    component: MISM_Tickets,
  },
  {
    path: "/mism-tickets-list",
    name: "mism-tickets-list",
    exact: true,
    pageTitle: "MISM Tickets List",
    component: MISM_Tickets_List,
  },
  {
    path: "/mism-create-ticket",
    name: "mism-create-ticket",
    exact: true,
    pageTitle: "MISM Create Ticket",
    component: MISM_Create_Ticket,
  },
  {
    path: "/mism-support-tickets",
    name: "mism-support-tickets",
    exact: true,
    pageTitle: "MISM Support Tickets",
    component: MISM_Support_Tickets,
  },
  {
    path: "/mism-resolved-list",
    name: "mism-resolved-list",
    exact: true,
    pageTitle: "MISM Resolved List",
    component: MISM_Resolved_List,
  },
  {
    path: "/mism-assign-tickets-solved",
    name: "mism-assign-tickets-solved",
    exact: true,
    pageTitle: "MISM Assign Tickets Solved",
    component: MISM_Assign_Tickets_Solved,
  },
  {
    path: "/mism-leave-requests",
    name: "mism-leave-requests",
    exact: true,
    pageTitle: "MISM Leave Requests",
    component: MISM_Leave_Requests,
  },
  {
    path: "/mism-manage-leaves",
    name: "mism-manage-leaves",
    exact: true,
    pageTitle: "MISM Manage Leaves",
    component: MISM_Manage_Leaves,
  },
  {
    path: "/mism-leaves-type",
    name: "mism-leaves-type",
    exact: true,
    pageTitle: "MISM Leaves Type",
    component: MISM_Leaves_Type,
  },
  {
    path: "/mism-asserts",
    name: "mism-asserts",
    exact: true,
    pageTitle: "MISM Asserts",
    component: MISM_Asserts,
  },
  {
    path: "/mism-assets-list",
    name: "mism-assets-list",
    exact: true,
    pageTitle: "MISM Assets List",
    component: MISM_Assets_List,
  },
  {
    path: "/mism-assets-management",
    name: "mism-assets-management",
    exact: true,
    pageTitle: "MISM Assets management",
    component: MISM_Assets_management,
  },
  {
    path: "/mism-create-assert",
    name: "mism-create-assert",
    exact: true,
    pageTitle: "MISM Create assert",
    component: MISM_Create_assert,
  },
  {
    path: "/mism-assign-assert",
    name: "mism-assign-assert",
    exact: true,
    pageTitle: "MISM Assign assert",
    component: MISM_Assign_assert,
  },
  {
    path: "/mism-destory-assert",
    name: "mism-destory-assert",
    exact: true,
    pageTitle: "MISM Destory assert",
    component: MISM_Destory_assert,
  },
  {
    path: "/mism-assert-category",
    name: "mism-assert-category",
    exact: true,
    pageTitle: "MISM assert Category",
    component: MISM_assert_Category,
  },
  {
    path: "/mism-new-category",
    name: "mism-new-category",
    exact: true,
    pageTitle: "MISM New category",
    component: MISM_New_category,
  },
  {
    path: "/mism-manage-category",
    name: "mism-manage-category",
    exact: true,
    pageTitle: "MISM Manage category",
    component: MISM_Manage_category,
  },
  {
    path: "/mism-asset-brand",
    name: "mism-asset-brand",
    exact: true,
    pageTitle: "MISM Asset Brand",
    component: MISM_Asset_Brand,
  },
  {
    path: "/mism-new-brand",
    name: "mism-new-brand",
    exact: true,
    pageTitle: "MISM New Brand",
    component: MISM_New_Brand,
  },
  {
    path: "/mism-manage-brand",
    name: "mism-manage-brand",
    exact: true,
    pageTitle: "MISM Manage Brand",
    component: MISM_Manage_Brand,
  },
  {
    path: "/mism-reports",
    name: "mism-reports",
    exact: true,
    pageTitle: "MISM Reports",
    component: MISM_Reports,
  },
  {
    path: "/mism-daily",
    name: "mism-daily",
    exact: true,
    pageTitle: "MISM Daily",
    component: MISM_Daily,
  },
  {
    path: "/mism-monthly",
    name: "mism-monthly",
    exact: true,
    pageTitle: "MISM Monthly",
    component: MISM_Monthly,
  },
  {
    path: "/mism-yearly",
    name: "mism-yearly",
    exact: true,
    pageTitle: "MISM Yearly",
    component: MISM_Yearly,
  },
  {
    path: "/mism-process",
    name: "mism-process",
    exact: true,
    pageTitle: "MISM Process",
    component: MISM_Process,
  },
  {
    path: "/mism-settings",
    name: "mism-settings",
    exact: true,
    pageTitle: "MISM Settings",
    component: MISM_Settings,
  },
  {
    path: "/mise-inventroy-control",
    name: "mise-inventroy-control",
    exact: true,
    pageTitle: "MISE Inventroy Control",
    component: MISE_Inventroy_Control,
  },
  {
    path: "/mise-ware-houses",
    name: "mise-ware-houses",
    exact: true,
    pageTitle: "MISE Ware Houses",
    component: MISE_Ware_Houses,
  },
  {
    path: "/mise-products",
    name: "mise-products",
    exact: true,
    pageTitle: "MISE Products",
    component: MISE_Products,
  },
  {
    path: "/mise-billing-invoices",
    name: "mise-billing-invoices",
    exact: true,
    pageTitle: "MISE Billing Invoices",
    component: MISE_Billing_Invoices,
  },
  {
    path: "/mise-invoice-payments",
    name: "mise-invoice-payments",
    exact: true,
    pageTitle: "MISE Invoice Payments",
    component: MISE_Invoice_Payments,
  },
  {
    path: "/mise-tax-type",
    name: "mise-tax-type",
    exact: true,
    pageTitle: "MISE Tax Type",
    component: MISE_Tax_Type,
  },
  {
    path: "/mise-product-category",
    name: "mise-product-category",
    exact: true,
    pageTitle: "MISE Product Category",
    component: MISE_Product_Category,
  },
  {
    path: "/mise-suppliers",
    name: "mise-suppliers",
    exact: true,
    pageTitle: "MISE Suppliers",
    component: MISE_Suppliers,
  },
  {
    path: "/mise-new-supplier",
    name: "mise-new-supplier",
    exact: true,
    pageTitle: "MISE new supplier",
    component: MISE_new_supplier,
  },
  {
    path: "/mise-suppliers-list",
    name: "mise-suppliers-list",
    exact: true,
    pageTitle: "MISE suppliers list",
    component: MISE_suppliers_list,
  },
  {
    path: "/mise-purchases",
    name: "mise-purchases",
    exact: true,
    pageTitle: "MISE Purchases",
    component: MISE_Purchases,
  },
  {
    path: "/mise-new-purchase",
    name: "mise-new-purchase",
    exact: true,
    pageTitle: "MISE New Purchase",
    component: MISE_New_Purchase,
  },
  {
    path: "/mise-purchase-list",
    name: "mise-purchase-list",
    exact: true,
    pageTitle: "MISE Purchase List",
    component: MISE_Purchase_List,
  },
  {
    path: "/mise-sales-order",
    name: "mise-sales-order",
    exact: true,
    pageTitle: "MISE Sales Order",
    component: MISE_Sales_Order,
  },
  {
    path: "/mise-sales-order-list",
    name: "mise-sales-order-list",
    exact: true,
    pageTitle: "MISE Sales Order List",
    component: MISE_Sales_Order_List,
  },
  {
    path: "/mise-new-order-invoice",
    name: "mise-new-order-invoice",
    exact: true,
    pageTitle: "MISE New Order Invoice",
    component: MISE_New_Order_Invoice,
  },
  {
    path: "/mise-manage-orders",
    name: "mise-manage-orders",
    exact: true,
    pageTitle: "MISE Manage Orders",
    component: MISE_Manage_Orders,
  },
  {
    path: "/mise-paid-orders",
    name: "mise-paid-orders",
    exact: true,
    pageTitle: "MISE Paid Orders",
    component: MISE_Paid_Orders,
  },
  {
    path: "/mise-unpaid-orders",
    name: "mise-unpaid-orders",
    exact: true,
    pageTitle: "MISE Unpaid Orders",
    component: MISE_Unpaid_Orders,
  },
  {
    path: "/mise-delivered-orders",
    name: "mise-delivered-orders",
    exact: true,
    pageTitle: "MISE Delivered Orders",
    component: MISE_Delivered_Orders,
  },
  {
    path: "/mise-cancelled-orders",
    name: "mise-cancelled-orders",
    exact: true,
    pageTitle: "MISE Cancelled Orders",
    component: MISE_Cancelled_Orders,
  },
  {
    path: "/mise-credit-types",
    name: "mise-credit-types",
    exact: true,
    pageTitle: "MISE Credit Types",
    component: MISE_Credit_Types,
  },
  {
    path: "/mise-help-desk",
    name: "mise-help-desk",
    exact: true,
    pageTitle: "MISE Help Desk",
    component: MISE_Help_Desk,
  },
  {
    path: "/mise-tickets",
    name: "mise-tickets",
    exact: true,
    pageTitle: "MISE Tickets",
    component: MISE_Tickets,
  },
  {
    path: "/mise-tickets-list",
    name: "mise-tickets-list",
    exact: true,
    pageTitle: "MISE Tickets List",
    component: MISE_Tickets_List,
  },
  {
    path: "/mise-create-ticket",
    name: "mise-create-ticket",
    exact: true,
    pageTitle: "MISE Create Ticket",
    component: MISE_Create_Ticket,
  },
  {
    path: "/mise-support-tickets",
    name: "mise-support-tickets",
    exact: true,
    pageTitle: "MISE Support Tickets",
    component: MISE_Support_Tickets,
  },
  {
    path: "/mise-resolved-list",
    name: "mise-resolved-list",
    exact: true,
    pageTitle: "MISE Resolved List",
    component: MISE_Resolved_List,
  },
  {
    path: "/mise-assign-tickets-solved",
    name: "mise-assign-tickets-solved",
    exact: true,
    pageTitle: "MISE Assign Tickets Solved",
    component: MISE_Assign_Tickets_Solved,
  },
  {
    path: "/mise-leave-requests",
    name: "mise-leave-requests",
    exact: true,
    pageTitle: "MISE Leave Requests",
    component: MISE_Leave_Requests,
  },
  {
    path: "/mise-manage-leaves",
    name: "mise-manage-leaves",
    exact: true,
    pageTitle: "MISE Manage Leaves",
    component: MISE_Manage_Leaves,
  },
  {
    path: "/mise-leaves-type",
    name: "mise-leaves-type",
    exact: true,
    pageTitle: "MISE Leaves Type",
    component: MISE_Leaves_Type,
  },
  {
    path: "/mise-asserts",
    name: "mise-asserts",
    exact: true,
    pageTitle: "MISE Asserts",
    component: MISE_Asserts,
  },
  {
    path: "/mise-assets-list",
    name: "mise-assets-list",
    exact: true,
    pageTitle: "MISE Assets List",
    component: MISE_Assets_List,
  },
  {
    path: "/mise-assets-management",
    name: "mise-assets-management",
    exact: true,
    pageTitle: "MISE Assets management",
    component: MISE_Assets_management,
  },
  {
    path: "/mise-create-assert",
    name: "mise-create-assert",
    exact: true,
    pageTitle: "MISE Create assert",
    component: MISE_Create_assert,
  },
  {
    path: "/mise-assign-assert",
    name: "mise-assign-assert",
    exact: true,
    pageTitle: "MISE Assign assert",
    component: MISE_Assign_assert,
  },
  {
    path: "/mise-destory-assert",
    name: "mise-destory-assert",
    exact: true,
    pageTitle: "MISE Destory assert",
    component: MISE_Destory_assert,
  },
  {
    path: "/mise-assert-category",
    name: "mise-assert-category",
    exact: true,
    pageTitle: "MISE assert Category",
    component: MISE_assert_Category,
  },
  {
    path: "/mise-new-category",
    name: "mise-new-category",
    exact: true,
    pageTitle: "MISE New category",
    component: MISE_New_category,
  },
  {
    path: "/mise-manage-category",
    name: "mise-manage-category",
    exact: true,
    pageTitle: "MISE Manage category",
    component: MISE_Manage_category,
  },
  {
    path: "/mise-asset-brand",
    name: "mise-asset-brand",
    exact: true,
    pageTitle: "MISE Asset Brand",
    component: MISE_Asset_Brand,
  },
  {
    path: "/mise-new-brand",
    name: "mise-new-brand",
    exact: true,
    pageTitle: "MISE New Brand",
    component: MISE_New_Brand,
  },
  {
    path: "/mise-manage-brand",
    name: "mise-manage-brand",
    exact: true,
    pageTitle: "MISE Manage Brand",
    component: MISE_Manage_Brand,
  },
  {
    path: "/mise-reports",
    name: "mise-reports",
    exact: true,
    pageTitle: "MISE Reports",
    component: MISE_Reports,
  },
  {
    path: "/mise-daily",
    name: "mise-daily",
    exact: true,
    pageTitle: "MISE Daily",
    component: MISE_Daily,
  },
  {
    path: "/mise-monthly",
    name: "mise-monthly",
    exact: true,
    pageTitle: "MISE Monthly",
    component: MISE_Monthly,
  },
  {
    path: "/mise-yearly",
    name: "mise-yearly",
    exact: true,
    pageTitle: "MISE Yearly",
    component: MISE_Yearly,
  },
  {
    path: "/mise-process",
    name: "mise-process",
    exact: true,
    pageTitle: "MISE Process",
    component: MISE_Process,
  },
  {
    path: "/mise-settings",
    name: "mise-settings",
    exact: true,
    pageTitle: "MISE Settings",
    component: MISE_Settings,
  },
  {
    path: "/ph-leave-requests",
    name: "ph-leave-requests",
    exact: true,
    pageTitle: "PH Leave Requests",
    component: PH_Leave_Requests,
  },
  {
    path: "/ph-manage-leaves",
    name: "ph-manage-leaves",
    exact: true,
    pageTitle: "PH Manage Leaves",
    component: PH_Manage_Leaves,
  },
  {
    path: "/ph-leaves-type",
    name: "ph-leaves-type",
    exact: true,
    pageTitle: "PH Leaves Type",
    component: PH_Leaves_Type,
  },
  {
    path: "/ph-processes",
    name: "ph-processes",
    exact: true,
    pageTitle: "PH Processes",
    component: PH_Processes,
  },
  {
    path: "/ph-new-process",
    name: "ph-new-process",
    exact: true,
    pageTitle: "PH New Process",
    component: PH_New_Process,
  },
  {
    path: "/ph-process-flow",
    name: "ph-process-flow",
    exact: true,
    pageTitle: "PH Process flow",
    component: PH_Process_flow,
  },
  {
    path: "/ph-process-management",
    name: "ph-process-management",
    exact: true,
    pageTitle: "PH Process Management",
    component: PH_Process_Management,
  },
  {
    path: "/ph-completed-process",
    name: "ph-completed-process",
    exact: true,
    pageTitle: "PH Completed Process",
    component: PH_Completed_Process,
  },
  {
    path: "/ph-complacencies",
    name: "ph-complacencies",
    exact: true,
    pageTitle: "PH Complacencies",
    component: PH_Complacencies,
  },
  {
    path: "/ph-process",
    name: "ph-process",
    exact: true,
    pageTitle: "PH Process",
    component: PH_Process,
  },
  {
    path: "/ph-client",
    name: "ph-client",
    exact: true,
    pageTitle: "PH Client",
    component: PH_Client,
  },
  {
    path: "/ph-reports",
    name: "ph-reports",
    exact: true,
    pageTitle: "PH Reports",
    component: PH_Reports,
  },
  {
    path: "/ph-daily",
    name: "ph-daily",
    exact: true,
    pageTitle: "PH Daily",
    component: PH_Daily,
  },
  {
    path: "/ph-monthly",
    name: "ph-monthly",
    exact: true,
    pageTitle: "PH Monthly",
    component: PH_Monthly,
  },
  {
    path: "/ph-yearly",
    name: "ph-yearly",
    exact: true,
    pageTitle: "PH Yearly",
    component: PH_Yearly,
  },
  {
    path: "/ph-process",
    name: "ph-process",
    exact: true,
    pageTitle: "PH Process",
    component: PH_Process,
  },
  {
    path: "/ph-payments-and-commissions",
    name: "ph-payments-and-commissions",
    exact: true,
    pageTitle: "PH Payments and Commissions",
    component: PH_Payments_and_Commissions,
  },
  {
    path: "/ph-messaging",
    name: "ph-messaging",
    exact: true,
    pageTitle: "PH Messaging",
    component: PH_Messaging,
  },
  {
    path: "/ph-settings",
    name: "ph-settings",
    exact: true,
    pageTitle: "PH Settings",
    component: PH_Settings,
  },
  {
    path: "/pm-leave-requests",
    name: "pm-leave-requests",
    exact: true,
    pageTitle: "PM Leave Requests",
    component: PM_Leave_Requests,
  },
  {
    path: "/pm-manage-leaves",
    name: "pm-manage-leaves",
    exact: true,
    pageTitle: "PM Manage Leaves",
    component: PM_Manage_Leaves,
  },
  {
    path: "/pm-leaves-type",
    name: "pm-leaves-type",
    exact: true,
    pageTitle: "PM Leaves Type",
    component: PM_Leaves_Type,
  },
  {
    path: "/pm-processes",
    name: "pm-processes",
    exact: true,
    pageTitle: "PM Processes",
    component: PM_Processes,
  },
  {
    path: "/pm-new-process",
    name: "pm-new-process",
    exact: true,
    pageTitle: "PM New Process",
    component: PM_New_Process,
  },
  {
    path: "/pm-process-flow",
    name: "pm-process-flow",
    exact: true,
    pageTitle: "PM Process flow",
    component: PM_Process_flow,
  },
  {
    path: "/pm-process-management",
    name: "pm-process-management",
    exact: true,
    pageTitle: "PM Process Management",
    component: PM_Process_Management,
  },
  {
    path: "/pm-completed-process",
    name: "pm-completed-process",
    exact: true,
    pageTitle: "PM Completed Process",
    component: PM_Completed_Process,
  },
  {
    path: "/pm-complacencies",
    name: "pm-complacencies",
    exact: true,
    pageTitle: "PM Complacencies",
    component: PM_Complacencies,
  },
  {
    path: "/pm-process",
    name: "pm-process",
    exact: true,
    pageTitle: "PM Process",
    component: PM_Process,
  },
  {
    path: "/pm-client",
    name: "pm-client",
    exact: true,
    pageTitle: "PM Client",
    component: PM_Client,
  },
  {
    path: "/pm-reports",
    name: "pm-reports",
    exact: true,
    pageTitle: "PM Reports",
    component: PM_Reports,
  },
  {
    path: "/pm-daily",
    name: "pm-daily",
    exact: true,
    pageTitle: "PM Daily",
    component: PM_Daily,
  },
  {
    path: "/pm-monthly",
    name: "pm-monthly",
    exact: true,
    pageTitle: "PM Monthly",
    component: PM_Monthly,
  },
  {
    path: "/pm-yearly",
    name: "pm-yearly",
    exact: true,
    pageTitle: "PM Yearly",
    component: PM_Yearly,
  },
  {
    path: "/pm-process",
    name: "pm-process",
    exact: true,
    pageTitle: "PM Process",
    component: PM_Process,
  },
  {
    path: "/pm-payments-and-commissions",
    name: "pm-payments-and-commissions",
    exact: true,
    pageTitle: "PM Payments and Commissions",
    component: PM_Payments_and_Commissions,
  },
  {
    path: "/pm-messaging",
    name: "pm-messaging",
    exact: true,
    pageTitle: "PM Messaging",
    component: PM_Messaging,
  },
  {
    path: "/pm-settings",
    name: "pm-settings",
    exact: true,
    pageTitle: "PM Settings",
    component: PM_Settings,
  },
  {
    path: "/tlfe-leave-requests",
    name: "tlfe-leave-requests",
    exact: true,
    pageTitle: "TLFE Leave Requests",
    component: TLFE_Leave_Requests,
  },
  {
    path: "/tlfe-manage-leaves",
    name: "tlfe-manage-leaves",
    exact: true,
    pageTitle: "TLFE Manage Leaves",
    component: TLFE_Manage_Leaves,
  },
  {
    path: "/tlfe-leaves-type",
    name: "tlfe-leaves-type",
    exact: true,
    pageTitle: "TLFE Leaves Type",
    component: TLFE_Leaves_Type,
  },
  {
    path: "/tlfe-executives",
    name: "tlfe-executives",
    exact: true,
    pageTitle: "TLFE Executives",
    component: TLFE_Executives,
  },
  {
    path: "/tlfe-assigned",
    name: "tlfe-assigned",
    exact: true,
    pageTitle: "TLFE Assigned",
    component: TLFE_Assigned,
  },
  {
    path: "/tlfe-managed",
    name: "tlfe-managed",
    exact: true,
    pageTitle: "TLFE Managed",
    component: TLFE_Managed,
  },
  {
    path: "/tlfe-cases",
    name: "tlfe-cases",
    exact: true,
    pageTitle: "TLFE Cases",
    component: TLFE_Cases,
  },
  {
    path: "/tlfe-assigned",
    name: "tlfe-assigned",
    exact: true,
    pageTitle: "TLFE Assigned",
    component: TLFE_Assigned,
  },
  {
    path: "/tlfe-managed",
    name: "tlfe-managed",
    exact: true,
    pageTitle: "TLFE Managed",
    component: TLFE_Managed,
  },
  {
    path: "/tlfe-reassigned",
    name: "tlfe-reassigned",
    exact: true,
    pageTitle: "TLFE Reassigned",
    component: TLFE_Reassigned,
  },
  {
    path: "/tlfe-disciplinary",
    name: "tlfe-disciplinary",
    exact: true,
    pageTitle: "TLFE Disciplinary",
    component: TLFE_Disciplinary,
  },
  {
    path: "/tlfe-actions",
    name: "tlfe-actions",
    exact: true,
    pageTitle: "TLFE Actions",
    component: TLFE_Actions,
  },
  {
    path: "/tlfe-resolutions",
    name: "tlfe-resolutions",
    exact: true,
    pageTitle: "TLFE Resolutions",
    component: TLFE_Resolutions,
  },
  {
    path: "/tlfe-payments-and-commissions",
    name: "tlfe-payments-and-commissions",
    exact: true,
    pageTitle: "TLFE Payments and Commissions",
    component: TLFE_Payments_and_Commissions,
  },
  {
    path: "/tlfe-messaging",
    name: "tlfe-messaging",
    exact: true,
    pageTitle: "TLFE Messaging",
    component: TLFE_Messaging,
  },
  {
    path: "/tlfe-settings",
    name: "tlfe-settings",
    exact: true,
    pageTitle: "TLFE Settings",
    component: TLFE_Settings,
  },
  {
    path: "/tlpb-leave-requests",
    name: "tlpb-leave-requests",
    exact: true,
    pageTitle: "TLPB Leave Requests",
    component: TLPB_Leave_Requests,
  },
  {
    path: "/tlpb-manage-leaves",
    name: "tlpb-manage-leaves",
    exact: true,
    pageTitle: "TLPB Manage Leaves",
    component: TLPB_Manage_Leaves,
  },
  {
    path: "/tlpb-leaves-type",
    name: "tlpb-leaves-type",
    exact: true,
    pageTitle: "TLPB Leaves Type",
    component: TLPB_Leaves_Type,
  },
  {
    path: "/tlpb-phonebanker",
    name: "tlpb-phonebanker",
    exact: true,
    pageTitle: "TLPB PhoneBanker",
    component: TLPB_PhoneBanker,
  },
  {
    path: "/tlpb-assigned",
    name: "tlpb-assigned",
    exact: true,
    pageTitle: "TLPB Assigned",
    component: TLPB_Assigned,
  },
  {
    path: "/tlpb-managed",
    name: "tlpb-managed",
    exact: true,
    pageTitle: "TLPB Managed",
    component: TLPB_Managed,
  },
  {
    path: "/tlpb-cases",
    name: "tlpb-cases",
    exact: true,
    pageTitle: "TLPB Cases",
    component: TLPB_Cases,
  },
  {
    path: "/tlpb-assigned",
    name: "tlpb-assigned",
    exact: true,
    pageTitle: "TLPB Assigned",
    component: TLPB_Assigned,
  },
  {
    path: "/tlpb-managed",
    name: "tlpb-managed",
    exact: true,
    pageTitle: "TLPB Managed",
    component: TLPB_Managed,
  },
  {
    path: "/tlpb-reassigned",
    name: "tlpb-reassigned",
    exact: true,
    pageTitle: "TLPB Reassigned",
    component: TLPB_Reassigned,
  },
  {
    path: "/tlpb-disciplinary",
    name: "tlpb-disciplinary",
    exact: true,
    pageTitle: "TLPB Disciplinary",
    component: TLPB_Disciplinary,
  },
  {
    path: "/tlpb-actions",
    name: "tlpb-actions",
    exact: true,
    pageTitle: "TLPB Actions",
    component: TLPB_Actions,
  },
  {
    path: "/tlpb-resolutions",
    name: "tlpb-resolutions",
    exact: true,
    pageTitle: "TLPB Resolutions",
    component: TLPB_Resolutions,
  },
  {
    path: "/tlpb-payments-and-commissions",
    name: "tlpb-payments-and-commissions",
    exact: true,
    pageTitle: "TLPB Payments and Commissions",
    component: TLPB_Payments_and_Commissions,
  },
  {
    path: "/tlpb-messaging",
    name: "tlpb-messaging",
    exact: true,
    pageTitle: "TLPB Messaging",
    component: TLPB_Messaging,
  },
  {
    path: "/tlpb-settings",
    name: "tlpb-settings",
    exact: true,
    pageTitle: "TLPB Settings",
    component: TLPB_Settings,
  },
  {
    path: "/tlt-tracing",
    name: " tlt-tracing ",
    exact: true,
    pageTitle: " TLT Tracing ",
    component: TLT_Tracing,
  },
  {
    path: "/tlt-identifying",
    name: " tlt-identifying ",
    exact: true,
    pageTitle: " TLT Identifying ",
    component: TLT_Identifying,
  },
  {
    path: "/tlt-search",
    name: " tlt-search ",
    exact: true,
    pageTitle: " TLT Search ",
    component: TLT_Search,
  },
  {
    path: "/tlt-results",
    name: " tlt-results ",
    exact: true,
    pageTitle: " TLT Results ",
    component: TLT_Results,
  },
  {
    path: "/tlt-tracers",
    name: " tlt-tracers ",
    exact: true,
    pageTitle: " TLT Tracers ",
    component: TLT_Tracers,
  },
  {
    path: "/tlt-assigned",
    name: " tlt-assigned ",
    exact: true,
    pageTitle: " TLT Assigned ",
    component: TLT_Assigned,
  },
  {
    path: "/tlt-managed",
    name: " tlt-managed ",
    exact: true,
    pageTitle: " TLT Managed ",
    component: TLT_Managed,
  },
  {
    path: "/tlt-cases",
    name: " tlt-cases ",
    exact: true,
    pageTitle: " TLT Cases ",
    component: TLT_Cases,
  },
  {
    path: "/tlt-assigned",
    name: " tlt-assigned ",
    exact: true,
    pageTitle: " TLT Assigned ",
    component: TLT_Assigned,
  },
  {
    path: "/tlt-managed",
    name: " tlt-managed ",
    exact: true,
    pageTitle: " TLT Managed ",
    component: TLT_Managed,
  },
  {
    path: "/tlt-reassigned",
    name: " tlt-reassigned ",
    exact: true,
    pageTitle: " TLT Reassigned ",
    component: TLT_Reassigned,
  },
  {
    path: "/tlt-disciplinary",
    name: " tlt-disciplinary ",
    exact: true,
    pageTitle: " TLT Disciplinary ",
    component: TLT_Disciplinary,
  },
  {
    path: "/tlt-actions",
    name: " tlt-actions ",
    exact: true,
    pageTitle: " TLT Actions ",
    component: TLT_Actions,
  },
  {
    path: "/tlt-resolutions",
    name: " tlt-resolutions ",
    exact: true,
    pageTitle: " TLT Resolutions ",
    component: TLT_Resolutions,
  },
  {
    path: "/tlt-payments-and-commissions",
    name: " tlt-payments-and-commissions ",
    exact: true,
    pageTitle: " TLT Payments and Commissions ",
    component: TLT_Payments_and_Commissions,
  },
  {
    path: "/tlt-messaging",
    name: " tlt-messaging ",
    exact: true,
    pageTitle: " TLT Messaging ",
    component: TLT_Messaging,
  },
  {
    path: "/tlt-leave-requests",
    name: " tlt-leave-requests ",
    exact: true,
    pageTitle: " TLT Leave Requests ",
    component: TLT_Leave_Requests,
  },
  {
    path: "/tlt-manage-leaves",
    name: " tlt-manage-leaves ",
    exact: true,
    pageTitle: " TLT Manage Leaves ",
    component: TLT_Manage_Leaves,
  },
  {
    path: "/tlt-leaves-type",
    name: " tlt-leaves-type ",
    exact: true,
    pageTitle: " TLT Leaves Type ",
    component: TLT_Leaves_Type,
  },
  {
    path: "/tlt-settings",
    name: " tlt-settings ",
    exact: true,
    pageTitle: " TLT Settings ",
    component: TLT_Settings,
  },
  {
    path: "/pb-cases",
    name: " pb-cases ",
    exact: true,
    pageTitle: " PB Cases ",
    component: PB_Cases,
  },
  {
    path: "/pb-assigned",
    name: " pb-assigned ",
    exact: true,
    pageTitle: " PB Assigned ",
    component: PB_Assigned,
  },
  {
    path: "/pb-managed",
    name: " pb-managed ",
    exact: true,
    pageTitle: " PB Managed ",
    component: PB_Managed,
  },
  {
    path: "/pb-reassigned",
    name: " pb-reassigned ",
    exact: true,
    pageTitle: " PB Reassigned ",
    component: PB_Reassigned,
  },
  {
    path: "/pb-disciplinary",
    name: " pb-disciplinary ",
    exact: true,
    pageTitle: " PB Disciplinary ",
    component: PB_Disciplinary,
  },
  {
    path: "/pb-actions",
    name: " pb-actions ",
    exact: true,
    pageTitle: " PB Actions ",
    component: PB_Actions,
  },
  {
    path: "/pb-resolutions",
    name: " pb-resolutions ",
    exact: true,
    pageTitle: " PB Resolutions ",
    component: PB_Resolutions,
  },
  {
    path: "/pb-messaging",
    name: " pb-messaging ",
    exact: true,
    pageTitle: " PB Messaging ",
    component: PB_Messaging,
  },
  {
    path: "/pb-payments-and-commissions",
    name: " pb-payments-and-commissions ",
    exact: true,
    pageTitle: " PB Payments and Commissions ",
    component: PB_Payments_and_Commissions,
  },
  {
    path: "/pb-leave-requests",
    name: " pb-leave-requests ",
    exact: true,
    pageTitle: " PB Leave Requests ",
    component: PB_Leave_Requests,
  },
  {
    path: "/pb-manage-leaves",
    name: " pb-manage-leaves ",
    exact: true,
    pageTitle: " PB Manage Leaves ",
    component: PB_Manage_Leaves,
  },
  {
    path: "/pb-leaves-type",
    name: " pb-leaves-type ",
    exact: true,
    pageTitle: " PB Leaves Type ",
    component: PB_Leaves_Type,
  },
  {
    path: "/pb-settings",
    name: " pb-settings ",
    exact: true,
    pageTitle: " PB Settings ",
    component: PB_Settings,
  },
  {
    path: "/fe-cases",
    name: " fe-cases ",
    exact: true,
    pageTitle: " FE Cases ",
    component: FE_Cases,
  },
  {
    path: "/fe-assigned",
    name: " fe-assigned ",
    exact: true,
    pageTitle: " FE Assigned ",
    component: FE_Assigned,
  },
  {
    path: "/fe-managed",
    name: " fe-managed ",
    exact: true,
    pageTitle: " FE Managed ",
    component: FE_Managed,
  },
  {
    path: "/fe-reassigned",
    name: " fe-reassigned ",
    exact: true,
    pageTitle: " FE Reassigned ",
    component: FE_Reassigned,
  },
  {
    path: "/fe-disciplinary",
    name: " fe-disciplinary ",
    exact: true,
    pageTitle: " FE Disciplinary ",
    component: FE_Disciplinary,
  },
  {
    path: "/fe-actions",
    name: " fe-actions ",
    exact: true,
    pageTitle: " FE Actions ",
    component: FE_Actions,
  },
  {
    path: "/fe-resolutions",
    name: " fe-resolutions ",
    exact: true,
    pageTitle: " FE Resolutions ",
    component: FE_Resolutions,
  },
  {
    path: "/fe-messaging",
    name: " fe-messaging ",
    exact: true,
    pageTitle: " FE Messaging ",
    component: FE_Messaging,
  },
  {
    path: "/fe-payments-and-commissions",
    name: " fe-payments-and-commissions ",
    exact: true,
    pageTitle: " FE Payments and Commissions ",
    component: FE_Payments_and_Commissions,
  },
  {
    path: "/fe-leave-requests",
    name: " fe-leave-requests ",
    exact: true,
    pageTitle: " FE Leave Requests ",
    component: FE_Leave_Requests,
  },
  {
    path: "/fe-manage-leaves",
    name: " fe-manage-leaves ",
    exact: true,
    pageTitle: " FE Manage Leaves ",
    component: FE_Manage_Leaves,
  },
  {
    path: "/fe-leaves-type",
    name: " fe-leaves-type ",
    exact: true,
    pageTitle: " FE Leaves Type ",
    component: FE_Leaves_Type,
  },
  {
    path: "/fe-settings",
    name: " fe-settings ",
    exact: true,
    pageTitle: " FE Settings ",
    component: FE_Settings,
  },
  {
    path: "/tc-tracing",
    name: " tc-tracing",
    exact: true,
    pageTitle: " TC Tracing",
    component: TC_Tracing,
  },
  {
    path: "/tc-identifying",
    name: " tc-identifying",
    exact: true,
    pageTitle: " TC Identifying",
    component: TC_Identifying,
  },
  {
    path: "/tc-search",
    name: " tc-search",
    exact: true,
    pageTitle: " TC Search",
    component: TC_Search,
  },
  {
    path: "/tc-results",
    name: " tc-results",
    exact: true,
    pageTitle: " TC Results",
    component: TC_Results,
  },
  {
    path: "/tc-cases",
    name: " tc-cases",
    exact: true,
    pageTitle: " TC Cases",
    component: TC_Cases,
  },
  {
    path: "/tc-assigned",
    name: " tc-assigned",
    exact: true,
    pageTitle: " TC Assigned",
    component: TC_Assigned,
  },
  {
    path: "/tc-managed",
    name: " tc-managed",
    exact: true,
    pageTitle: "TC Managed",
    component: TC_Managed,
  },
  {
    path: "/tc-reassigned",
    name: " tc-reassigned",
    exact: true,
    pageTitle: "TC Reassigned",
    component: TC_Reassigned,
  },
  {
    path: "/tc-disciplinary",
    name: " tc-disciplinary",
    exact: true,
    pageTitle: "TC Disciplinary",
    component: TC_Disciplinary,
  },
  {
    path: "/tc-actions",
    name: " tc-actions",
    exact: true,
    pageTitle: "TC Actions",
    component: TC_Actions,
  },
  {
    path: "/tc-resolutions",
    name: " tc-resolutions",
    exact: true,
    pageTitle: "TC Resolutions",
    component: TC_Resolutions,
  },
  {
    path: "/tc-messaging",
    name: " tc-messaging",
    exact: true,
    pageTitle: "TC Messaging",
    component: TC_Messaging,
  },
  {
    path: "/tc-payments-and-commissions",
    name: " tc-payments-and-commissions",
    exact: true,
    pageTitle: "TC Payments and Commissions",
    component: TC_Payments_and_Commissions,
  },
  {
    path: "/tc-leave-requests",
    name: " tc-leave-requests",
    exact: true,
    pageTitle: "TC Leave Requests",
    component: TC_Leave_Requests,
  },
  {
    path: "/tc-manage-leaves",
    name: " tc-manage-leaves",
    exact: true,
    pageTitle: "TC Manage Leaves",
    component: TC_Manage_Leaves,
  },
  {
    path: "/tc-leaves-type",
    name: " tc-leaves-type",
    exact: true,
    pageTitle: "TC Leaves Type",
    component: TC_Leaves_Type,
  },
  {
    path: "/tc-settings",
    name: " tc-settings",
    exact: true,
    pageTitle: "TC Settings",
    component: TC_Settings,
  },
  {
    path: "/coh-operations",
    name: " coh-operations",
    exact: true,
    pageTitle: "COH Operations",
    component: COH_Operations,
  },
  {
    path: "/coh-resources",
    name: " coh-resources",
    exact: true,
    pageTitle: "COH Resources",
    component: COH_Resources,
  },
  {
    path: "/coh-finances",
    name: " coh-finances",
    exact: true,
    pageTitle: "COH Finances",
    component: COH_Finances,
  },
  {
    path: "/coh-messaging",
    name: " coh-messaging",
    exact: true,
    pageTitle: "COH Messaging",
    component: COH_Messaging,
  },
  {
    path: "/coh-maps",
    name: " coh-maps",
    exact: true,
    pageTitle: "COH Maps",
    component: COH_Maps,
  },
  {
    path: "/coh-payments-and-commissions",
    name: " coh-payments-and-commissions",
    exact: true,
    pageTitle: "COH Payments and Commissions",
    component: COH_Payments_and_Commissions,
  },
  {
    path: "/coh-process-based",
    name: " coh-process-based",
    exact: true,
    pageTitle: "COH Process based",
    component: COH_Process_based,
  },
  {
    path: "/coh-total-payments",
    name: " coh-total-payments",
    exact: true,
    pageTitle: "COH Total Payments",
    component: COH_Total_Payments,
  },
  {
    path: "/coh-settings",
    name: " coh-settings",
    exact: true,
    pageTitle: "COH Settings",
    component: COH_Settings,
  },
  {
    path: "/zoh-operations",
    name: " zoh-operations",
    exact: true,
    pageTitle: "ZOH Operations",
    component: ZOH_Operations,
  },
  {
    path: "/zoh-resources",
    name: " zoh-resources",
    exact: true,
    pageTitle: "ZOH Resources",
    component: ZOH_Resources,
  },
  {
    path: "/zoh-finances",
    name: " zoh-finances",
    exact: true,
    pageTitle: "ZOH Finances",
    component: ZOH_Finances,
  },
  {
    path: "/zoh-messaging",
    name: " zoh-messaging",
    exact: true,
    pageTitle: "ZOH Messaging",
    component: ZOH_Messaging,
  },
  {
    path: "/zoh-maps",
    name: " zoh-maps",
    exact: true,
    pageTitle: "ZOH Maps",
    component: ZOH_Maps,
  },
  {
    path: "/zoh-payments-and-commissions",
    name: " zoh-payments-and-commissions",
    exact: true,
    pageTitle: "ZOH Payments and Commissions",
    component: ZOH_Payments_and_Commissions,
  },
  {
    path: "/zoh-process-based",
    name: " zoh-process-based",
    exact: true,
    pageTitle: "ZOH Process based",
    component: ZOH_Process_based,
  },
  {
    path: "/zoh-total-payments",
    name: " zoh-total-payments",
    exact: true,
    pageTitle: "ZOH Total Payments",
    component: ZOH_Total_Payments,
  },
  {
    path: "/zoh-settings",
    name: " zoh-settings",
    exact: true,
    pageTitle: "ZOH Settings",
    component: ZOH_Settings,
  },
  {
    path: "/soh-operations",
    name: " soh-operations",
    exact: true,
    pageTitle: "SOH Operations",
    component: SOH_Operations,
  },
  {
    path: "/soh-resources",
    name: " soh-resources",
    exact: true,
    pageTitle: "SOH Resources",
    component: SOH_Resources,
  },
  {
    path: "/soh-finances",
    name: " soh-finances",
    exact: true,
    pageTitle: "SOH Finances",
    component: SOH_Finances,
  },
  {
    path: "/soh-messaging",
    name: " soh-messaging",
    exact: true,
    pageTitle: "SOH Messaging",
    component: SOH_Messaging,
  },
  {
    path: "/soh-maps",
    name: " soh-maps",
    exact: true,
    pageTitle: "SOH Maps",
    component: SOH_Maps,
  },
  {
    path: "/soh-payments-and-commissions",
    name: " soh-payments-and-commissions",
    exact: true,
    pageTitle: "SOH Payments and Commissions",
    component: SOH_Payments_and_Commissions,
  },
  {
    path: "/soh-process-based",
    name: " soh-process-based",
    exact: true,
    pageTitle: "SOH Process based",
    component: SOH_Process_based,
  },
  {
    path: "/soh-total-payments",
    name: " soh-total-payments",
    exact: true,
    pageTitle: "SOH Total Payments",
    component: SOH_Total_Payments,
  },
  {
    path: "/soh-settings",
    name: " soh-settings",
    exact: true,
    pageTitle: "SOH Settings",
    component: SOH_Settings,
  },
  {
    path: "/roh-operations",
    name: " roh-operations",
    exact: true,
    pageTitle: "ROH Operations",
    component: ROH_Operations,
  },
  {
    path: "/roh-resources",
    name: " roh-resources",
    exact: true,
    pageTitle: "ROH Resources",
    component: ROH_Resources,
  },
  {
    path: "/roh-finances",
    name: " roh-finances",
    exact: true,
    pageTitle: "ROH Finances",
    component: ROH_Finances,
  },
  {
    path: "/roh-messaging",
    name: " roh-messaging",
    exact: true,
    pageTitle: "ROH Messaging",
    component: ROH_Messaging,
  },
  {
    path: "/roh-maps",
    name: " roh-maps",
    exact: true,
    pageTitle: "ROH Maps",
    component: ROH_Maps,
  },
  {
    path: "/roh-payments-and-commissions",
    name: " roh-payments-and-commissions",
    exact: true,
    pageTitle: "ROH Payments and Commissions",
    component: ROH_Payments_and_Commissions,
  },
  {
    path: "/roh-process-based",
    name: " roh-process-based",
    exact: true,
    pageTitle: "ROH Process based",
    component: ROH_Process_based,
  },
  {
    path: "/roh-total-payments",
    name: " roh-total-payments",
    exact: true,
    pageTitle: "ROH Total Payments",
    component: ROH_Total_Payments,
  },
  {
    path: "/roh-settings",
    name: " roh-settings",
    exact: true,
    pageTitle: "ROH Settings",
    component: ROH_Settings,
  },
  {
    path: "/dhbh-operations",
    name: " dhbh-operations",
    exact: true,
    pageTitle: "DHBH Operations",
    component: DHBH_Operations,
  },
  {
    path: "/dhbh-resources",
    name: " dhbh-resources",
    exact: true,
    pageTitle: "DHBH Resources",
    component: DHBH_Resources,
  },
  {
    path: "/dhbh-finances",
    name: " dhbh-finances",
    exact: true,
    pageTitle: "DHBH Finances",
    component: DHBH_Finances,
  },
  {
    path: "/dhbh-messaging",
    name: " dhbh-messaging",
    exact: true,
    pageTitle: "DHBH Messaging",
    component: DHBH_Messaging,
  },
  {
    path: "/dhbh-maps",
    name: " dhbh-maps",
    exact: true,
    pageTitle: "DHBH Maps",
    component: DHBH_Maps,
  },
  {
    path: "/dhbh-payments-and-commissions",
    name: " dhbh-payments-and-commissions",
    exact: true,
    pageTitle: "DHBH Payments and Commissions",
    component: DHBH_Payments_and_Commissions,
  },
  {
    path: "/dhbh-process-based",
    name: " dhbh-process-based",
    exact: true,
    pageTitle: "DHBH Process based",
    component: DHBH_Process_based,
  },
  {
    path: "/dhbh-total-payments",
    name: " dhbh-total-payments",
    exact: true,
    pageTitle: "DHBH Total Payments",
    component: DHBH_Total_Payments,
  },
  {
    path: "/dhbh-settings",
    name: " dhbh-settings",
    exact: true,
    pageTitle: "DHBH Settings",
    component: DHBH_Settings,
  },
  {
    path: "/cop-operations",
    name: " cop-operations",
    exact: true,
    pageTitle: "COP Operations",
    component: COP_Operations,
  },
  {
    path: "/cop-resources",
    name: " cop-resources",
    exact: true,
    pageTitle: "COP Resources",
    component: COP_Resources,
  },
  {
    path: "/cop-finances",
    name: " cop-finances",
    exact: true,
    pageTitle: "COP Finances",
    component: COP_Finances,
  },
  {
    path: "/cop-messaging",
    name: " cop-messaging",
    exact: true,
    pageTitle: "COP Messaging",
    component: COP_Messaging,
  },
  {
    path: "/cop-maps",
    name: " cop-maps",
    exact: true,
    pageTitle: "COP Maps",
    component: COP_Maps,
  },
  {
    path: "/cop-payments-and-commissions",
    name: " cop-payments-and-commissions",
    exact: true,
    pageTitle: "COP Payments and Commissions",
    component: COP_Payments_and_Commissions,
  },
  {
    path: "/cop-process-based",
    name: " cop-process-based",
    exact: true,
    pageTitle: "COP Process based",
    component: COP_Process_based,
  },
  {
    path: "/cop-total-payments",
    name: " cop-total-payments",
    exact: true,
    pageTitle: "COP Total Payments",
    component: COP_Total_Payments,
  },
  {
    path: "/cop-settings",
    name: " cop-settings",
    exact: true,
    pageTitle: "COP Settings",
    component: COP_Settings,
  },
  {
    path: "/cua-resources",
    name: " cua-resources",
    exact: true,
    pageTitle: "CuA Resources",
    component: CuA_Resources,
  },
  {
    path: "/cua-asserts",
    name: " cua-asserts",
    exact: true,
    pageTitle: "CuA Asserts",
    component: CuA_Asserts,
  },
  {
    path: "/cua-users",
    name: " cua-users",
    exact: true,
    pageTitle: "CuA Users",
    component: CuA_Users,
  },
  {
    path: "/cua-country-reports",
    name: " cua-country-reports",
    exact: true,
    pageTitle: "CuA Country Reports",
    component: CuA_Country_Reports,
  },
  {
    path: "/cua-settings",
    name: " cua-settings",
    exact: true,
    pageTitle: "CuA Settings",
    component: CuA_Settings,
  },
  {
    path: "/sa-resources",
    name: " sa-resources",
    exact: true,
    pageTitle: "SA Resources",
    component: SA_Resources,
  },
  {
    path: "/sa-asserts",
    name: " sa-asserts",
    exact: true,
    pageTitle: "SA Asserts",
    component: SA_Asserts,
  },
  {
    path: "/sa-users",
    name: " sa-users",
    exact: true,
    pageTitle: "SA Users",
    component: SA_Users,
  },
  {
    path: "/sa-state-reports",
    name: " sa-state-reports",
    exact: true,
    pageTitle: "SA State Reports",
    component: SA_State_Reports,
  },
  {
    path: "/sa-settings",
    name: " sa-settings",
    exact: true,
    pageTitle: "SA Settings",
    component: SA_Settings,
  },
  {
    path: "/coa-resources",
    name: " coa-resources",
    exact: true,
    pageTitle: "COA Resources",
    component: COA_Resources,
  },
  {
    path: "/coa-asserts",
    name: " coa-asserts",
    exact: true,
    pageTitle: "COA Asserts",
    component: COA_Asserts,
  },
  {
    path: "/coa-users",
    name: " coa-users",
    exact: true,
    pageTitle: "COA Users",
    component: COA_Users,
  },
  {
    path: "/coa-company-reports",
    name: " coa-company-reports",
    exact: true,
    pageTitle: "COA Company Reports",
    component: COA_Company_Reports,
  },
  {
    path: "/coa-settings",
    name: " coa-settings",
    exact: true,
    pageTitle: "COA Settings",
    component: COA_Settings,
  },
  {
    path: "/sya-codes-dp",
    name: " sya-codes-dp",
    exact: true,
    pageTitle: "SyA Codes DP",
    component: SyA_Codes_DP,
  },
  {
    path: "/sya-contactable",
    name: " sya-contactable",
    exact: true,
    pageTitle: "SyA Contactable",
    component: SyA_Contactable,
  },
  {
    path: "/sya-non-contactable",
    name: " sya-non-contactable",
    exact: true,
    pageTitle: "SyA Non Contactable",
    component: SyA_Non_Contactable,
  },
  {
    path: "/sya-code-matching-list",
    name: " sya-code-matching-list",
    exact: true,
    pageTitle: "SyA Code Matching List",
    component: SyA_Code_Matching_List,
  },
  {
    path: "/sya-settings",
    name: " sya-settings",
    exact: true,
    pageTitle: "SyA Settings",
    component: SyA_Settings,
  },
  {
    path: "/sua-company",
    name: " sua-company",
    exact: true,
    pageTitle: "SuA Company",
    component: SuA_Company,
  },
  {
    path: "/sua-company-details",
    name: " sua-company-details",
    exact: true,
    pageTitle: "SuA Company Details",
    component: SuA_Company_Details,
  },
  {
    path: "/sua-backup-company-data",
    name: " sua-backup-company-data",
    exact: true,
    pageTitle: "SuA Backup company data",
    component: SuA_Backup_company_data,
  },
  {
    path: "/sua-restore-company-data",
    name: " sua-restore-company-data",
    exact: true,
    pageTitle: "SuA Restore company data",
    component: SuA_Restore_company_data,
  },
  {
    path: "/sua-basic",
    name: " sua-basic",
    exact: true,
    pageTitle: "SuA Basic",
    component: SuA_Basic,
  },
  {
    path: "/sua-currency",
    name: " sua-currency",
    exact: true,
    pageTitle: "SuA Currency",
    component: SuA_Currency,
  },
  {
    path: "/sua-language",
    name: " sua-language",
    exact: true,
    pageTitle: "SuA Language",
    component: SuA_Language,
  },
  {
    path: "/sua-taxes",
    name: " sua-taxes",
    exact: true,
    pageTitle: "SuA Taxes",
    component: SuA_Taxes,
  },
  {
    path: "/sua-system",
    name: " sua-system",
    exact: true,
    pageTitle: "SuA System",
    component: SuA_System,
  },
  {
    path: "/sua-log",
    name: " sua-log",
    exact: true,
    pageTitle: "SuA Log",
    component: SuA_Log,
  },
  {
    path: "/sua-location-log",
    name: " sua-location-log",
    exact: true,
    pageTitle: "SuA Location Log",
    component: SuA_Location_Log,
  },
  {
    path: "/sua-backup",
    name: " sua-backup",
    exact: true,
    pageTitle: "SuA Backup",
    component: SuA_Backup,
  },
  {
    path: "/sua-restore",
    name: " sua-restore",
    exact: true,
    pageTitle: "SuA restore",
    component: SuA_restore,
  },
  {
    path: "/sua-company-based-reports",
    name: " sua-company-based-reports",
    exact: true,
    pageTitle: "SuA Company based Reports",
    component: SuA_Company_based_Reports,
  },
  {
    path: "/sua-service-reports",
    name: " sua-service-reports",
    exact: true,
    pageTitle: "SuA Service Reports",
    component: SuA_Service_Reports,
  },
  {
    path: "/sua-maintenance-reports",
    name: " sua-maintenance-reports",
    exact: true,
    pageTitle: "SuA Maintenance Reports",
    component: SuA_Maintenance_Reports,
  },
  {
    path: "/sua-settings",
    name: " sua-settings",
    exact: true,
    pageTitle: "SuA Settings",
    component: SuA_Settings,
  },

  // dashboards list
  {
    path: "/hr-manager-dashboard",
    name: "hr-manager-dashboard",
    exact: true,
    pageTitle: "HR Manager Dashboard",
    component: HR_Manager_Dashboard,
  },
  {
    path: "/hr-executive-dashboard",
    name: "hr-executive-dashboard",
    exact: true,
    pageTitle: "HR Executive Dashboard",
    component: HR_Executive_Dashboard,
  },
  {
    path: "/training-head-dashboard",
    name: "training-head-dashboard",
    exact: true,
    pageTitle: "Training Head Dashboard",
    component: Training_Head_Dashboard,
  },
  {
    path: "/trainee-team-leader-dashboard",
    name: "trainee-team-leader-dashboard",
    exact: true,
    pageTitle: "Trainee Team Leader Dashboard",
    component: Trainee_Team_Leader_Dashboard,
  },
  {
    path: "/trainer-dashboard",
    name: "trainer-dashboard",
    exact: true,
    pageTitle: "Trainer Dashboard",
    component: Trainer_Dashboard,
  },
  {
    path: "/office-admin-dashboard",
    name: "office-admin-dashboard",
    exact: true,
    pageTitle: "Office Admin Dashboard",
    component: Office_Admin_Dashboard,
  },
  {
    path: "/finance-manager-dashboard",
    name: "finance-manager-dashboard",
    exact: true,
    pageTitle: "Finance Manager Dashboard",
    component: Finance_Manager_Dashboard,
  },
  {
    path: "/accounts-head-dashboard",
    name: "accounts-head-dashboard",
    exact: true,
    pageTitle: "Accounts Head Dashboard",
    component: Accounts_Head_Dashboard,
  },
  {
    path: "/accounts-executive-dashboard",
    name: "accounts-executive-dashboard",
    exact: true,
    pageTitle: "Accounts Executive Dashboard",
    component: Accounts_Executive_Dashboard,
  },
  {
    path: "/accounts-clerk-dashboard",
    name: "accounts-clerk-dashboard",
    exact: true,
    pageTitle: "Accounts Clerk Dashboard",
    component: Accounts_Clerk_Dashboard,
  },
  {
    path: "/it-head-dashboard",
    name: "it-head-dashboard",
    exact: true,
    pageTitle: "IT Head Dashboard",
    component: IT_Head_Dashboard,
  },
  {
    path: "/it-manager-dashboard",
    name: "it-manager-dashboard",
    exact: true,
    pageTitle: "IT Manager Dashboard",
    component: IT_Manager_Dashboard,
  },
  {
    path: "/asserts-manager-hw-administrator-dashboard",
    name: "asserts-manager-hw-administrator-dashboard",
    exact: true,
    pageTitle: "Asserts Manager HW Administrator Dashboard",
    component: Asserts_Manager_HW_Administrator_Dashboard,
  },
  {
    path: "/administrator-dashboard",
    name: "administrator-dashboard",
    exact: true,
    pageTitle: "Administrator Dashboard",
    component: Administrator_Dashboard,
  },
  {
    path: "/mis-manager-dashboard",
    name: "mis-manager-dashboard",
    exact: true,
    pageTitle: "MIS Manager Dashboard",
    component: MIS_Manager_Dashboard,
  },
  {
    path: "/mis-executive-dashboard",
    name: "mis-executive-dashboard",
    exact: true,
    pageTitle: "MIS Executive Dashboard",
    component: MIS_Executive_Dashboard,
  },
  {
    path: "/process-head-dashboard",
    name: "process-head-dashboard",
    exact: true,
    pageTitle: "Process Head Dashboard",
    component: Process_Head_Dashboard,
  },
  {
    path: "/process-manager-dashboard",
    name: "process-manager-dashboard",
    exact: true,
    pageTitle: "Process Manager Dashboard",
    component: Process_Manager_Dashboard,
  },
  {
    path: "/team-leader-field-executives-dashboard",
    name: "team-leader-field-executives-dashboard",
    exact: true,
    pageTitle: "Team Leader Field Executives Dashboard",
    component: Team_Leader_Field_Executives_Dashboard,
  },
  {
    path: "/team-leader-phone-bankers-dashboard",
    name: "team-leader-phone-bankers-dashboard",
    exact: true,
    pageTitle: "Team Leader Phone Bankers Dashboard",
    component: Team_Leader_Phone_Bankers_Dashboard,
  },
  {
    path: "/team-leader-tracers-dashboard",
    name: "team-leader-tracers-dashboard",
    exact: true,
    pageTitle: "Team Leader Tracers Dashboard",
    component: Team_Leader_Tracers_Dashboard,
  },
  {
    path: "/phone-banker-dashboard",
    name: "phone-banker-dashboard",
    exact: true,
    pageTitle: "Phone Banker Dashboard",
    component: Phone_Banker_Dashboard,
  },
  {
    path: "/field-executive-dashboard",
    name: "field-executive-dashboard",
    exact: true,
    pageTitle: "Field Executive Dashboard",
    component: Field_Executive_Dashboard,
  },
  {
    path: "/tracers-dashboard",
    name: "tracers-dashboard",
    exact: true,
    pageTitle: "Tracers Dashboard",
    component: Tracers_Dashboard,
  },
  {
    path: "/country-operations-head-dashboard",
    name: "country-operations-head-dashboard",
    exact: true,
    pageTitle: "Country Operations Head Dashboard",
    component: Country_Operations_Head_Dashboard,
  },
  {
    path: "/zone-operations-head-dashboard",
    name: "zone-operations-head-dashboard",
    exact: true,
    pageTitle: "Zone Operations Head Dashboard",
    component: Zone_Operations_Head_Dashboard,
  },
  {
    path: "/state-operations-head-dashboard",
    name: "state-operations-head-dashboard",
    exact: true,
    pageTitle: "State Operations Head Dashboard",
    component: State_Operations_Head_Dashboard,
  },
  {
    path: "/regional-oper-head-dashboard",
    name: "regional-oper-head-dashboard",
    exact: true,
    pageTitle: "Regional Oper Head Dashboard",
    component: Regional_Oper_Head_Dashboard,
  },
  {
    path: "/district-head-branch-head-dashboard",
    name: "district-head-branch-head-dashboard",
    exact: true,
    pageTitle: "District Head Branch Head Dashboard",
    component: District_Head_Branch_Head_Dashboard,
  },
  {
    path: "/company-owner-partner-dashboard",
    name: "company-owner-partner-dashboard",
    exact: true,
    pageTitle: "Company Owner Partner Dashboard",
    component: Company_Owner_Partner_Dashboard,
  },
  {
    path: "/country-administrator-dashboard",
    name: "country-administrator-dashboard",
    exact: true,
    pageTitle: "Country Administrator Dashboard",
    component: Country_Administrator_Dashboard,
  },
  {
    path: "/state-administrator-dashboard",
    name: "state-administrator-dashboard",
    exact: true,
    pageTitle: "State Administrator Dashboard",
    component: State_Administrator_Dashboard,
  },
  {
    path: "/company-administrator-dashboard",
    name: "company-administrator-dashboard",
    exact: true,
    pageTitle: "Company Administrator Dashboard",
    component: Company_Administrator_Dashboard,
  },
  {
    path: "/system-administrator-dashboard",
    name: "system-administrator-dashboard",
    exact: true,
    pageTitle: "System Administrator Dashboard",
    component: System_Administrator_Dashboard,
  },
  {
    path: "/super-administrator-dashboard",
    name: "super-administrator-dashboard",
    exact: true,
    pageTitle: "Super Administrator Dashboard",
    component: Super_Administrator_Dashboard,
  },
];

export default Routes;
