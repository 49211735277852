import React from 'react'

function Out_Of_Stock() {
  return (
    <div>
      Out_Of_Stock
Out_Of_Stock
    </div>
  )
}

export default Out_Of_Stock
