import React from 'react'

function Actions() {
  return (
    <div>
      Actions
Actions
    </div>
  )
}

export default Actions
