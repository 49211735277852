import React from 'react'

function assert_Category() {
  return (
    <div>
      assert_Category
assert_Category
    </div>
  )
}

export default assert_Category
